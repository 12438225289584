import React from 'react';
import PropTypes from 'prop-types';

const LandingItem = ({
  title, subtitle, children, titlePaddingBottom,
  curBlock, changeCurBlock, identifier,
}) => {
  return (
    <div className="sub-item">
      <h2
        onClick={() => changeCurBlock(identifier)}
        style={{
          paddingBottom: titlePaddingBottom,
        }}
      >
        {title}
      </h2>
      <p className={identifier === curBlock ? 'show' : ''}><strong>{subtitle}</strong></p>
      <p className={identifier === curBlock ? 'show' : ''}>{children}</p>
    </div>
  )
}

LandingItem.propTypes = {
  identifier: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  titlePaddingBottom: PropTypes.number,
  curBlock: PropTypes.string,
  changeCurBlock: PropTypes.func,
}

LandingItem.defaultPros = {
  titlePaddingBottom: 0,
}

export default LandingItem;
