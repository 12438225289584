import React from 'react';

import {
  // Button,
  Container,
  Row,
  Col,
} from "reactstrap";

const UpgradeFailure = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Failure!</h2>
        </Col>
      </Row>
      <Row>
        <p>activate membership failed</p>
      </Row>
    </Container>
  )
}

export default UpgradeFailure;
