import React, { useState } from 'react';

import {
  Button,
  NavLink,
} from "reactstrap";

import PropTypes from 'prop-types';
import classnames from "classnames";

import ConfirmModal from '../../modals/ConfirmModal';

const MemberInvitedItem = ({
  member,
  onUserReinvited,
}) => {
  const { name, email, user_id: userId } = member;
  const [ isModalShown, setIsModalShown ] = useState(false);

  const onInviteYes = () => {
    onUserReinvited(userId).then(() => {
      setIsModalShown(false);
    })
  }

  return (<>
    <div className="datasource-wrap">
      <div className="drop-menu">
          <NavLink
            className={classnames("mb-sm-3 mb-md-0")}
            role="tab"
          >
            {name ? name : email.split('@')[0]}
          </NavLink>

          <div className="invited--status">invited</div>
          <div className="btn-wrap">
            <Button color="info" type="button" onClick={() => setIsModalShown(true)}>
              re-invite
            </Button>
          </div>
      </div>
    </div>
    <ConfirmModal
      description={'are you sure you want to reinvite this user?'}
      isOpen={isModalShown}
      onYes={onInviteYes}
      onNo={() => setIsModalShown(false)}
      onToggle={() => {setIsModalShown(!isModalShown)}} />
  </>)
}

MemberInvitedItem.propTypes = {
  name: PropTypes.string,
}

MemberInvitedItem.defaultProps = {
  name: 'Untitled',
}

export default MemberInvitedItem;
