import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import API from 'helpers/api';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const SessionEmbed = () => {
    const [loadingEmbedUrl, setLoadingEmbedUrl] = useState(true);
    // const [error, setError] = useState('');
    const { analysisId } = useParams();

    useEffect(() => {
        setLoadingEmbedUrl(true);
        API.getSessionEmbedUrl({analysisId})
        .then(res => {
            const { url } = res;
            const containerDiv = document.getElementById("dashboardContainer");
            if (containerDiv.firstChild) {
                containerDiv.removeChild(containerDiv.firstChild);
            }
    
            const options = {
                url,
                container: containerDiv,
                parameters: {
                    country: "United States"
                },
                scrolling: "no",
                height: "100%",
                width: "100%",
                footerPaddingEnabled: false,
            };
            QuickSightEmbedding.embedDashboard(options);
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setLoadingEmbedUrl(false);
        })
    }, [
        setLoadingEmbedUrl,  // selectedDashboard
        analysisId,
    ]);
    
    return (
    <>
        {/* {error && <h4>{error}</h4>} */}
        {loadingEmbedUrl && <h2>Loading author dashboard... </h2>}
        <div className='dashboardContainer' id='dashboardContainer'></div>
    </>);
}

export default SessionEmbed;
