import createReducer from '../../redux/helpers/createReducer';

import {
  namespace,
  fetchFamilyOptionsRequest, fetchFamilyOptionsSuccess, fetchFamilyOptionsError,
  setDatasetMember, removeDatasetMember,
  addSpreadsIndex, updateSpreadsIndex, removeSpreadsIndex,
  addCustomIndex, updateCustomIndex, removeCustomIndex,
  fetchDatasetsRequest, fetchDatasetsSuccess, fetchDatasetsError,
  retrieveDatasetRequest, retrieveDatasetSuccess, retrieveDatasetFailure,
  updateDatasetRequest, updateDatasetSuccess, updateDatasetFailure,
  addItemToDatasetRequest, addItemToDatasetSuccess, addItemToDatasetFailure,
  removeItemFromDatasetRequest, removeItemFromDatasetSuccess, removeItemFromDatasetFailure,
  createDatasetSuccess, clearDatasetDraft, updateCustomIndexAttributes, updateSelectedCustomIndex,
} from './actions';

const defaultState = {
  familyOptions: null,
  fetchingFamilyOptions: false,
  fetchingFamilyOptionsError: null,
  datasetBuilderDraft: {
    members: {},
    spreads: [],
    custom: [{
      alias: 'custom',
      func: '',
      cursor: 0,
      isSelected: false,
    }],
  },
  datasets: [],
  fetchingDatasets: false,
  curDataset: {},
  retrievingDataset: false,
  updatingDataset: false,
  addingItemToDataset: false,
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
  [fetchFamilyOptionsRequest.Type]: (state, action) => ({
    ...state,
    fetchingFamilyOptions: true,
    fetchingFamilyOptionsError: null,
  }),
  [fetchFamilyOptionsSuccess.Type]: (state, { payload }) => ({
    ...state,
    fetchingFamilyOptions: false,
    familyOptions: payload.familyOptions,
  }),
  [fetchFamilyOptionsError.Type]: (state, { payload }) => ({
    ...state,
    fetchingFamilyOptions: true,
    fetchingFamilyOptionsError: payload.error,
  }),
  [clearDatasetDraft.Type]: (state, action) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      members: {},
      spreads: [],
      custom: [],
    },
  }),
  [setDatasetMember.Type]: (state, action: { payload: {familyId: string, member: Object, index: number}}) => {
    const originalMembers = state.datasetBuilderDraft.members[action.payload.familyId] || [];
    return {
      ...state,
      datasetBuilderDraft: {
        ...state.datasetBuilderDraft,
        members: {
          ...state.datasetBuilderDraft.members,
          [action.payload.familyId]: [
            ...originalMembers.slice(0, action.payload.index),
            action.payload.member,
            ...originalMembers.slice(action.payload.index + 1),
          ]
        }
      }
    }
  },
  [removeDatasetMember.Type]: (state, action: { payload: {familyId: string, index: number}}) => {
    return {
      ...state,
      datasetBuilderDraft: {
        ...state.datasetBuilderDraft,
        members: {
          ...state.datasetBuilderDraft.members,
          [action.payload.familyId]: [
            ...state.datasetBuilderDraft.members[action.payload.familyId].slice(0, action.payload.index),
            ...state.datasetBuilderDraft.members[action.payload.familyId].slice(action.payload.index + 1),
          ]
        }
      }
    }
  },
  [addSpreadsIndex.Type]: (state, action: { payload: {left: Object, right: Object} }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      spreads: [
        ...state.datasetBuilderDraft.spreads,
        action.payload,
      ]
    }
  }),
  [updateSpreadsIndex.Type]: (state, action: { payload: {index: number, spreadsIndex: {left: Object, right: Object}} }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      spreads: [
        ...state.datasetBuilderDraft.spreads.slice(0, action.payload.index),
        action.payload.spreadsIndex,
        ...state.datasetBuilderDraft.spreads.slice(action.payload.index + 1),
      ]
    }
  }),
  [removeSpreadsIndex.Type]: (state, action: { payload: {index: number}}) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      spreads: [
        ...state.datasetBuilderDraft.spreads.slice(0, action.payload.index),
        ...state.datasetBuilderDraft.spreads.slice(action.payload.index + 1),
      ]
    }
  }),
  [addCustomIndex.Type]: (state, action: { payload: {
    id: String,
    alias: String,
    func: String,
    isSelected: Boolean,
    cursor: Number,
  } }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      custom: [
        ...state.datasetBuilderDraft.custom,
        action.payload,
      ]
    }
  }),
  [updateCustomIndex.Type]: (state, action: { payload: {
    id: String, customIndex: {
      id: String,
      alias: String,
      func: String,
      isSelected: Boolean,
      cursor: Number,
    }
  } }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      custom: state.datasetBuilderDraft.custom.map(item => {
        const { id } = item;
        const { id: updatedId, customIndex: updatedIndex } = action.payload;

        if (id === updatedId) {
          return updatedIndex
        } else {
          return item;
        }
      }),
    }
  }),
  [updateCustomIndexAttributes.Type]: (state, action: { payload: {
    id: String, attributes: Object,
  } }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      custom: state.datasetBuilderDraft.custom.map(item => {
        const { id } = item;
        const { id: updatedId, attributes, } = action.payload;

        if (id === updatedId) {
          return {
            ...item,
            ...attributes,
          }
        } else {
          return item;
        }
      }),
    }
  }),
  [updateSelectedCustomIndex.Type]: (state, action: { payload: {
    id: String, isSelected: Boolean,
  } }) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      custom: state.datasetBuilderDraft.custom.map(item => {
        const { id } = item;
        const { id: updatedId, isSelected, } = action.payload;

        if (id === updatedId) {
          return {
            ...item,
            isSelected,
          }
        } else {
          return {
            ...item,
            isSelected: false,
          };
        }
      }),
    }
  }),
  [removeCustomIndex.Type]: (state, action: { payload: {id: String}}) => ({
    ...state,
    datasetBuilderDraft: {
      ...state.datasetBuilderDraft,
      custom: state.datasetBuilderDraft.custom.filter(({id}) => id !== action.payload.id)
    }
  }),
  [createDatasetSuccess.Type]: (state, action) => ({
    ...state,
    datasetBuilderDraft: defaultState.datasetBuilderDraft,
  }),
  [fetchDatasetsRequest.Type]: (state, action) => ({
    ...state,
    fetchingDatasets: true,
  }),
  [fetchDatasetsSuccess.Type]: (state, action: { payload: { datasets: Array<Object> } }) => ({
    ...state,
    fetchingDatasets: false,
    datasets: action.payload.datasets,
  }),
  [fetchDatasetsError.Type]: (state, action) => ({
    ...state,
    fetchingDatasets: false,
  }),
  [retrieveDatasetRequest.Type]: (state, action) => ({
    ...state,
    retrievingDataset: true,
    curDataset: {},
  }),
  [retrieveDatasetSuccess.Type]: (state, { payload }) => ({
    ...state,
    retrievingDataset: false,
    curDataset: payload,
    datasetBuilderDraft: {
      spreads: payload.spreads,
      custom: payload.custom,
      members: payload.datasetItems.reduce((result, instance) => {
        result[instance.product_id]=instance.members.map(item => ({
          ...item,
          contract_line_num: item.contract_line_num || 1,
          tenor: item.tenor || '',
        }));
        return result;
      }, {})
    }
  }),
  [retrieveDatasetFailure.Type]: (state, action) => ({
    ...state,
    retrievingDataset: false,
    curDataset: {},
  }),
  [updateDatasetRequest.Type]: (state, action) => ({
    ...state,
    updatingDataset: true,
    // curDataset: {},
  }),
  [updateDatasetSuccess.Type]: (state, { payload }) => ({
    ...state,
    updatingDataset: false,
    // curDataset: payload,
  }),
  [updateDatasetFailure.Type]: (state, action) => ({
    ...state,
    updatingDataset: false,
    // curDataset: {},
  }),
  [addItemToDatasetRequest.Type]: (state, action) => ({
    ...state,
    addingItemToDataset: true,
  }),
  [addItemToDatasetSuccess.Type]: (state, { payload }) => ({
    ...state,
    addingItemToDataset: false,
    curDataset: payload,
  }),
  [addItemToDatasetFailure.Type]: (state, action) => ({
    ...state,
    addingItemToDataset: false,
  }),
  [removeItemFromDatasetRequest.Type]: (state, action) => ({
    ...state,
    addingItemToDataset: true,
  }),
  [removeItemFromDatasetSuccess.Type]: (state, { payload }) => ({
    ...state,
    addingItemToDataset: false,
    curDataset: payload,
  }),
  [removeItemFromDatasetFailure.Type]: (state, action) => ({
    ...state,
    addingItemToDataset: false,
  }),
})

export default reducer
export {
  namespace,
}
