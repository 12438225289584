import React, { useState } from 'react';

import PropTypes from 'prop-types';

const AttributeSelect = ({
  value, options, onChange, size, disabled,
}) => {
  const [curVal, setCurVal] = useState(value);
  const onSelectVal = (value) => {
    setCurVal(value);
    onChange(value);
  }
  return (
    <>
      <div className="select-box ml--5">
        <select 
          value={curVal}
          disabled={disabled}
          className={size}
          onChange={(e) => onSelectVal(e.target.value)}
          >
          {options && options.map(({name, value}, idx) => 
            <option value={value} key={idx} defaultValue={curVal === value}>{name}</option>
          )}
        </select>
      </div>
    </>
  )
}

AttributeSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

AttributeSelect.defaultProps = {
  disabled: false,
}

export default AttributeSelect;
