import React from 'react';
import PropTypes from 'prop-types';

import { StringUtil } from 'libs/utils';

const CartItem = ({
  item, onRemoveFromCart, queryType,
}) => {
  const {
    product_id: productId,
    product_name: productName,
    subpublisher, publisher: exchangeGroup,
    // native_id
  } = item;

  const onRemoveYes = () => {
    onRemoveFromCart(productId, queryType);
  }

  return (
    <>
      <div className="cart--item" title={`subpublisher: ${subpublisher}(${exchangeGroup})`}>
        { StringUtil.truncate(productName, 40) }
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onRemoveYes()} >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
    </>
  )
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  queryType: PropTypes.string,
}

CartItem.defaultProps = {
  queryType: 'time_series',
}

export default CartItem;
