import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

// reactstrap components
import {
  Button,
  Container,
  Row, Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Nav,
  NavItem,
} from "reactstrap";
import FilterCondition from "components/molecules/filter/FilterCondition";
import ProductItem from "components/molecules/filter/ProductItem";
import CartItem from '../../components/molecules/filter/CartItem';
// import ConfirmModal from '../../components/modals/ConfirmModal';

import API from '../../helpers/api';

import {
  fetchFilterOptionsRequest,
  setSelectedFilterOptions,
  setIsLoading,
  setProducts,
  fetchCartRequest,
  addToCartRequest,
  removeFromCartRequest,
} from './actions'

function DataBuilder({
  isLoading, onLoadingUpdated,
  availableOptions, fetchFilterOptionsRequest,
  selectedOptions, onSelectedOptionsUpdated,
  products, onProductsUpdated,
  cartItems,
  fetchCartRequest,
  addToCartRequest,
  removeFromCartRequest,
  queryType,
}) {
  const [search, setSearch] = useState('');
  const [queryTimer, setQueryTimer] = useState(null);
  const history = useHistory();

  const {
    asset_classes: assetClassesOptions,
    publishers: sources,
    data_types: dataTypes,
    final_settlement_risks: risks,
    data_categories: riskCategories,
    publisher_classifications,
    sectors,
  } = availableOptions || {};

  const {
    asset_classes: selectedAssetClasses,
    publishers: selectedSources,
    data_types: selectedDataTypes,
    publisher_classifications: selectedSecurityTypes,
    data_categories: selectedRiskCategories,
    sectors: selectedSectors, 
    final_settlement_risks: selectedFinalSettlementRisks,
  } = selectedOptions;

  const { products: fetchedProducts, total } = products;

  useEffect(() => {
    fetchCartRequest(queryType);
  }, [fetchCartRequest, queryType])

  useEffect(() => {
    fetchFilterOptionsRequest(queryType);
  }, [fetchFilterOptionsRequest, queryType])

  const getProducts = useCallback(() => {
    API.filterProducts(selectedOptions, queryType).then(res => {
      onProductsUpdated(res);
    }).catch(err => {
      console.error(err);
    })
  }, [selectedOptions, onProductsUpdated, queryType])

  useEffect(() => {
    getProducts()
  }, [selectedOptions, getProducts]);

  const onOptionSelectionUpdated = (identifier, option, checked) => {
    const originalValue = selectedOptions[identifier] || []
    const updated = {
      ...selectedOptions,
      [identifier]: checked ? originalValue.concat([option]) : originalValue.filter(item => item !== option)
    }
    onSelectedOptionsUpdated(updated);
  }

  const resetFilters = () => {
    onSelectedOptionsUpdated({})
  }

  const updateSearchQuery = (value) => {
    onSelectedOptionsUpdated({
      ...selectedOptions,
      query: value
    });
    setQueryTimer(null);
  }

  const onSearchQueryUpdated = (e) => {
    setSearch(e.target.value);
    if (queryTimer !== null) {
      clearTimeout(queryTimer);
      setQueryTimer(null);
    }

    setQueryTimer(setTimeout(() => {updateSearchQuery(e.target.value)}, 1000));
  }

  return (
    <>
      <Container className="databuilder pt--20" fluid>
        <Row>
          <Col xl="2">
            <h2>build your dataset</h2>
            <p>{total === undefined ? 'Loading products...' : `${total} available products`}</p>

            <div className="reset--btn">
              <Button color="info" type="button" onClick={() => resetFilters()}>
                reset filters
              </Button>
            </div>

            <div className="filter_wrap">
              <FilterCondition
                identifier={'asset_classes'}
                name={'asset class'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedAssetClasses || []}
                items={assetClassesOptions || []} />

              <FilterCondition
                identifier={'sectors'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSectors || []}
                name={'sector'}
                items={sectors || []} />

              {/* <FilterCondition
                name={'location'}
                items={['north america', 'south america', 'europe', 'asia']} /> */}

              <FilterCondition
                identifier={'data_types'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedDataTypes || []}
                name={'data type'}
                items={dataTypes || []} />

              {/* <FilterCondition
                identifier={'final_settlement_risks'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedFinalSettlementRisks || []}
                name={'final risk'}
                items={risks || []} /> */}

              <FilterCondition
                identifier={'publisher_classifications'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSecurityTypes || []}
                name={'classification'}
                items={publisher_classifications || []} />
            </div>
          </Col>
          <Col xl="6">
            <FormGroup
              className={"mb-3 mt-5"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="narrow the results by adding search criteria"
                  type="text"
                  value={search}
                  onChange={onSearchQueryUpdated}
                />
              </InputGroup>
            </FormGroup>
            <div className="product--list">
              <strong>product list</strong> | click to add the product to your cart
            </div>
            <div>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="pr-4"><strong>view</strong></NavItem>
                <NavItem>enabled</NavItem>
                <NavItem>
                <label className="custom-toggle">
                  <input type="checkbox" 
                    checked={selectedOptions.enabled || false}
                    onChange={(e) => {onSelectedOptionsUpdated({
                      ...selectedOptions,
                      enabled: e.target.checked,
                    })}}
                     />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
                </NavItem>
                <NavItem>all</NavItem>
              </Nav>
            </div>
            <div className="mb-2">
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><strong>sources</strong>&nbsp;</NavItem>
                <NavItem className="product-item">
                  {sources && sources.map((item, key) =>
                    selectedSources && selectedSources.includes(item.value) ? <Button key={key} color="remove" type="button" onClick={() => onOptionSelectionUpdated('publishers', item.value, false)}>{item.value}</Button>
                    : <Button key={key} color="info" type="button" onClick={() => onOptionSelectionUpdated('publishers', item.value, true)}>{item.value}</Button>
                  )}
                </NavItem>
              </Nav>
            </div>
            <div className="mb-2">
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><strong>risk type</strong>&nbsp;</NavItem>
                <NavItem className="product-item">
                  {riskCategories && riskCategories.map((item, key) =>
                    selectedRiskCategories && selectedRiskCategories.includes(item.value) ? <Button key={key} color="remove" type="button" onClick={() => onOptionSelectionUpdated('data_categories', item.value, false)}>{item.value}</Button>
                    : <Button key={key} color="info" type="button" onClick={() => onOptionSelectionUpdated('data_categories', item.value, true)}>{item.value}</Button>
                  )}
                </NavItem>
              </Nav>
            </div>
            <div className="mb-2">
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><strong>final risk</strong>&nbsp;</NavItem>
                <NavItem className="product-item">
                  {risks && risks.map((item, key) =>
                    selectedFinalSettlementRisks && selectedFinalSettlementRisks.includes(item.value) ? <Button key={key} color="remove" type="button" onClick={() => onOptionSelectionUpdated('final_settlement_risks', item.value, false)}>{item.value}</Button>
                    : <Button key={key} color="info" type="button" onClick={() => onOptionSelectionUpdated('final_settlement_risks', item.value, true)}>{item.value}</Button>
                  )}
                </NavItem>
              </Nav>
            </div>

            <div className="filter_wrap">
              {fetchedProducts && fetchedProducts.map((item, key) => 
                <ProductItem
                  data={item} key={key}
                  onAddToCart={addToCartRequest}
                  onRemoveFromCart={removeFromCartRequest}
                  cartItems={cartItems}
                  queryType={queryType}
                />
              )}
            </div>
          </Col>
          <Col xl="4">
            <br />
              <strong>product cart</strong> | preview your dataset
            <br />
            <div className="filter_wrap cart--wrap">
              {cartItems && cartItems.map((item, key) => (
                <CartItem item={item} key={key} onRemoveFromCart={removeFromCartRequest} queryType={queryType} />
              ))}
            </div>
            <br />
            <FormGroup className={"mb-3"}>
              {queryType === 'snapshot' ? (
                <Button
                  color="info" type="button" disabled={!cartItems || cartItems.length === 0}
                  onClick={() => history.push('/admin/buildsnap')}>
                  manage contracts
                </Button>
              ) : (
                <Button
                  color="info" type="button" disabled={!cartItems || cartItems.length === 0}
                  onClick={() => history.push('/admin/buildmember')}>
                  manage contracts
                </Button>
              )}
            </FormGroup>
            <br />

            <div className="desc-card text-center">
              <br />
              <h6><strong>how do I build a dataset?</strong></h6>
              <p>search for the market and fundamental data you want!</p>
              <p>
                <strong>have a specific underlying in mind?</strong> <br />
                you can type your query (such as "cl" or "wti") in the search bar at the top.
                 the relevant products will appear below.
              </p>
              <p>
                <strong>not sure what you want?</strong><br />
                start filtering through your universe of data.
                 narrow down your search results by typing in the search box as well.
              </p>
              <br />
              <ul>
                <li className="text-left">click "<strong>add</strong>" to add an underlying to your product cart</li>
                <li className="text-left">click "<strong>remove</strong>" or the "x" button to delete it.</li>
                <li className="text-left">click "<strong>manage contracts</strong>" to finish building your dataset.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

DataBuilder.propTypes = {
  queryType: PropTypes.string,
}

DataBuilder.defaultProps = {
  queryType: 'time_series',
}

const mapStateToProps = state => {
  return {
    availableOptions: state.admin.availableFilterOprions,
    selectedOptions: state.admin.selectedFilterOptions,
    isLoading: state.admin.isLoading,
    products: state.admin.products,
    cartItems: state.admin.cartItems,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchFilterOptionsRequest: (queryType: String) => dispatch(fetchFilterOptionsRequest({ queryType })),
    onSelectedOptionsUpdated: options => dispatch(setSelectedFilterOptions(options)),
    onLoadingUpdated: isLoading => dispatch(setIsLoading(isLoading)),
    onProductsUpdated: data => dispatch(setProducts(data)),

    fetchCartRequest: (queryType: String) => dispatch(fetchCartRequest({ queryType })),
    addToCartRequest: (productId: String, queryType: String) => dispatch(addToCartRequest({productId, queryType})),
    removeFromCartRequest: (productId: String, queryType: String) => dispatch(removeFromCartRequest({productId, queryType})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBuilder);
