import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// reactstrap components
import {
  Container, Row, Col,
  FormGroup, Button,
  Input, InputGroup,
} from "reactstrap";

import API from '../../helpers/api';

import {
  fetchCartRequest,
  checkoutCartRequest, checkoutCartSuccess, checkoutCartError,
  clearSelectedFilterOptions,
} from './actions'

import {
  setDatasetMember, removeDatasetMember,
} from 'containers/datasets/actions';

import CodeModal from 'components/modals/CodeModal';
import BuilderTitle from "components/molecules/builder/BuilderTitle";
import BuilderPublisher from "components/molecules/builder/BuilderPublisher";
import SnapdaySwitch from 'components/molecules/builder/SnapdaySwitch';
import AttributeSelect from "components/molecules/builder/AttributeSelect";
import BuilderSnapInstruction from "components/molecules/builder/BuilderSnapInstruction";

function DataBuilderSnapshot({
  cartItems,
  fetchCartRequest,
  checkoutCartRequest, checkoutCartSuccess, checkoutCartError,
  setDatasetMember, removeDatasetMember,
  familyOptions, fetchFamilyOptionsRequest, fetchingFamilyOptions,
  clearSelectedFilterOptions,
}) {
  const marketDataFamilies = cartItems.filter(item => item.data_type === 'market_data');
  const [datasetName, setDatasetName] = useState('');
  const [showQuery, setShowQuery] = useState(false);
  const [query, setQuery] = useState('');
  const [isHistorical, setIsHistorical] = useState(false);
  const [snapshotDay, setSnapshotDay] = useState(new Date());
  const [numberOfContracts, setNumberOfContracts] = useState(24);
  const [relativeDays, setRelativeDays] = useState([1, 5, 21, 63, 252]);
  const queryType = 'snapshot';

  const history = useHistory();

  const groupedMarketDataFamilies = marketDataFamilies.reduce((acc, cur) => {
    if(!acc[cur.publisher]){
      acc[cur.publisher]=[]
    }
    acc[cur.publisher].push(cur)
    return acc
  }, {})

  useEffect(() => {
    fetchCartRequest()
  }, [fetchCartRequest]);

  const saveDataSet = () => {
    checkoutCartRequest()
    API.checkout(
      datasetName, {custom: null, spreads: null}, null, null,
      queryType, isHistorical,
      relativeDays, moment(snapshotDay).format('YYYY-MM-DD'),
      numberOfContracts,
    ).then(() => {
      checkoutCartSuccess()
      clearSelectedFilterOptions()
      history.push('/admin/library');
    }).catch(err => {
      checkoutCartError()
      alert(err?.response?.data?.Message || 'Something went wrong');
    })
  }

  const updateSnapshotDay = (stateVal, dateVal) => {
    setIsHistorical(stateVal);
    setSnapshotDay(dateVal);
  }

  const handleChangeRelativeDays = (val, i) => {
    let value = [...relativeDays];
    value[i] = val;
    setRelativeDays(value);
  }

  const addRelativeDayItem = () => {
    let value = [...relativeDays];
    value[value.length] = 1;
    setRelativeDays(value);
  }
  const removeRelativeDayItem = (i) => {
    let value = [...relativeDays];
    value.splice(i,1);
    setRelativeDays(value);
  }

  const onPreviewQuery = () => {
    API.previewDataSetQuery(
      null, null, null, null, queryType, isHistorical,
      relativeDays, moment(snapshotDay).format('YYYY-MM-DD'),
      numberOfContracts,
    ).then(({ query, status, msg }) => {
      if (status) {
        setQuery(query)
        setShowQuery(true);
      } else {
        console.warn(msg)
      }
    }).catch(err => {
      console.error(err);
      alert(err?.response?.data?.Message || 'Something went wrong')
    })
  }

  return (
    <>
      <Container className="databuilder datamember pt--20" fluid>
        <Row>
          <Col xl="8" className="border--b">
            <h1>
              build your dataset  &nbsp;&nbsp;&nbsp;
              <Button color="info" type="button" onClick={() => history.push('/admin/creator')} className="add-btn">modify data family</Button>
            </h1>
            <p className="mb--0">select your dataset parameters</p>
          </Col>
          <Col xl="4" className="border--b">
            <h2 className="mb-0">save your dataset</h2>
            <p className="mb-0">add dataset to library</p>
            <div className="flex flex-start dataset-input">
              <FormGroup
                className={"mb-0 mt-1"}>
                <InputGroup className="input-group-merge input-group-alternative mt-0">
                  <Input
                    placeholder="name your dataset"
                    type="text"
                    value={datasetName}
                    onChange={(e) => {setDatasetName(e.target.value)}}
                  />
                </InputGroup>
              </FormGroup> &nbsp;              
              {process.env.REACT_APP_STAGE !== 'production' && <Button color="info" type="button" onClick={onPreviewQuery} className="mr--0">preview</Button>}
              <Button color="info" type="button" onClick={saveDataSet}>save</Button>
            </div>
          </Col>
        </Row>
        <Row className="member_content">
          {/* --- data family list --- start */}
          <Col xl="7" className="border--r">
            <BuilderSnapInstruction defaultOpen={false} />

            <br />
            <BuilderTitle 
              title="data family list"
              subtitle="view data families in your dataset"  />

            <div className="ml-2 dataFamilyMemberSection">
              {Object.entries(groupedMarketDataFamilies).map(([exchangeGroup, families]) => (
                <BuilderPublisher
                  key={exchangeGroup} name={exchangeGroup} families={families}
                  setDatasetMember={setDatasetMember}
                  removeDatasetMember={removeDatasetMember}
                  queryType={queryType}
                  defaultOpen={true}
                />
              ))}
            </div>
            
          </Col>
          {/* --- data family member selection --- end */}

          {/* --- create market data family indices --- start */}
          <Col xl="5">
            <h2 className="mb-0 mt-4">dataset parameters</h2>
            <p>edit dataset parameters</p>
            <div className="flex flex-start align-item-start mb-4 pb-4">
              <div className="pt--20">
                <div className="snap-item flex">
                  <label>number of contracts</label>
                  <AttributeSelect
                    value={numberOfContracts}
                    options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24].map(item => ({name: item, value: item}))}
                    onChange={(value) => setNumberOfContracts(value)}/>
                </div>
                {false && <div className="snap-item flex">
                  <label>snapshot day</label>
                  <SnapdaySwitch 
                    isFixed={isHistorical} 
                    option1={'last day (default)'} 
                    option2={'historical day'} 
                    selDate={snapshotDay}
                    onClick={updateSnapshotDay}/>
                </div>}

                <div className="snap-item mb--10 flex align-item-start">
                  <label>relative days <br /><span>business days prior to snapshot day</span></label>
                  <div className="w-150">
                    { relativeDays.map((el, i) =>
                      <div className="flex mb--10" key={i}>
                        <FormGroup
                          className={"mb-0 w-60"}>
                          <InputGroup className="input-group-merge input-group-alternative mt-0">
                            <Input
                              placeholder=""
                              type="number"
                              value={el||1}
                              onChange={(e) => {handleChangeRelativeDays(e.target.value, i)}}
                            />
                          </InputGroup>
                        </FormGroup>
                        <Button color="remove" type="button" onClick={() => removeRelativeDayItem(i)}>remove</Button>
                      </div>
                    )}
                    <Button color="info" type="button" onClick={addRelativeDayItem} className="new-relative-btn">add new relative day</Button>
                  </div>
                </div>
              </div>
            </div>            
          </Col>
        </Row>
        <CodeModal
          title='Preview DataSet Query' isOpen={showQuery}
          onToggle={() => setShowQuery(!showQuery)} content={query}
          />
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  cartItems: state.admin.cartItems,
  fetchingFamilyOptions: state.datasets.fetchingFamilyOptions,
  familyOptions: state.datasets.familyOptions,
  spreads: state.datasets.datasetBuilderDraft.spreads,
  custom: state.datasets.datasetBuilderDraft.custom,
})

const mapDispatchToProps = dispatch => ({
  checkoutCartRequest: () => dispatch(checkoutCartRequest()),
  checkoutCartSuccess: () => dispatch(checkoutCartSuccess()),
  checkoutCartError: () => dispatch(checkoutCartError()),
  fetchCartRequest: () => dispatch(fetchCartRequest({ queryType: 'snapshot' })),
  setDatasetMember: (familyId: String, index: Number, member: Object) => dispatch(setDatasetMember({familyId, member, index})),
  removeDatasetMember: (familyId: String, index: Number) => dispatch(removeDatasetMember({familyId, index})),
  clearSelectedFilterOptions: () => dispatch(clearSelectedFilterOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataBuilderSnapshot);
