import React from 'react';

import PropTypes from 'prop-types';

import {
  Button, FormGroup, InputGroup, Input,
  Modal, ModalBody, ModalHeader,
} from "reactstrap";

const SimpleInputModal = ({
  title,
  description,
  isOpen,
  onToggle,
  placeholder,
  value,
  onChange,
  onYes = () => {},
  onNo = () => {},
}) => {
  return (
    <Modal className="companyModal credentialModal" toggle={onToggle} isOpen={isOpen}>
      {title &&
      <ModalHeader>
        {title}
      </ModalHeader>}
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          <p>{description}</p>
          <FormGroup
            className={"mb-3"}>
            <InputGroup className="input-group-merge input-group-alternative">
              {/* <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-settings-gear-65" />
                </InputGroupText>
              </InputGroupAddon> */}
              <Input
                placeholder={placeholder}
                type="text"
                value={value}
                onChange={(event) =>onChange(event.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <Button className="btn-info" color="info" type="button" onClick={onYes}>yes</Button>
          <Button className="btn-info" color="info" type="button" onClick={onNo}>no</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

SimpleInputModal.propTypes = {
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

SimpleInputModal.defaultProps = {
  placeholder: '',
  value: '',
}

export default SimpleInputModal;
