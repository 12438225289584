import React, { useState } from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Modal, ModalBody
} from "reactstrap";

function ProfileItem({ico, title, type, value, func}) {
  const [isEdit, setIsEdit] = useState(false);
  const [typemodalOpen, setTypeModalOpen] = useState(false);
  const [rolemodalOpen, setRoleModalOpen] = useState(false);

  const onEdit = () => {
    if( type === 4 ) {
      setTypeModalOpen(!typemodalOpen);
      return false;
    }
    if( type === 5 ) {
      setRoleModalOpen(!rolemodalOpen);
      return false;
    }
    setIsEdit(!isEdit);
  }

  const onSelectType = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    func(type, txt);
    setTypeModalOpen(!typemodalOpen)
  }

  const onSelectRole = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    func(type, txt);
    setRoleModalOpen(!rolemodalOpen)
  }

  const onUpdateData = (val) => {
    func(type, val);
  }

  return (<>
    {/* Company Type modal */}
    <Modal className="companyModal" toggle={() => setTypeModalOpen(!typemodalOpen)} isOpen={typemodalOpen}>
        <ModalBody>
          <div className="wrap">
            <div className="item-list">
              <ul>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'agriculture company/producer')}><span className="btn-inner--text">agriculture company/producer</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'asset manager/family office')}><span className="btn-inner--text">asset manager/family office</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'bank')}><span className="btn-inner--text">bank</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'broker/fcm')}><span className="btn-inner--text">broker/fcm</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consulting/financial advisory firm')}><span className="btn-inner--text">consulting/financial advisory firm</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consumer goods & services/airline/construction')}><span className="btn-inner--text">consumer goods &amp; services/airline/construction</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'energy company/producer/utility')}><span className="btn-inner--text">energy company/producer/utility</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'exchange')}><span className="btn-inner--text">exchange</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
              </ul>
            </div>
            <div className="item-list">
              <ul>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'fintech')}><span className="btn-inner--text">fintech</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'government/regulator')}><span className="btn-inner--text">government/regulator</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'hedge fund/cta')}><span className="btn-inner--text">hedge fund/cta</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'independent trades/investor')}><span className="btn-inner--text">independent trades/investor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'index services')}><span className="btn-inner--text">index services</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'industry trade association')}><span className="btn-inner--text">industry trade association</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'insurance company')}><span className="btn-inner--text">insurance company</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'isv (independent software provider)')}><span className="btn-inner--text">isv (independent software provider)</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
              </ul>
            </div>
            <div className="item-list">
              <ul>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'market data vendor')}><span className="btn-inner--text">market data vendor</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'media')}><span className="btn-inner--text">media</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'metals company/producer/mining')}><span className="btn-inner--text">metals company/producer/mining</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consulting/financial advisory firm')}><span className="btn-inner--text">consulting/financial advisory firm</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consumer goods & services/airline/construction')}><span className="btn-inner--text">consumer goods &amp; services/airline/construction</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'energy company/producer/utility')}><span className="btn-inner--text">energy company/producer/utility</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                </li>
                <li>
                  <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'exchange')}><span className="btn-inner--text">exchange</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                </li>
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>

    {/* Company Role modal */}
    <Modal className="companyModal" toggle={() => setRoleModalOpen(!rolemodalOpen)} isOpen={rolemodalOpen}>
          <ModalBody>
            <div className="wrap">
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'accounting/finance')}><span className="btn-inner--text">accounting/finance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'broker')}><span className="btn-inner--text">broker</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'clearing')}><span className="btn-inner--text">clearing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'consultant')}><span className="btn-inner--text">consultant</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'executive')}><span className="btn-inner--text">executive</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'financial advisor/registered investment advisor')}><span className="btn-inner--text">financial advisor/registered investment advisor</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'investor')}><span className="btn-inner--text">investor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'legal/compliance')}><span className="btn-inner--text">legal/compliance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'market data')}><span className="btn-inner--text">market data</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'marketing')}><span className="btn-inner--text">marketing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'operations')}><span className="btn-inner--text">operations</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'portfolio manager')}><span className="btn-inner--text">portfolio manager</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'professor')}><span className="btn-inner--text">professor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'research analyst/professional')}><span className="btn-inner--text">research analyst/professional</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'risk manager/middle office')}><span className="btn-inner--text">risk manager/middle office</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'sales')}><span className="btn-inner--text">sales</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'student')}><span className="btn-inner--text">student</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'technology')}><span className="btn-inner--text">technology</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'trader')}><span className="btn-inner--text">trader</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'treasurer')}><span className="btn-inner--text">treasurer</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>

    <div className="edit-profile-item">
        <div className={ ico === '' ? "title-section pl-3" : "title-section"}>
          {ico !== '' ? (<i className={'ni ' + ico} />) : null }
             {title}
        </div>
        <div className="input-section">
          {isEdit && type !== 4 && type !== 5 ? (
            <FormGroup
              className={"mb-0"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  placeholder=""
                  type="text"
                  value={value}
                  onChange={(e) => {onUpdateData(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
          ) : value}
        </div>
        <div className="btn-section">
            <Button color={isEdit && type !== 4 && type !== 5 ? 'remove' : 'info' } type="button" onClick={() => onEdit()}>
              {isEdit && type !== 4 && type !== 5 ? 'save' : 'edit' }
            </Button>
        </div>
    </div>
  </>);
}

export default ProfileItem;
