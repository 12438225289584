import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Collapse,
  NavLink,
} from "reactstrap";

import DataSourceItem from './DataSourceItem';

const DataPublisher = ({
  name,
  items,
  setCurDataSourceItem,
  onEnableDataSource,
  onDisableDataSource,
  agreements,
  error,
  credentials,
  onCredentialsUpdated,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu">
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          {name}
          <i className={classnames("ni ni-button-play", {rotate: isOpen})}></i>
        </NavLink>
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      <br />
      <ul>
        {items.map((item, idx) => (
          <DataSourceItem
            key={idx}
            itemData={item}
            title={item.name} value={'credentials required'}
            usr={''} psw={''} enable={item.enabled} type={1}
            agreements={agreements}
            onSelected={() => setCurDataSourceItem(item)}
            onEnableDataSource={onEnableDataSource}
            onDisableDataSource={onDisableDataSource}
            credentials={credentials}
            onCredentialsUpdated={onCredentialsUpdated}
            error={error}
            />
        ))}
      </ul>
    </Collapse>
  </>)
}

DataPublisher.propTypes = {
  name: PropTypes.string.isRequired,
}

export default DataPublisher;
