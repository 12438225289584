import React, { useEffect, useState, Fragment } from "react";
import PropTypes from 'prop-types';

import { Table } from "reactstrap";

function OptionsExplorerTable({
  tableData, category,
  leftWeight, rightWeight,
}) {
  const { cols, rows, data } = tableData;

  const [maxValue, setMaxValue] = useState(1);

  const toHashIdentifier = (original: String, weight: Number): String => {
    const [_mode, _value] = original.split('|')
    return [weight, _value, _mode].join('_')
  }

  useEffect(() => {
    setMaxValue(
      tableData.data
        .map(row => Math.max(...row))
        .reduce((max, current) => Math.max(max, current), -Infinity))
  }, [
    tableData,
  ]);

  const minValue = rightWeight !== null ? 1 : tableData.data
    .map(row => Math.min(...row))
    .reduce((min, current) => Math.min(min, current), Infinity)

  const getBackgroundColor = (value) => {
    const startColor = [76, 133, 173];
    const middleColor = [241, 220, 217];
    const endColor = [152, 209, 161];
  
    if (value < 1) {
      return 'rgb(255, 255, 255)';
    } else {
      const rate = (value - minValue) / (maxValue - minValue);
  
      const interpolatedColors = startColor.map((channel, i) => {
        const channelInterpolation = Math.round(
          channel + (rate * (endColor[i] - channel)) +
          ((1 - rate) * (middleColor[i] - channel))
        );
        return channelInterpolation;
      });
  
      return `rgb(${interpolatedColors.join(', ')})`;
    }
  }

  const renderDataCell = (col: String, row: String, value: String) => {
    if (value !== null) {
      const segs = [
        category,
        toHashIdentifier(row, leftWeight),
      ]

      if (rightWeight !== null) {
        segs.push(toHashIdentifier(col, rightWeight))
      }
      const hashValue = segs.join('__')
      return <a
        href={`#${hashValue}`}
        style={{ backgroundColor: getBackgroundColor(value) }}
      >{value}</a>
    } else {
      return <></>
    }
  }

  const splitArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const renderSingleLegContent = () => {
    const perRow = 10;
    const strikeRows = splitArray(tableData.rows, perRow);
    const valueRows = splitArray(tableData.data, perRow);
    const merged = strikeRows.map((row, idx) => ([row, valueRows[idx]]))
    return (
      <tbody>
        {merged.map(([strikeRow, valueRow], idx) => (
          <Fragment key={idx}>
            <tr>
              <th>strike</th>
              {strikeRow.map((cel, celIdx) => (
                <th key={celIdx}>{cel}</th>
              ))}
            </tr>
            <tr>
              <th>ERS</th>
              {valueRow.map((cel, celIdx) => {
                const [one, value] = strikeRow[celIdx].split('|');
                return (
                  <td key={celIdx}>
                    <a
                      href={`#single__${leftWeight}_${value}_${one}`}
                      style={{ backgroundColor: getBackgroundColor(cel) }}
                    >{cel}</a>
                  </td>)
              })}
            </tr>
          </Fragment>
        ))}
      </tbody>
    )
  }

  const renderDoubleLegContent = () => {
    return (
      <tbody>
        <tr>
          <th></th>
          {cols.map((col, idx) => (<th key={idx}>{col}</th>))}
        </tr>
        {rows.map((row, rowIdx) => (
          <tr key={rowIdx}>
            <th style={{
              borderTop: 'none'
            }}>{row}</th>
            {cols.map((col, colIdx) => (
              <td key={colIdx}>
                {renderDataCell(col, row, data[rowIdx][colIdx])}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    )
  }

  return (<>
    <Table className="align-items-center table-flush" responsive>
      {rightWeight === null ? renderSingleLegContent() : renderDoubleLegContent()}
    </Table>
  </>)
}

OptionsExplorerTable.propTypes = {
  category: PropTypes.string,
  leftWeight: PropTypes.number.isRequired,
  rightWeight: PropTypes.number,
  tableData: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  })
}

OptionsExplorerTable.defaultProps = {
  rightWeight: null,
}

OptionsExplorerTable.defaultProps = {
  right: {},
}

export default OptionsExplorerTable;
