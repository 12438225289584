import React from 'react';
import PropTypes from 'prop-types';

import { Button } from "reactstrap";

import { cotActorMeasureMappings } from 'helpers/constants';
import AttributeSelect from './AttributeSelect';

const MarketFamilyCotMember = ({
  idx, productId, member, onAddNewMember,
  options, setDatasetMember, onRemoveMember,
  getMemberColumnName, isLast, selIndice,
  onClickCopyToIndex,
}) => {
  const {
    actor: actorOptions, measure: measureOptions
  } = options;

  const {
    actor = actorOptions[0], measure = cotActorMeasureMappings[actorOptions[0]][0]
  } = member;

  const onChange = (key: String) => (value: Array<String>) => {
    let changedMember = {
      ...member,
      [key]: value,
    }

    if (key === 'actor') {
      const { measure: originalMeasure } = member;

      changedMember = {
        ...changedMember,
        measure: cotActorMeasureMappings[value].indexOf(originalMeasure) > -1 ? originalMeasure : cotActorMeasureMappings[value][0],
      }
    }

    setDatasetMember(productId, idx, {
      ...changedMember,
      column_name_alias: getMemberColumnName(changedMember)
    })
  }

  return (
    <div className="flex">
      <div className="flex flex-start dataset-input">

        <AttributeSelect
          value={actor || ''}
          options={actorOptions.map(item => ({name: item, value: item}))}
          onChange={onChange('actor')} />

        <AttributeSelect
          placeholder={'measure'} value={measure || ''}
          options={measureOptions.filter(item => {
            if (!!cotActorMeasureMappings[actor]) {
              return cotActorMeasureMappings[actor].indexOf(item) > -1
            } else {
              return cotActorMeasureMappings[actorOptions[0]].indexOf(item) > -1
            }
          }).map(item => ({name: item, value: item}))}
          onChange={onChange('measure')} />

        <Button color="remove" type="button" onClick={onRemoveMember}>remove</Button>

        {isLast && <Button
                        color="info" type="button"
                        className="new-btn"
                        onClick={onAddNewMember}
                      >new</Button>}
      </div>
    </div>
  )
}

MarketFamilyCotMember.propTypes = {
  idx: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  member: PropTypes.shape({
    actor: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    measure: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  options: PropTypes.object,
  setDatasetMember: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  getMemberColumnName: PropTypes.func.isRequired,
  isLast: PropTypes.bool,
  onAddNewMember: PropTypes.func.isRequired,
  onClickCopyToIndex: PropTypes.func.isRequired,
  selIndice: PropTypes.arrayOf(PropTypes.string)
}

MarketFamilyCotMember.defaultProps = {
  isLast: false,
  selIndice: [],
}

export default MarketFamilyCotMember;
