import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// reactstrap components
import {
  Container, Row, Col,
  FormGroup, Button,
  Input, InputGroup,
} from "reactstrap";

import API from '../../helpers/api';

import { clearSelectedFilterOptions } from "containers/admin/actions";

import {
  retrieveDatasetRequest,
  updateDatasetRequest, updateDatasetSuccess, updateDatasetFailure,
} from 'containers/datasets/actions';

import BuilderTitle from "components/molecules/builder/BuilderTitle";
import BuilderPublisher from "components/molecules/builder/BuilderPublisher";
import SnapdaySwitch from 'components/molecules/builder/SnapdaySwitch';
import AttributeSelect from "components/molecules/builder/AttributeSelect";

function DataSetMemberSnapshot({
  datasetItems, datasetName, details,
  retrieveDatasetRequest,
  updateDatasetRequest, updateDatasetSuccess, updateDatasetFailure,
  setDatasetMember, removeDatasetMember,
  familyOptions, fetchFamilyOptionsRequest, fetchingFamilyOptions,
  clearSelectedFilterOptions,
}) {
  const { datasetId } = useParams();
  const [newDatasetName, setNewDatasetName] = useState('');
  const [isHistorical, setIsHistorical] = useState(false);
  const [snapshotDay, setSnapshotDay] = useState(new Date());
  const [numberOfContracts, setNumberOfContracts] = useState(null);
  const [relativeDays, setRelativeDays] = useState([]);
  const queryType = 'snapshot';

  const history = useHistory();

  const groupedMarketDataFamilies = datasetItems.reduce((acc, cur) => {
    if(!acc[cur.publisher]){
      acc[cur.publisher]=[]
    }
    acc[cur.publisher].push(cur)
    return acc
  }, {})

  useEffect(() => {
    retrieveDatasetRequest(datasetId)
  }, [retrieveDatasetRequest, datasetId]);

  useEffect(() => {
    const {
      limit,
      relative_days: relativeDays,
      is_historical: isHistorical,
      snapshot_day: snapshotDay,
    } = details;

    if (relativeDays && relativeDays.length > 0) {
      setNumberOfContracts(limit);
      setIsHistorical(isHistorical);
      setRelativeDays(relativeDays);
      setSnapshotDay(snapshotDay);
    }
  }, [
    details, setNumberOfContracts, setIsHistorical, setSnapshotDay,
  ]);

  useEffect(() => {
    if (datasetName) {
      setNewDatasetName(datasetName);
    }
  }, [datasetName, setNewDatasetName]);

  const saveDataSet = () => {
    updateDatasetRequest()
    API.updateDataset(
      datasetId, newDatasetName, null, null, null, 
      queryType, null, isHistorical,
      relativeDays, moment(snapshotDay).format('YYYY-MM-DD'),
      numberOfContracts,
    ).then(() => {
      updateDatasetSuccess()
      clearSelectedFilterOptions()
      history.push('/admin/library');
    }).catch(err => {
      updateDatasetFailure()
      alert(err?.response?.data?.Message || 'Something went wrong');
    })
  }

  const updateSnapshotDay = (stateVal, dateVal) => {
    setIsHistorical(stateVal);
    setSnapshotDay(dateVal);
  }

  const handleChangeRelativeDays = (val, i) => {
    let value = [...relativeDays];
    value[i] = val;
    setRelativeDays(value);
  }

  const addRelativeDayItem = () => {
    let value = [...relativeDays];
    value[value.length] = 1;
    setRelativeDays(value);
  }
  const removeRelativeDayItem = (i) => {
    let value = [...relativeDays];
    value.splice(i,1);
    setRelativeDays(value);
  }

  return (
    <>
      <Container className="databuilder datamember pt--20" fluid>
        <Row>
          <Col xl="12" className="border--b">
            <h1>build your dataset</h1>
            <p className="mb--0">select your dataset parameters</p>
          </Col>
        </Row>
        <Row className="member_content">
          {/* --- data family list --- start */}
          <Col xl="4" className="border--r">
            <br />
            <BuilderTitle 
              title="data family list"
              subtitle="view data families in your dataset"  />

            <div className="ml-2 dataFamilyMemberSection">
              {Object.entries(groupedMarketDataFamilies).map(([exchangeGroup, families]) => (
                <BuilderPublisher
                  key={exchangeGroup} name={exchangeGroup} families={families}
                  queryType={queryType}
                  defaultOpen={true}
                />
              ))}
            </div>
            
          </Col>
          {/* --- data family member selection --- end */}

          {/* --- create market data family indices --- start */}
          <Col xl="8">
            <h2 className="mb-0 mt-4">dataset parameters</h2>
            <p>edit dataset parameters</p>
            <div className="flex flex-start align-item-start mb-4 pb-4">
              <div className="pt--20">
                <div className="snap-item flex">
                  <label>number of contracts</label>
                  {numberOfContracts && <AttributeSelect
                    value={numberOfContracts}
                    options={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25].map(item => ({name: item, value: item}))}
                    onChange={(value) => setNumberOfContracts(value)}/>}
                </div>
                {false && <div className="snap-item flex">
                  <label>snapshot day</label>
                  <SnapdaySwitch 
                    isFixed={isHistorical} 
                    option1={'last day (default)'} 
                    option2={'historical day'} 
                    selDate={snapshotDay}
                    onClick={updateSnapshotDay}/>
                </div>}

                <div className="snap-item mb--10 flex align-item-start">
                  <label>relative days <br /><span>business days prior to snapshot day</span></label>
                  <div className="w-150">
                    { relativeDays.map((el, i) =>
                      <div className="flex mb--10" key={i}>
                        <FormGroup
                          className={"mb-0 w-60"}>
                          <InputGroup className="input-group-merge input-group-alternative mt-0">
                            <Input
                              placeholder=""
                              type="number"
                              value={el||1}
                              onChange={(e) => {handleChangeRelativeDays(e.target.value, i)}}
                            />
                          </InputGroup>
                        </FormGroup>
                        <Button color="remove" type="button" onClick={() => removeRelativeDayItem(i)}>remove</Button>
                      </div>
                    )}
                    <Button color="info" type="button" onClick={addRelativeDayItem} className="new-relative-btn">add new relative day</Button>
                  </div>
                </div>
              </div>

              <div className="snap-description">
                <h3>how do I snap?</h3>
                <p><strong>snapshot</strong> allows you to observe tradable futures activity on a specific day.</p>
                <p><strong>1.</strong> first select the number of contract tenors you would like 
                to use for all data families (prompt 24 months is the default).</p>
                <p><strong>2.</strong> next, choose the last available day or a specific date in the past as the 'snapshot day' that 
                will be the reference point for the dataset.</p>
                <p><strong>3.</strong> finally, select how many business days prior to the snapshot day 
                on which you would like to observe the activity of your data families.</p>
                <ul>
                  <li><strong>1:</strong> previous day</li>
                  <li><strong>5:</strong> previous week</li>
                  <li><strong>21:</strong> previous month</li>
                  <li><strong>63:</strong> previous quarter</li>
                  <li><strong>252:</strong> previous year</li>
                </ul>
              </div>
            </div>
            

            <h2 className="mb-0 mt-4">save your dataset</h2>
            <p>add dataset to library</p>
            <div className="flex flex-start dataset-input mb-4 pb-4">
              <FormGroup
                className={"mb-0 mt-1"}>
                <InputGroup className="input-group-merge input-group-alternative mt-0">
                  <Input
                    placeholder="name your dataset"
                    type="text"
                    value={newDatasetName}
                    onChange={(e) => {setNewDatasetName(e.target.value)}}
                  />
                </InputGroup>
              </FormGroup> &nbsp;
              <Button color="info" type="button" onClick={() => history.push(`/admin/library/${datasetId}/edit`)}>modify data family</Button>
              <Button color="info" type="button" onClick={saveDataSet}>save</Button>
            </div>
            
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  datasetItems: state.datasets.curDataset.datasetItems || [],
  datasetName: state.datasets.curDataset.name || '',
  details: state.datasets.curDataset.details || {},

  fetchingFamilyOptions: state.datasets.fetchingFamilyOptions,
  familyOptions: state.datasets.familyOptions,
  spreads: state.datasets.datasetBuilderDraft.spreads,
  custom: state.datasets.datasetBuilderDraft.custom,
})

const mapDispatchToProps = dispatch => ({
  updateDatasetRequest: () => dispatch(updateDatasetRequest()),
  updateDatasetSuccess: () => dispatch(updateDatasetSuccess()),
  updateDatasetFailure: () => dispatch(updateDatasetFailure()),
  retrieveDatasetRequest: (datasetId: String) => dispatch(retrieveDatasetRequest({ datasetId })),
  clearSelectedFilterOptions: () => dispatch(clearSelectedFilterOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataSetMemberSnapshot);
