import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Row, Col, Button,
} from 'reactstrap'

import ConfirmModal from 'components/modals/ConfirmModal';

const Ingress = ({
  ipAddress, description, onSubmitDelete,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteYes = () => {
    onSubmitDelete(ipAddress).then(() => {
      setShowDeleteModal(false);
    })
  }

  return (
    <>
      <Row>
        <Col>{ipAddress}</Col>
        <Col>{description}</Col>
        <Col><Button className="add--member" onClick={() => setShowDeleteModal(true)}>delete</Button></Col>
      </Row>
      <ConfirmModal
        title={`double check!`}
        description={`are you sure to revoke db access from ${ipAddress}?`}
        isOpen={showDeleteModal} onToggle={() => setShowDeleteModal(false)}
        onYes={onDeleteYes}
        onNo={() => setShowDeleteModal(false)}
        />
    </>
  )
};

Ingress.propTypes = {
  ipAddress: PropTypes.string.isRequired,
  description: PropTypes.string,
  onSubmitDelete: PropTypes.func.isRequired,
}

Ingress.defaultProps = {
  description: '',
}

export default Ingress;
