/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react library for routing
import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  Navbar, NavItem, NavLink,
  Nav, Container, Row,
  Col, Button, Form,
  FormGroup, InputGroup,
  Modal, ModalBody,
  Input, InputGroupAddon,
  InputGroupText
} from "reactstrap";

import { useAppContext } from "libs/contextLib";
import { Auth } from 'aws-amplify';

function AuthNavBar() {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory()

  const [showcontact, setShowContact] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [showlogout, setShowLogout] = useState(false);

  const submitLogout = async () => {
    setShowLogout(!showlogout);
  }

  const showContactModal = () => {
    setEmail('');
    setSubject('');
    setMessage('');
    setShowContact(!showcontact);
  }

  const confirmLogout = async () => {
    Auth.signOut().then(() => {
      userHasAuthenticated(false);
      history.push('/auth/login');
    });
  }

  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          {isAuthenticated ? (<></>
            // <NavbarBrand to="/admin/home" tag={Link}>
            //   <img
            //     alt="..."
            //     src={require("assets/img/brand/logo.png").default}
            //   />
            // </NavbarBrand>
          ) : (<></>
            // <NavbarBrand to="/" tag={Link}>
            //   <img
            //     alt="..."
            //     src={require("assets/img/brand/logo.png").default}
            //   />
            // </NavbarBrand>
          )}
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/home">
                    <img
                      alt="..."
                      src={require("assets/img/brand/logo.png").default}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              {false && <NavItem>
                <NavLink onClick={showContactModal} to='#' tag={Link}>
                  <span className="nav-link-inner--text">contact</span>
                </NavLink>
              </NavItem>}
              {isAuthenticated ? (
                // <NavItem>
                //   <NavLink to="/admin/home" tag={Link}>
                //     <span className="nav-link-inner--text">dashboard</span>
                //   </NavLink>
                // </NavItem>
                <NavItem>
                  <NavLink to='#' tag={Link} onClick={submitLogout}>
                    <span className="nav-link-inner--text">sign out</span>
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink to="/auth/login" tag={Link}>
                    <span className="nav-link-inner--text">sign in</span>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <hr className="d-lg-none" />
            {false && !isAuthenticated && (
              <Nav className="align-items-lg-center" navbar>
                <NavItem className="d-none d-lg-block ml-lg-4">
                  <Button
                    className="btn-trial"
                    color="default"
                    href="/auth/register"
                  >
                    <span className="nav-link-inner--text">sign up</span>
                  </Button>
                </NavItem>
              </Nav>)}
          </UncontrolledCollapse>
        </Container>
      </Navbar>

      {/* logout modal */}
      <Modal className="credentialModal" toggle={() => setShowLogout(!showlogout)} isOpen={showlogout}>
        <ModalBody>
          <h5>are you sure you want to log out?</h5>
          <div className="logout-wrap">
            <Button color="warning" type="button" onClick={confirmLogout}>
              yes, please!
            </Button>
            <Button color="warning" type="button" onClick={() => setShowLogout(!showlogout)}>
              no, not yet!
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* support and contact modal */}
      <Modal className="credentialModal" toggle={() => setShowContact(!showcontact)} isOpen={showcontact}>
        <ModalBody>
          <h5>we'd love to hear from you.<br />drop us a note below.</h5>
          <p className="support_mail">info@etherealrisk.com</p>
          <Form role="form">
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-settings-gear-65" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="full name"
                  type="text"
                  value={fullName}
                  onChange={(e) => { setFullName(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-button-power" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="subject line"
                  type="text"
                  value={subject}
                  onChange={(e) => { setSubject(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative textarea-wrap">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-archive-2" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="message"
                  type="textarea"
                  value={message}
                  onChange={(e) => { setMessage(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>

            <div className="text-center support_btn">
              <Button color="info" type="button" onClick={() => setShowContact(!showcontact)}>
                &nbsp;&nbsp;&nbsp;send me!&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default AuthNavBar;
