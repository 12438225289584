import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    InputGroup,
    Modal, ModalBody
} from "reactstrap";
import { Auth } from 'aws-amplify';

function ProfilePass({
  fetchingCredentials, credentials, onShowCredentials
}) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [errmodalOpen, setErrModalOpen] = useState(false);

  const history = useHistory();

  const saveProfileDetail = async () => {
    if( oldPassword === '' || newPassword === '' ) {
      setErrorMessage('0');
      setErrModalOpen(!errmodalOpen);
    } else {
      Auth.currentAuthenticatedUser().then(user => {
        try {
          Auth.changePassword(user, oldPassword, newPassword)
            .then((res) => {
              setErrorMessage('1'); 
              setErrModalOpen(!errmodalOpen); 
            })
            .catch((err) => {
              setErrorMessage('0');
              setErrModalOpen(!errmodalOpen);
            });
        } catch (err) {
          console.error(err.response);
        }
      }).catch(err => {
        Auth.signOut().then(() => {
          history.push('auth/login');
        })
      });
    }
  }

  return (
    <>
      <h2>password and security</h2>

      <div className="edit-profile-item">
        <div className="title-section pl-3">old password</div>
        <div className="input-section">
            <FormGroup
              className={"mb-0"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  className="dark-border"
                  placeholder=""
                  type="password"
                  value={oldPassword}
                  onChange={(e) => {setOldPassword(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
        </div>
      <div className="btn-section">&nbsp;</div>
      </div>
      <div className="edit-profile-item">
        <div className="title-section pl-3">new password</div>
        <div className="input-section">
            <FormGroup
              className={"mb-0"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  className="dark-border"
                  placeholder=""
                  type="password"
                  value={newPassword}
                  onChange={(e) => {setNewPassword(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
        </div>
        <div className="btn-section">&nbsp;</div>
      </div>
    
      <div className="mt-3">
        <Button color="info" type="button" onClick={() => saveProfileDetail()}>
          save changes
        </Button>
      </div>
      <br /><br />

      <h2>database credentials</h2>

      {credentials && (
      <>
        <div className="edit-profile-item">
          <div className="title-section pl-3">database name</div>
          <div className="input-section">
              <FormGroup
                className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder=""
                    type="text"
                    value={credentials.dbname || ''}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
          </div>
          <div className="btn-section">&nbsp;</div>
        </div>

        <div className="edit-profile-item">
          <div className="title-section pl-3">database hostname</div>
          <div className="input-section">
              <FormGroup
                className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder=""
                    type="text"
                    value={credentials.hostname || ''}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
          </div>
          <div className="btn-section">&nbsp;</div>
        </div>

        <div className="edit-profile-item">
          <div className="title-section pl-3">username</div>
          <div className="input-section">
              <FormGroup
                className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder=""
                    type="text"
                    value={credentials.username}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
          </div>
          <div className="btn-section">&nbsp;</div>
        </div>

        <div className="edit-profile-item">
          <div className="title-section pl-3">password</div>
          <div className="input-section">
              <FormGroup
                className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder=""
                    type="text"
                    value={credentials.password}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
          </div>
          <div className="btn-section">&nbsp;</div>
        </div>

        <div className="edit-profile-item">
          <div className="title-section pl-3">External API Token</div>
          <div className="input-section">
              <FormGroup
                className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder=""
                    type="text"
                    value={credentials.token}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
          </div>
          <div className="btn-section">&nbsp;</div>
        </div>
      </>)}

      {!credentials && <div className="mt-3">
        <Button color="info" type="button" onClick={onShowCredentials}>
          show credentials
        </Button>
      </div>}

      {/* Company Role modal */}
      <Modal className="companyModal errModal" toggle={() => setErrModalOpen(!errmodalOpen)} isOpen={errmodalOpen}>
        <ModalBody>
          <div className="onboarding--wrap errorMessage_wrap">
            {errorMessage === '0' ? (<>
              <p className="err--msg">password didn't updated correctly.</p>
              <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>close</Button>
            </>) : (<>
              <p className="err--msg">thanks! password is updated.</p>
              <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>close</Button>
            </>)}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ProfilePass;
