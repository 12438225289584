import createAction from 'redux/helpers/createAction';

// =======================================
// Namespace
// =======================================
export const namespace = 'datasets';


// =======================================
// Action creators
// =======================================


export const fetchFamilyOptionsRequest = createAction(namespace, 'FETCH_FAMILY_OPTIONS_REQUEST');
export const fetchFamilyOptionsSuccess = createAction(namespace, 'FETCH_FAMILY_OPTIONS_SUCCESS');
export const fetchFamilyOptionsError = createAction(namespace, 'FETCH_FAMILY_OPTIONS_ERROR');

export const setDatasetMember = createAction(namespace, 'SET_DATASET_MEMBER');
export const removeDatasetMember = createAction(namespace, 'REMOVE_DATASET_MEMBER');

export const clearDatasetDraft = createAction(namespace, 'CLEAR_DATASET_DRAFT');

export const addSpreadsIndex = createAction(namespace, 'ADD_SPREADS_INDEX');
export const updateSpreadsIndex = createAction(namespace, 'UPDATE_SPREADS_INDEX');
export const removeSpreadsIndex = createAction(namespace, 'REMOVE_SPREADS_INDEX');

export const addCustomIndex = createAction(namespace, 'ADD_CUSTOM_INDEX');
export const updateCustomIndex = createAction(namespace, 'UPDATE_CUSTOM_INDEX');
export const updateCustomIndexAttributes = createAction(namespace, 'UPDATE_CUSTOM_INDEX_ATTRIBUTE');
export const updateSelectedCustomIndex = createAction(namespace, 'UPDATE_SELECTED_CUSTOM_INDEX');
export const removeCustomIndex = createAction(namespace, 'REMOVE_CUSTOM_INDEX');

export const createDatasetRequest = createAction(namespace, 'CREATE_DATASET_REQUEST');
export const createDatasetSuccess = createAction(namespace, 'CREATE_DATASET_SUCCESS');
export const createDatasetError = createAction(namespace, 'CREATE_DATASET_Error');

export const fetchDatasetsRequest = createAction(namespace, 'FETCH_DATASESTS_REQUEST');
export const fetchDatasetsSuccess = createAction(namespace, 'FETCH_DATASESTS_SUCCESS');
export const fetchDatasetsError = createAction(namespace, 'FETCH_DATASESTS_ERROR');

export const retrieveDatasetRequest = createAction(namespace, 'RETRIEVE_DATASET_REQUEST');
export const retrieveDatasetSuccess = createAction(namespace, 'RETRIEVE_DATASET_SUCCESS');
export const retrieveDatasetFailure = createAction(namespace, 'RETRIEVE_DATASET_FAILURE');

export const updateDatasetRequest = createAction(namespace, 'UPDATE_DATASET_REQUEST');
export const updateDatasetSuccess = createAction(namespace, 'UPDATE_DATASET_SUCCESS');
export const updateDatasetFailure = createAction(namespace, 'UPDATE_DATASET_FAILURE');

export const addItemToDatasetRequest = createAction(namespace, 'ADD_ITEM_TO_DATASET_REQUEST');
export const addItemToDatasetSuccess = createAction(namespace, 'ADD_ITEM_TO_DATASET_SUCCESS');
export const addItemToDatasetFailure = createAction(namespace, 'ADD_ITEM_TO_DATASET_FAILURE');

export const removeItemFromDatasetRequest = createAction(namespace, 'REMOVE_ITEM_FROM_DATASET_REQUEST');
export const removeItemFromDatasetSuccess = createAction(namespace, 'REMOVE_ITEM_FROM_DATASET_SUCCESS');
export const removeItemFromDatasetFailure = createAction(namespace, 'REMOVE_ITEM_FROM_DATASET_FAILURE');
