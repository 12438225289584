import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

import { Button } from 'reactstrap';

import API from 'helpers/api';

import LoadingModal from 'components/modals/LoadingModal';
import NewIpAddress from 'components/modals/NewIpAddress';
import Ingress from './Ingress';

const WhiteList = () => {
  const [showNewModal, setShowNewModal] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchWhiteList = useCallback(() => {
    setIsLoading(true);
    API.fetchWhiteList().then(({status, whitelist}) => {
      setWhitelist(whitelist);
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      setIsLoading(false);
    })
  }, []);

  const onAuthorizeIp = (ipAddress: String, description: String) => {
    setIsLoading(true);
    return API.addIpAddress(ipAddress, description).then(({status}) => {
      if (status) {
        fetchWhiteList();
        setShowNewModal(false);
      } else {
        setIsLoading(false);
      }
    }).catch(err => {
      setIsLoading(false);
    })
  }

  const onRevokeIp = (ipAddress: String) => {
    setIsLoading(true);
    return API.removeIpAddress(ipAddress).then(({status}) => {
      if (status) {
        fetchWhiteList();
      } else {
        setIsLoading(false);
      }
    }).catch(err => {
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchWhiteList();
  }, [fetchWhiteList]);

  return (
    <>
      <Button className="add--member" onClick={() => setShowNewModal(true)}>add ip address</Button>
      
      <h3>ip addresses</h3>

      {whitelist.map(({ip_address: ipAddress, description}, idx) => (
        <Ingress
          key={idx} ipAddress={ipAddress} description={description}
          onSubmitDelete={onRevokeIp}
          />
      ))}
      <NewIpAddress
        onSubmit={onAuthorizeIp}
        isOpen={showNewModal} onToggle={() => setShowNewModal(false)} />

      <LoadingModal isOpen={isLoading} />
    </>
  )
}

export default WhiteList;
