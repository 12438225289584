import React, { useState } from 'react';

import PropTypes from 'prop-types';

const ToggleSelect = ({
  value, options, onChange
}) => {
  const [curVal, setCurVal] = useState(value === options[1]);
  const onChangeStatus = (cur) => {
    onChange(cur?options[1]:options[0]);
    setCurVal(cur);
  }
  return (
    <>
      <label className="custom-toggle has-value-toggle">
        <input type="checkbox" 
          checked={curVal}
          onChange={() => onChangeStatus(!curVal)}
        />
        <span className="custom-toggle-slider rounded-circle">
          {curVal ? options[1] : options[0]}
        </span>
      </label>
    </>
  )
}

ToggleSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
}

export default ToggleSelect;
