import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API from 'helpers/api';

import {
  Button,
} from 'reactstrap';
import NewDepartmentModal from './NewDepartmentModal';
import DepartmentItem from './DepartmenItem';
import LoadingModal from 'components/modals/LoadingModal';

const Departments = ({
  userRole,
}) => {
  const [showNewDepartmentModal, setShowNewDepartmentModal] = useState(false);
  const [users, setUsers] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = ['admin', 'owner'].indexOf(userRole.toLowerCase()) >= 0;

  const fetchCompanyUsers = useCallback(() => {
    API.getCompanyUsers()
    .then(({users}) => {
      setUsers(users.filter(({role, status}) => (role.toLowerCase() !== 'owner' && status === 'active')));
    }).catch(err => {
      console.error(err);
    })
  }, [setUsers]);

  const fetchDepartments = useCallback(() => {
    setIsLoading(true);
    return API.fetchDepartments().then(({status, departments}) => {
      if (status) {
        setDepartments(departments);
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [setDepartments])

  const updateDepartmentMembers = (departmentId: String, newMembers: Array<String>) => {
    setIsLoading(true);
    return API.updateDepartmentMembers(departmentId, newMembers)
    .then(({status}) => {
      console.log('SUCCESS');
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const onCreateDepartment = (name: String, members: Array<String>) => {
    setIsLoading(true);
    return API.createDepartment(name, members)
    .then(({status}) => {
      if (status) {
        fetchDepartments();
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setShowNewDepartmentModal(false);
      setIsLoading(false);
    })
  }

  const onDeleteDepartment = (departmentId: String) => {
    setIsLoading(true);
    return API.deleteDepartment(departmentId)
    .then(({status}) => {
      if (status) {
        fetchDepartments();
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setShowNewDepartmentModal(false);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    fetchDepartments();
    fetchCompanyUsers();
  }, [fetchDepartments, fetchCompanyUsers]);

  return (
    <>
      {isAdmin && <Button className="add--member" onClick={() => setShowNewDepartmentModal(true)}>new department</Button>}
      {users && departments.map(({id, name, members}, idx) => (
        <DepartmentItem
          id={id} name={name} key={idx} users={users} members={members}
          isAdmin={isAdmin}
          onUpdateMembers={updateDepartmentMembers}
          onDeleteDepartment={onDeleteDepartment}
          />
      ))}

      {users && <NewDepartmentModal
        users={users} onSubmit={onCreateDepartment}
        usedNames={departments.map(({name}) => (name))}
        isOpen={showNewDepartmentModal} onToggle={() => setShowNewDepartmentModal(false)} />}
      <LoadingModal isOpen={isLoading} />
    </>
  )
};

Departments.propTypes = {
  userRole: PropTypes.string,
}

Departments.defaultProps = {
  userRole: '',
}

export default Departments;
