export class StringUtil {
  static truncate(value, length: Number = 10): String {
    return value.length > length ? `${value.substring(0, length)}...` : value;
  }
}

export class ArrayUtil {
  static generateNumberArray(max: Number, min: Number = 1): Array<Number> {
    return Array.apply(
      null, {length: max + 1}).map(Number.call, Number).slice(min)
  }
}

export function getDatesInRange(startDate, endDate) {
  const date = new Date(startDate.getTime());
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
