import React, { useEffect } from "react";
// import { connect } from 'react-redux';
import classnames from "classnames";
import PropTypes from 'prop-types';
import moment from "moment";

// reactstrap components
import {
  Button, NavItem,
  // InputGroup,
  Nav,
  NavLink, Input,
  FormGroup, Row, Col,
} from "reactstrap";
import Slider from "nouislider";
import ReactDatetime from "react-datetime";

import { getDatesInRange } from "libs/utils";
import { commodities, viewFlags } from "helpers/constants";
// import ToggleSelect from '../builder/ToggleSelect';

function Commodity({
  contracts, commodity, onCommodityChanged,
  selectedContract, onContractSelected,
  onParameterChanged, parameters,
  timeSeries, setFutureSeries,
  setMarkers,
  volLow, volHigh,
  setVolLow, setVolHigh,
  steepness, setSteepness,
  onOrderClicked,
  viewFlag, onChangeViewFlag,
  targetObject, setTarget,
  lossObject, setLoss,
}) {
  const volScenarioRef = React.useRef(null);
  const { nativeID: selectedNativeID } = commodity;
  const { timeHorizon } = parameters;
  const {
    min: targetMin,
    max: targetMax,
    value: targetValue
  } = targetObject;
  const {
    min: lossMin,
    max: lossMax,
    value: lossValue
  } = lossObject;

  const {
    contractTenor: selecteDContractTenor = '',
    expiryDate: selectedContractExpiryDate = '',
  } = selectedContract;

  // new code
  // const [userPrefStructure, setUserPrefStructure] = useState([{ weight: '', strike: '', type: 'call' }]);

  // const onChangeStructure = (id, weight, strike, type) => {
  //   let tmpValue = [...userPrefStructure];
  //   tmpValue[id] = { weight: weight, strike: strike, type: type };
  //   setUserPrefStructure(tmpValue);
  // }
  // const onAddPrefStructure = () => {
  //   setUserPrefStructure([...userPrefStructure, { weight: '', strike: '', type: 'call' }])
  // }
  // const onRemovePrefStructure = (idx) => {
  //   setUserPrefStructure(userPrefStructure.filter((_, i) => i !== idx));
  // }

  const onTimeHorizonChanged = (value) => {
    if (timeSeries.length > 0) {
      const lastDate = new Date(timeSeries[timeSeries.length - 1].x);
      if (lastDate < value.toDate()) {
        const extraDates = getDatesInRange(lastDate, value.toDate());
        setFutureSeries(extraDates.map((x, idx) => ({ x: x.toISOString().split('T')[0], y: idx === extraDates.length - 1 ? 100 : null })))
        onParameterChanged({
          timeHorizon: value.format('YYYY-MM-DD'),
        });
      } else {
        alert('time horizon must be later than today.');
        return false;
      }
    }
  }

  const validate = (current) => {
    const weeks_2_from_now = moment().add(14, "day");

    if (!selectedContractExpiryDate) {
      return current.isAfter(weeks_2_from_now);
    } else {
      return (
        current.isAfter(weeks_2_from_now) &&
        current.isBefore(moment(selectedContractExpiryDate, 'YYYY-MM-DD').subtract(4, 'day'))
      )
    }
  }

  const changeContract = (tenor: String, expiry: String) => {
    let defaultTimeHorizon = new Date();
    defaultTimeHorizon.setDate(defaultTimeHorizon.getDate() + 14);

    onContractSelected(tenor, expiry);
    onParameterChanged({ timeHorizon: defaultTimeHorizon.toISOString().split('T')[0] })
  }

  useEffect(() => {
    if (!!volScenarioRef.current.noUiSlider) {
      volScenarioRef.current.noUiSlider.destroy()
    }

    Slider.create(volScenarioRef.current, {
      start: [-0.05, 0.06],
      connect: [false, true, false],
      step: 0.005,
      range: { min: -0.15, max: 0.15 },
    }).on("update", function ([low, high], handle) {
      setVolLow(parseFloat(low));
      setVolHigh(parseFloat(high));
    });
  }, [
    setVolHigh, setVolLow,
  ]);

  return (
    <div className="sub-page">
      <h2 className="mb-0 align-center">set parameters</h2>
      <p className="pb-0 mb-0 align-center line-height-1">get more info by<br />hovering over the numbers</p>
      <hr />


      <div className="select-wrap two-columns">
        <p>{'commodity'}</p>
        <div className="section-no">
          <span>1</span>
        </div>
        <div className="tooltip-no">
          Click 'Choose a Commodity' to select a specific commodity for detailed analysis and historical price visualization.
        </div>
        <Nav
          className="flex-row"
          role="tablist"
        >
          {Object.entries(commodities).map(([subpublisher, data], spIdx) => {
            return Object.entries(data).map(([nativeID, title], productIdx) => {
              const uniqueIdx = `${spIdx}=${productIdx}`
              return (
                <NavItem key={uniqueIdx}>
                  <NavLink
                    aria-selected={selectedNativeID === nativeID}
                    className={classnames("", {
                      active: selectedNativeID === nativeID
                    })}
                    onClick={() => onCommodityChanged({ subpublisher, nativeID, title })}
                    href="#"
                    role="tab"
                  >
                    {title}
                  </NavLink>
                </NavItem>
              )
            })
          })}
        </Nav>
      </div>
      <div className="select-wrap two-columns">
        <p>{'view'}</p>
        <div className="section-no">
          <span>2</span>
        </div>
        <div className="tooltip-no">
          Select 'Bullish' if you anticipate the market rising. This analyzes calls with potential profit (green line) targets above the market and potential loss (red line) stop-loss below. Choose 'Bearish' if you expect the market to decline. This focuses on puts with potential profit (green line) stop-loss above and potential loss (red line) targets below, helping you visualize potential profit and loss zones.</div>
        <Nav
          className="flex-row"
          role="tablist"
        >
          {viewFlags.map((item, idx) => (
            <NavItem key={idx}>
              <NavLink
                className={classnames("", {
                  active: item === viewFlag
                })}
                onClick={() => onChangeViewFlag(item)}
                href="#" role="tab"
              >{item}</NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <div className="select-wrap">
        <p>contract</p>
        <div className="section-no">
          <span>3</span>
        </div>
        <div className="tooltip-no">
          Choosing the right <strong>'Contract'</strong> is crucial for your options strategy. Contracts define the delivery of oil, with front-end contracts being more volatile compared to the back-end. Depending on your market view, you might lean towards front-end or back-end contracts. However, when dealing with options, it's essential to understand the risks involved:
          <ul>
            <li><strong>Front-end contracts</strong> are more sensitive to gamma and theta, meaning they respond more acutely to price movements and time decay.</li>
            <li><strong>Back-end contracts</strong>, on the other hand, have a higher exposure to vega, indicating a greater sensitivity to changes in the underlying asset's volatility.</li>
          </ul>
          Ensure you align your contract choice with your market expectations and risk appetite.</div>
        <Nav
          className="flex-row contracts"
          role="tablist"
        >
          {contracts.slice(0, 6).map(({ contractTenor, expiryDate }, idx) => (
            <NavItem key={idx}>
              <NavLink
                aria-selected={selecteDContractTenor === contractTenor}
                className={classnames("", {
                  active: selecteDContractTenor === contractTenor
                })}
                onClick={() => changeContract({ contractTenor, expiryDate })}
                href="#"
                role="tab"
              >
                {contractTenor}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <Nav
          className="flex-row contracts"
          role="tablist"
        >
          {contracts.slice(6).map(({ contractTenor, expiryDate }, idx) => (
            <NavItem key={idx}>
              <NavLink
                aria-selected={selecteDContractTenor === contractTenor}
                className={classnames("", {
                  active: selecteDContractTenor === contractTenor
                })}
                onClick={() => changeContract({ contractTenor, expiryDate })}
                href="#"
                role="tab"
              >
                {contractTenor}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>

      <div className="select-wrap">
        <p>time horizon</p>
        <div className="section-no">
          <span>4</span>
        </div>
        <div className="tooltip-no">
          The 'Time Horizon' is a crucial factor in options trading, influencing value and risk. The Options Score Explorer's Monte Carlo simulation assesses different scenarios based on this duration. Understanding and optimizing your 'Time Horizon' can enhance your chances of success in the options market.
        </div>
        <FormGroup className={"mb-0 text w-100"}>
          <ReactDatetime
            inputProps={{
              placeholder: "select a date"
            }}
            closeOnSelect={true}
            dateFormat={"MMM DD, YYYY"}
            initialValue={new Date()}
            value={new Date(timeHorizon + 'T00:00:00')}
            timeFormat={false}
            onChange={onTimeHorizonChanged}
            isValidDate={validate}
          />
        </FormGroup>
        {/* <p className="cal-desc"> Choose your investment view's end date.</p> */}
      </div>

      <div className="select-wrap">
        <p>price target</p>
        <div className="section-no">
          <span>5</span>
        </div>
        <div className="tooltip-no">
          The 'Price Target' sets your strategy's objective. Essential for trading and risk management, it should reflect your market view and asset trends. The Options Score Explorer assesses feasibility and risk, guiding your decisions for improved success potential." </div>
        <div className="">
          <p className="mb-1">target: {targetValue}</p>
          <Input
            type="range" id={`input-target-profit`}
            className="input-range-blue"
            value={targetValue}
            placeholder={'enter price'}
            min={targetMin}
            max={targetMax}
            step={0.1}
            onChange={(e) => { setTarget(parseFloat(e.target.value)) }}
          />
        </div>
        <div className="clear"></div>
      </div>

      <div className="select-wrap">
        <p>stop loss</p>
        <div className="section-no">
          <span>6</span>
        </div>
        <div className="tooltip-no">
          The 'Stop Loss Level' defines a threshold to limit potential losses in your options stance. When this level is reached within the Monte Carlo simulation, the position is liquidated.
          This mechanism ensures that you don't risk the entire starting premium and enables more advanced simulations. It's a crucial risk management technique rooted in your risk tolerance and strategy. The Options Score Explorer assesses the implications of different stop loss levels, offering guidance for more informed decisions. Always consider asset volatility and investment objectives when setting an optimal stop loss.</div>
        <div className="">
          <p className="mb-1">loss: {lossValue}</p>
          <Input
            type="range" id={`input-stop-loss`}
            className="input-range-red"
            value={lossValue} min={lossMin} max={lossMax} step={0.1}
            placeholder={'enter price'}
            onChange={(e) => { setLoss(parseFloat(e.target.value)) }}
          />
        </div>
      </div>

      <div className="select-wrap">
        <p className="mb-1">volatility scenarios</p>
        <div className="section-no">
          <span>7</span>
        </div>
        <div className="tooltip-no">
          With 'Volatility Regime Offsets,' you can select two levels of volatility regimes: high and low. We provide valuations for your chosen options structures under these distinct market conditions. By comparing the results, you gain valuable insights into how your options may perform in both high and low volatility scenarios, allowing you to make more informed decisions based on varying market conditions.
        </div>
        <div className="">
          <p className="mb-1">steepness: {steepness}</p>
          <Input
            type="range" id={`input-steepness`}
            value={steepness} min={-0.15} max={0}
            step={0.15} disabled
            placeholder={'enter price'}
            onChange={(e) => { setSteepness(parseFloat(e.target.value)) }}
          />
        </div>
        <div>
          <div className="mb-2 mt-2">
            <span className="left">low</span><span className="right">high</span>
          </div>
          <div className="clear"></div>
          <div ref={volScenarioRef} />
          <Row>
            <Col xs="6">
              <span className="range-slider-value value-low">
                {volLow}
              </span>
            </Col>
            <Col className="text-right" xs="6">
              <span className="range-slider-value value-high">
                {volHigh}
              </span>
            </Col>
          </Row>
        </div>
        {/* <div className="left">
          <p className="mb-1">low</p>
          <FormGroup
            className={"mb-3 text1"}>
            <InputGroup className="input-group-merge input-group-alternative">
              <Input
                type="number"
                value={volLow}
                step={0.005}
                placeholder={'enter price'}
                onChange={(e) => {setVolLow(parseFloat(e.target.value))}}
              />
            </InputGroup>
          </FormGroup>
        </div>
        <div className="right">
          <p className="mb-1">high</p>
          <FormGroup
            className={"mb-3 text1"}>
            <InputGroup className="input-group-merge input-group-alternative">
              <Input
                type="number"
                value={volHigh}
                step={0.005}
                placeholder={'enter price'}
                min="0"
                onChange={(e) => {setVolHigh(parseFloat(e.target.value))}}
              />
            </InputGroup>
          </FormGroup>
        </div> */}
        <div className="clear"></div>
      </div>

      {/* <div className="select-wrap">
        <p className="mb-1">user-preferred structure</p>
        <div className="section-no">
          <span>8</span>
        </div>
        <div className="mb-1">
          <input type="checkbox" id={`userPreferred`}
            onChange={() => console.log('add user preferred structure')}
          />
          <label htmlFor="userPreferred">add user-preferred structure</label>
        </div>
        {userPrefStructure?.map((item, idx) =>
          <div className="flex flex-start" key={idx}>
            <FormGroup
              className={"text1"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  type="text"
                  placeholder="weight"
                  value={item.weight}
                  onChange={(e) => { onChangeStructure(idx, e.target.value, item.strike, item.type) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"text1"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <Input
                  type="number"
                  placeholder="strike"
                  value={item.strike}
                  onChange={(e) => { onChangeStructure(idx, item.weight, e.target.value, item.type) }}
                  step={0.5}
                />
              </InputGroup>
            </FormGroup>
            <ToggleSelect
              value={item.type}
              options={['call', 'put']}
              onChange={(cur) => onChangeStructure(idx, item.weight, item.strike, cur)} />
            {idx > 0 ? <input type="button" className="minus-btn" value="-" onClick={() => onRemovePrefStructure(idx)} /> : <input type="button" className="plus-btn" value="+" onClick={() => onAddPrefStructure()} />}
          </div>
        )}
      </div> */}
      <br />
      {/* <hr /> */}
      <Button color="info" type="button" className="order-btn" onClick={onOrderClicked}>
        order analysis
      </Button>


    </div>
  );
}

Commodity.propTypes = {
  commodity: PropTypes.shape({
    nativeID: PropTypes.string.isRequired,
    subpublisher: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape({
    contractTenor: PropTypes.string.isRequired,
    expiryDate: PropTypes.string.isRequired,
  })).isRequired,
  selectedContract: PropTypes.shape({
    contractTenor: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
  facts: PropTypes.shape({
    trade_date: PropTypes.string,
    current_price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    wow_change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    '3m_change': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    yoy_change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  viewFlag: PropTypes.string.isRequired,
  onChangeViewFlag: PropTypes.func.isRequired,
  parameters: PropTypes.shape({
    timeHorizon: PropTypes.string.isRequired,
  }),
  onParameterChanged: PropTypes.func.isRequired,
  onCommodityChanged: PropTypes.func.isRequired,
  onContractSelected: PropTypes.func.isRequired,
  onOrderClicked: PropTypes.func.isRequired,
  targetObject: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  setTarget: PropTypes.func.isRequired,
  lossObject: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  setLoss: PropTypes.func.isRequired,
  steepness: PropTypes.number.isRequired,
  setSteepness: PropTypes.func.isRequired,
}

export default Commodity;
