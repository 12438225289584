import React, { useEffect, useState, useCallback } from "react";
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Button, Card, CardBody,
  FormGroup, Form,
  Input, InputGroupAddon, InputGroupText, InputGroup,
  Container, Row, Col,
} from "reactstrap";

import { setLoading } from './actions';
import { useAppContext } from 'libs/contextLib';

const transitionStyles = {
  enter: {
    transform: "translateX(0)"
  },
  show: {
    opacity: "1"
  }
}

const Accept = ({
  location,
  isLoading, onLoadingChanged,
}) => {
  const searchParams = (new URLSearchParams(location.search));
  const [
    givenEmail,
    // givenCode
  ] = [
      searchParams.get('email'),
      // searchParams.get('code')
    ];
  const url = decodeURI(window.location.href);
  const givenCode = url.substr(url.indexOf('&code=') + '&code='.length);
  const [email, setEmail] = useState(givenEmail);
  const [code, setCode] = useState(givenCode);
  const [confirmError, setConfirmError] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [user, setUser] = useState(null);

  const [show, setShow] = useState(false);

  const [agree, setAgree] = useState(false);

  const { userHasAuthenticated } = useAppContext();
  const history = useHistory();

  const confirmCode = useCallback(async () => {
    onLoadingChanged(true);
    setConfirming(true);
    try {
      await Auth.confirmSignUp(email, code);
      setConfirmed(true);
      setShow(true);
    } catch (e) {
      if (e.message.indexOf("User cannot be confirmed. Current status is FORCE_CHANGE_PASSWORD") === 0) {
        console.log(e.message);
      } else {
        setConfirmError(e.message);
        console.log(e)
      }
    }

    Auth.signIn(email, code)
      .then(res => {
        setUser(res);
        setConfirmed(true);
        setShow(true);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setConfirming(false)
        onLoadingChanged(false);
      })
  }, [
    email, code, onLoadingChanged,
  ]);

  const submitPassword = (userSession) => {
    if (agree) {
      Auth.completeNewPassword(
        userSession,
        password,
      ).then(() => {
        userHasAuthenticated(true);
        history.push('/admin/home');
      }).catch(e => {
        if (e.code === 'NotAuthorizedException' && e.message.indexOf('session is expired') >= 0) {
          Auth.signIn(email, code)
            .then(res => {
              submitPassword(res)
            })
            .catch(err => {
              console.error(err);
            })

        } else {
          setPasswordError(e.message);
        }
      })
    } else {
      setPasswordError('Almost there! Just review and accept our Privacy Policy to proceed.')
    }
  }

  useEffect(() => {
    if (!givenEmail || !givenCode) {
      return
    }

    confirmCode()
  }, [
    givenEmail, givenCode, confirmCode, setShow
  ]);

  const renderForm = () => {
    if (confirmed) {
      return (
        <>
          {show ? (<>
            <h1 style={{ ...transitionStyles['enter'] }}>ethereal</h1>
            <h1 style={{ ...transitionStyles['enter'] }}>risk</h1>
          </>) : (<>
            <h1>ethereal</h1>
            <h1>risk</h1>
          </>)}
          <h3 className="mb-5">set a new password</h3>
          <Form role="form">
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-key-25" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="password"
                  type="password"
                  value={password}
                  onChange={(e) => { setPassword(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="re-enter password"
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => { setPasswordConfirmation(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            {passwordError && (
              <div className="text-muted">
                <small>
                  <span className="text-yan font-weight-700">
                    {passwordError}
                  </span>
                </small>
              </div>
            )}
            <div className="text-desc mt-4 mb-3">
              <span>Let's keep your account safe! Your password should be at least 8 characters long and include an uppercase letter, a number, and a symbol</span>
            </div>
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckAgree"
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckAgree"
              >
                <span className="text-muted">I've read and agree to the  &nbsp;
                  <Link to='/privacy' target={'_blank'}>Privacy Policy</Link> and the
                  <Link to='/terms' target={'_blank'}> Terms of Use</Link>
                </span>
              </label>
            </div>
            <div className="text-center">
              <Button
                className="mt-4" color="info" type="button"
                disabled={password.length < 6 || password !== passwordConfirmation || !agree}
                onClick={() => submitPassword(user)}>
                set password
              </Button>
            </div>
          </Form>
        </>
      )
    }

    if (confirming) {
      return (
        <h3>Accepting the invitation...</h3>
      )
    } else {
      return (
        <>
          <h3 className="mb-5">accept invitation</h3>
          <Form role="form">
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-lock-circle-open" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="verification code from email"
                  type="text"
                  value={code}
                  onChange={(e) => { setCode(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>
            {confirmError && (
              <div className="text-muted">
                <small>
                  <span className="text-yan font-weight-700">
                    {confirmError}
                  </span>
                </small>
              </div>
            )}
            <div className="text-center">
              <Button className="mt-4 btn-info" color="info" type="button" onClick={confirmCode}>
                verify
              </Button>
            </div>
          </Form>
        </>
      )
    }
  }

  return (
    <div className="full_wrap">
      <Container className="mt-10">
        <Row className="auth__row">
          <Col lg="6" md="8">
            <Card className="mb-0 auth_wrap signin--wrap">
              <CardBody className="px-lg-5">{renderForm()}</CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadingChanged: isLoading => dispatch(setLoading(isLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accept);
