import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Button, Collapse, Input,
  NavLink, Row, Col,
  Modal, ModalBody, ModalFooter,
} from "reactstrap";

import API from 'helpers/api';
import LoadingModal from 'components/modals/LoadingModal';

import ConfirmModal from 'components/modals/ConfirmModal';
import SimpleInputModal from 'components/modals/SimpleInputModal';

const LibraryItem = ({
  data,
  onAddToCart,
  getDatasets,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const [ sqlModal, setSqlModal ] = useState(false);
  const [ newAnalysiModalOpen, setNewAnalysisModalOpen ] = useState(false);
  const [ isDeleteOpen, setIsDeleteOpen ] = useState(false);
  const [ isRenameOpen, setIsRenameOpen ] = useState(false);
  const [ newAnalysisName, setNewAnalysisName ] = useState('');
  const { name: datasetName, id: datasetId, families, sql } = data;
  const [ isLoading, setIsLoading ] = useState(false);

  const [ newName, setNewName ] = useState(datasetName);

  const history = useHistory();

  const onClickCreateAnalaysis = () => {
    setNewAnalysisModalOpen(true);
  }

  const onCreateAnalysis = () => {
    setIsLoading(true);
    API.createAnalysis(datasetId, newAnalysisName).then(({analysis_id: analysisId}) => {
      setIsLoading(false);
      history.push(`/admin/library/${analysisId}`);
    }).catch(err => {
      alert(err);
      setIsLoading(false);
    })
  }

  const onEditDataset = () => {
    history.push(`/admin/library/${datasetId}/edit`);
  }

  const renameDataSet = () => {
    setIsLoading(true);
    API.renameDataSet(datasetId, newName).then(({status}) => {
      getDatasets().finally(() => {
        setIsLoading(false);
        setIsRenameOpen(false);
      })
    }).catch(err => {
      setIsLoading(false);
      setIsRenameOpen(false);
    })
  }

  const deleteDataSet = () => {
    setIsLoading(true);
    API.deleteDataSet(datasetId).then(({status}) => {
      getDatasets().finally(() => {
        setIsLoading(false);
        setIsDeleteOpen(false);
      })
    }).catch(err => {
      setIsLoading(false);
      setIsDeleteOpen(false);
    })
  }

  return (
    <>
      <div className="datalib-wrap">
        <div className="datasource-wrap">
          <div className="drop-menu product-item">
            <Row>
              <Col xl="6">
                <NavLink
                  className={classnames("mb-sm-3 mb-md-0")}
                  onClick={() => setIsOpen(!isOpen)}
                  role="tab"
                >
                  {datasetName}
                  <i className={classnames("ni ni-button-play", {rotate: isOpen})}></i>
                </NavLink>
              </Col>
              <Col xl="6">
                <Button color="info" type="button" onClick={onEditDataset}>
                  edit
                </Button>
                <Button color="info" type="button" onClick={() => setIsRenameOpen(true)}>
                  rename
                </Button>
                <Button color="info" type="button" onClick={onClickCreateAnalaysis}>
                  create analysis
                </Button>
                <Button color="info" type="button" onClick={() => setSqlModal(true)}>
                  sql
                </Button>
                <Button color="info" type="button" onClick={() => setIsDeleteOpen(true)}>
                  delete
                </Button>
                <div className="clear"></div>
              </Col>
            </Row>
          </div>
        </div>
        <Collapse isOpen={isOpen}>
          <div className="pt--10">
            <Row>
              <Col xl="6" className="pl--20">
                <strong>dataset families</strong>
                {families.map(({product_id: productId, publisher: source, product_name: productName}) => (
                  <p className="mb--0" key={productId}>
                    {productName ? productName : 'Untitled'} | {source}
                  </p>
                ))}
              </Col>
              <Col xl="6">
                <strong>used in dashboards</strong>
                <p className="mb--0">dashboard 1</p>
                <p className="mb--0">dashboard 2</p>
                <p className="mb--0">dashboard 3</p>
                <p className="mb--0">dashboard 4</p>
              </Col>
            </Row>
          </div>
        </Collapse>
      </div>

      {/* Error modal */}
      <Modal className="companyModal credentialModal" toggle={() => setSqlModal(!sqlModal)} isOpen={sqlModal}>
        <ModalBody>
          <div className="onboarding--wrap errorMessage_wrap">
            <p><strong>did you know?</strong> enterprise users<br /> can query datasets using python,<br /> excel, and more using<br /> 
            <strong>{sql}</strong></p>
            <Button className="btn-info" color="info" type="button" onClick={() => setSqlModal(false)}>active enterprise membership</Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Analysis Create Modal */}
      <Modal className="companyModal credentialModal" toggle={() => setNewAnalysisModalOpen(false)} isOpen={newAnalysiModalOpen}>
        <ModalBody>
          <div className="onboarding--wrap errorMessage_wrap">
            <p>are you sure that you want to create a new analysis with<br /><strong>{datasetName}</strong> dataset?</p>
            <Input
                placeholder="name your new analysis"
                type="text"
                value={newAnalysisName}
                onChange={(event) => setNewAnalysisName(event.target.value)}
              />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="btn-info" color="info" type="button"
            disabled={newAnalysisName.length === 0}
            onClick={() => onCreateAnalysis()}>create now</Button>
        </ModalFooter>
      </Modal>
      <ConfirmModal
        description={`are you sure you want to delete ${datasetName} dataset?`}
        onToggle={() => setIsDeleteOpen(!isDeleteOpen)}
        isOpen={isDeleteOpen}
        onYes={deleteDataSet}
        />
      <SimpleInputModal
        description={`do you want to rename ${datasetName} dataset?`}
        onToggle={() => setIsRenameOpen(!isRenameOpen)}
        isOpen={isRenameOpen}
        placeholder='new dataset name'
        value={newName}
        onChange={setNewName}
        onYes={renameDataSet}
        />
      <LoadingModal isOpen={isLoading} />
    </>
  )
}

LibraryItem.propTypes = {
  data: PropTypes.object.isRequired,
  getDatasets: PropTypes.func.isRequired,
}

export default LibraryItem;
