import React from 'react';

import PropTypes from 'prop-types';

const FundamentalFamily = ({
  families,
}) => {
  return (
    <>
      <div>
        {families.map( (item, idx) => (
          <p key={idx} className="family-item"><strong>{item.raw_native_id}</strong>: {item.product_name}</p>
        ))}
      </div>
    </>
  )
}

FundamentalFamily.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    product_name: PropTypes.string.isRequired,
    product_id: PropTypes.string.isRequired,
  }))
}

export default FundamentalFamily;
