/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// import { Link, useHistory } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import { useAppContext } from '../../libs/contextLib';
import LoadingModal from "components/modals/LoadingModal";

function Verification({
  location,
}) {
  const { state: { givenEmail = '', givenCode = '', givenPassword = '' } = {} } = location;
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedCode, setfocusedCode] = useState(false);
  const [email, setEmail] = useState(givenEmail);
  const [password, setPassword] = useState(givenPassword);
  // const [isCheckAgree, setIsCheckAgree] = useState(false);
  const [code, setCode] = useState(givenCode);
  // const [codeSent, setCodeSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { userHasAuthenticated } = useAppContext();

  const history = useHistory();

  const handleConfirmCode = async () => {
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(email.trim(), code.trim());
    } catch (e) {
      if (e?.message !== 'User cannot be confirmed. Current status is CONFIRMED') {
        setError('something went wrong. please contact support.');
        setIsLoading(false);
        return
      }
    }

    try {
      await Auth.signIn(email.trim(), password);
      userHasAuthenticated(true);
      history.push('/auth/onboarding');
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  const onClickResendCode = async () => {
    if (email.length === 0) {
      alert('please enter your email!');
      return;
    }

    try {
      setIsLoading(true)
      await Auth.resendSignUp(email.trim());
    } catch (e) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="authextra code--extra">
        <div className="authextra__wrap">
          <h4>did you know?</h4>
          <p>with ethereal risk, you can create dashboards using data from multiple sources like the one below. save the dashboard to easily access or edit it in the future.</p>
          <br /><br />
          <div>
            <img
              alt="graph"
              src={require("assets/img/theme/graph1.png").default}
            />
          </div>
        </div>
      </div>
      <Row className="auth__row code--row">
        <Col lg="7">
          <Card className="auth_wrap rect--width">
            <CardBody className="px-lg-5 py-lg-5">
              <h3>enter verification code</h3>
              <Row className="mt-3 mb-3">
                <Col xs="12 mt-3">
                  <span className="auth--txt">please check your email for a message from </span>
                  <a href='mailto:jean@etherealrisk.com' title='jean@etherealrisk.com' className="auth--link ml-0">jean@etherealrisk.com</a>
                </Col>
              </Row>
              <Row className="mt-3 mb-3">
                <Col xs="12 mt-3">
                  <span className="auth--txt">haven't received your verification email?</span><br />
                  <span
                    className={"auth--link ml-0"}
                    onClick={onClickResendCode}
                  >
                    resend code
                  </span>
                </Col>
              </Row>
              <Form role="form">
                <FormGroup
                  className={classnames({
                    focused: focusedEmail,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="email"
                      type="email"
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(false)}
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedPassword,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="password"
                      type="password"
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(false)}
                      value={password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedCode,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="verification code from email"
                      type="text"
                      onFocus={() => setfocusedCode(true)}
                      onBlur={() => setfocusedCode(false)}
                      value={code}
                      onChange={(e) => { setCode(e.target.value) }}
                    />
                  </InputGroup>
                </FormGroup>
                {error && (
                  <div className="text-muted">
                    <small>
                      <span className="text-yan font-weight-700">
                        {error}
                      </span>
                    </small>
                  </div>
                )}
                <div className="text-center">
                  <Button className="mt-4 btn-info" color="info" type="button" onClick={handleConfirmCode}>
                    verify
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <LoadingModal isOpen={isLoading} />
    </>)
}

export default Verification;
