import React from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup, Button,
  Input, InputGroup,
} from "reactstrap";

import { ArrayUtil } from 'libs/utils';

import AttributeSelect from './AttributeSelect';

import {
  MEMBER_MODE_RELATIVE, MEMBER_MODE_FIXED, MEMBER_MODE_COLOR, MEMBER_MODE_STRIP,  // units,
  MEMBER_SUB_MODE_STRIPT_YEAR, MEMBER_SUB_MODE_STRIPT_HALF_YEAR, MEMBER_SUB_MODE_STRIPT_QUARTER,
  stripTypes, memberModes, contractColors, months, stripYears, stripQuarters, stripHalfYears,
} from 'helpers/constants';
import MarketMemberFunction from './MarketMemberFunction';

const MarketFamilyMember = ({
  idx, productId, member, options,
  setDatasetMember, onRemoveMember,
  getMemberColumnName, units,
  isLast, onAddNewMember, selIndice, onClickCopyToIndex,
}) => {
  const {
    tenor, contract_line_num, attribute, unit,
    color, mode,
    sub_mode: subMode,
    strip_year: stripYear,
    strip_quarter: stripQuarter,
    strip_half_year: stripHalfYear,
    month = '12',
    functions = [],
  } = member;
  const { tenor_dates, max_contract_line_num: maxContractLineNum } = options;
  const contractLineNumbers = maxContractLineNum > 0 ? ArrayUtil.generateNumberArray(maxContractLineNum) : [];

  const onChange = (key: String) => (value: String) => {
    const changedMember = {
      ...member,
      [key]: value
    }

    setDatasetMember(productId, idx, {
      ...changedMember,
      column_name_alias: getMemberColumnName(changedMember),
    })
  }

  const onChangeContract = (value) => {
    onChange('mode')(value);
  }

  const onChangeColor = (value: String) => {
    onChange('color')(value)
  }

  const onChangeStripType = (value) => {
    onChange('sub_mode')(value);
  }

  const onChangeStripYear = (value: String) => {
    onChange('strip_year')(value);
  }

  const onChangeStripQuarter = (value: String) => {
    onChange('strip_quarter')(value)
  }

  const onChangeStripHalfYear = (value: String) => {
    onChange('strip_half_year')(value)
  }

  const onChangeUnit = (unitValue: String) => {
    const [{name: unitCaption}] = units.filter(({value}) => `${value}` === unitValue);

    const changedMember = {
      ...member,
      unit: unitValue,
      unit_caption: unitCaption,
    }

    setDatasetMember(productId, idx, {
      ...changedMember,
      column_name_alias: getMemberColumnName(changedMember),
    })
  }

  const onAddFunction = () => {
    onChange('functions')([...functions, {window_function: '', window_parameter: '20'}]);
  }

  const onRemoveFunction = (selectedIdx: Number) => {
    console.log(selectedIdx);
    console.log(functions);
    const newFunctions = functions.filter((func, idx) => idx !== selectedIdx);
    onChange('functions')(newFunctions)
  }

  const onChangeFunctionAttribute = (selectedIdx: Number) => (key: String, value: String) => {
    const newFunctions = functions.map((func, idx) => {
      if (idx === selectedIdx) {
        return {
          ...func,
          [key]: value,
        }
      } else {
        return func
      }
    });
    onChange('functions')(newFunctions)
  }

  const renderOptions = () => {
    switch (mode) {
      case MEMBER_MODE_FIXED:
        return (
          <AttributeSelect
            value={tenor}
            size={'size-medium'}
            options={tenor_dates.map(item => ({value: item, name: item}))}
            onChange={onChange('tenor')} />
        )

      case MEMBER_MODE_RELATIVE:
        return (
          <AttributeSelect
            value={contract_line_num}
            size={'size-min'}
            options={contractLineNumbers.map(item => ({name: item, value: item}))}
            onChange={onChange('contract_line_num')} />
        )

      case MEMBER_MODE_COLOR:
        return (
          <>
            <AttributeSelect
              value={color}
              size={'size-medium'}
              options={contractColors.map(item => ({name: item, value: item}))}
              onChange={onChangeColor} />
            <AttributeSelect
              value={month}
              size={'size-min'}
              options={months}
              onChange={onChange('month')} />
          </>
        )

      case MEMBER_MODE_STRIP:
        return (
          <>
            <AttributeSelect
              value={subMode}
              size={'size-medium'}
              options={stripTypes.map(item => ({name: item.name, value: item.value}))}
              onChange={onChangeStripType} />

              {subMode === MEMBER_SUB_MODE_STRIPT_YEAR ? (
                <AttributeSelect
                  value={stripYear}
                  size={'size-min'}
                  options={stripYears.map(item => ({name: item, value: item}))}
                  onChange={onChangeStripYear} />
              ) : null }
              {subMode === MEMBER_SUB_MODE_STRIPT_HALF_YEAR ? (
                <>
                  <AttributeSelect
                    value={stripYear}
                    size={'size-min'}
                    options={stripYears.map(item => ({name: item, value: item}))}
                    onChange={onChangeStripYear} />
                  <AttributeSelect
                    value={stripHalfYear}
                    size={'size-min'}
                    options={stripHalfYears.map(item => ({name: item, value: item}))}
                    onChange={onChangeStripHalfYear} />
                </>
              ) : null }
              {subMode === MEMBER_SUB_MODE_STRIPT_QUARTER ? (
                <>
                  <AttributeSelect
                    value={stripYear}
                    size={'size-min'}
                    options={stripYears.map(item => ({name: item, value: item}))}
                    onChange={onChangeStripYear} />
                  <AttributeSelect
                    value={stripQuarter}
                    size={'size-min'}
                    options={stripQuarters.map(item => ({name: item, value: item}))}
                    onChange={onChangeStripQuarter} />
                </>
              ) : null }
          </>
        )

      default:
        return null;
    }
  }

  return (
    <>
    <div className="flex">
      <div className="flex flex-start dataset-input">
        <>
          <AttributeSelect
            value={mode}
            size={'size-medium'}
            options={memberModes}
            onChange={(e) => onChangeContract(e)}
            />

          {renderOptions()}

          <AttributeSelect
            value={attribute}
            options={[{value: 'settlement', name: 'settlement'}]}
            onChange={() => onChange('attribute')} />

          {units.length > 0 && <AttributeSelect
            value={unit}
            size={'size-min'}
            options={units}
            onChange={onChangeUnit} />}
          <Button color="info" type="button" onClick={onAddFunction} className="ml-1">add function</Button>
          <Button color="remove" type="button" onClick={onRemoveMember} className="ml-1">remove</Button>
        </>
        {isLast &&
          <Button
            color="info" type="button"
            className="new-btn"
            onClick={onAddNewMember}
          >new</Button>}
      </div>
      <div className="flex dataset-input">
        <FormGroup
          className={"mb-0 mt-1 w-native"}>
          <InputGroup className="input-group-merge input-group-alternative mt-0">
            <Input
              placeholder="native id"
              type="text"
              value={member.column_name_alias}
              className="native-id-input"
              readOnly={true}
            />
          </InputGroup>
        </FormGroup> &nbsp;
        {selIndice.length === 0 ? null : ( <Button color="info" type="button" onClick={() => {onClickCopyToIndex(member)}}>copy to index</Button> )}
      </div>
    </div>
    <div className="ml-3 mt-2 mb-2">
      {functions && functions.map(({window_function: windowFunction, window_parameter: windowParameter}, idx) => (
        <MarketMemberFunction
          key={idx} idx={idx} windowFunction={windowFunction} windowParameter={windowParameter}
          onChange={onChangeFunctionAttribute(idx)} onRemoveFunction={onRemoveFunction}/>
      ))}
  </div>
  </>)
}

MarketFamilyMember.propTypes = {
  idx: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  member: PropTypes.shape({
    mode: PropTypes.string.isRequired,
    tenor: PropTypes.string,
    contract_line_num: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    attribute: PropTypes.string.isRequired,
  }),
  tenorOptions: PropTypes.arrayOf(PropTypes.string.isRequired),
  contractLineNumbers: PropTypes.arrayOf(PropTypes.number.isRequired),
  attributes: PropTypes.arrayOf(PropTypes.string.isRequired),
  setDatasetMember: PropTypes.func.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
  getMemberColumnName: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  isLast: PropTypes.bool,
  onAddNewMember: PropTypes.func.isRequired,
  selIndice: PropTypes.arrayOf(PropTypes.object),
  onClickCopyToIndex: PropTypes.func.isRequired,
}

MarketFamilyMember.defaultProps = {
  isLast: false,
  selIndice: [],
}

export default MarketFamilyMember;
