import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";
import { useAppContext } from "libs/contextLib";

function Terms() {
  const { isAuthenticated } = useAppContext();
  return (
    <>
      <Container className={isAuthenticated ? 'home-wrap privacy-wrap' : 'home-wrap privacy-wrap mt-7'} fluid>
        <h2 className="about--title text-center">terms of use</h2>
        <p>
        Please read this page before accessing and using this site, including any hosted software, platform and/or services accessible from this site, as it explains certain restrictions on the use thereof. It is your responsibility to be aware of and to observe all applicable laws and regulations of your relevant jurisdiction.
        1. About us
        The etherealrisk website “www.etherealrisk.com” (“our site”) is issued for all purposes by Quiet Spot, Inc (“Quiet Spot”) and the expression “we” and “our” shall be construed accordingly).
        </p>
        <p>
        Quiet Spot and each of its affiliates are collectively referred to in this site as “Quiet Spot” and references to “Quiet Spot” are to Quiet Spot and/or its relevant affiliate.
        </p>
        <p>
        2. Your use of our site
        </p>
        <p>
        This Disclaimer sets out the terms on which you may make use of our site, including any hosted software, platform and/or services accessible from our site (such software, platform and/or services, our “Services”).
        </p>
        <p>
        This Disclaimer applies to every page on our site, whether or not such pages are individually disclaimed. Please read this Disclaimer carefully before you start to use our site and/or Services.
        </p>
        <p>
        BY ACCESSING AND USING OUR SITE AND/OR SERVICES, YOU HAVE INDICATED THAT YOU ACCEPT THIS DISCLAIMER AND THAT YOU AGREE TO ABIDE BY IT AND ANY POLICES INCORPORATED BY REFERENCE HEREIN. IF YOU DO NOT AGREE TO THIS DISCLAIMER, PLEASE REFRAIN FROM USING OUR SITE AND/OR SERVICES.
        </p>
        <p>
        This Disclaimer also incorporates our Cookies and Privacy Policy and, in respect of your access to and use of certain Services and all other rules, policies and procedures that may be published from time to time on our site, each of which is incorporated by reference and each of which may be updated by us from time to time without notice to you.
        </p>
        <p>
        We may revise this Disclaimer from time to time by updating this page. The revised Disclaimer will take effect from when it is posted. You are expected to check this page from time to time and to take notice of any changes we may have made, as by using our site and/or Services you acknowledge that you have accepted any such amendments.
        </p>
        <p>
        You must NOT use our site and/or Services (or permit or procure others to use it) as follows:
        </p>
        <p>
        (A) for any unlawful, improper or illegal purpose or activity;
        (B) to violate our or any third party’s copyright, trademark, proprietary or other intellectual property rights;
        (C) to damage our name or reputation or that of our affiliates or any third parties;
        (D) to impersonate any of our employees or other person or use a false name while using our site and/or Services or implying an association with us;
        (E) to penetrate our security measures or other entities’ systems (“hacking”);
        (F) to generate excessive amounts of internet traffic, to interfere with our network or other’s use of our site and/or Services or to engage in activities designed to or having the effect of degrading or denying service to users of our site and/or Services or others;
        (G) to introduce viruses, worms, harmful code and/or Trojan horses onto the internet or into our site and/or Services or any other entity’s systems and it is your responsibility to ensure that whatever you download or select for your use from our site and/or Services is free from such items; and/or
        (H) to transmit confidential or proprietary information, except solely at your own risk.
        </p>
        <p>
        3. Compliance with law and regulation
        When using our site and/or Services, you agree you will comply with all applicable local, national and international laws and regulations, including those related to data privacy, international communications and exportation of technical or personal data. It may be illegal to view and/or download the information contained on our site and/or use our Services in certain countries and we and our affiliates disclaim all responsibility if you access or download any information from our site and/or use our Services in breach of any law or regulation of the country in which you are a citizen or in which you are resident or domiciled.
        </p>
        <p>
        4. Accuracy of information
        We have taken reasonable care to ensure that the information on our site is accurate, current, complete, fit for its intended purpose and compliant with applicable law and regulation as at the date of issue. However, errors or omissions may occur due to circumstances beyond our control and no warranty is given, or representation made, regarding the accuracy, validity or completeness of the information on our site and no liability is accepted by us for the accuracy or completeness of such information. You must conduct your own due diligence and investigations rather than relying on any information on our site.
        </p>
        <p>
        5. Access
        We reserve the right to deny you access to our site and/or Services, or immediately to suspend or terminate your access to our site and/or Services, or to disable any user name or password (whether chosen by you or allocated by us) at any time (in our sole discretion).
        </p>
        <p>
        6. Liability for use of our site
        Access to our site and/or Services is permitted on a temporary basis and we reserve the right to withdraw or amend the Services and/or information we provide on, or through, our site without notice. We do not accept any liability if for any reason our site and/or any Service is unavailable at any time or for any period. Our site is provided for informational purposes only and shall not form the basis of, or be relied on in connection with, any contract.
        </p>
        <p>
        The information and opinions contained in our site have been compiled, or arrived at, by us from sources believed by us to be reliable as at the date of publication. However, the information and any opinions contained on our site are subject to change and for background purposes only. We, our affiliates and other third parties connected to us do not accept any responsibility for any reliance which is placed by any visitor to our site, or by anyone who may be informed of any of its contents, on any information or opinions which are expressed herein and, to the extent permitted by law, expressly exclude all conditions, warranties, representations, undertakings and other terms which might otherwise be implied by statute, common law or in equity.
        </p>
        <p>
        We, our affiliates and other third parties connected to us therefore expressly exclude any liability and responsibility arising from any reliance placed on such materials and information by any visitor to our site, or by anyone who may be informed of any of its contents, for (i) the accuracy, validity, timeliness, merchantability or completeness of, any information or data (whether prepared by us or by any third party) for any particular purpose or use or that the information or data will be free from error and (ii) any direct, indirect or consequential loss or damage of any kind incurred by any visitor to our site, or by anyone who may be informed of any of its contents, in connection with our site or in reliance on the information or opinions contained in it, or in connection with the use, inability to use, or results of the use of our site or any websites linked to it or materials posted on it or any use of our Services. This does not affect our liability for any loss or damage which cannot be excluded or limited under applicable law.
        </p>
        <p>
        The internet is not a completely reliable transmission medium and neither we nor any of our affiliates accept any liability for any data transmission errors such as data loss or damage or alteration of any kind or for the security or confidentiality of information transmitted across the internet to or from us or any of our affiliates. Any such transmission of information is entirely at your own risk.
        </p>
        <p>
        7. Linked websites
        Should you leave our site via a link contained within our site and view content that is not provided by us, you do so at your own risk. We provide links only as a convenience and without liability. In particular, and without limitation, you understand and agree that the ability to link to another website and the fact that a link exists does not mean that we endorse or guarantee such site, the site sponsor or the site contents. The content which you access via such links will not have been developed, checked for accuracy, or otherwise reviewed by us. We make no guarantees or representations as to, and shall have no liability for, any electronic content delivered by any third party, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any electronic content. Your reliance on any information contained on any site reached through a link shall be your own responsibility. When you leave our site, unless otherwise indicated, you will be subject to the terms of use and privacy policies of the new website which you are visiting. If you reached our site via a link, please be advised that the ability to link to our site and the fact that a link exists does not mean that we necessarily endorse, support, sanction, encourage, verify, or agree with the comments, opinions, or statements posted on the website from which you linked to our site (the “Linking Site”). We are not responsible for any damages or losses arising from any delays, defects or omissions that may exist in the services, information or other content provided in the Linking Site. We make no guarantee or representation as to, and shall have no liability for, any content on any Linking Site, including, without limitation, the accuracy, subject matter, quality, sequence or timeliness of any such content.
        </p>
        <p>
        8. Indemnity
        You agree to indemnify, defend, and hold harmless us, our affiliates and our officers, partners, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising out of or in any way connected with your breach of this Disclaimer and/or access to or use of our site and/or Services.
        </p>
        <p>
        9. Intellectual property rights
        We are the owner or the licensee of all intellectual property rights in our site, the materials published on it, and our Services. Those works are protected by laws and treaties around the world. All such rights are reserved. You may use the materials on our site for your own reference only. Such materials may not otherwise be reproduced, distributed, stored in a data retrieval system or transmitted, in any form or by any means (electronic, mechanical, photocopying, recording or otherwise) without our prior written consent. Nothing on our site and no use of our Services should be construed as granting any license or right in relation to any of our trademarks or those of our affiliates or any third party.
        </p>
        <p>
        10. Severability
        If any specific term or condition of this Disclaimer is determined by a court of competent jurisdiction to be invalid, void or unenforceable, that determination shall not affect the validity of the remaining provisions of this Disclaimer. Headings used in this Disclaimer are for convenience, are not substantive and shall not be used to interpret or construe any of the provisions contained in this Disclaimer. When used in these this Disclaimer the words “includes” and “including” shall be deemed to be followed by the phrase “without limitation”.
        </p>
        <p>
        11. Governing Law
        This Disclaimer and any non-contractual obligations arising from or connected with it shall be governed by and shall be construed in accordance with English law. English courts will have exclusive jurisdiction over any dispute arising from, or related to, use of our site and/or Services (whether arising out of or in connection with contractual or non-contractual obligations) (“proceedings”) and it is a condition of using our site and/or Services that you waive any objection to proceedings in such courts on the grounds of venue or that proceedings have been brought in an inconvenient forum, although we retain the right to bring proceedings against you for breach of this Disclaimer in your country of residence or any other relevant country.
        </p>
        <p>
        12. Third parties
        For EEA Users: we shall have the benefit of the rights conferred on us by this Disclaimer but otherwise no person who is not a party to this Disclaimer may enforce its terms under the Contracts (Rights of Third Parties) Act 1999.
        </p>
        <p>
        13. Contact us
        If you have any enquiries in relation to our site, the information on it and/or our Services, please contact info@etherealrisk.com.
        </p>
        <p>
        Third Party Disclaimers
        Amazon Web Services, the “Powered by AWS” logo, are trademarks of Amazon.com, Inc. or its affiliates in the United States and/or other countries."
        </p>
        <p>
          <br /><br />
        </p>
      </Container>
    </>
  );
}

export default Terms;
