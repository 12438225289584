import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import createSagaMiddleware from 'redux-saga';

import rootSaga from './rootSaga';
import reducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default createStore(
  reducer(history),
  composeWithDevTools(
    applyMiddleware(...[sagaMiddleware, routerMiddleware(history)]),
  ),
)

sagaMiddleware.run(rootSaga);
