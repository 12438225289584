import React from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Container,
  Row, Col,
} from "reactstrap";

const DataBuilderSwitcher = () => {
  const history = useHistory();
  return (
    <>
      <Container className="datatype pt--20" fluid>
        <h2>what type of dataset would you like to build?</h2>
        <p className="h-desc">choose a time series or snapshot format for your dataset</p>
        <Row className="mt--30">
          <Col xl="6" className="border--r pt--20 pb--20">
            <div className="datatype-item">
              <h4>time series</h4>
              <p>view your market and<br /> fundamental data over a period<br /> of time</p>
              <Button color="info" onClick={() => history.push('/admin/creator')}>time to build</Button>

              <img 
                alt={''}
                width={'90px'}
                height={'90px'}
                src={
                  require("assets/img/theme/ico-graph.png").default
                } />
            </div>
          </Col>
          <Col xl="6" className="pt--20 pb--20">
            <div className="datatype-item">
              <h4>snapshot</h4>
              <p>observe activity of<br /> tradable futures data on a<br /> specified day</p>
              <Button color="info" onClick={() => history.push('/admin/creator/snapshot')}>snap it!</Button>

              <img 
                alt={''}
                width={'90px'}
                height={'90px'}
                src={
                  require("assets/img/theme/ico-calendar.png").default
                } />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataBuilderSwitcher);