import createAction from 'redux/helpers/createAction';

// =======================================
// Namespace
// =======================================
export const namespace = 'admin';


// =======================================
// Action creators
// =======================================

export const setError = createAction(namespace, 'SET_ERROR');
export const setDataSources = createAction(namespace, 'SET_DATA_SOURCES');
export const setIsLoading = createAction(namespace, 'SET_IS_LOADING');
export const setCredentials = createAction(namespace, 'SET_CREDENTIALS');
export const setUsers = createAction(namespace, 'SET_USERS');

export const setSelectedFilterOptions = createAction(namespace, 'SET_SELECTED_FILTER_OPTIONS');
export const clearSelectedFilterOptions = createAction(namespace, 'CLEAR_SELECTED_FILTER_OPTIONS');
export const setProducts = createAction(namespace, 'SET_PRODUCTS');
export const setCartItems = createAction(namespace, 'SET_CART_ITEMS');

export const fetchFilterOptionsRequest = createAction(namespace, 'FETCH_FILTER_OPTIONS_REQUEST');
export const fetchFilterOptionsSuccess = createAction(namespace, 'FETCH_FILTER_OPTIONS_SUCCESS');
export const fetchFilterOptionsError = createAction(namespace, 'FETCH_FILTER_OPTIONS_ERROR');

export const fetchCartRequest = createAction(namespace, 'FETCH_CART_REQUEST');
export const fetchCartSuccess = createAction(namespace, 'FETCH_CART_SUCCESS');
export const fetchCartError = createAction(namespace, 'FETCH_CART_ERROR');

export const addToCartRequest = createAction(namespace, 'ADD_TO_CART_REQUEST');
export const addToCartSuccess = createAction(namespace, 'ADD_TO_CART_SUCCESS');
export const addToCartError = createAction(namespace, 'ADD_TO_CART_ERROR');

export const removeFromCartRequest = createAction(namespace, 'REMOVE_FROM_CART_REQUEST');
export const removeFromCartSuccess = createAction(namespace, 'REMOVE_FROM_CART_SUCCESS');
export const removeFromCartError = createAction(namespace, 'REMOVE_FROM_CART_ERROR');

export const checkoutCartRequest = createAction(namespace, 'CHECKOUT_CART_REQUEST');
export const checkoutCartSuccess = createAction(namespace, 'CHECKOUT_CART_SUCCESS');
export const checkoutCartError = createAction(namespace, 'CHECKOUT_CART_ERROR');
