import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Container,
  Row, Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Nav,
  NavItem,
} from "reactstrap";
import FilterCondition from "components/molecules/filter/FilterCondition";
import ProductItem from "components/molecules/filter/ProductItem";
import CartItem from '../../components/molecules/filter/CartItem';
// import ConfirmModal from '../../components/modals/ConfirmModal';

import API from '../../helpers/api';

import {
  fetchFilterOptionsRequest,
  setSelectedFilterOptions,
  setIsLoading,
  setProducts,
} from 'containers/admin/actions';

import {
  retrieveDatasetRequest,
  removeItemFromDatasetRequest,
  addItemToDatasetRequest,
} from './actions'

function DataSetFamilyBuilder({
  isLoading, onLoadingUpdated,
  availableOptions, fetchFilterOptionsRequest,
  selectedOptions, onSelectedOptionsUpdated,
  products, onProductsUpdated,
  datasetItems,
  retrieveDatasetRequest,
  addItemToDatasetRequest,
  removeItemFromDatasetRequest,
  dataset,
}) {
  const [search, setSearch] = useState('');
  const [queryTimer, setQueryTimer] = useState(null);
  const params = useParams();
  const history = useHistory();
  const { datasetId } = params;

  const { name: datasetName, queryType } = dataset;

  const {
    asset_classes: assetClassesOptions,
    publishers: sources,
    data_types: dataTypes,
    data_categories: riskCategories,
    publisher_classifications,
    sectors, 
  } = availableOptions || {};

  const {
    asset_classes: selectedAssetClasses,
    publishers: selectedSources,
    data_types: selectedDataTypes,
    publisher_classifications: selectedSecurityTypes,
    data_categories: selectedRiskCategories,
    sectors: selectedSectors, 
  } = selectedOptions;

  const { products: fetchedProducts, total } = products;

  useEffect(() => {
    retrieveDatasetRequest(datasetId);
  }, [retrieveDatasetRequest, datasetId])

  useEffect(() => {
    fetchFilterOptionsRequest(queryType)
  }, [fetchFilterOptionsRequest, queryType])

  const getProducts = useCallback(() => {
    API.filterProducts(selectedOptions, queryType).then(res => {
      onProductsUpdated(res);
    }).catch(err => {
      console.error(err);
    })
  }, [selectedOptions, onProductsUpdated, queryType])

  useEffect(() => {
    getProducts()
  }, [selectedOptions, getProducts]);

  const onOptionSelectionUpdated = (identifier, option, checked) => {
    const originalValue = selectedOptions[identifier] || []
    const updated = {
      ...selectedOptions,
      [identifier]: checked ? originalValue.concat([option]) : originalValue.filter(item => item !== option)
    }
    onSelectedOptionsUpdated(updated);
  }

  const resetFilters = () => {
    onSelectedOptionsUpdated({})
  }

  const updateSearchQuery = (value) => {
    onSelectedOptionsUpdated({
      ...selectedOptions,
      query: value
    });
    setQueryTimer(null);
  }

  const onSearchQueryUpdated = (e) => {
    setSearch(e.target.value);
    if (queryTimer !== null) {
      clearTimeout(queryTimer);
      setQueryTimer(null);
    }

    setQueryTimer(setTimeout(() => {updateSearchQuery(e.target.value)}, 1000));
  }

  const onClickManageMember = () => {
    if (queryType === 'snapshot') {
      history.push(`/admin/library/${datasetId}/edit-members/snapshot`)
    } else {
      history.push(`/admin/library/${datasetId}/edit-members`)
    }
  }

  return (
    <>
      <Container className="databuilder pt--20" fluid>
        <Row>
          <h2><strong>{datasetName}</strong></h2>
        </Row>
        <Row>
          <Col xl="2">
            <h2>edit dataset</h2>
            <p>{total === undefined ? 'Loading products...' : `${total} available products`}</p>

            <div className="reset--btn">
              <Button color="info" type="button" onClick={() => resetFilters()}>
                reset filters
              </Button>
            </div>

            <div className="filter_wrap">
              <FilterCondition
                identifier={'asset_classes'}
                name={'asset class'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedAssetClasses || []}
                items={assetClassesOptions || []} />

              <FilterCondition
                identifier={'sectors'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSectors || []}
                name={'sector'}
                items={sectors || []} />

              {/* <FilterCondition
                name={'location'}
                items={['north america', 'south america', 'europe', 'asia']} /> */}

              <FilterCondition
                identifier={'data_types'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedDataTypes || []}
                name={'data type'}
                items={dataTypes || []} />

              <FilterCondition
                identifier={'data_categories'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedRiskCategories || []}
                name={'risk category'}
                items={riskCategories || []} />

              <FilterCondition
                identifier={'publisher_classifications'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSecurityTypes || []}
                name={'classification'}
                items={publisher_classifications || []} />
            </div>
          </Col>
          <Col xl="6">
            <FormGroup
              className={"mb-3 mt-5"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="narrow the results by adding search criteria"
                  type="text"
                  value={search}
                  onChange={onSearchQueryUpdated}
                />
              </InputGroup>
            </FormGroup>
            <div className="product--list">
              <strong>product list</strong> | click to add the product to your cart
            </div>
            <div>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><strong>view</strong></NavItem>
                <NavItem>enabled</NavItem>
                <NavItem>
                <label className="custom-toggle">
                  <input type="checkbox" 
                    checked={selectedOptions.enabled || false}
                    onChange={(e) => {onSelectedOptionsUpdated({
                      ...selectedOptions,
                      enabled: e.target.checked,
                    })}}
                     />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
                </NavItem>
                <NavItem>all</NavItem>
              </Nav>
            </div>
            <div className="mb-2">
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem><strong>sources</strong>&nbsp;</NavItem>
                <NavItem className="product-item">
                  {sources && sources.map((item, key) =>
                    selectedSources && selectedSources.includes(item.value) ? <Button key={key} color="remove" type="button" onClick={() => onOptionSelectionUpdated('publishers', item.value, false)}>{item.value}</Button>
                    : <Button  key={key} color="info" type="button" onClick={() => onOptionSelectionUpdated('publishers', item.value, true)}>{item.value}</Button>
                  )}
                </NavItem>
              </Nav>
            </div>

            <div className="filter_wrap">
              {fetchedProducts && fetchedProducts.map((item, key) => 
                <ProductItem
                  data={item} key={key}
                  onAddToCart={(productId) => addItemToDatasetRequest(datasetId, productId)}
                  onRemoveFromCart={(productId) => removeItemFromDatasetRequest(datasetId, productId)}
                  cartItems={datasetItems}
                />
              )}
            </div>
          </Col>
          <Col xl="4">
            <br />
              <strong>product cart</strong> | preview your dataset
            <br />
            <div className="filter_wrap cart--wrap">
              {datasetItems && datasetItems.map((item, key) => (
                <CartItem
                  item={item} key={key}
                  onRemoveFromCart={(productId) => removeItemFromDatasetRequest(datasetId, productId)} />
              ))}
            </div>
            <br />
            <FormGroup className={"mb-3"}>
              <Button
                color="info" type="button" disabled={!datasetItems || datasetItems.length === 0}
                onClick={onClickManageMember}>
                manage contracts
              </Button>
            </FormGroup>
          </Col>
        </Row>
      </Container>
      {/* <ConfirmModal
        description={`are you sure to create the dataset ${datasetName}?`}
        isOpen={isCheckoutCofirmModalShown} onToggle={() => setIsCheckoutConfirmModalShown(false)}
        onYes={saveDataset} />
      <NotificationModal
        description='dataset successfully saved!'
        isOpen={isCheckoutSuccessModalShown} onToggle={() => setIsCheckoutSuccessModalShown(false)}
        onOkay={() => setIsCheckoutSuccessModalShown(false)}
        /> */}
    </>
  );
}

const mapStateToProps = state => {
  return {
    availableOptions: state.admin.availableFilterOprions,
    selectedOptions: state.admin.selectedFilterOptions,
    isLoading: state.admin.isLoading,
    products: state.admin.products,
    datasetItems: state.datasets.curDataset.datasetItems || [],
    dataset: state.datasets.curDataset || {},
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchFilterOptionsRequest: (queryType: String) => dispatch(fetchFilterOptionsRequest({ queryType })),
    onSelectedOptionsUpdated: options => dispatch(setSelectedFilterOptions(options)),
    onLoadingUpdated: isLoading => dispatch(setIsLoading(isLoading)),
    onProductsUpdated: data => dispatch(setProducts(data)),

    retrieveDatasetRequest: (datasetId) => dispatch(retrieveDatasetRequest({datasetId})),
    addItemToDatasetRequest: (datasetId: String, productId: String) => dispatch(addItemToDatasetRequest({datasetId, productId})),
    removeItemFromDatasetRequest: (datasetId: String, productId: String) => dispatch(removeItemFromDatasetRequest({datasetId, productId})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataSetFamilyBuilder);
