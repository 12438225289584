import React from 'react';

import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

const ConfirmModal = ({
  title,
  description,
  isOpen,
  onToggle,
  onYes = () => {},
  onNo = () => {},
}) => {
  return (
    <Modal className="companyModal credentialModal" toggle={onToggle} isOpen={isOpen}>
      {title &&
      <ModalHeader>
        {title}
      </ModalHeader>}
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          <p>{description}</p>
          <Button className="btn-info" color="info" type="button" onClick={onYes}>yes</Button>
          <Button className="btn-info" color="info" type="button" onClick={onNo}>no</Button>
        </div>
      </ModalBody>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default ConfirmModal;
