import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from "reactstrap";
import ResponsiveHeatMap from '../charts/NivoHitmap';

const HeatmapSection = ({
  title, heatmap
}) => {
  const [heatmapHeight, setHeatmapHeight] = useState(450);
  useEffect(() => {
    if( heatmap.length > 15 )
      setHeatmapHeight(650);
    else
      setHeatmapHeight(450);
  }, [heatmap]);
  return (
  <Row className='scenario-item-wrap ml-0 mr-0 datamember'>
    <Col xl="12">
      <h2 className='pl-2 pt-2 mt-1 mb-3'>{title}</h2>
    </Col>
    <Col xl="12" style={{ height: heatmapHeight }}>
      <ResponsiveHeatMap
        data={heatmap}
        xLegendText='flat price change (%)'
        yLegendText='value dates'
        />
    </Col>
  </Row>
  )
}

HeatmapSection.propTypes = {
  title: PropTypes.string.isRequired,
  heatmap: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      x: PropTypes.string.isRequired,
      y: PropTypes.number.isRequired,
    })).isRequired,
  })).isRequired
}

export default HeatmapSection;
