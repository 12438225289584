/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

const ForgotPassword = () => {
  const codeAlreadysent = (new URLSearchParams(window.location.search)).get('sent');
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedCode, setFocusedCode] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [code, setCode] = useState('')
  const [codeSent, setCodeSent] = useState(codeAlreadysent !== null);

  const history = useHistory();

  const submitSendCode = async () => {
    try {
      await Auth.forgotPassword(email);
      setCodeSent(true);
    } catch (e) {
      setError(e.message);
    }
  }

  const submitUpdatePassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      setCodeSent(false);
      history.push('/auth/login');
    } catch (e) {
      setError(e.message);
    }
  }

  return (
    <>
      <div className="full_wrap">
        <Container className="mt-10">
          <Row className="auth__row">
            <Col lg="5" md="7">
              <Card className="mb-0 auth_wrap signin--wrap">
                <CardBody className="px-lg-5">
                  {codeSent ? (<>
                    <h3 className="mt-7 mb-3">verify new password</h3>
                  </>) : (<>
                    <h3 className="mt-3">forgot password</h3>
                    <br />
                    <span className="auth--txt">we'll send a code to verify your identity</span>
                    <Row className="mt-3 mb-5">
                      {false && <Col xs="12 mt-3">
                        <span className="auth--txt">new user? </span>
                        <span
                          className={"auth--link"}
                          onClick={() => history.push("/auth/register")}
                        >
                          create account
                        </span>
                      </Col>}
                    </Row>
                  </>)}
                  <Form role="form" autoComplete='off'>
                    {codeSent ? (<>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: focusedCode,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="email address again"
                            type="email"
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(true)}
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: focusedCode,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-code-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="code from email"
                            type="text"
                            onFocus={() => setFocusedCode(true)}
                            onBlur={() => setFocusedCode(true)}
                            value={code}
                            autoComplete='code'
                            onChange={(e) => { setCode(e.target.value) }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: focusedPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="new password"
                            type="password"
                            onFocus={() => setfocusedPassword(true)}
                            onBlur={() => setfocusedPassword(true)}
                            value={password}
                            onChange={(e) => { setPassword(e.target.value) }}
                            autoComplete='new-password'
                          />
                        </InputGroup>
                      </FormGroup>
                      {error && (
                        <div className="text-muted">
                          <small>
                            <span className="text-yan font-weight-700">
                              {error}
                            </span>
                          </small>
                        </div>
                      )}
                      <div className="text-center">
                        <Button className="my-4" color="info" type="button" onClick={submitUpdatePassword}>
                          update password
                        </Button>
                      </div>
                      {false && <Row className="mt-3">
                        <Col xs="12 mt-3 text-right">
                          <span className="auth--txt">don't have access to your email? </span>
                          <span
                            className={"auth--link"}
                            onClick={() => history.push("/auth/register")}
                          >
                            create a new account
                          </span>
                        </Col>
                      </Row>}
                    </>) : (<>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="email"
                            type="email"
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(true)}
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                          />
                        </InputGroup>
                      </FormGroup>
                      {error && (
                        <div className="text-muted">
                          <small>
                            <span className="text-yan font-weight-700">
                              {error}
                            </span>
                          </small>
                        </div>
                      )}
                      <div className="text-center">
                        <Button className="my-4" color="info" type="button" onClick={submitSendCode}>
                          send email
                        </Button>
                      </div>
                      <Row className="mt-3">
                        <Col xs="12 mt-3 text-right">
                          <span className="auth--txt">remember already? </span>
                          <span
                            className={"auth--link"}
                            onClick={() => history.push("/auth/login")}
                          >
                            back to sign in
                          </span>
                        </Col>
                      </Row>
                    </>)}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ForgotPassword;
