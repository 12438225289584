import createReducer from 'redux/helpers/createReducer'

import {
  namespace,
  setLoading,
  setError,

  fetchProfileRequest, fetchProfileSuccess, fetchProfileError,
  submitProfileRequest, submitProfileSuccess, submitProfileError,
  fetchCredentialsRequest, fetchCredentialsSuccess, fetchCredentialsFailure,
  fetchTransactionsRequest, fetchTransactionsSuccess, fetchTransactionsFailure,
} from './actions';

// =======================================
// Default state
// =======================================
const defaultState = {
  fetchingProfile: false,
  fetchingProfileError: null,
  submittingProfile: false,
  submittingProfileError: null,
  isLoading: false,
  error: null,
  profile: {},
  fetchingCredentials: false,
  credentials: null,
  transactions: [],
  fetchingTransactions: false,
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
  [setLoading.Type]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [setError.Type]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [fetchProfileRequest.Type]: (state, action) => ({
    ...state,
    fetchingProfile: true,
  }),
  [fetchProfileSuccess.Type]: (state, { payload }) => ({
    ...state,
    fetchingProfile: false,
    profile: payload,
  }),
  [fetchProfileError.Type]: (state, { error }) => ({
    ...state,
    fetchingProfile: false,
    fetchingProfileError: error,
  }),
  [submitProfileRequest.Type]: (state, action) => ({
    ...state,
    submittingProfile: true,
    submittingProfileError: null,
  }),
  [submitProfileSuccess.Type]: (state, { payload }) => ({
    ...state,
    submittingProfile: false,
    profile: payload,
    submittingProfileError: null,
  }),
  [submitProfileError.Type]: (state, action) => ({
    ...state,
    submittingProfile: false,
    submittingProfileError: 'something went wrong',
  }),
  [fetchCredentialsRequest.Type]: (state, action) => ({
    ...state,
    fetchingCredentials: true,
  }),
  [fetchCredentialsSuccess.Type]: (state, action: { payload: { credentials: Object }}) => ({
    ...state,
    fetchingCredentials: false,
    credentials: action.payload.credentials,
  }),
  [fetchCredentialsFailure.Type]: (state, action) => ({
    ...state,
    fetchingCredentials: false,
  }),
  [fetchTransactionsRequest.Type]: (state, action) => ({
    ...state,
    fetchingTransactions: true,
  }),
  [fetchTransactionsSuccess.Type]: (state, action: { payload: { transactions: Object }}) => ({
    ...state,
    fetchingTransactions: false,
    transactions: action.payload.transactions,
  }),
  [fetchTransactionsFailure.Type]: (state, action) => ({
    ...state,
    fetchingTransactions: false,
  }),
})

export default reducer
export { namespace }
