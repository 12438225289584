import React from 'react';

import Embed from 'components/QuickSight/Embed';
import AdminHeader from "components/Headers/AdminHeader.js";

import { Container } from 'reactstrap';

function QuickSight() {
  return (
    <>
      <AdminHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Embed />
      </Container>
    </>
  )
};

export default QuickSight;
