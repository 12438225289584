/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import LoadingModal from "components/modals/LoadingModal";

function Register() {
  // const [focusedName, setfocusedName] = useState(false);
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isCheckAgree, setIsCheckAgree] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const handleSignUp = async () => {
    if (isCheckAgree) {
      try {
        setIsLoading(true);
        await Auth.signUp(email, password);
        history.push({
          pathname: '/auth/verification',
          state: {
            givenEmail: email,
            givenPassword: password,
          }
        })
        if (error) {
          setError('');
        }
      } catch (e) {
        if (e.message === 'An account with the given email already exists.')
          setError('an account with the given email already exists, please go to sign in page');
        else
          setError(e.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("please review the Privacy Policy and check 'I agree' before continuing.");
    }
  }

  return (
    <>
      <Row className="auth__row">
        <Col lg="8">
          <Card className="auth_wrap">
            <CardBody className="px-lg-5 py-lg-5">
              <h3>create new account</h3>
              <Row className="mt-2 mb-4">
                <Col xs="12 mt-3">
                  <span className="auth--txt">already have an account? </span>
                  <span
                    className={"auth--link"}
                    onClick={() => history.push("/auth/login")}
                  >
                    sign in
                  </span>
                </Col>
              </Row>
              <Form role="form">
                <FormGroup
                  className={classnames({
                    focused: focusedEmail,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="email"
                      type="email"
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(false)}
                      value={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className={classnames({
                    focused: focusedPassword,
                  })}
                >
                  <InputGroup className="input-group-merge input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="password"
                      type="password"
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(false)}
                      value={password}
                      onChange={(e) => { setPassword(e.target.value) }}
                    />
                  </InputGroup>
                  <br />
                  <h4>password requirements:</h4>
                  <ul>
                    <li>8 minimum characters</li>
                    <li>one CAPITAL letter</li>
                    <li>one NUMBER</li>
                    <li>one SYMBOL</li>
                  </ul>
                </FormGroup>
                {error && (
                  <div className="text-muted">
                    <small>
                      <span className="text-yan font-weight-700">
                        {error}
                      </span>
                    </small>
                  </div>
                )}
                <Row className="normal-lh">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        onChange={(e) => { setIsCheckAgree(e.target.checked) }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <Link to='/privacy' target={'_blank'}>Privacy Policy</Link>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-3" color="info" type="button" onClick={handleSignUp}>
                    create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          {/* <Row className="mt-3">
            <Col xs="12" className="text-center">
              <span
                className="text-light"
                onClick={() => setCodeSent(true)}
                >
                <small>I received a code.</small>
              </span>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <div className="authextra register--extra">
        <div className="authextra__wrap">
          <div className="authextra__logo">
            <img
              alt="Logo"
              src={require("assets/img/brand/logo_1.png").default}
            />
          </div>
          <h4>why sign up?</h4>
          <p>you'll immediately be able to:</p>

          <div className="authextra__item">
            <img
              alt="ethreal explorer"
              src={require("assets/img/theme/box.png").default}
            />
            <span>intuitively search through your universe of market and fundamental data</span>
          </div>
          <div className="authextra__item">
            <img
              alt="across publishers"
              src={require("assets/img/theme/book.png").default}
            />
            <span>create, save, and share datasets which can easily be exported without touching a line of code</span>
          </div>
          <div className="authextra__item">
            <img
              alt="dashboard"
              src={require("assets/img/theme/settings.png").default}
            />
            <span>get more from your data by creating dashboards using our pre-built templates and machine learning insights</span>
          </div>
        </div>
      </div>
      <LoadingModal isOpen={isLoading} />
    </>)
}

export default Register;
