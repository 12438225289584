import React, { useState } from "react";
// reactstrap components
import {
  Button,
  FormGroup,
  Modal,
  Input,
  InputGroup,
  ModalBody,
} from "reactstrap";

const DataSourceItem = ({
  itemData,
  agreements,
  onSelected,
  onEnableDataSource,
  onDisableDataSource,
  credentials,
  onCredentialsUpdated,
  error,
}) => {
  const {
    name, enabled, subTitle,
    group_name: groupName,
    auth_required: authRequired,
    auth_mechanism: authMechanism,
  } = itemData;

  const credential = (credentials || {})[groupName] || {};

  const [isEdit, setIsEdit] = useState(false);
  const agreed = enabled || agreements[groupName]

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errModal, setErrModal] = useState(false);

  const onDataAuthCredentialUpdated = (attribute, value) => {
    onCredentialsUpdated({
      credentials,
      [groupName]: {
        ...credential,
        [attribute]: value
      }
    })
  }

  const onEdit = () => {
    setIsEdit(!isEdit);
  }

  const onChangeStatus = (e) => {
    e.preventDefault();
    if( !agreed ) {
      setErrModal(true);
      return false;
    }

    setShowConfirmModal(true);
    return false;
  }

  const onYes = () => {
    if (enabled) {
      onDisableDataSource(groupName, credential);
      setShowConfirmModal(false);
    } else {
      onEnableDataSource(groupName, credential);
      setShowConfirmModal(false);
    }
  }

  const onNo = () => {
    setShowConfirmModal(false);
  }

  return (
  <li onClick={onSelected}>
    <div className="datasource-item">
      <div className="enable-option">
        <label className="custom-toggle">
          <input type="checkbox" 
            onClick={onChangeStatus}
            defaultChecked={enabled}
            />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </div>
      <div className="edit-profile-item">
        <div className="title-section pl-3">
          {name}
          {subTitle && <><br />{subTitle}</>}
        </div>
        <div className="enable-section">
          {authRequired ? 'credentials required' : 'no credentials required'}
        </div>
        <div className="credential-section">
          {isEdit ? (
          <>
            {Object.entries(authMechanism).map(([idx, attribute]) => (
              <FormGroup key={idx} className={"mb-0"}>
                <InputGroup className="input-group-merge input-group-alternative">
                  <Input
                    className="dark-border"
                    placeholder={attribute}
                    type={attribute === 'password' ? 'password' : 'text'}
                    value={credential[attribute] || ''}
                    autoComplete={'new-password'}
                    onChange={(e) => {onDataAuthCredentialUpdated(attribute, e.target.value)}}
                  />
                </InputGroup>
              </FormGroup>
            ))}
          </>) : (
          <>{Object.entries(authMechanism).map(([idx, attribute]) => 
            <span key={idx}>
              {credential[attribute] ? (attribute === 'password' ? '****' : credential[attribute]) : attribute} <br />
            </span>)}
          </>)}
        </div>
        <div className="btn-section">
          {authRequired &&
          <Button color="info" type="button" disabled={enabled} onClick={() => onEdit()}>
            {isEdit ? 'save' : 'edit'}
          </Button>}
        </div>
      </div>
    </div>

    {/* Confirm modal */}
    <Modal className="companyModal credentialModal" toggle={() => setShowConfirmModal(!showConfirmModal)} isOpen={showConfirmModal}>
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          {enabled
          ? <p>are you sure you want to disable <strong>{name}</strong>?</p>
          : <p>are you sure you want to enable <strong>{name}</strong>?</p>
          }
          <Button className="btn-info" color="info" type="button" onClick={() => onYes()}>yes</Button>
          <Button className="btn-info" color="info" type="button" onClick={() => onNo()}>no</Button>
        </div>
      </ModalBody>
    </Modal>

    {/* Error modal */}
    <Modal className="companyModal credentialModal" toggle={() => setErrModal(!errModal)} isOpen={errModal}>
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          <p>you need to check agreement</p>
        </div>
      </ModalBody>
    </Modal>
  </li>);
}

export default DataSourceItem;
