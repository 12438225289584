import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import UserSubscriptionItem from "./UserSubscriptionItem";
import ConfirmModal from '../../modals/ConfirmModal';

import {
  Button,
  Collapse,
  NavLink,
} from "reactstrap";
import { QuickSightRoles } from 'helpers/constants';

const MemberPublisher = ({
  member,
  onUserStateUpdated,
  onUserSubscriptionUpdated,
  onUserPasswordReset,
  onUserRoleUpdated,
  roles,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUserToggleConfirmModalShown, setIsUserToggleConfirmModalShown] = useState(false);
  const [isResetConfirmModalShown, setIsResetConfirmModalShown] = useState(false);

  const {
    name, user_id: userId, email, role, qs_role: qsRole,
    data_sources: userSubscriptions, status
  } = member || {};
  const enabled = (status === 'active');

  const [newRole, setNewRole] = useState(role);
  const [newQsRole, setNewQsRole] = useState(qsRole);
  const [roleUpdateConfirmModalShow, setRoleUpdateConfirmModalShow] = useState(false);
  const [qsRoleUpdateConfirmModalShow, setQsRoleUpdateConfirmModalShow] = useState(false);

  const onUserToggleClicked = (e) => {
    e.preventDefault();
    setIsUserToggleConfirmModalShown(true);
    return false;
  }

  const onUserToggleYes = () => {
    onUserStateUpdated(userId, !enabled).then(() => {
      setIsUserToggleConfirmModalShown(false);
    })
  }

  const onUserPasswordResetYes = () => {
    onUserPasswordReset(userId).then(() => {
      setIsResetConfirmModalShown(false);
    })
  }

  const onRoleChangeConfirmed = () => {
    onUserRoleUpdated(userId, newRole).then(() => {
      setRoleUpdateConfirmModalShow(false);
    })
  }

  const onQsRoleChangeConfirmed = () => {
    onUserRoleUpdated(userId, newRole, newQsRole).then(() => {
      setQsRoleUpdateConfirmModalShow(false);
    })
  }

  return (
    <>
      <div className="datasource-wrap">
        <div className="drop-menu">
          <NavLink
            className={classnames("mb-sm-3 mb-md-0")}
            onClick={() => setIsOpen(!isOpen)}
            role="tab"
          >
            {name ? name : 'Unknown'}
            {status === 'active' && <i className={classnames("ni ni-button-play", { rotate: isOpen })}></i>}
          </NavLink>

          <div className="role--section">
            {role !== 'owner' && roles && (
              <select value={newRole} onChange={e => { setNewRole(e.target.value); setRoleUpdateConfirmModalShow(true) }}>
                {roles.map((item, idx) => <option key={idx} value={item}>{item}</option>)}
              </select>
            )}
          </div>

          <div className="role--section">
            {role !== 'owner' && (
              <select value={newQsRole} disabled
                onChange={e => { setNewQsRole(e.target.value); setQsRoleUpdateConfirmModalShow(true) }}>
                {QuickSightRoles.map((item, idx) => <option key={idx} value={item}>{item}</option>)}
              </select>
            )}
          </div>

          <div className="auth--section">
            {role !== 'owner' && <strong>{email}</strong>}
            {/* db username:  <strong>{dbUsername}</strong> */}
          </div>

          <div className="btn-wrap">
            {role !== 'owner' && status === 'active' &&
              <Button color="info" type="button" onClick={() => setIsResetConfirmModalShown(true)}>
                reset
              </Button>}
          </div>

          <div className="enable-option">
            {role !== 'owner' && <label className="custom-toggle">
              <input type="checkbox"
                checked={enabled}
                onClick={onUserToggleClicked}
                onChange={() => { }}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>}
          </div>
        </div>
      </div>
      {status === 'active' && <Collapse isOpen={isOpen}>
        <br />
        <ul>
          {userSubscriptions.length > 0 && userSubscriptions.map((subscription, idx) => (
            <UserSubscriptionItem
              key={idx} subscription={subscription} userId={userId}
              onUserSubscriptionUpdated={onUserSubscriptionUpdated}
            />
          ))}
        </ul>
      </Collapse>}
      <ConfirmModal
        description={
          enabled
            ? <p>are you sure you want to disable<br />this user?</p>
            : <p>are you sure you want to enable<br />this user?</p>
        }
        isOpen={isUserToggleConfirmModalShown}
        onYes={onUserToggleYes}
        onNo={() => setIsUserToggleConfirmModalShown(false)}
        onToggle={() => { setIsUserToggleConfirmModalShown(!isUserToggleConfirmModalShown) }} />
      <ConfirmModal
        description={<p>are you sure you want to reset<br /> the user's password? they will<br />receive a reset code via email.</p>}
        isOpen={isResetConfirmModalShown}
        onYes={onUserPasswordResetYes}
        onNo={() => setIsResetConfirmModalShown(false)}
        onToggle={() => { setIsResetConfirmModalShown(!isResetConfirmModalShown) }} />
      <ConfirmModal
        description={<>are you sure you want to change<br /> the member's role?</>}
        isOpen={roleUpdateConfirmModalShow}
        onYes={onRoleChangeConfirmed}
        onNo={() => setRoleUpdateConfirmModalShow(false)}
        onToggle={() => { setNewRole(role); setRoleUpdateConfirmModalShow(false) }} />
      <ConfirmModal
        description={<>are you sure you want to change<br />the member's quicksight role to {newQsRole}?</>}
        isOpen={qsRoleUpdateConfirmModalShow}
        onYes={onQsRoleChangeConfirmed}
        onNo={() => setQsRoleUpdateConfirmModalShow(false)}
        onToggle={() => { setNewQsRole(qsRole); setQsRoleUpdateConfirmModalShow(false) }} />
    </>
  )
}

MemberPublisher.propTypes = {
  member: PropTypes.object.isRequired,
  onUserRoleUpdated: PropTypes.func.isRequired,
  onUserStateUpdated: PropTypes.func.isRequired,
  onUserSubscriptionUpdated: PropTypes.func.isRequired,
  onUserPasswordReset: PropTypes.func.isRequired,
}

export default MemberPublisher;
