import React, { useState } from 'react';

import {
  Button,
  Container,
  Row, Col,
  Input,
} from "reactstrap";

import API from 'helpers/api';

const ToolsPage = () => {
  const [origin, setOrigin] = useState('');
  const [result, setResult] = useState('');
  const [logs, setLogs] = useState([]);

  const [nativeId, setNativeId] = useState('');
  const [productCode, setProductCode] = useState('');
  const [nativeIdLogs, setNativeIdLogs] = useState([]);

  const onEncrypt = () => {
    API.encrypt(origin).then(({value}) => {
      setResult(value);
      setLogs([...logs, [origin, value]]);
    }).catch(({response}) => {
      const { data } = response;
      alert(data.msg)
    })
  }

  const onDecrypt = () => {
    API.decrypt(origin).then(({value}) => {
      setResult(value);
      setLogs([...logs, [origin, value]]);
    }).catch(({response}) => {
      const { data } = response;
      alert(data.msg)
    })
  }

  const onDecryptCode = () => {
    API.decryptNativeID(nativeId).then(({code}) => {
      setProductCode(code);
      setNativeIdLogs([...nativeIdLogs, [nativeId, code]]);
    }).catch(({response}) => {
      const { data } = response;
      alert(data.msg)
    })
  }

  return (
    <Container>
      <Row>
        <Col><h2>Administration Tools</h2></Col>
      </Row>
      <Row>
        <Col sm={6}>
          <h3>Encrypt/Decrypt</h3>
          <Input onChange={(event) => setOrigin(event.target.value)} value={origin} />
          <Input disabled={true} value={result} />
          <Row>
            <Col xs={4}>
              <Button
                className={'form-control'}
                disabled={origin.length === 0}
                onClick={onEncrypt}
              >Encrypt</Button>
            </Col>
            <Col xs={{size: 4, offset: 4}}>
              <Button
                className={'form-control'}
                disabled={origin.length === 0}
                onClick={onDecrypt}
              >Decrypt</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {logs.reverse().map(([left, right], idx) => (
                <div key={idx}><code>{`${left} => ${right}`}</code><br /></div>
              ))}
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <h3>Native ID Toolkit</h3>
          <Input onChange={(event) => setNativeId(event.target.value)} value={nativeId} />
          <Input disabled={true} value={productCode} />
          <Row>
            <Col sm={{size: 4, offset: 8}}>
              <Button
                className={'form-control'}
                disabled={nativeId.length === 0}
                onClick={onDecryptCode}
              >Decrypt</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {nativeIdLogs.reverse().map(([left, right], idx) => (
                <div key={idx}><code>{`${left} => ${right}`}</code><br /></div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default ToolsPage;
