import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Collapse,
  NavLink,
} from "reactstrap";

const BuilderSnapInstruction = ({
  defaultOpen
}) => {
  const [ isOpen, setIsOpen ] = useState(defaultOpen);
  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu">
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          <h2>instructions
            <i className={classnames("ni ni-button-play float-none ml-2", {rotate: isOpen})}></i>
          </h2>
        </NavLink>
        <p>click the caret for pro-tips on building your dataset!</p>
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      <div className="desc-card text-left pl--20 font-15 max-width-700">
        <br />
        <h6><strong>how do I snap?</strong></h6>
        <p>
        <strong>snapshot</strong> allows you to observe tradable futures activity on a specific day.
        </p>
        <div className="text-left">
          <p>
            <strong>1.</strong> first select the number of contract tenors you would like 
                to use for all data families (prompt 24 months is the default). <br />
            <strong>2.</strong> next, choose the last available day or a specific date in the past as the 'snapshot day' that 
                will be the reference point for the dataset. <br />
            <strong>3.</strong> finally, select how many business days prior to the snapshot day 
                on which you would like to observe the activity of your data families.
          </p>
        </div>
        <div className="text-left">
          <p>
            <strong>1:</strong> previous day<br />
            <strong>5:</strong> previous week<br />
            <strong>21:</strong> previous month<br />
            <strong>63:</strong> previous quarter<br />
            <strong>252:</strong> previous year<br />
          </p>
        </div>
      </div>
    </Collapse>
  </>)
}

BuilderSnapInstruction.propTypes = {
  defaultOpen: PropTypes.bool,
}

BuilderSnapInstruction.defaultProps = {
  defaultOpen: false,
}

export default BuilderSnapInstruction;
