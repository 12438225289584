import React, { useState } from "react";
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import classnames from "classnames";

import {
  Button, Card, CardBody,
  FormGroup, Form,
  Input, InputGroupAddon, InputGroupText, InputGroup,
  Container, Row, Col,
} from "reactstrap";

import { setLoading } from './actions';
import { useAppContext } from 'libs/contextLib';

const transitionStyles = {
  enter: {
    transform: "translateX(0)"
  },
  show: {
    opacity: "1"
  }
}

const Login = ({
  isLoading,
  onLoadingChanged,
  location,
}) => {
  const { state: { givenEmail = '' } = {}, search } = location;
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [focusedCode, setFocusedCode] = useState(false);
  const [email, setEmail] = useState(givenEmail);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [notConfirmed, setNotConfirmed] = useState(false);
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [user, setUser] = useState(null);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [agree, setAgree] = useState(false)
  const { userHasAuthenticated } = useAppContext();

  const searchParams = new URLSearchParams(search);

  const history = useHistory();

  const submitCompleteNewPassword = (e) => {
    e.preventDefault();
    Auth.completeNewPassword(
      user,
      newPassword,
    ).then(() => {
      userHasAuthenticated(true);
      history.push('/admin/home');
    }).catch(e => {
      setError(e.message);
    })
  }

  const { hash } = useLocation();

  const submitLogin = async () => {
    onLoadingChanged(true);
    return Auth.signIn(email, password).then(res => {
      const { challengeName } = res;
      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(res)
        setNewPasswordRequired(true);
      } else {
        userHasAuthenticated(true);
        const url = searchParams.get('redirect') || '/admin/home';
        history.push(`${url}${hash}`);
      }
    }).catch(err => {

      if (err.code === 'UserNotConfirmedException') {
        setNotConfirmed(true);
      } else if (err.code === 'PasswordResetRequiredException') {
        history.push({
          pathname: '/auth/forgot-password',
          search: '?sent=yes',
          state: { codeAlreadysent: true },
        })
      } else {
        setError(err.message);
      }
    }).finally(() => {
      onLoadingChanged(false);
    });
  }

  const submitResend = async () => {
    onLoadingChanged(true);
    try {
      await Auth.resendSignUp(email);
      setCodeSent(true);
      setError('');
    } catch (e) {
      setError(e.message)
    } finally {
      onLoadingChanged(false);
    }
  }

  const submitConfirmCode = async () => {
    onLoadingChanged(true);
    try {
      await Auth.confirmSignUp(email, code);
      setNotConfirmed(false);
      setCodeSent(false);
      setError('');
    } catch (e) {
      setError(e.message);
    } finally {
      onLoadingChanged(false);
    }
  }

  if (user && newPasswordRequired) {
    return (
      <div className="full_wrap">
        <Container className="mt-10">
          <Row className="auth__row">
            <Col lg="5" md="7">
              <Card className="mb-0 auth_wrap signin--wrap">
                <CardBody className="px-lg-5">
                  <h1 style={{ ...transitionStyles['enter'] }}>ethereal</h1>
                  <h1 style={{ ...transitionStyles['enter'] }}>risk</h1>
                  <h3>enter your new password</h3>
                  <Row className="mt-4 mb-4">
                    <Col xs="12 mt-3">
                      <span className="auth--txt">already have one? </span>
                      <span
                        className={"auth--link"}
                        onClick={() => history.push("/auth/login")}
                      >
                        back to login
                      </span>
                    </Col>
                    <Col xs="12 mt-3">
                      <span className="auth--txt">forgot password? </span>
                      <span
                        className={"auth--link"}
                        onClick={() => history.push("/auth/forgot-password")}
                      >
                        recover it here
                      </span>
                    </Col>
                  </Row>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedCode,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="new password"
                          type="password"
                          value={newPassword}
                          onChange={(e) => { setNewPassword(e.target.value) }}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: focusedCode,
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="new password confirmation"
                          type="password"
                          value={newPasswordConfirmation}
                          onChange={(e) => { setNewPasswordConfirmation(e.target.value) }}
                        />
                      </InputGroup>
                    </FormGroup>
                    {error && (
                      <div className="text-muted">
                        <small>
                          <span className="text-yan font-weight-700">
                            {error}
                          </span>
                        </small>
                      </div>
                    )}
                    <div className="text-desc mt-4 mb-3">
                      <span>Let's keep your account safe! Your password should be at least 8 characters long and include an uppercase letter, a number, and a symbol</span>
                    </div>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckAgree"
                        type="checkbox"
                        checked={agree}
                        onChange={(e) => setAgree(e.target.checked)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckAgree"
                      >
                        <span className="text-muted">I've read and agree to the  &nbsp;
                          <Link to='/privacy' target={'_blank'}>Privacy Policy</Link>
                        </span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button
                        className="mt-4" color="info" type="button"
                        disabled={newPassword.length > 6 && newPassword !== newPasswordConfirmation && agree}
                        onClick={submitCompleteNewPassword}>
                        confirm new password
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  } else {
    return (
      <div className="full_wrap">
        <Container className="mt-10">
          <Row className="auth__row">
            <Col lg="5" md="7">
              <Card className="mb-0 auth_wrap signin--wrap">
                <CardBody className="px-lg-5">
                  <h1 style={{ ...transitionStyles['enter'] }}>ethereal</h1>
                  <h1 style={{ ...transitionStyles['enter'] }}>risk</h1>
                  <h3>sign in</h3>
                  <Row className="mt-4 mb-4">
                    {false && <Col xs="12 mt-3">
                      <span className="auth--txt">new user? </span>
                      <span
                        className={"auth--link"}
                        onClick={() => history.push("/auth/register")}
                      >
                        create account
                      </span>
                    </Col>}
                    <Col xs="12 mt-3">
                      <span className="auth--txt">forgot password? </span>
                      <span
                        className={"auth--link"}
                        onClick={() => history.push("/auth/forgot-password")}
                      >
                        recover it here
                      </span>
                    </Col>
                  </Row>
                  <Form role="form">
                    {codeSent ? (
                      <>
                        <FormGroup
                          className={classnames("mb-3", {
                            focused: focusedCode,
                          })}
                        >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="code"
                              type="text"
                              onFocus={() => setFocusedCode(true)}
                              onBlur={() => setFocusedCode(true)}
                              value={code}
                              onChange={(e) => { setCode(e.target.value) }}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id="customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheckLogin"
                          >
                            <span className="text-muted">remember me</span>
                          </label>
                        </div>
                        {error && (
                          <div className="text-muted">
                            <small>
                              <span className="text-yan font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        )}
                        <div className="text-center">
                          <Button className="mt-4" color="info" type="button" onClick={submitConfirmCode}>
                            Confirm Code
                          </Button>
                        </div>
                      </>)
                      : (
                        <>
                          <FormGroup
                            className={classnames("mb-3", {
                              focused: focusedEmail,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="email"
                                type="email"
                                onFocus={() => setfocusedEmail(true)}
                                onBlur={() => setfocusedEmail(true)}
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: focusedPassword,
                            })}
                          >
                            <InputGroup className="input-group-merge input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-lock-circle-open" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="password"
                                type="password"
                                onFocus={() => setfocusedPassword(true)}
                                onBlur={() => setfocusedPassword(true)}
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                              />
                            </InputGroup>
                          </FormGroup>
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id=" customCheckLogin"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor=" customCheckLogin"
                            >
                              <span className="text-muted">remember me</span>
                            </label>
                          </div>
                          {error && (
                            <div className="text-muted">
                              <small>
                                <span className="text-yan font-weight-700">
                                  {error}
                                </span>
                              </small>
                            </div>
                          )}
                          <div className="text-center mt-3">
                            {notConfirmed
                              ? (
                                <Button className="my-4" color="info" type="button" onClick={submitResend}>
                                  resend sode
                                </Button>)
                              : (
                                <Button className="my-4" disabled={isLoading} color="info" type="button" onClick={submitLogin}>
                                  sign in
                                </Button>)
                            }
                          </div>
                        </>)}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.isLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadingChanged: isLoading => dispatch(setLoading(isLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
