import React, { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';

import { Row, Col, Card, CardBody, CardHeader, CardFooter, Button } from 'reactstrap';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

// class Embed extends React.Component {

const Embed = () => {
    const [loadingDashboardList, setLoadingDashboardList] = useState(true);
    const [loadingDashboardUrl, setLoadingDashboardUrl] = useState(true);
    const [dashboards, setDashboards] = useState([]);
    const [selectedDashboard, setSelectedDashboard] = useState(null);
    const [error, setError] = useState('');    

    useEffect(() => {
        const getDashboardList = async () => {
            const data = await Auth.currentSession();
            const jwtToken = data.idToken.jwtToken;
            const params = { 
                headers: {},
                response: true,
                queryStringParameters: {
                    token: jwtToken,
                }
            }

            try {
                const response = await API.get('ethereal', `/qs/dashboards`, params);
                if (response.data.dashboards.length > 0) {
                    setDashboards(response.data.dashboards);
                    setSelectedDashboard(response.data.dashboards[0]);
                }
            } catch (e) {
                setError(e.message);
            }
            setLoadingDashboardList(false);
        }

        getDashboardList();
        
    }, [
        setLoadingDashboardUrl, setDashboards, setSelectedDashboard
    ]);
    
    useEffect(() => {
        const getDashboardUrl = async () => {
            const data = await Auth.currentSession();
            const jwtToken = data.idToken.jwtToken;
            
            const params = { 
                headers: {},
                response: true,
                queryStringParameters: {
                    token: jwtToken,
                }
            }
    
            if (!selectedDashboard) {
                return null;
            }
            const dashboardId = selectedDashboard.id;
    
            setLoadingDashboardUrl(true);
            const quicksight = await API.get('ethereal', `/qs/dashboards/${dashboardId}`, params);
            const containerDiv = document.getElementById("dashboardContainer");
            if (containerDiv.firstChild) {
                containerDiv.removeChild(containerDiv.firstChild);
            }
    
            const options = {
                url: quicksight.data.url,
                container: containerDiv,
                parameters: {
                    country: "United States"
                },
                scrolling: "yes",
                height: "100%",
                width: "100%",
                footerPaddingEnabled: false,
            };
            QuickSightEmbedding.embedDashboard(options);
            setLoadingDashboardUrl(false);
        }

        getDashboardUrl();
    }, [
        setLoadingDashboardUrl, selectedDashboard
    ]);
    
    return (
    <>
        {error && <h4>{error}</h4>}
        {loadingDashboardList ? (
            <h2>Loading Dashboard List...</h2>
        ) : loadingDashboardUrl && (
            <h2>Loading Preview... </h2>
        )}
        <Row key={'dashboard-container'}>
            <Col key={'dashboard-list'}>
                {dashboards.length > 0 && dashboards.map(dashboard => (
                    <Card>
                        <CardHeader>{dashboard.name}</CardHeader>
                        <CardBody>Thumbnail image here...</CardBody>
                        <CardFooter>
                            <Button onClick={() => {setSelectedDashboard(dashboard)}}>Previwe</Button>
                        </CardFooter>
                    </Card>
                    )
                )}
            </Col>
            <Col key={'dashboard-preview'}>
                <div className='dashboardContainer' id='dashboardContainer'></div>
            </Col>
        </Row>
    </>);
}

export default Embed;
