import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import classnames from "classnames";

import {
  Button, Row, Col,
} from 'reactstrap';
import Select2 from "react-select2-wrapper";

import ConfirmModal from 'components/modals/ConfirmModal';

const DepartmentItem = ({
  id, name, users, members, onUpdateMembers, onDeleteDepartment,
  isAdmin,
}) => {
  const [showDeleteDepartmentModal, setShowDeleteDepartmentModal] = useState(false);
  const [showUpdateMembersModal, setShowUpdateMembersModal] = useState(false);
  const [newMembers, setNewMembers] = useState([]);

  const onChange = () => {
    onUpdateMembers(id, newMembers).then(() => {
      setShowUpdateMembersModal(false);
    })
  };

  const onDelete = () => {
    onDeleteDepartment(id)
    .then(() => {
      setShowDeleteDepartmentModal(false);
    })
  };

  useState(() => {
    setNewMembers(members);
  }, [setNewMembers, members]);

  return (
    <>
    <Row>
      <Col>
        <h3>{name}</h3>
      </Col>
      {isAdmin && <Col>
        <Button color="danger" className='float-right btn-sm mt-4' type="button" onClick={() => setShowDeleteDepartmentModal(true)}>
          delete
        </Button>
      </Col>}
    </Row>
    <Row>
      <Col>
        <Select2
          className={'form-control'}
          disabled={!isAdmin}
          data-minimum-results-for-search="Infinity"
          value={newMembers}
          multiple
          onChange={(e) => {setNewMembers(Array.from(e.target.selectedOptions, option => option.value))}}
          data={users.map(({
            user_id: userId, name, role, email,
          }) => ({id: userId, text: `${name}(${email}): ${role}`}))}
          />
      </Col>
    </Row>
    <Row className='mt-2'>
      <Col>
        <Button
          color='primary' className='btn-sm float-right'
          disabled={!isAdmin || members === newMembers}
          onClick={() => setShowUpdateMembersModal(true)}
        >Save changes</Button>
      </Col>
    </Row>
    <ConfirmModal
      description={<>are you sure you want to delete<br/>{name} department?</>}
      isOpen={showDeleteDepartmentModal}
      onYes={onDelete}
      onNo={() => setShowDeleteDepartmentModal(false)}
      onToggle={() => {setShowDeleteDepartmentModal(false)}} />
    <ConfirmModal
      description={`are you sure to update members of ${name} department?`}
      isOpen={showUpdateMembersModal}
      onYes={onChange}
      onNo={() => setShowUpdateMembersModal(false)}
      onToggle={() => setShowUpdateMembersModal(false)} />
  </>
  )
};

DepartmentItem.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  })).isRequired,
  members: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onUpdateMembers: PropTypes.func.isRequired,
  onDeleteDepartment: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

DepartmentItem.defaultProps = {
};

export default DepartmentItem;
