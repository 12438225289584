import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Collapse,
  NavLink,
} from "reactstrap";

const BuilderInstruction = ({
  defaultOpen
}) => {
  const [ isOpen, setIsOpen ] = useState(defaultOpen);
  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu">
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          <h2>instructions
            <i className={classnames("ni ni-button-play float-none ml-2", {rotate: isOpen})}></i>
          </h2>
        </NavLink>
        <p>click the caret for pro-tips on building your dataset!</p>
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      <div className="desc-card text-left pl--20 font-15 max-width-700">
        <br />
        <h6><strong>show me some time series!</strong></h6>
        <p>
          your data families are organized by data source on the left.
        </p>
        <div className="text-left">
          <p>
            <strong>1.</strong> add the contracts and spreads you want. <br />
            <strong>2.</strong> select the dataset time-frame (e.g. 1, 3, 5 years) <br />
            <strong>3.</strong> save your dataset
          </p>
        </div>
        <p><strong>how do I add contracts?</strong></p>
        <div className="text-left">
          <p>
            <strong>1.</strong> select whether you want <strong>relative</strong>, <strong>fixed</strong>, or <strong>color</strong> contract type.<br />
            <strong>2.</strong> select the specific tenor dimensions<br />
            &nbsp;&nbsp;&nbsp;<strong>a.</strong> for <strong>relative</strong> contract, just pick the line (e.g. 1, 2, 3) you want.<br />
            &nbsp;&nbsp;&nbsp;<strong>b.</strong> for <strong>fixed</strong> contract, select the specific tenor (e.g. "01Dec21") <br />
            &nbsp;&nbsp;&nbsp;<strong>c.</strong> for for color contract, select the month (e.g. Jan, Feb, etc) and color (white, red, blue)<br />
            <strong>3.</strong> select your desired measure (such as settlement)<br />
            <strong>4.</strong> click "<strong>remove</strong>" to delete this member.<br />
            <strong>5.</strong> click "<strong>new</strong>" to add another member to the same data family.<br />
          </p>
        </div>
        <p><strong>how do I add a spread?</strong></p>
        <div className="text-left">
          <p>
            <strong>1.</strong> select the two spread contracts<br />
            <strong>2.</strong> click "<strong>remove</strong>" to delete this spread
          </p>
        </div>
      </div>
    </Collapse>
  </>)
}

BuilderInstruction.propTypes = {
  defaultOpen: PropTypes.bool,
}

BuilderInstruction.defaultProps = {
  defaultOpen: false,
}

export default BuilderInstruction;
