import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// reactstrap components
import {
  Container, Row, Col,
  FormGroup, Button,
  Input, InputGroup,
  // Nav, NavItem,
} from "reactstrap";

import API from '../../helpers/api';
import { timeFrames } from "helpers/constants";
import {
  fetchCartRequest, clearSelectedFilterOptions,
  checkoutCartRequest, checkoutCartSuccess, checkoutCartError
} from './actions'

import {
  setDatasetMember, removeDatasetMember,
  addSpreadsIndex, updateSpreadsIndex, removeSpreadsIndex,
  addCustomIndex, updateCustomIndex, removeCustomIndex,
  updateCustomIndexAttributes, updateSelectedCustomIndex,
} from 'containers/datasets/actions';

import CodeModal from 'components/modals/CodeModal';
import BuilderTitle from "components/molecules/builder/BuilderTitle";
import IndicesItem from "components/molecules/builder/IndicesItem";
import CustomIndices from "components/molecules/builder/CustomIndices";
import AttributeSelect from "components/molecules/builder/AttributeSelect";
import BuilderPublisher from "components/molecules/builder/BuilderPublisher";
import BuilderInstruction from "components/molecules/builder/BuilderInstruction";

function DataBuilderMember({
  cartItems, fetchCartRequest,
  checkoutCartRequest, checkoutCartSuccess, checkoutCartError,
  setDatasetMember, removeDatasetMember,
  // familyOptions, fetchFamilyOptionsRequest, fetchingFamilyOptions,
  members, spreads, custom,
  addSpreadsIndex, updateSpreadsIndex, removeSpreadsIndex,
  addCustomIndex, updateCustomIndex, removeCustomIndex,
  updateCustomIndexAttributes, updateSelectedCustomIndex,
  clearSelectedFilterOptions,
}) {
  const marketDataFamilies = cartItems.filter(item => item.data_type === 'market_data');
  const fundamentalDataFamilies = cartItems.filter(item => item.data_type === 'fundamental_data');

  const [datasetName, setDatasetName] = useState('');
  const [showQuery, setShowQuery] = useState(false);
  const [query, setQuery] = useState('');
  const [timeFrame, setTimeFrame] = useState(timeFrames[0].value);

  const selIndice = custom.filter(item => item.isSelected);

  const history = useHistory();

  const getMemberOptionValue = ({ id }) => (id);

  const groupedMarketDataFamilies = marketDataFamilies.reduce((acc, cur) => {
    if(!acc[cur.publisher]){
      acc[cur.publisher]=[]
    }
    acc[cur.publisher].push(cur)
    return acc
  }, {})

  const groupedFundamentalDataFamilies = fundamentalDataFamilies.reduce((acc, cur) => {
    if(!acc[cur.publisher]){
      acc[cur.publisher]=[]
    }
    acc[cur.publisher].push(cur)
    return acc
  }, {})

  const memberOptions = Object.entries(members).filter(([productId]) => productId.indexOf('cot__') === -1).reduce(
    (accumulator, [productId, members], productIndex) => accumulator.concat(members.map(
      (member, memberIndex) => ({productId, productIndex, memberIndex, member}))
    ), []);

  const memberList = memberOptions.map(({member, productId, memberIndex, productIndex}) => ({
    id: member.id,
    name: member.column_name_alias,
    value: getMemberOptionValue(member),
    productId,
    data: member,
  }));

  const getDefaultSpreadsIndex = () => (
    memberOptions.length === 0
    ? {}
    : {
      left: getMemberOptionValue(memberOptions[0].member),
      right: getMemberOptionValue(memberOptions[0].member),
      whole: false,
    }
  )

  const getDefaultCustomIndex = () => ({
    alias: '',
    func: '',
    isSelected: false,
    cursor: 0,
  })

  // custom Indices
  const appendToSelectedCustomIndex = (native_id: String) => {
    const [selected] = selIndice;

    if (!selected) {
      return
    }

    const { id, func, cursor = 0 } = selected;

    updateCustomIndexAttributes(id, {
      func: func.substring(0, cursor) + native_id + func.substring(cursor),
      cursor: cursor + native_id.length
    })
  }

  useEffect(() => {
    fetchCartRequest()
  }, [fetchCartRequest]);

  const saveDataSet = () => {
    checkoutCartRequest()
    API.checkout(
      datasetName,
      {
        custom: custom.filter(item => item.func.length > 0 && item.alias.length > 0).map(
          ({func, alias}) => ({func, alias})),
        spreads,
      },
      members,
      timeFrame
    ).then(() => {
      checkoutCartSuccess()
      clearSelectedFilterOptions()
      history.push('/admin/library');
    }).catch(err => {
      checkoutCartError()
      alert('Something went wrong');
    })
  }

  const updateSpreadsIndexAttribute = (
    idx: Number
  ) => (attribute: String, value: Any) => {
    const curIndex = {
      ...spreads[idx],
      [attribute]: value,
    }

    updateSpreadsIndex({index: idx, spreadsIndex: curIndex});
  }

  const onPreviewQuery = () => {
    API.previewDataSetQuery(
      members,
      spreads,
      custom.filter(item => item.func.length > 0 && item.alias.length > 0).map(
        ({func, alias}) => ({func, alias})),
      timeFrame
    ).then(({ query, status, msg }) => {
      if (status) {
        setQuery(query)
        setShowQuery(true);
      } else {
        console.warn(msg)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const addIndices = (flag: String, length: Number) => {
    if( length !== 0 ) {
      if( flag === 'spread' ) {
        addSpreadsIndex({
          id: uuidv4(),
          ...getDefaultSpreadsIndex()
        });
      } else {
        addCustomIndex({
          id: uuidv4(),
          ...getDefaultCustomIndex(),
          alias: `customm${length}`
        })
      }
    }
  }

  return (
    <>
      <Container className="databuilder datamember pt--20" fluid>
        <Row>
          <Col xl="8" className="border--b">
            <h1>
              build your dataset  &nbsp;&nbsp;&nbsp;
              <Button color="info" type="button" onClick={() => history.push('/admin/creator')} className="add-btn">modify data family</Button>
            </h1>
            <p className="mb--0">select data family contracts and create custom indices</p>

          </Col>
          <Col xl="4" className="border--b">
            <h2 className="mb-0">save your dataset</h2>
            <p className="mb-0">add dataset to library</p>
            <div className="flex flex-start dataset-input">
              <FormGroup
                className={"mb-0 mt-1"}>
                <InputGroup className="input-group-merge input-group-alternative mt-0">
                  <Input
                    placeholder="name your dataset"
                    type="text"
                    value={datasetName}
                    onChange={(e) => {setDatasetName(e.target.value)}}
                  />
                </InputGroup>
              </FormGroup> &nbsp;              
              {process.env.REACT_APP_STAGE !== 'production' && <Button color="info" type="button" onClick={onPreviewQuery} className="mr--0">preview</Button>}
              <Button color="info" type="button" onClick={saveDataSet}>save</Button>
            </div>
          </Col>
        </Row>
        <Row className="member_content">
          {/* --- data family member selection --- start */}
          <Col xl="7" className="border--r">
            <BuilderInstruction defaultOpen={false} />

            <br />
            <BuilderTitle 
              title="data family member selection"
              subtitle="add tenor and attributes for family members"  />

            <div className="ml-2 dataFamilyMemberSection">
              {Object.entries(groupedMarketDataFamilies).map(([exchangeGroup, families]) => (
                <BuilderPublisher
                  key={exchangeGroup} name={exchangeGroup} families={families}
                  members={members} setDatasetMember={setDatasetMember}
                  removeDatasetMember={removeDatasetMember}
                  defaultOpen={true}
                  selIndice={selIndice}
                  copyToIndexBtnClick={appendToSelectedCustomIndex}
                />
              ))}
              
              {Object.entries(groupedFundamentalDataFamilies).map(([exchangeGroup, families]) => (
                <BuilderPublisher
                  key={exchangeGroup} name={exchangeGroup} families={families}
                  members={members} dataType={'fundamental'}
                  defaultOpen={true}
                />
              ))}
            </div>
            
          </Col>
          {/* --- data family member selection --- end */}

          {/* --- create market data family indices --- start */}
          <Col xl="5" className="pl--40">
            <h2 className="mb-0 mt-4">dataset time-frame</h2>
            <p>select time-frame for your dataset</p>
            <div className="flex flex-start align-item-center border--b pb--20">
                <label className="mb-0">number of years &nbsp;&nbsp;</label>
                <AttributeSelect
                    value={timeFrame}
                    options={timeFrames}
                    onChange={setTimeFrame}/> 
            </div>
            <br />
            
            <BuilderTitle
              title="market data family indices"
              subtitle="add spreads and custom functions" btn="" />
            <Row>
              <Col xl="12">
                <h2 className="mt-2">spreads</h2>
                {spreads.map((spread, idx) => (
                  <IndicesItem
                    key={idx}
                    item={spread}
                    itemkey={idx}
                    members={memberList}
                    onUpdate={updateSpreadsIndexAttribute(idx)}
                    getMemberOptionValue={getMemberOptionValue}
                    onRemove={() => removeSpreadsIndex({index: idx})}
                    type="spreads" />
                ))}
                <Button
                  color="info" title={memberOptions.length === 0 ? 'please add contracts.' : undefined}
                  type="button" className="add-btn"
                  disabled={memberOptions.length === 0}
                  onClick={() => addIndices('spread', memberOptions.length)}
                >new spread</Button>
              </Col>
            </Row>

            <br />
            <h2 className="mb-0 mt-4">custom indices</h2>
            <Button color="info" type="button" className="operation-btn" onClick={() => appendToSelectedCustomIndex('+')}>+</Button>
            <Button color="info" type="button" className="operation-btn" onClick={() => appendToSelectedCustomIndex('*')}>x</Button>
            <Button color="info" type="button" className="operation-btn" onClick={() => appendToSelectedCustomIndex('-')}>-</Button>
            <Button color="info" type="button" className="operation-btn" onClick={() => appendToSelectedCustomIndex('/')}>/</Button>

            <CustomIndices 
              indices={custom}
              addNewCustomIndex={() => addIndices('custom', custom.length + 1)}
              removeCustomIndex={removeCustomIndex}
              selectCustomIndex={updateSelectedCustomIndex}
              updateCustomIndexAttributes={updateCustomIndexAttributes}
              />
                        
          </Col>
        </Row>
        <CodeModal title='Preview DataSet Query' isOpen={showQuery} onToggle={() => setShowQuery(!showQuery)} content={query} />
      </Container>
    </>
  );
}

const mapStateToProps = state => ({
  cartItems: state.admin.cartItems,
  fetchingFamilyOptions: state.datasets.fetchingFamilyOptions,
  familyOptions: state.datasets.familyOptions,
  members: state.datasets.datasetBuilderDraft.members,
  spreads: state.datasets.datasetBuilderDraft.spreads,
  custom: state.datasets.datasetBuilderDraft.custom
    // .length > 0
    // ? state.datasets.datasetBuilderDraft.custom
    // : [{func: '', alais: ''}],
})

const mapDispatchToProps = dispatch => ({
  checkoutCartRequest: () => dispatch(checkoutCartRequest()),
  checkoutCartSuccess: () => dispatch(checkoutCartSuccess()),
  checkoutCartError: () => dispatch(checkoutCartError()),
  fetchCartRequest: () => dispatch(fetchCartRequest({ queryType: 'time_series' })),
  setDatasetMember: (familyId: String, index: Number, member: Object) => dispatch(setDatasetMember({familyId, member, index})),
  removeDatasetMember: (familyId: String, index: Number) => dispatch(removeDatasetMember({familyId, index})),
  addSpreadsIndex: (payload: {left: Object, right: Object}) => dispatch(addSpreadsIndex(payload)),
  updateSpreadsIndex: (payload: {left: String, right: String}) => dispatch(updateSpreadsIndex(payload)),
  removeSpreadsIndex: (payload: {index: Number}) => dispatch(removeSpreadsIndex(payload)),
  addCustomIndex: (payload: {
    id: String, func: String, alias: String, isSelected: Boolean, cursor: Number,
  }) => dispatch(addCustomIndex(payload)),
  updateCustomIndex: (payload: {
    id: String, func: String, alias: String, isSelected: Boolean, cursor: Number,
  }) => dispatch(updateCustomIndex(payload)),
  updateCustomIndexAttributes: (id: String, attributes: Objects) => dispatch(updateCustomIndexAttributes({
    id, attributes,
  })),
  updateSelectedCustomIndex: (id: String, isSelected: Boolean = true) => dispatch(updateSelectedCustomIndex(
    {id, isSelected}
  )),
  removeCustomIndex: (id: String) => dispatch(removeCustomIndex({id})),
  clearSelectedFilterOptions: () => dispatch(clearSelectedFilterOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataBuilderMember);
