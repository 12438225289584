import React from "react";

import SessionEmbedDashboard from 'components/QuickSight/SessionEmbed';

function SessionEmbed() {
  return (
    <>
      <SessionEmbedDashboard />
    </>
  );
}

export default SessionEmbed;
