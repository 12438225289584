import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import {
  Row, Col,
} from "reactstrap";

import { volatilityScatterPlotSpec as originalSpec } from "../charts/vegaPriceViewSpecs";
import VegaChart from "../charts/VegaChart";

const VolatilityView = ({
  commodity, contract, data,
  volHigh, volLow, steepness,
}) => {
  const { nativeID, title, subpublisher } = commodity;
  const [spec, setSpec] = useState(originalSpec);
  useEffect(() => {
    if (!data.length) {
      return
    }

    const {
      vconcat: [
        {
          layer: [
            firstLayer,
            verticalLayer, horizontalLayer,
            baseVolLayer, highLineLayer, lowLineLayer,
            ...otherLayers
          ],
          ...others
        },
        ...items
      ],
    } = originalSpec;

    const atmvolMin = Math.min(...data.map(({ atmvol }) => atmvol))
    const atmvolMax = Math.max(...data.map(({ atmvol }) => atmvol))
    const futurepriceMin = Math.min(...data.map(({ futuresprice }) => futuresprice))
    const futurepriceMax = Math.max(...data.map(({ futuresprice }) => futuresprice))

    const lastVolData = data[data.length - 1]
    const { futuresprice: startingFlatPrice, atmvol: sigma_0 } = lastVolData;

    const getBaseVol = (price: Number) => (sigma_0 + steepness * Math.log(price / startingFlatPrice))
    const getHighVolScenario = (price: Number) => (getBaseVol(price) + volHigh)
    const getLowVolScenario = (price: Number) => (getBaseVol(price) + volLow)

    setSpec({
      ...originalSpec,
      vconcat: [
        {
          layer: [
            firstLayer,
            {
              ...verticalLayer,
              data: {
                values: [
                  { y: atmvolMin, x: startingFlatPrice },
                  { y: atmvolMax, x: startingFlatPrice },
                ]
              }
            },
            {
              ...horizontalLayer,
              data: {
                values: [
                  { y: sigma_0, x: futurepriceMin },
                  { y: sigma_0, x: futurepriceMax }
                ]
              }
            },
            {
              ...baseVolLayer,
              data: {
                values: [
                  { y: getBaseVol(futurepriceMin), x: futurepriceMin },
                  { y: getBaseVol(futurepriceMax), x: futurepriceMax }
                ]
              }
            },
            {
              ...highLineLayer,
              data: {
                values: [
                  { y: getHighVolScenario(futurepriceMin), x: futurepriceMin },
                  { y: getHighVolScenario(futurepriceMax), x: futurepriceMax }
                ]
              }
            },
            {
              ...lowLineLayer,
              data: {
                values: [
                  { y: getLowVolScenario(futurepriceMin), x: futurepriceMin },
                  { y: getLowVolScenario(futurepriceMax), x: futurepriceMax }
                ]
              }
            },
            ...otherLayers
          ],
          ...others,
        },
        ...items
      ]
    });
  }, [
    data, volHigh, volLow, steepness,
  ])

  useEffect(() => {
    console.log(spec)
  }, [spec])

  return (
    <div className="sub-page">
      <b>{`${title} volatility scatter plot`}</b>
      <Row style={{ height: 500 }}>
        <Col>
          {nativeID && contract && subpublisher && data.length > 0 &&
            <VegaChart
              spec={{
                ...spec,
                data: {
                  values: data
                }
              }}
            />}
        </Col>
      </Row>
    </div>)
}

VolatilityView.propTypes = {
  commodity: PropTypes.shape({
    nativeID: PropTypes.string.isRequired,
    subpublisher: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  contract: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    native_id: PropTypes.string.isRequired,
  })),
  volLow: PropTypes.number.isRequired,
  volHigh: PropTypes.number.isRequired,
  steepness: PropTypes.number.isRequired,
}

export default VolatilityView;
