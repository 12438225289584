import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { VegaLite } from 'react-vega';

import RestAPI from 'helpers/api';

export function VegaChart({
  spec,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [configWithoutUrl, setConfigWithoutUrl] = useState({ name: 'data' });
  const [prevUrl, setPrevUrl] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    const { data: defaultData = {} } = spec;
    const { url, format: { property } = {} } = defaultData;

    if (!!url && !!property) {
      setConfigWithoutUrl({ name: property });

      if (url !== prevUrl) {
        setPrevUrl(url);
      }
    } else {
      setConfigWithoutUrl(defaultData);
    }
  }, [
    spec, prevUrl,
  ]);

  useEffect(() => {
    if (!prevUrl) {
      return
    }

    const location = new URL(prevUrl);
    setIsLoading(true);
    RestAPI.sendGetRequest(`${location.pathname}${location.search}`)
      .then((response) => {
        setData(response);
      })
      .catch((err) => { console.error(err) })
      .finally(() => { setIsLoading(false); })
  }, [
    prevUrl,
  ])

  return (
    <div style={{ overflow: 'auto' }}>
      {!isLoading && <VegaLite
        spec={{
          ...spec,
          data: configWithoutUrl,
        }}
        actions={false}
        renderer={'svg'}
        data={data}
      />}
    </div>
  );
}

VegaChart.propTypes = {
  spec: PropTypes.object.isRequired,
}

export default VegaChart;
