import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';

// import classnames from "classnames";

import API from '../../helpers/api';
import OutputView from "components/molecules/optimizer/OutputView";

function OptionsOptimizerLogs() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    API.fetchOptionsRecommenderLogs()
    .then(({logs}) => {
      setLogs(logs);
    })
  }, []);

  return (
    <section className="optimizer">
      <h1 className="border--b">{'options optimizer logs'}</h1>
      <OutputView logs={logs}/>
    </section>
  )
}

export default connect(null, null)(OptionsOptimizerLogs);
