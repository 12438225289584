import React from 'react';

import PropTypes from 'prop-types';

// reactstrap components
import { 
  Input, Button, FormGroup , InputGroup
} from "reactstrap";

const CustomIndices = ({
  indices, addNewCustomIndex, removeCustomIndex,
  updateCustomIndexAttributes, selectCustomIndex,
}) => {
  const onSetIsSelected = (id, target) => {
    selectCustomIndex(id)
  }

  const onSetCustomValue = (id: String, func: String, selectionStart: Number) => {
    updateCustomIndexAttributes(id, {
      func: func,
      cursor: selectionStart,
    })
  }

  const onClickCustomValue = (id, selectionStart) => {
    updateCustomIndexAttributes(id, {cursor: selectionStart});
  }

  return (
    <>
      {indices.length === 0 && (
        <Button
          color="info" type="button"
          className="new-btn ptb--6"
          onClick={() => addNewCustomIndex()}
        >new</Button>)
      }
      {indices.map(({
        id, isSelected, func, alias,
      }, idx) => {
        return (
          <div key={idx} className="flex custom-indice flex-start">
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id={`customIndiceOption${idx}`}
                data={idx}
                type="checkbox"
                checked={!!isSelected}
                onChange={(e) => {onSetIsSelected(id, e.target)}}
              />
              <label
                className="custom-control-label"
                htmlFor={`customIndiceOption${idx}`}
              >
              </label>
            </div>
            <FormGroup
              className={"mb-0"}>
              <InputGroup className="input-group-merge input-group-alternative mt-0">
                <Input
                  placeholder=""
                  type="text"
                  id={`customIndexInput${idx}`}
                  className="custom-indices-value"
                  value={func}
                  readOnly={!isSelected}
                  onChange={(e) => {onSetCustomValue(id, e.target.value, e.target.selectionStart)}}
                  onClick={(e) => {onClickCustomValue(id, e.target.selectionStart)}}
                />
              </InputGroup>
            </FormGroup> &nbsp;
            <FormGroup
              className={"mb-0"}>
              <InputGroup className="input-group-merge input-group-alternative mt-0">
                <Input
                  placeholder=""
                  type="text"
                  id={`customIndicAlias${idx}`}
                  className="custom-indices-alias"
                  value={alias}
                  onChange={(e) => {updateCustomIndexAttributes(id, {alias: e.target.value})}}
                />
              </InputGroup>
            </FormGroup>&nbsp;
            <Button
              color="remove" type="button" className="remove-btn mr--0"
              onClick={() => {removeCustomIndex(id)}}
            >remove</Button>&nbsp;
            {idx === indices.length - 1 && <Button
              color="info" type="button"
              className="new-btn ptb--6"
              onClick={() => addNewCustomIndex()}
              >new</Button>}
          </div>
        )
      })}
      <p>not available for fundamental data</p>
    </>
  )
}

CustomIndices.propTypes = {
  indices: PropTypes.arrayOf(PropTypes.object).isRequired,
  addNewCustomIndex: PropTypes.func.isRequired,
  removeCustomIndex: PropTypes.func.isRequired,
  updateCustomIndexAttributes: PropTypes.func.isRequired,
  selectCustomIndex: PropTypes.func.isRequired,
}

CustomIndices.defaultProps = {
  indices: []
}

export default CustomIndices;
