import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Collapse,
  NavLink
} from "reactstrap";
import moment from "moment";
// import { useHistory } from 'react-router-dom';
import RestAPI from "helpers/api";
import PortfolioSection from 'components/molecules/scenario/PortfolioSection';
import MarketDataSection from 'components/molecules/scenario/MarketDataSection';
import AnalysisSection from 'components/molecules/scenario/AnalysisSection';
import HeatmapSection from 'components/molecules/scenario/HeatmapSection';
import { analysisContractOptions, analysisProductOptions } from "helpers/constants";

function Scenario() {
  let defaultLeg = {
    product: analysisProductOptions[0].value, contract: analysisContractOptions[0],
    direction: 'buy', weight: 0.0, strike: 0.0, type: 'call'
  };

  const [fetchingMarket, setFetchingMarket] = useState(false);
  const [portfolioName, setPortfolioName] = useState('');
  const [legs, setLegs] = useState([defaultLeg]);
  const [marketData, setMarketData] = useState([]);
  const [heatmap, setHeatmap] = useState([]);
  const [isOpenInput, setIsOpenInput] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const [flatPriceMeta, setFlatPriceMeta] = useState({min: -10, max: 10, delta: 2})
  const [volatilityMeta, setVolatilityMeta] = useState({min: -5, max: 5, delta: 1})

  const [chartData, setChartData] = useState([{
    id: 'chart',
    data: []
  }])

  const fetchMarketData = useCallback(() => {
    setFetchingMarket(true);
    RestAPI.fetchMarketData(legs)
    .then(({status, scenarios}) => {
      if (status) {
        setMarketData(scenarios)
      }
    }).catch(err => {
      console.error(err);
    }).finally(() => {
      setFetchingMarket(false);
    })
  }, [
    setMarketData, legs,
    setFetchingMarket,
  ]);

  const analyseScenario = useCallback(() => {
    setIsOpenInput(false);
    setIsOpen(true);
    return RestAPI.fetchScenarioAnalysis(
      legs, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'),
      flatPriceMeta, volatilityMeta,
    ).then(({
      status, heatmap
    }) => {
      if (status) {
        setHeatmap(heatmap);
      } else {
        console.error('Failed');
      }
    }).catch(err => {
      console.error(err);
    })
  }, [
    setHeatmap, legs,
    startDate, endDate,
    flatPriceMeta, volatilityMeta,
  ])

  const onNewLeg = () => {
    setLegs([...legs, {...defaultLeg}]);
  }

  const onChangeLeg = (key: String, idx: Number) => (value) => {
    setLegs(legs.map((item, index) => {
      if (index === idx) {
        return {
          ...item,
          [key]: value
        }
      } else {
        return item
      }
    }));
    fetchMarketData();
  };

  const onRemoveLeg = (index: Number) => {
    setLegs(legs.filter((item, idx) => idx !== index))
    fetchMarketData();
  }

  const onSavePortfolio = () => {
    console.warn('Not implemented! Just a placeholder.')
  }

  useEffect(() => {
    fetchMarketData(legs)
  }, [legs, fetchMarketData]);

  return (
    <>
      <Container className="scenario-container overflow-hidden" fluid>
        <Row className="mt-4">
          <Col xl="12">
            <h1 className="border--b">options scenario analysis</h1>
          </Col>
        </Row>

        <Collapse isOpen={isOpenInput}>
          <Row className="mt-2">
            <Col xl="6" className="border--r">
              <PortfolioSection
                title="construct options portfolio"
                portfolioName={portfolioName} onChangePortfolioName={setPortfolioName}
                legs={legs} onNewLeg={onNewLeg}
                onChangeLeg={onChangeLeg}
                onRemoveLeg={onRemoveLeg}
                onSavePortfolio={onSavePortfolio}
                fetching={fetchingMarket}
                />
            </Col>
            <Col xl="6">
              <MarketDataSection
                title="view current market data"
                marketData={marketData}
              />
              <br /><br />
              <AnalysisSection
                title="set scenario analysis parameters"
                onRunAnalysis={analyseScenario}
                startDate={startDate}
                onChangeStartDate={setStartDate}
                endDate={endDate}
                onChangeEndDate={setEndDate}
                flatPrice={flatPriceMeta}
                onChangeFlatPrice={setFlatPriceMeta}
                volatility={volatilityMeta}
                onChangeVolatility={setVolatilityMeta}
                chartData={chartData}
                onChangeChartData={setChartData}
                />
            </Col>
          </Row>
        </Collapse>
        <Row className="mt-4">
          <Col xl="12">
            <NavLink
              className="collapse-btn"
              onClick={() => { 
                setIsOpenInput(!isOpenInput);
                setIsOpen(true); 
              }}
              role="tab"
            >
              <Button color="info" size="sm">
                {!isOpenInput ? 'show inputs':'collapse inputs'}
              </Button>
            </NavLink>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl="12">
            <Collapse isOpen={isOpen}>
              <HeatmapSection
                title="view options scenario analysis"
                heatmap={heatmap} />
            </Collapse>
          </Col>
        </Row>
        <br /><br />
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {

  }
}

const mapDispatchToProps = dispatch => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scenario);
