import React from "react";
import PropTypes from 'prop-types';

import {
  Row, Col,
  Table,
} from "reactstrap";

function OutputView({
  logs,
}) {
  return (
    <div className="sub-page">
      <p className="pl-3">{`please check your options recommender report status here.`}</p>

      <Row>
        <Col xl={12}>
          <Table className="align-items-center table-flush" responsive>
            <thead>
              <tr>
                <td>{`#`}</td>
                <td>{`commodity`}</td>
                <td>{`trade date`}</td>
                <td>{`contract`}</td>
              </tr>
            </thead>
            <tbody>
              {logs.length === 0 ? (
                <tr>
                  <td colSpan={2}>{`no data found.`}</td>
                </tr>
              ) : logs.map(({
                id,
                params: {
                  trade_date: tradeDate,
                  commodity: [subpublisher, _, nativeID],
                  contract
                }
              }, idx) => (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>[{subpublisher}, {nativeID}]</td>
                  <td>{tradeDate}</td>
                  <td>{contract}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}

OutputView.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  }))
}

export default OutputView;
