import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Row, Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

const NewIpAddress = ({
  onToggle, isOpen, onSubmit,
}) => {
  const [ipAddress, setIpAddress] = useState('');
  const [description, setDescription] = useState('');

  const isValidIpAddress = (ipaddress: String): bool => {  
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([1-9]|[12][0-9]|3[0-2])$/.test(ipaddress)) {  
      return true
    }
    return false
  }

  const isValid = () => {
    return (
      isValidIpAddress(ipAddress) && description.length > 0
    )
  }

  return (
    <Modal className="companyModal credentialModal" toggle={onToggle} isOpen={isOpen}>
      <ModalHeader>
        authorize ip address to access your company db.
      </ModalHeader>
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          <FormGroup
            className={"mb-3"}>
            <InputGroup className="input-group-merge input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-settings-gear-65" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text" placeholder='Example: 123.214.11.24/32'
                value={ipAddress}
                onChange={(e) => {setIpAddress(e.target.value)}}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup
            className={"mb-3"}>
            <InputGroup className="input-group-merge input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-settings-gear-65" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="description"
                type="text"
                value={description}
                onChange={(e) => {setDescription(e.target.value)}}
              />
            </InputGroup>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button
              className="btn-info" color="info"
              disabled={!isValid()} type="button"
              onClick={() => {onSubmit(ipAddress, description)}}
              >
              submit
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

NewIpAddress.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

NewIpAddress.defaultProps = {

}

export default NewIpAddress;
