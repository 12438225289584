import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  // InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalBody
} from "reactstrap";

import { fetchProfileRequest, submitProfileRequest } from "containers/auth/actions";

const OnBoarding = ({
  profile, submittingProfileError, isComplete,
  fetchProfileRequest, submitProfileRequest,
}) => {
  const [fullName, setFullName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [companyRole, setCompanyRole] = useState('');
  const [assetClass, setAssetClass] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [typemodalOpen, setTypeModalOpen] = useState(false);
  const [rolemodalOpen, setRoleModalOpen] = useState(false);
  const [assetmodalOpen, setAssetModalOpen] = useState(false);
  const [errmodalOpen, setErrModalOpen] = useState(false);
  const [loggedEmail, setLoggedEmail] = useState('');

  const [submitted, setSubmitted] = useState(false);

  // const [iscompanyadmin, setIsCompanyAdmin] = useState();

  const history = useHistory();

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setLoggedEmail(user.username);
      fetchProfileRequest();
    }).catch(err => {
      Auth.signOut().then(() => history.push('auth/login'));
    });
  }, [fetchProfileRequest, history]);

  useEffect(() => {
    const { onboarding } = profile || {};

    if (!onboarding) {
      return
    }

    const {
      full_name: fullName,
      company_name: companyName,
      company_role: companyRole,
      company_type: companyType,
      primary_work_address: companyLocation,
      primary_asset_class: assetClass,
    } = onboarding;
    setFullName(fullName);
    setCompanyName(companyName);
    setCompanyRole(companyRole);
    setCompanyType(companyType);
    setCompanyLocation(companyLocation);
    setAssetClass(assetClass);
  }, [profile]);

  const submitForm = async () => {
    const isValid = (
      fullName && companyName &&
      companyLocation && companyType &&
      companyRole && assetClass
    )

    if (isValid) {
      const profile = {
        full_name: fullName,
        company_name: companyName,
        primary_work_address: companyLocation,
        company_type: companyType,
        company_role: companyRole,
        primary_asset_class: assetClass,
      }
      submitProfileRequest(profile);
      setSubmitted(true);
    } else {
      setErrorMessage("0");
      setErrModalOpen(!errmodalOpen);
    }
  }

  useEffect(() => {
    if (isComplete && submitted) {
      const { company: { status } = {} } = profile;

      if (status === 'approved') {
        window.location.href = '/admin/home';
      } else {
        setTimeout(() => {
          // history.push('/admin/home');
          history.push('/auth/success');
        }, 1500);
      }
    }
  }, [isComplete, submitted, history, profile])

  const onSelectType = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    setCompanyType(txt);
    setTypeModalOpen(!typemodalOpen)
  }

  const onSelectRole = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    setCompanyRole(txt);
    setRoleModalOpen(!rolemodalOpen)
  }

  const onSelectAsset = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    setAssetClass(txt);
    setAssetModalOpen(!assetmodalOpen)
  }

  return (
    <>
      <Container className="mt-10">
        <Row className="auth__row">
          <Col lg="7" md="7">
            <Card className="onboarding--wrap">
              <CardBody className="px-lg-5">
                <h2>We would love to know you better!</h2>
                <p>Sharing a bit about yourself helps us tailor our services to best fit your needs. Rest assured, your information is safe with us and will only be used to enhance your experience</p>
                <Row className="mt-3 mb-3">
                  <Col xs="12">
                    <span className="auth--txt">you are signed in as</span>
                    <a className={"auth--link"} href={loggedEmail}>{loggedEmail}</a>
                  </Col>
                </Row>
                <Form role="form">
                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">Hello there! What's your name?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-settings-gear-65" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="full name"
                        type="text"
                        value={fullName}
                        onChange={(e) => { setFullName(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">Where do you work?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="company name"
                        type="text"
                        value={companyName}
                        onChange={(e) => { setCompanyName(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">Where is your company based?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-hat-3" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="city, state"
                        type="text"
                        value={companyLocation}
                        onChange={(e) => { setCompanyLocation(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">What is your company type?</span>
                    </Col>
                  </Row>
                  <FormGroup
                    className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-button-power" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="company type"
                        type="text"
                        readOnly={true}
                        onClick={() => setTypeModalOpen(!typemodalOpen)}
                        value={companyType}
                        onChange={(e) => { setCompanyType(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">What's your role at the company?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-user-run" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="company role (e.g. analyst, portfolio manager)"
                        type="text"
                        readOnly={true}
                        onClick={() => setRoleModalOpen(!rolemodalOpen)}
                        value={companyRole}
                        onChange={(e) => { setCompanyRole(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">Which asset class do you primarily focus on?</span>
                    </Col>
                  </Row>
                  <FormGroup
                    className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      {/* <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-archive-2" />
                          </InputGroupText>
                        </InputGroupAddon> */}
                      <Input
                        placeholder="primary asset class"
                        type="text"
                        onClick={() => setAssetModalOpen(!assetmodalOpen)}
                        value={assetClass}
                        readOnly={true}
                        onChange={(e) => { setAssetClass(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>
                  {/* <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">are you a company administrator?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3 flex flex-start"}>
                    <div className="custom-control custom-control-alternative custom-checkbox mr-3">
                      <input
                        className="custom-control-input"
                        id="companyAdminYes"
                        checked={iscompanyadmin}
                        type="checkbox"
                        onClick={() => setIsCompanyAdmin(true)}
                        onChange={() => { }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="companyAdminYes"
                      >
                        <span className="text-muted">yes</span>
                      </label>
                    </div>

                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="companyAdminNo"
                        type="checkbox"
                        checked={!iscompanyadmin}
                        onClick={() => setIsCompanyAdmin(false)}
                        onChange={() => { }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="companyAdminNo"
                      >
                        <span className="text-muted">no</span>
                      </label>
                    </div>
                  </FormGroup> */}
                  <div className="mb-2 text-center">
                    <Button className="btn-info" color="info" type="button" onClick={submitForm}>
                      Let's go!
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Company Type modal */}
        <Modal className="companyModal" toggle={() => setTypeModalOpen(!typemodalOpen)} isOpen={typemodalOpen}>
          <ModalBody>
            <div className="wrap">
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'agriculture company/producer')}><span className="btn-inner--text">agriculture company/producer</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'asset manager/family office')}><span className="btn-inner--text">asset manager/family office</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'bank')}><span className="btn-inner--text">bank</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'broker/fcm')}><span className="btn-inner--text">broker/fcm</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consulting/financial advisory firm')}><span className="btn-inner--text">consulting/financial advisory firm</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'consumer goods & services/airline/construction')}><span className="btn-inner--text">consumer goods &amp; services/airline/construction</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'energy company/producer/utility')}><span className="btn-inner--text">energy company/producer/utility</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'exchange')}><span className="btn-inner--text">exchange</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'fintech')}><span className="btn-inner--text">fintech</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'government/regulator')}><span className="btn-inner--text">government/regulator</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'hedge fund/cta')}><span className="btn-inner--text">hedge fund/cta</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'independent trades/investor')}><span className="btn-inner--text">independent trades/investor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'index services')}><span className="btn-inner--text">index services</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'industry trade association')}><span className="btn-inner--text">industry trade association</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'insurance company')}><span className="btn-inner--text">insurance company</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'isv (independent software provider)')}><span className="btn-inner--text">isv (independent software provider)</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'market data vendor')}><span className="btn-inner--text">market data vendor</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'media')}><span className="btn-inner--text">media</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'metals company/producer/mining')}><span className="btn-inner--text">metals company/producer/mining</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'pension fund/endowment')}><span className="btn-inner--text">pension fund/endowment</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'propietary trading firm')}><span className="btn-inner--text">propietary trading firm</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'third party/vendor fund administrator')}><span className="btn-inner--text">third party/vendor fund administrator</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectType(e, 'university/education')}><span className="btn-inner--text">university/education</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Company Role modal */}
        <Modal className="companyModal" toggle={() => setRoleModalOpen(!rolemodalOpen)} isOpen={rolemodalOpen}>
          <ModalBody>
            <div className="wrap">
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'accounting/finance')}><span className="btn-inner--text">accounting/finance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'broker')}><span className="btn-inner--text">broker</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'clearing')}><span className="btn-inner--text">clearing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'consultant')}><span className="btn-inner--text">consultant</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'executive')}><span className="btn-inner--text">executive</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'financial advisor/registered investment advisor')}><span className="btn-inner--text">financial advisor/registered investment advisor</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'investor')}><span className="btn-inner--text">investor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'legal/compliance')}><span className="btn-inner--text">legal/compliance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'market data')}><span className="btn-inner--text">market data</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'marketing')}><span className="btn-inner--text">marketing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'operations')}><span className="btn-inner--text">operations</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'portfolio manager')}><span className="btn-inner--text">portfolio manager</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'professor')}><span className="btn-inner--text">professor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'research analyst/professional')}><span className="btn-inner--text">research analyst/professional</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'risk manager/middle office')}><span className="btn-inner--text">risk manager/middle office</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'sales')}><span className="btn-inner--text">sales</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'student')}><span className="btn-inner--text">student</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'technology')}><span className="btn-inner--text">technology</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'trader')}><span className="btn-inner--text">trader</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'treasurer')}><span className="btn-inner--text">treasurer</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Primary asset class modal */}
        <Modal className="companyModal errModal" toggle={() => setAssetModalOpen(!assetmodalOpen)} isOpen={assetmodalOpen}>
          <ModalBody>
            <div className="wrap">
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'commodities')}><span className="btn-inner--text">commodities</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'foreign exchange')}><span className="btn-inner--text">foreign exchange</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'equities')}><span className="btn-inner--text">equities</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'credit')}><span className="btn-inner--text">credit</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'rates')}><span className="btn-inner--text">rates</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectAsset(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Company Error modal */}
        <Modal className="credentialModal errModal" toggle={() => setErrModalOpen(!errmodalOpen)} isOpen={errmodalOpen}>
          <ModalBody>
            <div className="onboarding--wrap errorMessage_wrap">
              {errorMessage === '0' ? (<>
                <p className="err--msg">looks like we need to know a little more!</p>
                <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>back to form</Button>
              </>) : (<>
                <p className="err--msg">thanks we are all set, we are taking you to your homepage now</p>
              </>)}
            </div>
          </ModalBody>
        </Modal>

      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isComplete: !!state.auth.profile.isComplete,
    submittingProfileError: state.auth.submittingProfileError,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchProfileRequest: payload => dispatch(fetchProfileRequest(payload)),
    submitProfileRequest: payload => dispatch(submitProfileRequest(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);
