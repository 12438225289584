import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDatetime from "react-datetime";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Button,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";

import Nouislider from 'nouislider-react';
import ToggleSelect from '../builder/ToggleSelect';
import NivoLineChart from '../charts/NivoLineChart';

const AnalysisSection = ({
  title, onRunAnalysis,
  startDate, onChangeStartDate,
  endDate, onChangeEndDate,
  flatPrice, onChangeFlatPrice,
  volatility, onChangeVolatility,
  chartData, onChangeChartData,
}) => {
  const [tabNum, setTabNum] = useState(1);
  const [isIncrement, setIsIncrement] = useState('beta');
  const [backend, setBackend] = useState(0.25);
  const [steepness, setSteepness] = useState(0.75);

  const incrementsOpt = ['beta', 'para'];

  const onFlatPriceSliderChanged = useCallback((min: Number, max: Number) => {
    onChangeFlatPrice({
      ...flatPrice,
      min, max,
    })
  }, [flatPrice, onChangeFlatPrice])

  const onVolatilitySliderChanged = useCallback((min: Number, max: Number) => {
    onChangeVolatility({
      ...volatility,
      min, max,
    })
  }, [volatility, onChangeVolatility])

  useEffect(() => {
    let data = [];
    for( let idx = 0; idx < 13; idx++ ) {
      if( isIncrement === 'beta' ) {
        data.push({
          x: idx,
          y: parseFloat(((1 - backend) * Math.exp(-steepness * idx) + backend).toFixed(2))
        });
      } else {
        data.push({
          x: idx,
          y: 1
        });
      }
    }
    onChangeChartData([{'id': 'chart', 'data': data}]);
  }, [backend, steepness, isIncrement, onChangeChartData]);

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabNum(index);
  };

  const getClassNameReactDatetimeDays = (date) => {
    if (startDate && endDate) {
    }
    if (startDate && endDate && startDate._d + "" !== endDate._d + "") {
      if (
        new Date(endDate._d + "") > new Date(date._d + "") &&
        new Date(startDate._d + "") < new Date(date._d + "")
      ) {
        return " middle-date";
      }
      if (endDate._d + "" === date._d + "") {
        return " end-date";
      }
      if (startDate._d + "" === date._d + "") {
        return " start-date";
      }
    }
    return "";
  };

  const handleReactDatetimeChange = (who, date) => {
    if (
      startDate &&
      who === "endDate" &&
      new Date(startDate._d + "") > new Date(date._d + "")
    ) {
      onChangeStartDate(date);
      onChangeEndDate(date);
    } else if (
      endDate &&
      who === "startDate" &&
      new Date(endDate._d + "") < new Date(date._d + "")
    ) {
      onChangeStartDate(date);
      onChangeEndDate(date);
    } else {
      if (who === "startDate") {
        onChangeStartDate(date);
      } else {
        onChangeEndDate(date);
      }
    }
  };

  return (
  <Row className='scenario-item-wrap ml-0 mr-0 datamember'>
    <Col xl="12">
      <h2 className='pl-2 pt-2 mt-1'>{title}</h2>
    </Col>
    <Col xl="9" className="pt-2 pl-0 pr-0">
      <div className="nav-wrapper mr-2">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabNum === 1}
              className={tabNum === 1 ? 'selected':''}
              onClick={e => toggleNavs(e, "tabs", 1)}
              role="tab"
            >
              flat price
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabNum === 2}
              className={tabNum === 2 ? 'selected':''}
              onClick={e => toggleNavs(e, "tabs", 2)}
              role="tab"
            >
              volatility
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={"tabs" + tabNum}>
        <TabPane tabId="tabs1">
          <Row>
            <Col xl="8" className="border--r">
              <p className='pl-3'><strong>outright change</strong></p>
              <div className='flex ml-2'>
                <Nouislider
                  start={[flatPrice.min, flatPrice.max]}
                  connect={[false, true, false]}
                  tooltips = {[
                    {to: (value) => `${value} %`},
                    {to: (value) => `${value} %`}
                  ]}
                  step={1}
                  range={{min: -25, max: 25}}
                  className={'width-75 mt-3'}
                  onChange={(render, handle, [start, end], un, percentage) => {
                    onFlatPriceSliderChanged(parseInt(start), parseInt(end))
                  }}
                  />
                <div>
                  <p className='small-font'>set increments</p>
                  <InputGroup className="input-group-merge input-group-alternative max-width-40">
                    <Input
                      type="number" placeholder='value'
                      value={flatPrice.delta} min={1} max={flatPrice.max}
                      onChange={(e) => onChangeFlatPrice({...flatPrice, delta: parseInt(e.target.value)})}
                      />
                  </InputGroup>
                </div>
              </div>
            </Col>
            <Col xl="4">
              <p><strong className='font-13'>curve shape deformation</strong></p>
              <div className='flex align-item-start'>
                <ToggleSelect 
                  value={isIncrement}
                  options={incrementsOpt}
                  onChange={setIsIncrement} />
                <div className='small-font pr-2'>
                  <p className='small-font'>enter parameters</p>
                  <div className='flex input-flex'>
                    <label className='small-font'>back-end</label>
                    <InputGroup className="input-group-merge input-group-alternative max-width-70">
                      <Input
                        type="number" placeholder='value'
                        disabled={isIncrement==='para'}
                        value={backend} max={1} min={0} step={0.05}
                        onChange={(e) => setBackend(parseFloat(e.target.value))} />
                    </InputGroup>
                  </div>
                  <div className='flex input-flex'>
                    <label className='small-font'>steepness</label>
                    <InputGroup className="input-group-merge input-group-alternative max-width-70">
                      <Input
                        type="number" placeholder='value'
                        disabled={isIncrement==='para'}
                        value={steepness} max={1} min={0} step={0.05}
                        onChange={(e) => setSteepness(parseFloat(e.target.value))} />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='custom-chart-section' style={{ height: 300 }}>
                {chartData[0].data.length > 0 && <NivoLineChart data={chartData} />}
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="tabs2">
          <Row>
            <Col xl="8" className="border--r">
              <p className='pl-3'><strong>outright change</strong></p>
              <div className='flex ml-2'>
              <Nouislider
                  start={[volatility.min, volatility.max]}
                  connect={[false, true, false]}
                  step={1}
                  tooltips = {[
                    {to: (value) => `${value} %`},
                    {to: (value) => `${value} %`}
                  ]}
                  range={{
                    min: -6,
                    max: 6
                  }}
                  className={'width-75 mt-3'}
                  onChange={(render, handle, [start, end], un, percentage) => {
                    onVolatilitySliderChanged(parseInt(start), parseInt(end))
                  }}
                  />
                <div>
                  <p>set increments</p>
                  <InputGroup className="input-group-merge input-group-alternative max-width-40">
                    <Input
                      type="number" placeholder='value'
                      value={volatility.delta} min={1} max={volatility.max}
                      onChange={(e) => onChangeVolatility({...volatility, delta: parseInt(e.target.value)})} />
                  </InputGroup>
                </div>
              </div>
            </Col>
            <Col xl="4">
              <p><strong>curve shape deformation</strong></p>
              <div className='flex align-item-start'>
                <ToggleSelect 
                  value={isIncrement}
                  options={incrementsOpt}
                  onChange={setIsIncrement} />
                <div className='small-font pr-2'>
                  <p>enter parameters</p>
                  <div className='flex input-flex'>
                    <label className='small-font'>back-end</label>
                    <InputGroup className="input-group-merge input-group-alternative max-width-40">
                      <Input
                        type="text" placeholder='value'
                        value={backend}
                        onChange={(e) => setBackend(e.target.value)} />
                    </InputGroup>
                  </div>
                  <div className='flex input-flex'>
                    <label className='small-font'>steepness</label>
                    <InputGroup className="input-group-merge input-group-alternative max-width-40">
                      <Input
                        type="text" placeholder='value'
                        value={steepness}
                        onChange={(e) => setSteepness(e.target.value)} />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='custom-chart-section' style={{ height: 300 }}>
                {chartData.data?.length > 0 && <NivoLineChart data={chartData} />}
              </div>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </Col>
    <Col xl="3" className="mt-3 border--l">
      <h3>value dates</h3>
      <Row>
        <Col>
          <p className='mb-0'>start date</p>
          <FormGroup className='mb-1'>
            <ReactDatetime
              inputProps={{
                placeholder: "start date"
              }}
              closeOnSelect={true}
              dateFormat="DD-MMM-YYYY"
              initialValue={new Date()}
              value={startDate}
              timeFormat={false}
              onChange={(e) =>
                handleReactDatetimeChange("startDate", e)
              }
              renderDay={(props, currentDate, selectedDate) => {
                let classes = props.className;
                classes += getClassNameReactDatetimeDays(
                  currentDate
                );
                return (
                  <td {...props} className={classes}>
                    {currentDate.date()}
                  </td>
                );
              }}
            />
          </FormGroup>
          <p className='mb-0'>end date</p>
          <FormGroup>
            <ReactDatetime
              inputProps={{
                placeholder: "end date"
              }}
              closeOnSelect={true}
              dateFormat="DD-MMM-YYYY"
              initialValue={new Date()}
              value={endDate}
              onChange={(e) =>
                handleReactDatetimeChange("endDate", e)
              }
              renderDay={(props, currentDate, selectedDate) => {
                let classes = props.className;
                classes += getClassNameReactDatetimeDays(
                  currentDate
                );
                return (
                  <td {...props} className={classes}>
                    {currentDate.date()}
                  </td>
                );
              }}
              timeFormat={false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Button color="info" onClick={() => {onRunAnalysis()}} size="sm" className='runScenarioBtn'>
        run scenario analysis
      </Button>
    </Col>
  </Row>
  )
}

AnalysisSection.propTypes = {
  title: PropTypes.string.isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        y: PropTypes.number.isRequired,
      }))
    }).isRequired
  ).isRequired,
  onChangeChartData: PropTypes.func.isRequired,
  onRunAnalysis: PropTypes.func.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,

  flatPrice: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    delta: PropTypes.number.isRequired,
  }).isRequired,
  onChangeFlatPrice: PropTypes.func.isRequired,
  volatility: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    delta: PropTypes.number.isRequired,
  }).isRequired,
  onChangeVolatility: PropTypes.func.isRequired,
}

export default AnalysisSection;
