/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React, { useState, useEffect } from "react";
// reactstrap components
import {
  Button,
} from "reactstrap";
import { useHistory } from 'react-router-dom';

import { useAppContext } from "libs/contextLib";
import LandingItem from "../../components/molecules/builder/LandingItem";

function Landing() {
  const history = useHistory();

  const { isAuthenticated } = useAppContext();
  const [show, setShow] = useState(false);
  const [curBlock, setCurBlock] = useState(null);

  useEffect(() => {
    if (isAuthenticated)
      history.push('/admin/home');

    setShow(true)
  }, [isAuthenticated, setShow]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });
  }

  const transitionStyles = {
    enter: {
      transform: "translateX(0)"
    },
    show: {
      opacity: "1"
    }
  }

  return (
    <>
      <div className="landing-wrap">
        <div className="landing-header">
          {show ? (<>
            <h1 style={{ ...transitionStyles['enter'] }}>ethereal</h1>
            <h1 style={{ ...transitionStyles['enter'] }}>risk</h1>
            <Button className="mt-5 mr-0" color="info" type="button"
              onClick={() => history.push("/auth/login")}
              style={{ ...transitionStyles['show'] }}>
              sign in
            </Button>
            <Button className="mt-3 ml-0 mr-0" color="warning" type="button"
              onClick={() => scrollToBottom()}
              style={{ ...transitionStyles['show'] }}>
              scroll to discover
            </Button>
            <Button className="mt-3 ml-0 mr-0" color="info" type="button"
              onClick={() => history.push("/auth/contactus")}
              style={{ ...transitionStyles['show'] }}>
              contact us
            </Button>
          </>) : (<>
            <h1>ethereal</h1>
            <h1>risk</h1>
            <Button className="mt-5 mr-0" color="info" type="button" onClick={() => history.push("/auth/login")}>
              sign in
            </Button>
            <Button className="mt-3 ml-0 mr-0" color="warning" type="button" onClick={() => scrollToBottom()}>
              scroll to discover
            </Button>
            <Button className="mt-3 ml-0 mr-0" color="info" type="button" onClick={() => history.push("/auth/contactus")}>
              contact us
            </Button>
          </>)}
        </div>
        <div className="landing-body">
          <LandingItem
            title={"what we do"} identifier="what" curBlock={curBlock} changeCurBlock={setCurBlock}
            subtitle={"Simplifying Volatility with Precision"}>
            At Ethereal Risk, we demystify the complexities of volatility so you can focus on what matters most - your investments.
            Our cutting-edge platform analyzes and evaluates hundreds of thousands of commodities options, harnessing the power of
            state-of-the-art cloud computing to simulate future scenarios. With this invaluable insight, we identify the optimal
            combination of options that maximizes your potential returns, providing you with a clear framework to execute your market view.
          </LandingItem>
          <LandingItem
            title={"who we are"} identifier="who" curBlock={curBlock} changeCurBlock={setCurBlock}
            subtitle={"Built by Traders, For Traders"}>
            We speak your language because we are traders ourselves, with over two decades of professional investing and trading experience in commodities.
            Our firsthand understanding of options' power and the challenges of modeling their behavior in diverse market environments has driven us to develop
            a robust framework for option evaluation. Our team's expertise in advanced financial modeling, statistical programming, database architecture,
            and data visualization empowers you to seamlessly integrate our analysis into your investment process.
          </LandingItem>
          <LandingItem
            title={"why etherealrisk"} identifier="why" curBlock={curBlock} changeCurBlock={setCurBlock}
            titlePaddingBottom={10}
            subtitle={"Your Edge, Our Commitment"}>
            We don't just offer a system; we deliver your competitive edge. As an investor, you bring the market knowledge, and we equip you with the finest
            tools to transform that knowledge into reality. Our platform employs powerful search engines to explore the vast universe of commodities options,
            precisely identifying the optimal structure based on your inputs. We meticulously assess the dynamic relationship between price and volatility
            across numerous possible paths for each option, ensuring a comprehensive evaluation. Using a sophisticated fitness function, we prioritize options
            that maximize your average return over time, setting you on the path to success.
          </LandingItem>
        </div>
        <div className="landing-footer">
          <iframe
            width="720"
            height="480"
            src={`https://www.youtube.com/embed/wqc0KB4QY3g`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="What we do"
          />
        </div>
        {/* <LandingHeader /> */}
        {/* <section className="pb-6 pt-8 landing--section">
          <Container>
            <Row>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <img
                      alt="connect"
                      src={
                        require("assets/img/theme/visual.png").default
                      }
                    />
                    <h6>connect</h6>
                    <p>seamlessly and securely manage <strong>data subscriptions</strong> across private and public vendors using ethereal risk's cloud architecture</p>
                    <NavLink
                      className="py-2 px-3"
                      href="#this"
                    >
                    </NavLink>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <img
                      alt="build"
                      src={
                        require("assets/img/theme/model.png").default
                      }
                    />
                    <h6>build</h6>
                    <p>create, save, and share market and fundamental <strong>datasets</strong> without ever touching a line of code</p>
                    <NavLink
                      className="py-2 px-3"
                      href="#this"
                    >
                    </NavLink>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4">
                <Card>
                  <CardBody>
                    <img
                      alt="analyze"
                      src={
                        require("assets/img/theme/manage.png").default
                      }
                    />
                    <h6>analyze</h6>
                    <p>analyze your data by creating <strong>dashboards,</strong> leverage dynamic visual templates and machine learning insights</p>
                    <NavLink
                      className="py-2 px-3"
                      href="#this"
                    >
                    </NavLink>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="landing--section gray_white">
          <Container>
            <Row className="landing__graph pb-7 pt-7">
              <Col xl="6">
                <h5>one home for all of your data</h5>
                <p>database storage and integration is slow and expensive. <strong>ethereal risk</strong> allows you to access all of your private and public data from the cloud.</p>
                <h5>use your intuition, not your time</h5>
                <p><strong>ethereal risk</strong> manages the database engineering so you can get the most out of your data faster than ever before. with <strong>ethereal risk,</strong> you're in the driver's seat</p>
                <h5>no code? no problem.</h5>
                <p>create datasets that can easily be used by external modeling environments without touching a line of code.</p>
                <h5>designed by traders, for traders</h5>
                <p>with 15+ years of professional investing experience, our team designed <strong>ethereal risk</strong> with you in mind, leverage our curated visual templates and machine learning insights to take your analysis to the next level.</p>
              </Col>
              <Col xl="6">
                <img
                  alt="graph data"
                  src={
                    require("assets/img/theme/graph1.png").default
                  }
                />
              </Col>
            </Row>
            {false && <Row className="landing__mobile pb-7 pt-7 text-center">
              <Col xl="12">
                <h2>have an account already?</h2>
                <p>please visit us from your desktop or laptop.</p>
                <br /><br />
                <h2>no account? no problem</h2>
                <p>click the link below to set one up from you mobile device.</p>
                <Button className="mt-4" color="info" type="button" onClick={() => history.push("/auth/register")}>
                  sign me up!
                </Button>
              </Col>
            </Row>}
          </Container>
        </section> */}
      </div>
    </>
  );
}

export default Landing;
