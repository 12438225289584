import React, { useEffect } from "react";
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import API from '../../helpers/api';

function OptionsRecommenderPaymentSuccess() {
  const { requestId } = useParams();
  const history = useHistory();

  useEffect(() => {
    API.requestOptionsRecommender(requestId)
      .then(({ status }) => {
        if (status) {
          history.push('/admin/optimizer/success')
        } else {
          alert('Something went wrong. Please contact support!')
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }, [
    history, requestId,
  ])

  return (
    <section className="optimizer">
      <h1 className="border--b">{'Please wait while we process your request.'}</h1>
    </section>
  )
}

export default connect(null, null)(OptionsRecommenderPaymentSuccess);
