import { all } from 'redux-saga/effects';
import { authSagas } from 'containers/auth/saga';
import { adminSagas } from 'containers/admin/saga';
import { datasetSagas } from 'containers/datasets/saga';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...adminSagas,
    ...datasetSagas,
  ])
}
