import React, { useEffect, useState } from "react";
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import HeatmapSection from "components/molecules/scenario/HeatmapSection";
import API from '../../helpers/api';

function OptionsRecommenderPdfScenario() {
  const { requestId, left, right } = useParams();
  const [ heatmap, setHeatmap ] = useState([]);

  useEffect(() => {
    API.fetchPdfScenario(requestId, left, right)
    .then(({heatmap}) => {
      setHeatmap(heatmap);
    })
  }, [
    left, right, requestId,
  ])

  return (
    <section className="optimizer">
      <h1 className="border--b">{'options optimizer pdf scenario'}</h1>
      <div className="optimizer-wrap">
        <div className="pd-10 border-r">
          <HeatmapSection
            title="pdf scenario"
            heatmap={heatmap}
            />
        </div>
      </div>
    </section>
  )
}

export default connect(null, null)(OptionsRecommenderPdfScenario);
