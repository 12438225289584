import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";

function BackTester() {
  return (
    <>
      <Container className="home-wrap" fluid>
        <h2>backtester page</h2>
      </Container>
    </>
  );
}

export default BackTester;
