export const getPriceViewSpec = (yMin: Number = 60, yMax: Number = 110): Object => ({
  "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
  "transform": [
    {
      "window": [
        {
          "op": "mean",
          "field": "futuresprice",
          "as": "20_moving_average"
        }
      ],
      "sort": [
        {
          "field": "trade_date",
          "order": "ascending"
        }
      ],
      "ignorePeers": false,
      "frame": [
        -20,
        0
      ]
    },
    {
      "window": [
        {
          "op": "mean",
          "field": "futuresprice",
          "as": "50_moving_average"
        }
      ],
      "sort": [
        {
          "field": "trade_date",
          "order": "ascending"
        }
      ],
      "ignorePeers": false,
      "frame": [
        -50,
        0
      ]
    },
    {
      "window": [
        {
          "op": "mean",
          "field": "futuresprice",
          "as": "100_moving_average"
        }
      ],
      "sort": [
        {
          "field": "trade_date",
          "order": "ascending"
        }
      ],
      "ignorePeers": false,
      "frame": [
        -100,
        0
      ]
    }
  ],
  "vconcat": [
    {
      "width": 850,
      "height": 390,
      "layer": [
        {
          "data": {
            "values": [
              { "y": "65", "x": "2023-04-17" },
              { "y": "100", "x": "2023-04-17" }
            ]
          },
          "mark": {
            "type": "line",
            "color": "grey",
            "strokeDash": [3, 1]
          },
          "encoding": {
            "x": { "field": "x", "type": "temporal" },
            "y": { "field": "y", "type": "quantitative" }
          }
        },
        {
          "data": {
            "values": [
              { "y": "65", "x": "2023-06-10" },
              { "y": "100", "x": "2023-06-10" }
            ]
          },
          "mark": {
            "type": "line",
            "color": "grey",
            "strokeDash": [3, 1]
          },
          "encoding": {
            "x": { "field": "x", "type": "temporal" },
            "y": { "field": "y", "type": "quantitative" }
          }
        },
        {
          "mark": {
            "type": "line",
            "color": "green"
          },
          "encoding": {
            "x": {
              "field": "trade_date",
              "type": "temporal",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              },
              "axis": {
                "title": ""
              }
            },
            "y": {
              "datum": {
                "expr": "take_profit"
              },
              "type": "quantitative"
            }
          }
        },
        {
          "mark": {
            "type": "line",
            "color": "red"
          },
          "encoding": {
            "x": {
              "field": "trade_date",
              "type": "temporal",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              },
              "axis": {
                "title": ""
              }
            },
            "y": {
              "datum": {
                "expr": "stop_loss"
              },
              "type": "quantitative"
            }
          }
        },
        {
          "mark": {
            "type": "line",
            "point": true
          },
          "encoding": {
            "x": {
              "field": "trade_date",
              "type": "temporal",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              },
              "axis": {
                "title": "",
                "labelPadding": 12,
                "format": "%b-%y"
              }
            },
            "y": {
              "field": "futuresprice",
              "type": "quantitative",
              "axis": {
                "titleFontSize": 16,
                "titlePadding": 18,
                "labelPadding": 12
              },
              "scale": {
                "nice": true,
                "domain": [yMin, yMax],
                "zero": false
              }
            }
          }
        },
        {
          "mark": {
            "type": "line",
            "color": "blueviolet"
          },
          "encoding": {
            "x": {
              "timeUnit": "yearmonthdate",
              "field": "trade_date",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              }
            },
            "y": {
              "field": "20_moving_average",
              "type": "quantitative",
              "title": "Settlement"
            }
          }
        },
        {
          "mark": {
            "type": "line",
            "color": "blue"
          },
          "encoding": {
            "x": {
              "timeUnit": "yearmonthdate",
              "field": "trade_date",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              }
            },
            "y": {
              "field": "50_moving_average",
              "type": "quantitative",
              "title": "Settlement"
            }
          }
        },
        {
          "mark": {
            "type": "line",
            "color": "lightblue"
          },
          "encoding": {
            "x": {
              "timeUnit": "yearmonthdate",
              "field": "trade_date",
              "scale": {
                "domain": {
                  "param": "brush"
                }
              }
            },
            "y": {
              "field": "100_moving_average",
              "type": "quantitative",
              "title": "Settlement"
            }
          }
        }
      ]
    },
    {
      "width": 850,
      "height": 60,
      "mark": "line",
      "params": [
        {
          "name": "brush",
          "select": {
            "type": "interval",
            "encodings": [
              "x"
            ]
          }
        }
      ],
      "encoding": {
        "x": {
          "field": "trade_date",
          "title": "trade date",
          "axis": {
            "titleFontSize": 16,
            "titlePadding": 18,
            "format": "%b-%y"
          },
          "type": "temporal"
        },
        "y": {
          "field": "futuresprice",
          "type": "quantitative",
          "title": "",
          "axis": {
            "tickCount": 3,
            "grid": false
          },
          "scale": {
            "zero": false
          }
        }
      }
    }
  ],
  "resolve": {
    "scale": {
      "y": "independent"
    }
  }
})

export const volatilityScatterPlotSpec = {
  "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
  "vconcat": [
    {
      "width": 850,
      "height": 390,
      "layer": [
        {
          "mark": {
            "type": "line",
            "point": true,
            "tooltip": true
          },
          "encoding": {
            "x": {
              "field": "futuresprice",
              "type": "quantitative",
              "axis": {
                "format": "$.5",
                "labelPadding": 12,
                "titleFontSize": 16,
                "titlePadding": 18,
                "title": "flat price"
              },
              "scale": {
                "zero": false
              }
            },
            "y": {
              "field": "atmvol",
              "type": "quantitative",
              "axis": {
                "format": "%", "labelPadding": 12,
                "titleFontSize": 16,
                "titlePadding": 18
              },
              "scale": {
                "zero": false
              }
            },
            "color": {
              "field": "trade_date",
              "legend": {
                "title": "trade date",
                "titleFontSize": 16,
                "labelFontSize": 12
              },
              "type": "temporal"
            }
          }
        },
        {
          "data": {
            "values": [
              {
                "y": "0.2",
                "x": "81.28"
              },
              {
                "y": "0.6",
                "x": "81.28"
              }
            ]
          },
          "mark": {
            "type": "line",
            "color": "grey",
            "strokeDash": [
              3,
              1
            ]
          },
          "encoding": {
            "x": {
              "field": "x",
              "type": "quantitative"
            },
            "y": {
              "field": "y",
              "type": "quantitative"
            },
            "color": {
              "field": "x",
              "title": "starting flat price",
              "type": "nominal",
              "legend": {
                "labelFontSize": 12,
                "titleFontSize": 16
              },
              "scale": {
                "range": [
                  "grey"
                ]
              }
            }
          }
        },
        {
          "data": {
            "values": [
              {
                "y": "0.35",
                "x": "70"
              },
              {
                "y": "0.35",
                "x": "101"
              }
            ]
          },
          "mark": {
            "type": "line",
            "color": "grey",
            "strokeDash": [
              3,
              1
            ]
          },
          "encoding": {
            "x": {
              "field": "x",
              "type": "quantitative"
            },
            "y": {
              "field": "y",
              "type": "quantitative",
              "title": "atmvol"
            },
            "color": {
              "field": "y",
              "title": "starting atmvol",
              "type": "nominal",
              "legend": {
                "format": ".2%",
                "labelFontSize": 12,
                "titleFontSize": 18
              },
              "scale": {
                "range": [
                  "grey"
                ]
              }
            }
          }
        },
        {
          "data": {
            "values": [
              {
                "y": "0.4",
                "x": "70"
              },
              {
                "y": "0.271",
                "x": "101"
              }
            ]
          },
          "mark": {
            "type": "line",
            "color": "darkblue"
          },
          "encoding": {
            "x": {
              "field": "x",
              "type": "quantitative"
            },
            "y": {
              "field": "y",
              "type": "quantitative"
            },
            "color": {
              "field": "futuresprice",
              "title": "base vol",
              "type": "ordinal",
              "legend": {
                "labelFontSize": 0,
                "titleFontSize": 18
              },
              "scale": {
                "range": [
                  "darkblue"
                ]
              }
            }
          }
        },
        {
          "data": {
            "values": [
              {
                "y": "0.45",
                "x": "70"
              },
              {
                "y": "0.32",
                "x": "101"
              }
            ]
          },
          "mark": {
            "type": "line",
            "color": "orangered"
          },
          "encoding": {
            "x": {
              "field": "x",
              "type": "quantitative"
            },
            "y": {
              "field": "y",
              "type": "quantitative"
            },
            "color": {
              "field": "futuresprice",
              "title": "high vol",
              "type": "ordinal",
              "legend": {
                "labelFontSize": 0,
                "titleFontSize": 18
              },
              "scale": {
                "range": [
                  "orangered"
                ]
              }
            }
          }
        },
        {
          "data": {
            "values": [
              {
                "y": "0.35",
                "x": "70"
              },
              {
                "y": "0.22",
                "x": "101"
              }
            ]
          },
          "mark": {
            "type": "line",
            "color": "seagreen"
          },
          "encoding": {
            "x": {
              "field": "x",
              "type": "quantitative"
            },
            "y": {
              "field": "y",
              "type": "quantitative"
            },
            "color": {
              "field": "futuresprice",
              "title": "low vol",
              "type": "ordinal",
              "legend": {
                "labelFontSize": 0,
                "titleFontSize": 18
              },
              "scale": {
                "range": [
                  "seagreen"
                ]
              }
            }
          }
        }
      ]
    }
  ]
}
