import React from 'react';
import PropTypes from 'prop-types';

import { Button } from "reactstrap";

import AttributeSelect from './AttributeSelect';

import {
  WINDOW_FUNCTIONS,
} from 'helpers/constants';

const MarketMemberFunction = ({
  windowFunction, windowParameter, onChange, idx, onRemoveFunction,
}) => {
  return (
    <div className="mt-2 dataset-input">
      <AttributeSelect
        value={windowFunction}
        size={'size-medium'}
        options={WINDOW_FUNCTIONS}
        onChange={(value) => onChange('window_function', value)} />
      <input
        value={windowParameter} type='number' className='window-parameter'
        size={'size-medium'} max={200} min={1}
        onChange={(event) => onChange('window_parameter', event.target.value)} />

      <Button color="remove" type="button" className="ml-2" onClick={() => onRemoveFunction(idx)}>remove</Button>
    </div>
  )
}

MarketMemberFunction.propTypes = {
  idx: PropTypes.number,
  windowFunction: PropTypes.string,
  windowParameter: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRemoveFunction: PropTypes.func.isRequired,
}

export default MarketMemberFunction;
