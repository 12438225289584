import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";
import { useAppContext } from "libs/contextLib";

function Privacy() {
  const { isAuthenticated } = useAppContext();
  return (
    <>
      <Container className={isAuthenticated ? 'home-wrap privacy-wrap' : 'home-wrap privacy-wrap mt-7'} fluid>
        <h2 className="about--title text-center">privacy policy</h2>
        <p>
          <strong>
          This privacy policy and notice of Ethereal Risk, Inc., and its subsidiaries, (collectively, “ER” or “we” or “us” or “our”) (“Privacy Policy”) is effective as of October 1, 2021. 
          </strong>
        </p>
        <p>
          ER respects the privacy rights of its clients and users and we appreciate the importance of protecting personal and other user information. This Privacy Policy explains how ER collects, retains, uses, transfers and discloses information (including the information defined as personal data under the EU General Data Protection Regulation (GDPR)). This Privacy Policy relates to information that ER collects, uses or shares when you use or access our websites or services (collectively, the "ER Services"), including when you interact with us.
        </p>
        <p>
          Any agreement between you or the business of which you are part (your “Organization”) and us will supersede this Privacy Notice where the terms overlap.
        </p>
        <p>
          ER may amend this Privacy Policy at any time, but if amendments that we deem material are made to the policy, we may notify you or your Organization more directly by email or post a notice on our website prior to the changes becoming effective. You may review our Privacy Policy regularly to stay informed about our data protection practices.
        </p>
        <p>
          ER collects information in the following ways:<br />
          • ER collects information you directly provide to ER when visiting the ER website or registering for or using the ER SaaS Services.<br />
          • We collect data that relates to your use of the ER SaaS Services.<br />
          • We collect data from other third parties where you or your Organization as permitted us to access information from those third parties.
        </p>
        <p>
          In some situations, you may decide not to provide information to ER when asked for it in the context of using ER SaaS Services. Should you decide not to provide information where we need such information to operate the ER SaaS Services or fulfill our contractual and other obligations, we may suspend or refuse your access to the ER SaaS Services. This might happen in the following situations:<br />
            • Where we ask you to provide personal information to create an account; or<br />
            • Where we ask you to provide personal information to be able to identify you as a permitted user of the ER SaaS Services; or<br />
            • Where a third party service or data provider accessed through ER requires personal information to identify you as a permitted user of their service or data.
        </p>
        <p>
          ER uses information collected in order to maintain security in its provision of the ER SaaS Services to users and Organizations. We may collect, retain, process and transfer information within and to the U.S. and other countries and territories from which ER or its authorized third parties may operate, and these countries or territories may have different privacy laws that are different from those in your country of residence.
        </p>
        <p>
          On July 16, 2020, the Court of Justice of the European Union (CJEU) ruled that the EU - U.S. Privacy Shield and Swiss - U.S. Privacy Shield Framework is no longer a valid data transfer mechanism for transferring personal data from the European Economic Area (EEA) to the United States. Per guidance from the United States Department of Commerce, ER intends to comply with the EU-U.S. and Swiss-U.S. Privacy Shield Framework until such time as another applicable framework is put in place.
        </p>
        <p>
          We will not share personal information about you with any third parties, unless you allow it, as described in this Privacy Policy, or in connection with providing the ER SaaS Services to you or your Organization. We may share information with (i) third parties and vendors or other services providers working on our behalf; (ii) the third-party ER integrations or other third-party products that you choose to use while working with the ER SaaS Services or (iii) when necessary, to protect the security and safety of our users or when required by law or a legal process.
        </p>
        <p>
          ER takes the security of your personal information seriously and we take appropriate technological and organizational measures to help protect your personal information from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. We comply with applicable data protection, privacy, and security breach notification laws.
        </p>
        <p>
          The ER SaaS Services are not directed to persons under the age of 18 and we do not intend to or knowingly collect any data or information from anyone under 18. If you become aware that a person under the age of 18 has provided us with personal information, please contact us at <a href='mailto:privacy@etherealrisk.com'>privacy@etherealrisk.com</a>. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information as soon as possible.
        </p>
        <p>
          Please contact us at <a href='mailto:privacy@etherealrisk.com' target='blank_'>privacy@etherealrisk.com</a> if you have any questions about this Privacy Policy, have any concern about ER’s handling of your personal information, or if you would like to report any possible breach of laws or regulations relating to privacy. ER will respond to your inquiry promptly. 
        </p>
        <p>
          <br /><br />
        </p>
      </Container>
    </>
  );
}

export default Privacy;
