import { call, put, takeEvery } from 'redux-saga/effects';

import API from 'helpers/api';

import {
  fetchCartRequest, fetchCartSuccess, fetchCartError,
  addToCartRequest, addToCartSuccess, addToCartError,
  removeFromCartRequest, removeFromCartSuccess, removeFromCartError,
  // checkoutCartRequest, checkoutCartSuccess, checkoutCartError,
  fetchFilterOptionsRequest, fetchFilterOptionsSuccess, fetchFilterOptionsError,
} from './actions';

import {
  fetchFamilyOptionsRequest, fetchFamilyOptionsSuccess,
  clearDatasetDraft,
} from 'containers/datasets/actions';

function* fetchCart({ payload }) {
  try {
    const { queryType } = payload;
    yield put(clearDatasetDraft());
    const {
      cart_items: items, status, msg,
    } = yield call(API.getCartItems, queryType);

    if (status) {
      yield put(fetchCartSuccess({items}));
    } else {
      yield put(fetchCartError({error: msg}))
    }
  } catch (e) {
    yield put(fetchCartError({error: e}));
  }  
}

function* addToCart({ payload }) {
  try {
    const { productId, queryType } = payload
    const {
      cart_items: items, status, msg,
    } = yield call(API.addToCart, productId, queryType);

    if (status) {
      yield put(addToCartSuccess());
      yield put(fetchCartSuccess({items}));
    } else {
      yield put(addToCartError({error: msg}))
    }
  } catch (e) {
    yield put(addToCartError({error: e}));
  }  
}

function* removeFromCart({ payload }) {
  try {
    const { productId, queryType } = payload
    const {
      cart_items: items, status, msg,
    } = yield call(API.removeFromCart, productId, queryType);

    if (status) {
      yield put(removeFromCartSuccess());
      yield put(fetchCartSuccess({items}));
    } else {
      yield put(removeFromCartError({error: msg}))
    }
  } catch (e) {
    yield put(removeFromCartError({error: e}));
  }  
}

// function* checkoutCart({ payload }) {
//   try {
//     const { datasetName: name, indexes, members } = payload
//     console.log({name, indexes, members});
//     const {
//       cart_items: items, status, msg,
//     } = yield call(API.checkout, name, indexes, members);

//     if (status) {
//         yield put(checkoutCartSuccess());
//         yield put(createDatasetSuccess());
//         yield put(fetchCartSuccess({items}));
//     } else {
//         yield put(checkoutCartError({error: msg}))
//     }
//   } catch (e) {
//     yield put(checkoutCartError({error: e}))
//   }  
// }

function* fetchFilterOptions({ payload }) {
  try {
    const { queryType } = payload;
    const { status, options, msg } = yield call(API.getAvailableOptions, queryType);
    if (status) {
      yield put(fetchFilterOptionsSuccess({options}));
    } else {
      yield put(fetchFilterOptionsError({error: msg}));
    }
  } catch (e) {
    yield put(fetchFilterOptionsError({error: e}));
  }
}

function* updateFmailyFilterOptions({ payload }) {
  const { items } = payload;
  const nativeIds = items.map(item => item.native_id);
  if (nativeIds.length > 0) {
    yield put(fetchFamilyOptionsRequest({nativeIds}));
  } else {
    yield put(fetchFamilyOptionsSuccess({nativeIds: {}}));
  }
}

export const adminSagas = [
  takeEvery(fetchCartRequest.Type, fetchCart),
  takeEvery(addToCartRequest.Type, addToCart),
  takeEvery(removeFromCartRequest.Type, removeFromCart),
  // takeEvery(checkoutCartRequest.Type, checkoutCart),
  takeEvery(fetchFilterOptionsRequest.Type, fetchFilterOptions),
  takeEvery(fetchCartSuccess.Type, updateFmailyFilterOptions),
];
