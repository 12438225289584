export const userRoles = [
  'user', 'admin', 'owner',
];

export const MONTH_MAPPINGS = {
  'jan': 'f', 'feb': 'g', 'mar': 'h', 'apr': 'j',
  'may': 'k', 'jun': 'm', 'jul': 'n', 'aug': 'q',
  'sep': 'u', 'oct': 'v', 'nov': 'x', 'dec': 'z',
}

export const MONTH_NUMBER_MAPPINGS = {
  1: 'f', 2: 'g', 3: 'h', 4: 'j',
  5: 'k', 6: 'm', 7: 'n', 8: 'q',
  9: 'u', 10: 'v', 11: 'x', 12: 'z',
}

export const MEMBER_MODE_RELATIVE = 'relative';
export const MEMBER_MODE_FIXED = 'fixed';
export const MEMBER_MODE_COLOR = 'color';
export const MEMBER_MODE_STRIP = 'strip';
export const MEMBER_SUB_MODE_STRIPT_YEAR = 'year';
export const MEMBER_SUB_MODE_STRIPT_HALF_YEAR = 'half_year';
export const MEMBER_SUB_MODE_STRIPT_QUARTER = 'quarter';

export const timeFrames = [
  { name: '1 year', value: '1 year' },
  { name: '3 years', value: '3 years' },
  { name: '5 years', value: '5 years' }
]

export const memberModes = [
  { name: "nearby", value: MEMBER_MODE_RELATIVE },
  { name: "tenor", value: MEMBER_MODE_FIXED },
  { name: "color", value: MEMBER_MODE_COLOR },
  { name: "strip", value: MEMBER_MODE_STRIP },
];

export const contractColors = [
  'white', 'red', 'green', 'blue',
  // 'gold', 'purple', 'orange',
  // 'pink', 'silver', 'copper'
];

export const months = [
  { name: 'Dec', value: 12 },
  { name: 'Nov', value: 11 },
  { name: 'Oct', value: 10 },
  { name: 'Sep', value: 9 },
  { name: 'Aug', value: 8 },
  { name: 'Jul', value: 7 },
  { name: 'Jun', value: 6 },
  { name: 'May', value: 5 },
  { name: 'Apr', value: 4 },
  { name: 'Mar', value: 3 },
  { name: 'Feb', value: 2 },
  { name: 'Jan', value: 1 },
];

export const stripTypes = [
  { name: "cal year", value: MEMBER_SUB_MODE_STRIPT_YEAR },
  { name: "half year", value: MEMBER_SUB_MODE_STRIPT_HALF_YEAR },
  { name: "quarter", value: MEMBER_SUB_MODE_STRIPT_QUARTER },
];

export const stripYears = [
  2022, 2021, 2020, 2019, 2018
];

export const units = [
  'bbl', 'gallon'
];

export const WINDOW_FUNCTIONS = [
  { name: 'function', value: '' },
  { name: 'min', value: 'min' },
  { name: 'max', value: 'max' },
  { name: 'rolling average', value: 'avg' },
  { name: 'standard deviation', value: 'stddev' },
];

export const WINDOW_PARAMETERS = [
  { name: 'parameter', value: '' },
  { name: '5 days', value: '5' },
  { name: '20 days', value: '20' },
  { name: '60 days', value: '60' },
  { name: '252 days', value: '252' },
];

export const stripHalfYears = [1, 2];
export const stripQuarters = [1, 2, 3, 4];

export const QuickSightRoles = ['READER', 'AUTHOR'];

export const isProduction = process.env.REACT_APP_STAGE === 'production'

export const cotActorMeasureMappings = {
  le_4_tdr: ['conc_gross', 'conc_net'],
  le_8_tdr: ['conc_gross', 'conc_net'],
  m_money: ['change_in', 'pct_of_oi_', 'positions', 'traders'],
  nonrept: ['change_in', 'pct_of_oi_', 'positions'],
  other_rept: ['change_in', 'pct_of_oi_', 'positions', 'traders'],
  prod_merc: ['change_in', 'pct_of_oi_', 'positions', 'traders'],
  swap: ['change_in', 'pct_of_oi_', 'positions', 'traders'],
  tot_rept: ['change_in', 'pct_of_oi_', 'positions', 'traders'],
  whole_market: ['change_in', 'open_interest', 'traders']
}

export const analysisContractOptions = [
  'Jun22', 'Jul22', 'Aug22', 'Sep22', 'Oct22', 'Nov22', 'Dec22',
  'Jan23', 'Feb23', 'Mar23', 'Apr23', 'May23', 'Jun23'
];

export const analysisProductOptions = [
  { name: 'Brent - ICE American Options (B)', value: 'ifeu, options, b' },
  { name: 'WTI - ICE American Options (T)', value: 'ifeu, options, t' },
];

export const VIEW_FLAG_BULLISH = 'bullish';
export const VIEW_FLAG_BEARISH = 'bearish'
export const viewFlags = [
  VIEW_FLAG_BULLISH,
  VIEW_FLAG_BEARISH,
];

export const commodities = {
  nymex: {
    cl: 'WTI',
    bz: 'Brent',
    // rb: 'RBOB',
    //ho: 'Heat',
    //xxxxx: 'Gasoil'
  }
}
