import { VisualizationSpec } from 'vega-embed';

export const getSummaryDetailsSpect = (data: Object): VisualizationSpec => {
  return {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "data": data,
    "vconcat": [
      {
        "width": 800,
        "height": 400,
        "mark": "line",
        "encoding": {
          "x": {
            "field": "trade_date",
            "type": "temporal",
            "scale": { "domain": { "param": "brush" } },
            "axis": { "title": "" }
          },
          "y": {
            "field": "settlement", "type": "quantitative", "scale": {
              "zero": false
            }
          },
          "color": {
            "field": "simul_type",
            "type": "nominal"
          }
        }
      },
      {
        "width": 800,
        "height": 60,
        "mark": "line",
        "params": [
          {
            "name": "brush",
            "select": { "type": "interval", "encodings": ["x"] }
          }
        ],
        "encoding": {
          "x": {
            "field": "trade_date",
            "type": "temporal"
          },
          "y": {
            "field": "settlement",
            "type": "quantitative",
            "axis": { "tickCount": 3, "grid": false }
          }
        }
      }
    ]
  }  
}

export const summaryDetailsSpecStatic = getSummaryDetailsSpect({
  "url": "https://dev-api.etherealrisk.com/options/historical/mc/?subpublisher=nymex&native_id=cl&contract=Jun-23&view_length=7&take_profit=0.05&stop_loss=-0.05&num_paths=15",
  "format": {
    "type": "json",
    "property": "data"
  }
});
