import React from "react";

import Dashboard from 'components/QuickSight/Dashboard';

function Explorer() {
  const dashboardId = '19e2693e-bbc5-4ccc-9d27-01bebc2d9fd9';
  return (
    <>
      <Dashboard dashboardId={dashboardId} />
    </>
  );
}

export default Explorer;
