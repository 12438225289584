// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/heatmap
import PropTypes from 'prop-types';
import { ResponsiveHeatMap } from '@nivo/heatmap'
import { useEffect, useState } from 'react'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const NivoHitmap = ({
    data, xLegendText, yLegendText,
}) => {
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(5);

    useEffect(() => {
        const values = data.map(
            ({data}) => [
                Math.min(...data.map(({y}) => y)),
                Math.max(...data.map(({y}) => y))
            ]);
        setMinValue(Math.min(...values.map(([x, y]) => x)))
        setMaxValue(Math.max(...values.map(([x, y]) => y)))
    }, [
        data
    ]);

    return (
        <ResponsiveHeatMap
            data={data}
            margin={{ top: 20, right: 90, bottom: 60, left: 90 }}
            valueFormat=">-.2s"
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: '',
                legendOffset: 46
            }}
            axisRight={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLegendText,
                legendPosition: 'middle',
                legendOffset: 70
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLegendText,
                legendPosition: 'middle',
                legendOffset: -72
            }}
            colors={{
                type: 'diverging',
                scheme: 'blue_purple',
                divergeAt: 0.5,
                minValue: minValue,
                maxValue: maxValue
            }}
            emptyColor="#555555"
            legends={[
                {
                    anchor: 'bottom',
                    translateX: 0,
                    translateY: 30,
                    length: 250,
                    thickness: 8,
                    direction: 'row',
                    tickPosition: 'after',
                    tickSize: 3,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2s',
                    title: xLegendText,
                    titleAlign: 'middle',
                    titleOffset: 4
                }
            ]}
        />
    )
}

NivoHitmap.propTypes = {
    xLegendText: PropTypes.string.isRequired,
    yLegendText: PropTypes.string.isRequired,
}

export default NivoHitmap;
