/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";

import ProfileDetail from "views/pages/components/ProfileDetail";
import ProfilePass from "views/pages/components/ProfilePass";
import classnames from "classnames";

import { fetchCredentialsRequest, fetchTransactionsRequest } from './actions';

function Profile({
  profile, fetchingProfile, isComplete,
  fetchCredentialsRequest, fetchingCredentials, credentials,
  fetchTransactionsRequest, fetchingTransactions, transactions,
}) {
  const [tabs, setTabs] = useState(1);
  const history = useHistory();
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabs(index);
  };

  useEffect(() => {
    fetchTransactionsRequest();
  }, [
    fetchTransactionsRequest
  ])

  useEffect(() => {
    if (isComplete !== undefined && !isComplete) {
      history.push('/auth/onboarding')
    }
  }, [isComplete, history])

  return (
    <section className="profile">
      <div className="nav-wrapper profile-nav">
        <Nav
          className="flex-column"
          id="tabs-icons-text"
          role="tablist"
        >
          <NavItem>
            <NavLink
              aria-selected={tabs === 1}
              className={classnames("", {
                active: tabs === 1
              })}
              onClick={e => toggleNavs(e, 1)}
              href="#pablo"
              role="tab"
            >
              profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              aria-selected={tabs === 2}
              className={classnames("", {
                active: tabs === 2
              })}
              onClick={e => toggleNavs(e, 2)}
              href="#pablo"
              role="tab"
            >
              password and security
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="profile-wrap">
        <TabContent activeTab={"tabs" + tabs}>
          <TabPane tabId="tabs1">
            {!fetchingProfile && <ProfileDetail />}
          </TabPane>
          <TabPane tabId="tabs2">
            <ProfilePass
              fetchingCredentials={fetchingCredentials}
              credentials={credentials}
              onShowCredentials={fetchCredentialsRequest}
            />
          </TabPane>
        </TabContent>
      </div>
    </section>
  );
}

const mapStateToProps = state => ({
  credentials: state.auth.credentials,
  fetchingCredentials: state.auth.fetchingCredentials,
  fetchingProfile: state.auth.fetchingProfile,
  fetchingTransactions: state.auth.fetchingTransactions,
  isComplete: state.auth.profile.isComplete,
  profile: state.auth.profile,
  transactions: state.auth.transactions,
})

const mapDispatchToProps = dispatch => ({
  fetchCredentialsRequest: () => dispatch(fetchCredentialsRequest()),
  fetchTransactionsRequest: () => dispatch(fetchTransactionsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
