import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

import {
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from "reactstrap";

import classnames from "classnames";
import Members from "../../components/molecules/members/Members";
import WhiteList from "components/molecules/profiles/WhiteList";
// import ProfilePayment from "views/pages/components/ProfilePayment";

import { fetchCredentialsRequest, fetchTransactionsRequest } from '../auth/actions';
import Departments from "components/molecules/departments/Departments";

const AdminProfile = ({
  fetchTransactionsRequest, fetchingTransactions, transactions,
  userRole,
}) => {

  const [tabKey, setTabKey] = useState('departments');
  const history = useHistory();

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setTabKey(index);
  };

  useEffect(() => {
    fetchTransactionsRequest();
  }, [fetchTransactionsRequest])

  useEffect(() => {
    if (userRole === 'user') {
      history.push(`/admin/profile`);
    }
  }, [
    history,
    userRole,
  ])

  return (
    <section className="profile">
      <Row className="profile-wrap">
        <Col lg="11" md="12">
          <h2>administrator management</h2>
          <div className="admin--submenu">
            <Nav
              className="flex-row"
              id="tabs-icons-text"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  aria-selected={tabKey === 'members'}
                  className={classnames("", {
                    active: tabKey === 'members'
                  })}
                  onClick={e => toggleNavs(e, 'members')}
                  href="#pablo"
                  role="tab"
                >
                  member <br />privileges
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabKey === 'dataSources'}
                  className={classnames("", {
                    active: tabKey === 'dataSources'
                  })}
                  onClick={e => toggleNavs(e, 'dataSources')}
                  href="#pablo"
                  role="tab"
                >
                  data source <br />access
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabKey === 'billing'}
                  className={classnames("", {
                    active: tabKey === 'billing'
                  })}
                  onClick={e => toggleNavs(e, 'billing')}
                  href="#pablo"
                  role="tab"
                >
                  billing <br />&amp; costs
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabKey === 'whitelist'}
                  className={classnames("", {
                    active: tabKey === 'whitelist'
                  })}
                  onClick={e => toggleNavs(e, 'whitelist')}
                  href="#pablo"
                  role="tab"
                >
                  WhiteList<br />ip address
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  aria-selected={tabKey === 'departments'}
                  className={classnames("", {
                    active: tabKey === 'departments'
                  })}
                  onClick={e => toggleNavs(e, 'departments')}
                  role="tab"
                >
                  manage<br />departments
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <div className="admin--content">
            <TabContent activeTab={tabKey}>
              <TabPane tabId="members">
                {tabKey === 'members' && <Members />}
              </TabPane>
              <TabPane tabId="dataSources">
                {tabKey === 'dataSources' && <h2>data source access</h2>}
              </TabPane>
              <TabPane tabId="billing">
                {/* <ProfilePayment transactions={transactions} /> */}
              </TabPane>
              <TabPane tabId="whitelist">
                {tabKey === 'whitelist' && <WhiteList />}
              </TabPane>
              <TabPane tabId="departments">
                {tabKey === 'departments' && <Departments userRole={userRole} />}
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </section>
  );
}

const mapStateToProps = state => ({
  fetchingTransactions: state.auth.fetchingTransactions,
  transactions: state.auth.transactions,
  userRole: state.auth.profile?.company?.role,
})

const mapDispatchToProps = dispatch => ({
  fetchCredentialsRequest: () => dispatch(fetchCredentialsRequest()),
  fetchTransactionsRequest: () => dispatch(fetchTransactionsRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);

