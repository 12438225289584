/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

import {
  Container, Row, Col,
  Card, CardBody
} from "reactstrap";

import { fetchProfileRequest } from "./actions";

const Success = ({
  fetchProfileRequest, profile,
}) => {

  const history = useHistory();

  useEffect(() => {
    fetchProfileRequest();
  }, [fetchProfileRequest]);

  useEffect(() => {
    const { isComplete, company: { status } = {} } = profile || {};

    if (isComplete === false) {
      setTimeout(() => {
        history.push('/auth/onboarding');
      }, 3000);
    } else if (isComplete) {
      if (status === 'approved') {
        history.push('/admin/home');
      }
    }
  }, [history, profile])

  return (
      <div className="full_wrap">
        <Container className="mt-10">
          <Row className="auth__row">
            <Col lg="6" md="8">
              <Card className="mb-0 welcome--wrap">
                <CardBody className="px-lg-5">
                  <Row className="mt-2 mb-4">
                    <Col xs="6 mt-3">
                      <h3>welcome</h3>
                      <p>thank you for joining the ethereal risk community. one of our team members will be in touch with you in the next 24 hours to set up your free trial. </p>
                    </Col>
                    <Col xs="6 mt-3 text-right">
                      <img
                        alt="logo"
                        src={require("assets/img/brand/logo_white.png").default}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
})

const mapDispatchToProps = dispatch => ({
  fetchProfileRequest: () => dispatch(fetchProfileRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Success);
