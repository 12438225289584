import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Auth} from 'aws-amplify';
import { AppContext } from './libs/contextLib';
import AdminLayout from 'layouts/Admin.js';
import AuthLayout from 'layouts/Auth.js';
import LandingLayout from 'layouts/Landing';

const App = () => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const onLoad = async () => {
      try {
        const { username } = await Auth.currentAuthenticatedUser();
        window.Intercom('boot', {
          app_id: 'rkhjxqqf',
          email: username,
        });
        userHasAuthenticated(true);
        setIsAuthenticating(false);
      } catch (e) {
        setIsAuthenticating(false);
        window.Intercom('boot', {
          app_id: 'rkhjxqqf',
        });
      }
    };

    onLoad();
  }, [userHasAuthenticated, setIsAuthenticating]);

  useEffect(() => {
    return history.listen((location) => {
      window.Intercom('update');
    });
  }, [history]);

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      {!isAuthenticating && (
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/" render={(props) => <LandingLayout {...props} />} />
        </Switch>
      )}
    </AppContext.Provider>
  );
};

export default App;