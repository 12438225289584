import React, { useState } from "react";
// reactstrap components
import {
  Button,
  Modal,
  ModalBody,
} from "reactstrap";

const UserSubscriptionItem = ({
  subscription, userId,
  onUserSubscriptionUpdated,
}) => {
  const { name, group_name: groupName, user_enabled: state} = subscription;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onClickSubscribeToggle = (e) => {
    e.preventDefault();
    setShowConfirmModal(!showConfirmModal)
    return false;
  }
  
  const onYes = () => {
    onUserSubscriptionUpdated(userId, groupName, state).then(() => {
      setShowConfirmModal(false);
    });
  }

  const onNo = () => {
    setShowConfirmModal(false);
  }

  return (
  <li>
    <div className="datasource-item">
      <div className="edit-profile-item">
        <div className="title-section pl-3">
          {name}
        </div>
        <div className="enable-section">
          {state ? 'user enabled' : 'user not enabled'}
        </div>

        <div className="enable-option">
          <label className="custom-toggle">
            <input type="checkbox" 
              onClick={onClickSubscribeToggle}
              checked={state}
              onChange={e => {}}
              />
            <span className="custom-toggle-slider rounded-circle" />
          </label>
        </div>
      </div>
    </div>

    {/* Confirm modal */}
    <Modal className="companyModal credentialModal" toggle={() => setShowConfirmModal(!showConfirmModal)} isOpen={showConfirmModal}>
      <ModalBody>
        <div className="onboarding--wrap errorMessage_wrap">
          {state
          ? <p>are you sure you want to disable<br />the data source for this user?</p>
          : <p>are you sure you want to enable<br />the data source for this user?</p>
          }
          <Button className="btn-info" color="info" type="button" onClick={() => onYes()}>yes</Button>
          <Button className="btn-info" color="info" type="button" onClick={() => onNo()}>no</Button>
        </div>
      </ModalBody>
    </Modal>

  </li>);
}

export default UserSubscriptionItem;
