import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Modal, ModalBody, ModalFooter, ModalHeader,
  Input, Button, Col, Row,
} from 'reactstrap';
import Select2 from "react-select2-wrapper";

const NewDepartmentModal = ({
  isOpen, onToggle, users, onSubmit,
  usedNames,
}) => {
  const [name, setName] = useState('');
  const [members, setMembers] = useState([]);

  const onClickSave = () => {
    onSubmit(name, members)
    .then(() => {
      setName('');
      setMembers([]);
    })
  }

  const isValid = () => {
    return /^[a-zA-Z0-9]*$/.test(name) && name.length > 0 && usedNames.indexOf(name) < 0
  }

  return (
    <Modal className='companyModal credentialModal' isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>create a new department</ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <Input invalid={!isValid()} type='text' placeholder='Department Name' value={name} onChange={e => setName(e.target.value)} />
            <div className="invalid-feedback">Charater or number only with no repeat</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select2
              className={'form-control'}
              data-minimum-results-for-search="Infinity"
              defaultValue={members}
              multiple
              onChange={(e) => {setMembers(Array.from(e.target.selectedOptions, option => option.value))}}
              data={users.map(({
                user_id: userId, name, role, email,
              }) => ({id: userId, text: `${name}(${email}): ${role}`}))}
              />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col>
            <Button color='primary' disabled={!isValid()} onClick={onClickSave}>save</Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
};

NewDepartmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onToggle: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.shape({
    user_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  usedNames: PropTypes.arrayOf(PropTypes.string),
}

NewDepartmentModal.defaultProps = {
  onToggle: () => {},
  usedNames: [],
}

export default NewDepartmentModal;
