import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";

const MarketDataSection = ({
  title, marketData,
}) => {
  const [curTab, setCurTab] = useState(null);

  useEffect(() => {
    if (marketData.length > 0 && !curTab) {
      const [{product_name: firstProduct}] = marketData;
      setCurTab(firstProduct);
    }
  }, [marketData, curTab, setCurTab])

  const toggleNavs = (e, tabId) => {
    e.preventDefault();
    setCurTab(tabId);
  };

  return (
  <Row className='scenario-item-wrap ml-0 mr-0 datamember'>
    <Col xl="12">
      <h2 className='pl-2 pt-2 mt-1'>{title}</h2>
    </Col>
    <Col xl="12" className="pt-2 border--t pl-0 pr-0">
      <div className="nav-wrapper">
        <Nav
          className="nav-fill flex-column flex-md-row"
          id="tabs-icons-text"
          pills
          role="tablist"
        >
          {marketData.map(({product_name: productName}, idx) => (
            <NavItem key={idx}>
              <NavLink
                aria-selected={curTab === productName}
                className={curTab === productName ? 'selected' : ''}
                onClick={e => toggleNavs(e, productName)}
                role="tab"
              >
                {productName.toUpperCase()}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      <TabContent activeTab={curTab}>
        {marketData.map(({product_name: productName, data}, idx) => (
          <TabPane tabId={productName} key={idx}>
            <Table className="align-items-center mb-0" responsive>
              <colgroup>
                <col width="15%" />
                <col width="14%" />
                <col width="14%" />
                <col width="14%" />
                <col width="14%" />
                <col width="14%" />
                <col width="" />
              </colgroup>
              <thead className="thead-light">
                <tr>
                  <th scope="col">CONTRACT</th>
                  <th scope="col">FLAT PRICE</th>
                  <th scope="col">ATM</th>
                  <th scope="col">5D PUT</th>
                  <th scope="col">25D PUT</th>
                  <th scope="col">25D CALL</th>
                  <th scope="col">5D CALL</th>
                </tr>
              </thead>
            </Table>
            <div className='table-content'>
              <Table className="align-items-center" responsive>
                <colgroup>
                  <col width="15%" />
                  <col width="14%" />
                  <col width="14%" />
                  <col width="14%" />
                  <col width="14%" />
                  <col width="14%" />
                  <col width="" />
                </colgroup>
                <tbody>
                  {data.map((item, idx) => 
                    <tr key={idx}>
                      <td className='align-left'>{item.contract}</td>
                      <td className='align-left'>{item['flat price']}</td>
                      <td className='align-left'>{item.atm}%</td>
                      <td className='align-left'>{item['5d put']}%</td>
                      <td className='align-left'>{item['25d put']}%</td>
                      <td className='align-left'>{item['5d call']}%</td>
                      <td className='align-left'>{item['25d call']}%</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </TabPane>
        ))}
      </TabContent>
    </Col>
    <br /><br />
    <p className='pl--40'>note: skew points are marked as a percentage to the ATM vol</p>
  </Row>
  )
}

MarketDataSection.propTypes = {
  title: PropTypes.string.isRequired,
  marketData: PropTypes.array.isRequired,
}

export default MarketDataSection;
