import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import {
    Row, Col,
    Modal,
    ModalBody,
    Button,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
} from "reactstrap";

import Publisher from './DataPublisher';

function ProfileDataSource({
  company,
  onSubmitCompanyCreation,
  dataSources,
  onEnableDataSource,
  onDisableDataSource,
  error,
  credentials,
  onCredentialsUpdated,
}) {
  const {
    enabled: enabledDataSources,
    disabled: disabledDataSources
  } = dataSources;

  const [companyName, setCompanyName] = useState('');
  const [curDataSourceItem, setCurDataSourceItem] = useState(null);
  const [agreements, setAgreements] = useState({});
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [showcontact, setShowContact] = useState(false);
  const [showWaitModal, setShowWaitModal] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!!company) {
      const { company_id: companyId, role, status } = company;
      if (!companyId) {
        setCompanyModalOpen(true);
      } else if (role === 'user') {
        setShowContact(true);
      } else if (status === 'pending') {
        setShowWaitModal(true);
      }
    }
  }, [
    company, setShowContact, setCompanyModalOpen
  ]);

  // if (company !== null) {
  //   setCompanyModalOpen(false);
  // }

  // {company && (company.role === 'admin' || company.role === 'owner')

  const onAgreementChanged = (e) => {
    const { group_name: groupName } = curDataSourceItem;
    const { status } = company || {};

    if (status === 'pending') {
      setShowWaitModal(true);
      return
    }

    setAgreements({
      ...agreements,
      [groupName]: e.target.checked
    })
  }

  const onSetCompanyName = (comName) => {
    onSubmitCompanyCreation(comName).then(() => {
      setCompanyModalOpen(false);
    });
  }

  return (
    <>
      <Row>
        <Col lg="6" className="right--border">
          <h2>data source management</h2>

          <h3>enabled</h3>
          {enabledDataSources && Object.entries(enabledDataSources).map(([name, items], idx) => (
            <Publisher
              key={`enabled-${idx}`}
              name={name}
              items={items}
              agreements={agreements}
              onEnableDataSource={onEnableDataSource}
              onDisableDataSource={onDisableDataSource}
              setCurDataSourceItem={setCurDataSourceItem}
              error={error}
              credentials={credentials}
              onCredentialsUpdated={onCredentialsUpdated}
            />
          ))}

          <br />
          <h3>disabled</h3>
          {disabledDataSources && Object.entries(disabledDataSources).map(([name, items], idx) => (
            <Publisher
              key={`enabled-${idx}`}
              name={name}
              items={items}
              agreements={agreements}
              setCurDataSourceItem={
                company && company.company_id !== null
                ? setCurDataSourceItem
                : () => {setCompanyModalOpen(company.company_id === null)}
              }
              onEnableDataSource={onEnableDataSource}
              onDisableDataSource={onDisableDataSource}
              credentials={credentials}
              onCredentialsUpdated={onCredentialsUpdated}
              error={error}
            />
          ))}
        </Col>
        <Col lg="6" className={'instruction-block'}>
          <br /><br /><br />
          {curDataSourceItem ? (
          <>
            <h3><strong>{curDataSourceItem.name}</strong> data source set-up instructions</h3>
            {curDataSourceItem.publisher === 'ice' ? (<p>send an email to data@theice.com asking for credentials to access Downloads2. 
              fill out the responding user questionnaire with contact information and requested file access</p>) : null }

            <p>once you have your credentials from {curDataSourceItem.name},</p>

            <p>1. click ‘edit’ to enter your username and password</p>

            <p>2. check ‘I agree’ to incremental cost of data source curation</p>

            <p className="pl--20">cost of <strong>{curDataSourceItem.name}</strong> data source Ethereal Risk curation = <strong>${curDataSourceItem.price}/month</strong></p>

            <div className="pl--20">
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id="customCheckLogin"
                  type="checkbox"
                  checked={curDataSourceItem.enabled || agreements[curDataSourceItem.group_name] || false}
                  onChange={onAgreementChanged}
                  disabled={curDataSourceItem.enabled}
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckLogin"
                >
                  
                  <span className="text-muted">I agree to the above cost that will be charged to my payment method on file.</span>
                </label>
              </div>
            </div>
            <p>3. move the toggle to the left of the data source to enable and click ‘yes;’ on the pop-up</p>
          </>) : (
          <>
            <h3>instructions</h3>
            <p>the available list of data sources that can be accessed via ethereal risk is listed to the left. 
              The data sources are organized by the publishers to which you are enabled or not enabled. 
              click the down arrow to see the distribution channels provided by each publisher. 
              click on any distribution channel to see the set-up instructions and additional information about this source.</p>
          </>)}
        </Col>
      </Row>

      {/* Company Error modal */}
      <Modal className="companyModal credentialModal" isOpen={companyModalOpen}>
        <ModalBody>
          <div className="onboarding--wrap errorMessage_wrap">
            <h2>company onboarding</h2>
            <br />
            <p>thanks for confirming that you <br />are your company's administrator.</p>
            <p>we need a few additional details on your company<br /> to fully onboard your firm.</p>
            <br />
            <center><FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-settings-gear-65" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="company full name"
                  type="text"
                  value={companyName}
                  onChange={(e) => {setCompanyName(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
            </center>
            <br />
            <Button
              className="btn-info" color="info"
              disabled={companyName.length === 0} type="button"
              onClick={() => {onSetCompanyName(companyName)}}
              >
              submit
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Administrator checking modal */}
      {/* support and contact modal */}
      <Modal className="credentialModal" isOpen={showcontact}>
        <ModalBody>
          <h5>please contact your administrator</h5>
            <div className="text-center support_btn">
              <Button color="info" type="button" onClick={() => history.push('admin/home')}>
                home
              </Button>
            </div>
        </ModalBody>
      </Modal>

      {/* db creation notification modal */}
      <Modal className="credentialModal" isOpen={showWaitModal}>
        <ModalBody>
          <h5>thank you for entering your company information.</h5>
          <p>we will now create your company database, once it's ready this page will allow you to enable your datasources</p>
          <div className="text-center support_btn">
            <Button color="info" type="button" onClick={() => setShowWaitModal(false)}>
              okay, I got it
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ProfileDataSource;
