import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Collapse,
  NavLink,
} from "reactstrap";

import MarketFamily from './MarketFamily';
import FundamentalFamily from './FundamentalFamily';

const BuilderPublisher = ({
  name, families, members, setDatasetMember, removeDatasetMember,
  queryType, defaultOpen, dataType, selIndice, copyToIndexBtnClick
}) => {
  const [ isOpen, setIsOpen ] = useState(defaultOpen);
  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu">
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          {name}
          <i className={classnames("ni ni-button-play float-none ml-2", {rotate: isOpen})}></i>
        </NavLink>
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      {dataType === 'fundamental'
      ? <FundamentalFamily
          families={families}
          />
      : families.map((family, idx) => (
        <MarketFamily
          idx={idx} key={idx} type={'all'} family={family}
          members={members[family.product_id] || []}
          setDatasetMember={setDatasetMember}
          removeDatasetMember={removeDatasetMember}
          queryType={queryType}
          selIndice={selIndice}
          copyToIndexBtnClick={copyToIndexBtnClick}
          />
      ))}
    </Collapse>
  </>)
}

BuilderPublisher.propTypes = {
  name: PropTypes.string.isRequired,
  families: PropTypes.arrayOf(PropTypes.shape({
    publisher: PropTypes.string.isRequired,
  })).isRequired,
  members: PropTypes.object,
  setDatasetMember: PropTypes.func,
  removeDatasetMember: PropTypes.func,
  queryType: PropTypes.string,
  dataType: PropTypes.string,
  defaultOpen: PropTypes.bool,
}

BuilderPublisher.defaultProps = {
  queryType: 'time_series',
  dataType: '',
  defaultOpen: false,
  setDatasetMember: () => {},
  removeDatasetMember: () => {},
  members: {},
}

export default BuilderPublisher;
