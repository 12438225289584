import React from 'react';

import {
  // Button,
  Container,
  Row,
  Col,
} from "reactstrap";

const PaymentSuccess = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2>Success</h2>
        </Col>
      </Row>
      <Row>
        <p>Successfully activate membership</p>
      </Row>
    </Container>
  )
}

export default PaymentSuccess;
