import React from "react";
import PropTypes from 'prop-types';

function CommoditySub({ facts, commodity, contract,
}) {
  // const factsList = [
  //   { 'title': 'trade date', 'key': 'trade_date' },
  //   { 'title': 'current', 'key': 'current_price' },
  //   { 'title': 'WoW chg.', 'key': 'change_wow', 'isPercentage': true },
  //   { 'title': '3m chg.', 'key': 'change_3m', 'isPercentage': true },
  //   { 'title': 'YoY chg.', 'key': 'change_yoy', 'isPercentage': true },
  //   { 'title': 'current vol', 'key': 'current_vol', 'isPercentage': true },
  //   { 'title': 'vol week ago.', 'key': 'wow_vol', 'isPercentage': true },
  //   { 'title': 'vol 3m ago.', 'key': 'vol_3m', 'isPercentage': true },
  //   { 'title': 'vol year ago.', 'key': 'yoy_vol', 'isPercentage': true },
  // ];

  const { nativeID, subpublisher } = commodity;
  const { contractTenor = '' } = contract;

  return (
    <div className="sub-page">
      <h3 className="align-center mb-0">quick facts</h3>
      <hr />
      <div className="flex mt-3">
        <div className="card card-stats container">
          <div className="card-body">
            <div className="row">
              <h5 className="text-muted card-title mb-0">product</h5>
            </div>
            <div className="row">
              <span className="h2 font-weight-bold mb-0">{subpublisher}, futures, {nativeID}
              </span>
            </div>
            <div className="row">
              <h5 className="text-muted card-title mb-0">contract</h5>
            </div>
            <div className="row">
              <span className="h2 font-weight-bold mb-0">{contractTenor}
              </span>
            </div>
          </div>
        </div>
        <div className="card card-stats container">
          <div className="card-body">
            <div className="row">
              <h5 className="text-muted card-title mb-0">current price</h5>
            </div>
            <div className="row">
              <span className="h2 font-weight-bold mb-0">{facts.current_price}
              </span>
            </div>
            {!!facts.change_wow && (<div className="row">
              <p class="mt-3 mb-0 text-sm">
                <span class={"mr-2 text-" + (facts.change_wow > 0 ? "success" : "danger")}>
                  <i class={"fa fa-arrow-" + (facts.change_wow > 0 ? "up" : "down")}></i> {facts.change_wow}%</span>
                <span class="text-nowrap">week change</span></p>
            </div>)}
            {!!facts.change_3m && (<div className="row">
              <p class="mt-3 mb-0 text-sm">
                <span class={"mr-2 text-" + (facts.change_3m > 0 ? "success" : "danger")}>
                  <i class={"fa fa-arrow-" + (facts.change_3m > 0 ? "up" : "down")}></i> {facts.change_3m}%</span>
                <span class="text-nowrap">3m change</span></p>
            </div>)}
            {!!facts.change_yoy && (<div className="row">
              <p class="mt-3 mb-0 text-sm">
                <span class={"mr-2 text-" + (facts.change_yoy > 0 ? "success" : "danger")}>
                  <i class={"fa fa-arrow-" + (facts.change_yoy > 0 ? "up" : "down")}></i> {facts.change_yoy}%</span>
                <span class="text-nowrap">year change</span></p>
            </div>)}
          </div>
        </div>
        <div className="card card-stats container">
          <div className="card-body">
            <div className="row">
              <h5 className="text-muted card-title mb-0">current vol</h5>
            </div>
            <div className="row">
              <span className="h2 font-weight-bold mb-0">{facts.current_vol} %</span>
            </div>
            {!!facts.wow_vol && (
              <div className="row">
                <p className="mt-3 mb-0 text-sm">
                  <span className={"mr-2"}>
                    {facts.wow_vol}%
                  </span>
                  <span className="text-nowrap">last week</span>
                </p>
              </div>
            )}
            {!!facts.vol_3m && (
              <div className="row">
                <p className="mt-3 mb-0 text-sm">
                  <span className={"mr-2"}>
                    {facts.vol_3m}%
                  </span>
                  <span className="text-nowrap">3 months ago</span>
                </p>
              </div>
            )}
            {!!facts.yoy_vol && (
              <div className="row">
                <p className="mt-3 mb-0 text-sm">
                  <span className={"mr-2"}>
                    {facts.yoy_vol}%
                  </span>
                  <span className="text-nowrap">last year</span>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="card card-stats container">
          <div className="card-body">
            <div className="row">
              <h5 className="text-muted card-title mb-0">trade date</h5>
            </div>
            <div className="row">
              <span className="h2 font-weight-bold mb-0">{facts.trade_date}
              </span>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

CommoditySub.propTypes = {
  commodity: PropTypes.shape({
    nativeID: PropTypes.string.isRequired,
    subpublisher: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  contract: PropTypes.string.isRequired,
  facts: PropTypes.shape({
    trade_date: PropTypes.string,
    current_price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    wow_change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    '3m_change': PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    yoy_change: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default CommoditySub;
