import React, { useState } from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
    Button,
    Modal, ModalBody
} from "reactstrap";
import ProfileItem from './ProfileItem';
import API from 'helpers/api';

function ProfileDetail({
  profile,
}) {
  const { userId, onboarding, company } = profile;
  const [fullName, setFullName] = useState(onboarding ? onboarding.full_name : '');
  const [companyName, setCompanyName] = useState(onboarding ? onboarding.company_name : '');
  const [companyLocation, setCompanyLocation] = useState(onboarding ? onboarding.primary_work_address : '');
  const [companyType, setCompanyType] = useState(onboarding ? onboarding.company_type : '');
  const [companyRole, setCompanyRole] = useState(onboarding ? onboarding.company_role : '');
  const [assetClass, setAssetClass] = useState(onboarding ? onboarding.primary_asset_class : '');

  const [errorMessage, setErrorMessage] = useState('');
  const [errmodalOpen, setErrModalOpen] = useState(false);

  const setStatusVal = async (type, val) => {
    if( type === 1 )
        setFullName(val);
    if( type === 2 )
        setCompanyName(val);
    if( type === 3 )
        setCompanyLocation(val);
    if( type === 4 )
        setCompanyType(val);
    if( type === 5 )
        setCompanyRole(val);
    if( type === 6 )
        setAssetClass(val);
  }
  const saveProfileDetail = async () => {
    const isValid = (
        fullName && companyName &&
        companyLocation && companyType &&
        companyRole && assetClass
    )

    if( isValid ) {
        const profile = {
            full_name: fullName,
            company_name: companyName,
            primary_work_address: companyLocation,
            company_type: companyType,
            company_role: companyRole,
            primary_asset_class: assetClass,
        }
        API.saveOnBoarding(profile).then(res => {
            const { status } = res;
            if (status) {
                setErrorMessage("1");
            }
            setErrModalOpen(!errmodalOpen);
        }).catch(err => {
            setErrorMessage("0");
            setErrModalOpen(!errmodalOpen);
        });
    } else {
        setErrorMessage("0");
        setErrModalOpen(!errmodalOpen);
    }
  }
    
  return (
    <>
        {/* <h2>profile details{userId ? `(${userId})` : ''}</h2> */}
        <h2>profile details</h2>

        <div className="edit-profile-item">
          <div className="title-section">
            <i className="ni ni-single-02"></i>
            user id
          </div>
          <div className="input-section">
            {userId}
          </div>
          <div className="btn-section">
              
          </div>
        </div>
        {company && company.company_id && (<div className="edit-profile-item">
          <div className="title-section">
            <i className="ni ni-istanbul"></i>
            company id
          </div>
          <div className="input-section">
            {company.company_id}
          </div>
          <div className="btn-section">
              
          </div>
        </div>)}
        <ProfileItem ico="ni-settings-gear-65" title="full name" value={fullName} type={1} func={setStatusVal}></ProfileItem>
        <ProfileItem ico="ni-building" title="company name" value={companyName} type={2} func={setStatusVal}></ProfileItem>
        <ProfileItem ico="ni-hat-3" title="work address" value={companyLocation} type={3} func={setStatusVal}></ProfileItem>
        <ProfileItem ico="ni-button-power" title="company type" value={companyType} type={4} func={setStatusVal}></ProfileItem>
        <ProfileItem ico="ni-user-run" title="company role" value={companyRole} type={5} func={setStatusVal}></ProfileItem>
        <ProfileItem ico="ni-archive-2" title="primary asset class" value={assetClass} type={6} func={setStatusVal}></ProfileItem>
        <div className="mt-3">
            <Button color="info" type="button" onClick={() => saveProfileDetail()}>
                save changes
            </Button>
        </div>

        {/* Company Role modal */}
        <Modal className="companyModal errModal" toggle={() => setErrModalOpen(!errmodalOpen)} isOpen={errmodalOpen}>
          <ModalBody>
            <div className="onboarding--wrap errorMessage_wrap">
              {errorMessage === '0' ? (<>
                <p className="err--msg">looks like we need to know a little more!</p>
                <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>close</Button>
              </>) : (<>
                <p className="err--msg">thanks! we're all set.</p>
                <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>close</Button>
              </>)}
            </div>
          </ModalBody>
        </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
});

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetail);
