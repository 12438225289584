import { call, put, takeEvery } from 'redux-saga/effects';

import API from 'helpers/api';

import {
  fetchProfileRequest, fetchProfileSuccess, fetchProfileError,
  submitProfileRequest, submitProfileSuccess, submitProfileError,
  fetchCredentialsRequest, fetchCredentialsSuccess, fetchCredentialsFailure,
  fetchTransactionsRequest, fetchTransactionsSuccess, fetchTransactionsFailure,
} from './actions';

function* fetchProfile() {
  try {
    const {
        company, onboarding, status, user_id: userId,
        is_complete: isComplete, msg, membership,
    } = yield call(API.getOnBoardingStatus);

    if (status) {
        yield put(fetchProfileSuccess({
          company, onboarding, isComplete, userId, membership,
        }));
    } else {
        yield put(fetchProfileError({error: msg}))
    }
  } catch (e) {
    yield put(fetchProfileError({error: e}))
    console.error(e)
  }  
}

function* submitProfile({payload}) {
  try {
    const {
        company, onboarding, status, is_complete: isComplete, msg
    } = yield call(API.saveOnBoarding, payload);

    if (status) {
        yield put(submitProfileSuccess({
            company, onboarding, isComplete,
        }));
    } else {
        yield put(submitProfileError({error: msg}))
    }
  } catch (e) {
    yield put(submitProfileError({error: e}))
    console.error(e)
  }  
}

function* fetchCredentials() {
  try {
    const { credentials } = yield call(API.getCredentials);
    yield put(fetchCredentialsSuccess({credentials}))
  } catch (e) {
    yield put(fetchCredentialsFailure())
  }
}

function* fetchTransactions() {
  try {
    const { transactions } = yield call(API.getTransactions);
    yield put(fetchTransactionsSuccess({transactions}))
  } catch (e) {
    yield put(fetchTransactionsFailure())
  }
}

export const authSagas = [
  takeEvery(fetchProfileRequest.Type, fetchProfile),
  takeEvery(submitProfileRequest.Type, submitProfile),
  takeEvery(fetchCredentialsRequest.Type, fetchCredentials),
  takeEvery(fetchTransactionsRequest.Type, fetchTransactions),
];
