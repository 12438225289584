import React, { useState } from 'react';

import { 
  Row, Col, Input, Button,
} from "reactstrap";

import VegaChart from 'components/molecules/charts/VegaChart';
import {
  summaryDetailsSpecStatic,
} from 'components/molecules/charts/vegaListSpecs';

export function VegaDemo() {
  const [jsonString, setJsonString] = useState(JSON.stringify(summaryDetailsSpecStatic, null, 2));
  const [spec, setSpec] = useState(summaryDetailsSpecStatic);

  const updateJsonString = () => {
    try {
      setSpec(JSON.parse(jsonString));
    } catch {
      alert('Your json is invalid. Please check!')
    }
  }

  return (
    <>
      <section className="optimizer">
        <Row>
          <Col xl={4} lg={5} md={6} sm={12}>
            <Input
              value={jsonString} type='textarea'
              rows={30}
              onChange={(e) => setJsonString(e.target.value)} />
          </Col>
          <Col xl={8} lg={7} md={6} sm={12}>
            <div>
              <Button className="btn-info" color="info" type="button" onClick={updateJsonString}>Render</Button>
            </div>
            <div>
              <VegaChart spec={spec} />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default VegaDemo;
