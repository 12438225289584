import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  Collapse,
  NavLink,
} from "reactstrap";

const FilterCondition = ({
  identifier,
  name,
  items,
  selectedOptions,
  onCheckUpdated,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu">
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          {name}
          <i className={classnames("ni ni-button-play", {rotate: isOpen})}></i>
        </NavLink>
        {!isOpen ? (
          <div className="selected_options">
            {selectedOptions.map((item, key) =>
              <span key={key}>{item}</span>
            )}
          </div>
        ) : null}
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      <br />
      <div>
          {items && items.map((item, key) => <div key={key}>
            <input
              className="custom-control-input"
              id={`${identifier}-${key}`}
              type="checkbox"
              checked={selectedOptions.indexOf(item.value) >= 0}
              onChange={(e) => {onCheckUpdated(identifier, item.value, e.target.checked)}}
            />
            <label
              className="custom-control-label"
              htmlFor={`${identifier}-${key}`}
            >{item.value}</label>
            </div>)}
      </div>
    </Collapse>
  </>
  )
}

FilterCondition.propTypes = {
  identifier: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    count: PropTypes.number,
  })),
  onCheckUpdated: PropTypes.func.isRequired,
}

export default FilterCondition;
