import React, { useState } from 'react';

import PropTypes from 'prop-types';

import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

const CodeModal = ({
  title,
  content,
  isOpen,
  onToggle,
  onOkay = () => {},
}) => {
  const [copied, setCopied] = useState(false);

  const onCopyClicked = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return (
    <Modal className="companyModal codeModal" toggle={onToggle} isOpen={isOpen}>
      {title &&
      <ModalHeader>
        {title}
      </ModalHeader>}
      <ModalBody>
        <div className="onboarding--wrap codeContainer">
          <code>
            {content.split('\n').map((row, idx) => (
              <pre key={idx}>{row}</pre>
            ))}
          </code>
        </div>
      </ModalBody>
      <ModalFooter>
        <CopyToClipboard text={content}
          onCopy={onCopyClicked}>
          <Button className="btn-info" color="success" type="button">{copied ? 'copied to clipboard' : 'copy to clipboard'}</Button>
        </CopyToClipboard>
      </ModalFooter>
    </Modal>
  )
}

CodeModal.propTypes = {
  content: PropTypes.string,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  onOkay: PropTypes.func,
}

export default CodeModal;
