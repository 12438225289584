import React from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button
} from "reactstrap";

function OptionsPaymentSuccess() {
  const history = useHistory();
  return (
    <section className="optimizer">
      <h1 className="border--b">{'options score explorer'}</h1>
      <div className="payment-success">
        <h2>Awesome!</h2>
        <h3>We've received your order and are now creating your Options Score Explorer</h3>
        <h4>
          Stay tuned<br/>
          We'll drop you an email once your results are all set to view!<br/><br/>
        </h4>
        <br/>
        <Button color="info" onClick={() => history.push('/optimizer')} className='runNewAnalysis'>
          run new analysis
        </Button>
      </div>
    </section>
  )
}

export default connect(null, null)(OptionsPaymentSuccess);
