import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import API from 'helpers/api';

var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const Dashboard = ({
    dashboardId,
}) => {
    const [loadingDashboardUrl, setLoadingDashboardUrl] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoadingDashboardUrl(true);
        API.getDashboardUrl(dashboardId)
            .then(res => {
                const { url } = res;
                const containerDiv = document.getElementById("dashboardContainer");
                if (containerDiv.firstChild) {
                    containerDiv.removeChild(containerDiv.firstChild);
                }

                const options = {
                    url,
                    container: containerDiv,
                    parameters: {
                        country: "United States"
                    },
                    scrolling: "no",
                    height: "100%",
                    width: "100%",
                    footerPaddingEnabled: false,
                };
                QuickSightEmbedding.embedDashboard(options);
                setLoadingDashboardUrl(false);
            }).catch(err => {
                setLoadingDashboardUrl(false);
                //setError(err.response.data.msg);
                setError('');
            })
    }, [
        dashboardId, setLoadingDashboardUrl,  // selectedDashboard
    ]);

    return (
        <>
            {error && <h4>{error}</h4>}
            {loadingDashboardUrl && <h2>Loading Preview... </h2>}
            <div className='dashboardContainer' id='dashboardContainer'></div>
        </>);
}

Dashboard.propTypes = {
    dashboardId: PropTypes.string.isRequired,
}

export default Dashboard;
