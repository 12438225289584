import React from 'react';
import PropTypes from 'prop-types';

// reactstrap components
import {
  Button
} from "reactstrap";

const BuilderTitle = ({
  title, subtitle, btn, modifyDataFamily
}) => {
  return (
    <>
      <div className="flex">
        <div>
          <h2 className="mb--0">{title}</h2>
          {subtitle?(<p className="mb--0">{subtitle}</p>):null}
        </div>
        {btn? (<div>
          <Button color="info" type="button" onClick={() => modifyDataFamily()}>
            {btn}
          </Button>
        </div>) : null}        
      </div>
    </>
  )
}

BuilderTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  modifyDataFamily: PropTypes.func
}

export default BuilderTitle;
