import Lock from "containers/auth/Lock";
import Login from "containers/auth/Login";
import Success from "containers/auth/Success";
import Profile from "containers/auth/Profile";
import OnBoarding from "containers/auth/onBoarding";
import ContactUs from "containers/auth/ContactUs";
import Register from "containers/auth/Register";
import Verification from "containers/auth/Verification";
import ForgotPassword from 'containers/auth/ForgotPassword';
import Calendar from "containers/admin/Calendar";
import QuickSight from 'views/QuickSight';
// import Home from "containers/admin/Home";
import Explorer from "views/pages/dashboards/Explorer";
import SessionEmbed from "views/pages/dashboards/SessionEmbed";
import DataBuilder from "containers/admin/DataBuilder";
import DataBuilderSwitcher from "containers/admin/DataBuilderSwitcher";
import DataBuilderMember from "containers/admin/DataBuilderMember";
import DataBuilderSnapshot from "containers/admin/DataBuilderSnapshot";
import DataLibrary from "containers/datasets/DataLibrary";
import DataSetFamilyBuilder from "containers/datasets/DataSetFamilyBuilder";
import DataSetMemberBuilder from "containers/datasets/DataSetMemberBuilder";
import DataSetMemberSnapshot from "containers/datasets/DataSetMemberSnapshot";
import Modeling from "views/pages/dashboards/Modeling";
import BackTester from "views/pages/dashboards/BackTester";
import DataSources from 'containers/admin/DataSources';
import AdminProfile from 'containers/admin/AdminProfile';
import Tools from 'containers/admin/Tools';
import Upgrade from 'containers/auth/Upgrade';
import UpgradeSuccess from 'containers/auth/UpgradeSuccess';
import UpgradeFailure from 'containers/auth/UpgradeFailure';
import DailyDash from "views/pages/dashboards/DailyDash";
import Landing from 'containers/landing/Landing'
import Terms from 'containers/landing/Terms';
import Privacy from 'containers/landing/Privacy';
import { Redirect } from "react-router";

import OptionsOptimizer from 'containers/admin/OptionsOptimizer';
import OptionsRecommenderPaymentSuccess from "containers/admin/OptionsRecommenderPaymentSuccess";

import Scenario from "containers/admin/Scenario";
import OptionsOptimizerLogs from "containers/admin/OptionsOptimizerLogs";
import OptionsPaymentSuccess from "containers/admin/OptionsPaymentSuccess";
import OptionsRecommenderPdfScenario from "containers/admin/OptionsRecommenderPdfScenario";
import OptionsExplorer from "containers/admin/OptionsExplorer";
import VegaDemo from "containers/admin/VegaDemo";
import Accept from "containers/auth/Accept";

const routes = [
  {
    path: "/home",
    name: "home",
    icon: "ni ni-shop text-sidebar",
    // Need to change this home page later.
    // component: Home,
    component: OptionsOptimizer,
    show: false,
    layout: "/admin",
  },
  {
    path: "/scenario",
    name: "options scenario",
    icon: "ni ni-sound-wave text-sidebar",
    component: Scenario,
    show: false,
    layout: "/admin",
  },
  {
    path: "/creator",
    name: "dataset builder",
    icon: "ni ni-ungroup text-sidebar",
    component: DataBuilder,
    layout: "/admin",
    show: false,
  },
  {
    path: "/creator/snapshot",
    name: "dataset builder",
    icon: "ni ni-ungroup text-sidebar",
    component: (props) => <DataBuilder queryType='snapshot' {...props} />,
    layout: "/admin",
    show: false,
  },
  {
    path: "/optimizer",
    name: "options score explorer",
    icon: "ni ni-support-16 text-sidebar",
    component: OptionsOptimizer,
    layout: "/admin",
  },
  {
    path: "/optimizer/logs",
    name: "options optimizer logs",
    icon: "ni ni-support-16 text-sidebar",
    component: OptionsOptimizerLogs,
    layout: "/admin",
    show: false,
  },
  {
    path: "/optimizer/success",
    name: "options recommender",
    icon: "ni ni-support-16 text-sidebar",
    component: OptionsPaymentSuccess,
    layout: "/admin",
    show: false,
  },
  {
    path: "/creator/switch",
    name: "dataset builder",
    icon: "ni ni-ungroup text-sidebar",
    component: DataBuilderSwitcher,
    layout: "/admin",
    show: false,
  },
  {
    path: "/library",
    name: "dataset library",
    icon: "ni ni-ui-04 text-sidebar",
    component: DataLibrary,
    layout: "/admin",
    show: false,
  },
  {
    path: "/library/:datasetId/edit",
    name: "edit library",
    icon: "ni ni-ui-04 text-sidebar",
    show: false,
    component: DataSetFamilyBuilder,
    layout: "/admin",
  },
  {
    path: "/library/:datasetId/edit-members/snapshot",
    name: "edit library members",
    show: false,
    icon: "ni ni-ui-04 text-sidebar",
    component: DataSetMemberSnapshot,
    layout: "/admin",
  },
  {
    path: "/library/:datasetId/edit-members",
    name: "edit library members",
    show: false,
    icon: "ni ni-ui-04 text-sidebar",
    component: DataSetMemberBuilder,
    layout: "/admin",
  },
  {
    path: "/library/:analysisId",
    name: "library",
    icon: "ni ni-ui-04 text-sidebar",
    component: SessionEmbed,
    show: false,
    layout: "/admin",
  },
  {
    path: "/optimizer/checkout/success/:requestId",
    name: "optionsrecsuccess",
    icon: "ni ni-ui-04 text-sidebar",
    component: OptionsRecommenderPaymentSuccess,
    show: false,
    layout: "/admin",
  },
  {
    path: "/optimizer/pdf/scenario/:requestId/:left/:right",
    name: "optionsrecsuccess",
    icon: "ni ni-ui-04 text-sidebar",
    component: OptionsRecommenderPdfScenario,
    show: false,
    layout: "/admin",
  },
  {
    path: "/explorer/:requestId/",
    name: "explorer",
    icon: "ni ni-single-copy-04 text-sidebar",
    show: false,
    component: OptionsExplorer,
    layout: "/admin",
  },
  {
    path: "/explorer/vegalite/demo",
    name: "vega-list-demo",
    icon: "ni ni-single-copy-04 text-sidebar",
    show: false,
    component: VegaDemo,
    layout: "/admin",
  },
  {
    path: "/explorer",
    name: "explorer",
    icon: "ni ni-single-copy-04 text-sidebar",
    show: false,
    component: Explorer,
    layout: "/admin",
  },
  {
    path: "/assets",
    name: "analysis library",
    icon: "ni ni-single-copy-04 text-sidebar",
    component: SessionEmbed,
    layout: "/admin",
    show: false,
  },
  {
    path: "/buildmember",
    name: "data builder",
    icon: "ni ni-single-copy-04 text-sidebar",
    component: DataBuilderMember,
    show: false,
    layout: "/admin",
  },
  {
    path: "/buildsnap",
    name: "data snapshot",
    icon: "ni ni-single-copy-04 text-sidebar",
    component: DataBuilderSnapshot,
    show: false,
    layout: "/admin",
  },
  {
    path: "/modeling",
    name: "modeling",
    icon: "ni ni-align-left-2 text-sidebar",
    show: false,
    component: Modeling,
    layout: "/admin",
  },
  {
    path: "/backtester",
    name: "backtester",
    icon: "ni ni-map-big text-sidebar",
    show: false,
    component: BackTester,
    layout: "/admin",
  },
  {
    path: "/datasources",
    name: "data sources",
    icon: "ni ni-archive-2 text-sidebar",
    component: DataSources,
    layout: "/admin",
    show: false,
  },
  {
    path: "/adminprofile",
    name: "admin management",
    icon: "ni ni-archive-2 text-sidebar",
    component: AdminProfile,
    show: false,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "my profile",
    show: false,
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/dailydash",
    name: "daily dash",
    icon: "ni ni-chart-pie-35 text-sidebar",
    show: false,
    component: DailyDash,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "calendar",
    show: false,
    icon: "ni ni-calendar-grid-58 text-sidebar",
    component: Calendar,
    layout: "/admin",
  },
  {
    path: '/toolkit',
    name: 'Tools',
    icon: 'fa fa-cogs',
    show: false,
    component: Tools,
    // show: process.env.NODE_ENV === 'development',
    layout: '/admin'
  },
  {
    path: "/quicksight",
    name: "quicksight",
    show: false,
    icon: "ni ni-archive-2 text-sidebar",
    component: QuickSight,
    layout: "/admin",
  },
  {
    path: "/terms",
    name: "terms of use",
    icon: "ni ni-chart-pie-35 text-sidebar",
    component: Terms,
    show: false,
    layout: "/landing",
  },
  {
    path: "/privacy",
    name: "privacy policy",
    icon: "ni ni-chart-pie-35 text-sidebar",
    component: Privacy,
    show: false,
    layout: "/landing",
  },

  {
    path: "/login",
    name: "login",
    show: false,
    miniName: "L",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/contactus",
    name: "contact us",
    show: false,
    miniName: "L",
    component: ContactUs,
    layout: "/auth",
  },
  {
    path: "/success",
    name: "Success",
    show: false,
    component: Success,
    layout: "/auth",
  },
  {
    path: "/onboarding",
    name: "OnBoarding",
    show: false,
    miniName: "L",
    component: OnBoarding,
    layout: "/auth",
  },
  {
    path: "/upgrade",
    name: "Upgrade",
    icon: "ni ni-ungroup text-sidebar",
    component: Upgrade,
    layout: "/auth",
    show: false,
  },
  {
    path: "/success",
    name: "UpgradeSuccess",
    icon: "ni ni-ungroup text-sidebar",
    component: UpgradeSuccess,
    layout: "/auth",
    show: false,
  },
  {
    path: "/failure",
    name: "UpgradeFailure",
    icon: "ni ni-ungroup text-sidebar",
    component: UpgradeFailure,
    layout: "/auth",
    show: false,
  },
  {
    path: "/register",
    name: "Register",
    show: false,
    miniName: "R",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/accept",
    name: "Accept",
    show: false,
    miniName: "A",
    component: Accept,
    layout: "/auth",
  },
  {
    path: "/verification",
    name: "Verification",
    show: false,
    miniName: "R",
    component: Verification,
    layout: "/auth",
  },
  {
    path: "/magic",
    name: "Verification",
    show: false,
    miniName: "R",
    component: ({ location }) => {
      const searchParams = (new URLSearchParams(location.search));
      const [givenEmail] = [searchParams.get('email')];
      const url = decodeURI(window.location.href);
      const givenCode = url.substr(url.indexOf('&code=') + '&code='.length);
      return <Redirect to={{
        pathname: '/auth/verification',
        state: { givenEmail, givenCode },
      }} />
    },
    layout: "/auth",
  },
  // {
  //   path: "/accept",
  //   name: "Accept",
  //   show: false,
  //   miniName: "A",
  //   component: ({ location }) => {
  //     const searchParams = (new URLSearchParams(location.search));
  //     const givenEmail = searchParams.get('email');
  //     return <Redirect to={{
  //       pathname: '/auth/login',
  //       state: { givenEmail },
  //     }} />
  //   },
  //   layout: "/auth",
  // },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    show: false,
    miniName: "P",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/lock",
    name: "Lock",
    show: false,
    miniName: "L",
    component: Lock,
    layout: "/auth",
  },
  {
    path: "/",
    name: "landing",
    icon: "ni ni-shop text-sidebar",
    component: Landing,
    show: false,
    layout: "/landing",
  },
];

export default routes;
