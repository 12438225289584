import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// reactstrap components
import {
  Row, Col,
} from "reactstrap";

import { getPriceViewSpec } from "../charts/vegaPriceViewSpecs";
import VegaChart from "../charts/VegaChart";
import { VIEW_FLAG_BEARISH, VIEW_FLAG_BULLISH } from "helpers/constants";

function PriceView({
  commodity, contract, parameters, markers,
  timeSeries, setTimeSeries, futureSeries,
  viewFlag, currentTradeDate,
  targetObject, setTargetObject,
  lossObject, setLossObject, volatilityData,
}) {
  const { nativeID, title, subpublisher } = commodity;
  const [data, setData] = useState([]);

  const { params: defaultParams } = getPriceViewSpec();
  const [params, setParams] = useState(defaultParams);
  const [spec, setSpec] = useState(getPriceViewSpec());

  useEffect(() => {
    if (volatilityData.length > 0) {
      const {
        trade_date: firstDate,
        native_id: uniqueId,
        contract_tenor: contractTenor,
      } = volatilityData[0];
      const {
        trade_date: lastDate
      } = volatilityData[volatilityData.length - 1];

      setData([
        ...volatilityData,
        {
          "native_id": uniqueId,
          "contract_tenor": contractTenor,
          "trade_date2": firstDate,
        },
        {
          "native_id": uniqueId,
          "contract_tenor": contractTenor,
          "trade_date2": lastDate,
        }
      ]);
    } else {
      setData(volatilityData);
    }
  }, [
    volatilityData, subpublisher, nativeID, contract, setTimeSeries, currentTradeDate,
  ]);

  useEffect(() => {
    // David, please use this code block
    if (!!currentTradeDate && timeSeries.length > 0) {
      const { timeHorizon } = parameters;
      const currentTradeDateValues = [
        { "y": Math.min(...timeSeries.map(({ y }) => y)) - 5, "x": currentTradeDate },
        { "y": Math.max(...timeSeries.map(({ y }) => y)) + 5, "x": currentTradeDate }
      ]
      const [minPoint, maxPoint] = [
        { "y": Math.min(...timeSeries.map(({ y }) => y)) - 5, "x": timeHorizon },
        { "y": Math.max(...timeSeries.map(({ y }) => y)) + 5, "x": timeHorizon }
      ]

      const { y: currentPrice } = timeSeries[timeSeries.length - 1]

      const [minY, maxY] = viewFlag === VIEW_FLAG_BULLISH ? [
        Math.min(...timeSeries.map(({ y }) => y)),
        Math.max(Math.max(...timeSeries.map(({ y }) => y)) * 1.2, currentPrice * 1.3),
      ] : [
        Math.min(Math.min(...timeSeries.map(({ y }) => y)) * 0.7, currentPrice * .7),
        Math.max(...timeSeries.map(({ y }) => y)) * 1.06,
      ]

      const {
        vconcat: [
          {
            layer: [currentDateLayer, timeHorizonLayer, ...otherLayers],
            ...others
          },
          ...otherVconcts
        ],
      } = getPriceViewSpec(minY, maxY);

      setSpec({
        ...getPriceViewSpec(minY, maxY),
        vconcat: [
          {
            layer: [
              {
                ...currentDateLayer,
                data: {
                  values: currentTradeDateValues,
                }
              },
              {
                ...timeHorizonLayer,
                data: {
                  values: [minPoint, maxPoint],
                }
              },
              ...otherLayers,
            ],
            ...others,
          },
          ...otherVconcts,
        ]
      })
    }

  }, [
    currentTradeDate, parameters, timeSeries, viewFlag,
  ])

  useEffect(() => {
    if (!timeSeries.length) {
      return
    }

    const currentSettlement = timeSeries[timeSeries.length - 1].y;
    const upper = {
      value: Math.round(currentSettlement * 1.1),
      min: Math.round(currentSettlement * 1.02),
      max: Math.round(currentSettlement * (viewFlag === VIEW_FLAG_BULLISH ? 1.3 : 1.2)),
    }
    const lower = {
      value: Math.round(currentSettlement * 0.9),
      max: Math.round(currentSettlement * 0.98),
      min: Math.round(currentSettlement * (viewFlag === VIEW_FLAG_BEARISH ? 0.7 : 0.8)),
    }
    if (viewFlag === VIEW_FLAG_BULLISH) {
      setTargetObject(upper);
      setLossObject(lower);
    } else if (viewFlag === VIEW_FLAG_BEARISH) {
      setTargetObject(lower);
      setLossObject(upper);
    }
  }, [
    timeSeries, viewFlag,
    setTargetObject, setLossObject,
  ])

  useEffect(() => {
    const {
      value: targetValue,
    } = targetObject;

    const {
      value: lossValue,
    } = lossObject;

    setParams([
      {
        "name": "take_profit",
        "value": targetValue,
      },
      {
        "name": "stop_loss",
        "value": lossValue,
      }
    ])
  }, [
    targetObject, lossObject,
  ])

  // if (data.length > 0) {
  //   debugger
  // }

  return (
    <div className="sub-page">
      <b>{`${title} Settlement, (20, 50, 100) moving averages Chart`}</b>
      <Row>
        <Col xl="12" style={{ height: 600 }}>
          {data.length > 0 &&
            <VegaChart
              spec={{
                ...spec,
                params: params,
                data: {
                  values: data,
                }
              }}
            />}
        </Col>
      </Row>
    </div>
  );
}

PriceView.propTypes = {
  commodity: PropTypes.shape({
    nativeID: PropTypes.string.isRequired,
    subpublisher: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  parameters: PropTypes.shape({
    timeHorizon: PropTypes.string.isRequired,
  }),
  timeSeries: PropTypes.arrayOf(PropTypes.object).isRequired,
  onParameterChanged: PropTypes.func.isRequired,
  contract: PropTypes.string.isRequired,
  viewFlag: PropTypes.string.isRequired,
  currentTradeDate: PropTypes.string,
  targetObject: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  setTargetObject: PropTypes.func.isRequired,
  lossObject: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }),
  setLossObject: PropTypes.func.isRequired,
  volatilityData: PropTypes.arrayOf(PropTypes.shape({
    native_id: PropTypes.string.isRequired,
  })),
}

export default PriceView;