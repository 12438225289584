import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Row, InputGroupText,
  Col, Button, Form, 
  Card, CardBody,
  FormGroup, InputGroup,
  Modal, ModalBody,
  Input, InputGroupAddon,
} from "reactstrap";

import { fetchProfileRequest } from './actions';
import API from 'helpers/api';

const Upgrade = ({
  fetchingProfile, fetchProfileRequest,
  membership,
}) => {
  const [showcontact, setShowContact] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const { is_expired: isExpired, days_left: daysLeft, is_trial: isTrial } = membership || {};

  useEffect(() => {
    if (!fetchingProfile && isExpired === undefined) {
      fetchProfileRequest()
    }
  }, [
    fetchingProfile, isExpired, fetchProfileRequest
  ]);

  const onUpgrade = () => {
    API.getCheckoutURL().then(({ url }) => {
      window.location.href = url;
    }).catch(err => {
      alert('Something went wrong!')
      console.error(err);
    })
  }

  return (<>
    <div className="authextra code--extra">
      <div className="authextra__wrap">
        <h4>did you know?</h4>
        <p>with ethereal risk, you can create dashboards using data from multiple sources like the one below. save the dashboard to easily access or edit it in the future.</p>
        <br /><br />
        <div>
          <img
            alt="graph"
            src={require("assets/img/theme/graph1.png").default}
          />
        </div>          
      </div>
    </div>
    <Row className="auth__row code--row">
    <Col lg="7">
      <Card className="auth_wrap rect--width">
        <CardBody className="px-lg-5 py-lg-5">
          <h3>activate membership</h3>
          <Row className="mt-3 mb-3">
            <Col xs="12 mt-3">
              <p>we've enjoyed having you here. <strong>{isExpired ? 'Your membership has been expired.' : (isTrial ? `your trial ends in ${daysLeft} days` : 'you are already in premium plan')}</strong> <br />
              want to get more out of ethereal risk? <br />
              active your membership today.
              </p>
            </Col>
          </Row>
          <div className="text-center">
            <Button className="mt-4 btn-info" type="button" onClick={() => setShowContact(!showcontact)}>request a quote</Button>
            <Button className="mt-4 btn-info" disabled={!isTrial && !isExpired} color='info' onClick={onUpgrade}>enter payment info</Button>
          </div>
        </CardBody>
      </Card>
    </Col>
    </Row>

    {/* support and contact modal */}
    <Modal className="credentialModal" toggle={() => setShowContact(!showcontact)} isOpen={showcontact}>
        <ModalBody>
          <h5>we'd love to hear from you.<br />drop us a note below.</h5>
          <p className="support_mail">info@etherealrisk.com</p>
          <Form role="form">
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-settings-gear-65" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="full name"
                  type="text"
                  value={fullName}
                  onChange={(e) => {setFullName(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => {setEmail(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-button-power" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="subject line"
                  type="text"
                  value={subject}
                  onChange={(e) => {setSubject(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative textarea-wrap">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-archive-2" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="message"
                  type="textarea"
                  value={message}
                  onChange={(e) => {setMessage(e.target.value)}}
                />
              </InputGroup>
            </FormGroup>
            
            <div className="text-center support_btn">
              <Button color="info" type="button" onClick={() => setShowContact(!showcontact)}>
                &nbsp;&nbsp;&nbsp;send me!&nbsp;&nbsp;&nbsp;
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
  </>)
}

Upgrade.propTypes = {
  membership: PropTypes.shape({
    is_trial: PropTypes.bool.isRequired,
    is_expired: PropTypes.bool.isRequired,
    days_left: PropTypes.number.isRequired,
  }),
  fetchProfileRequest: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  membership: state.auth.profile.membership,
  fetchingProfile: state.auth.fetchingProfile,
});

const mapDispatchToProps = dispatch => ({
  fetchProfileRequest: () => dispatch(fetchProfileRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
