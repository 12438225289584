import React, { useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Modal, ModalBody
} from "reactstrap";

import RestAPI from "helpers/api";

const ContactUs = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyRole, setCompanyRole] = useState('');
  const [hearText, setHearText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [rolemodalOpen, setRoleModalOpen] = useState(false);
  const [errmodalOpen, setErrModalOpen] = useState(false);

  const history = useHistory();

  const submitForm = async () => {
    const isValid = (
      fullName && companyName &&
      companyRole && hearText
    )

    if (isValid) {
      RestAPI.contactUs(email, fullName, companyName, companyRole, hearText)
        .then(() => { history.push('/') })
        .catch(() => setErrorMessage('Something went wrong.'))
    } else {
      setErrorMessage('0');
    }
  }

  const onSelectRole = (e, txt) => {
    e.preventDefault();
    e.className += ' selected';
    setCompanyRole(txt);
    setRoleModalOpen(!rolemodalOpen)
  }

  return (
    <>
      <Container className="mt-10">
        <Row className="auth__row">
          <Col lg="7" md="7">
            <Card className="onboarding--wrap">
              <CardBody className="px-lg-5">
                <h2 className="border--b pb-0 mt-3 mb-3 w-70">contact us</h2>
                <p>please complete the form below. <br />
                  a member of our team will respond shortly.</p>
                <Form role="form">
                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">what is your name?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-settings-gear-65" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="full name"
                        type="text"
                        value={fullName}
                        onChange={(e) => { setFullName(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">what is your email?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="email address"
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">where do you work?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="company name"
                        type="text"
                        value={companyName}
                        onChange={(e) => { setCompanyName(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">what is your company role?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-user-run" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="company role (e.g. analyst, portfolio manager)"
                        type="text"
                        readOnly={true}
                        onClick={() => setRoleModalOpen(!rolemodalOpen)}
                        value={companyRole}
                        onChange={(e) => { setCompanyRole(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <Row className="mt-3 mb-1">
                    <Col xs="12">
                      <span className="auth--txt">how did you hear about ethereal risk?</span>
                    </Col>
                  </Row>
                  <FormGroup className={"mb-3"}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-archive-2" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="enter text here"
                        type="text"
                        value={hearText}
                        onChange={(e) => { setHearText(e.target.value) }}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="mb-2 text-center">
                    <Button className="btn-info" color="info" type="button" onClick={submitForm}>
                      send email
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {/* Company Role modal */}
        <Modal className="companyModal" toggle={() => setRoleModalOpen(!rolemodalOpen)} isOpen={rolemodalOpen}>
          <ModalBody>
            <div className="wrap">
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'accounting/finance')}><span className="btn-inner--text">accounting/finance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'broker')}><span className="btn-inner--text">broker</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'clearing')}><span className="btn-inner--text">clearing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'consultant')}><span className="btn-inner--text">consultant</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'executive')}><span className="btn-inner--text">executive</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'financial advisor/registered investment advisor')}><span className="btn-inner--text">financial advisor/registered investment advisor</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'investor')}><span className="btn-inner--text">investor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'legal/compliance')}><span className="btn-inner--text">legal/compliance</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'market data')}><span className="btn-inner--text">market data</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'marketing')}><span className="btn-inner--text">marketing</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'operations')}><span className="btn-inner--text">operations</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'other')}><span className="btn-inner--text">other</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'portfolio manager')}><span className="btn-inner--text">portfolio manager</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'professor')}><span className="btn-inner--text">professor</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
              <div className="item-list">
                <ul>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'research analyst/professional')}><span className="btn-inner--text">research analyst/professional</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'risk manager/middle office')}><span className="btn-inner--text">risk manager/middle office</span><span className="btn-inner--icon"><i className="ni ni-briefcase-24"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'sales')}><span className="btn-inner--text">sales</span><span className="btn-inner--icon"><i className="ni ni-book-bookmark"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'student')}><span className="btn-inner--text">student</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'technology')}><span className="btn-inner--text">technology</span><span className="btn-inner--icon"><i className="ni ni-basket"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'trader')}><span className="btn-inner--text">trader</span><span className="btn-inner--icon"><i className="ni ni-books"></i></span></Button>
                  </li>
                  <li>
                    <Button className="btn-select" type="button" onClick={(e) => onSelectRole(e, 'treasurer')}><span className="btn-inner--text">treasurer</span><span className="btn-inner--icon"><i className="ni ni-bag-17"></i></span></Button>
                  </li>
                </ul>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Company Error modal */}
        <Modal className="credentialModal errModal" toggle={() => setErrModalOpen(!errmodalOpen)} isOpen={errmodalOpen}>
          <ModalBody>
            <div className="onboarding--wrap errorMessage_wrap">
              {errorMessage === '0' ? (<>
                <p className="err--msg">looks like we need to know a little more!</p>
                <Button className="btn-info" color="info" type="button" onClick={() => setErrModalOpen(!errmodalOpen)}>back to form</Button>
              </>) : (<>
                <p className="err--msg">thanks we are all set</p>
              </>)}
            </div>
          </ModalBody>
        </Modal>

      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    isComplete: !!state.auth.profile.isComplete,
    submittingProfileError: state.auth.submittingProfileError,
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
