import OptionsExplorerTable from "components/molecules/optimizer/OptionsExplorerTable";
import React, { useEffect, useMemo, useState, useRef, Fragment } from "react";
import { connect } from 'react-redux';
import {
  useHistory, useLocation, useParams
} from "react-router";

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
// import { VegaLite } from 'react-vega';

import API from '../../helpers/api';
import { getRecommenderScenarioSpec } from "components/molecules/charts/vegaChatSpecs/recommenderScenario";
import { setIsLoading } from "./actions";
import VegaChart from "components/molecules/charts/VegaChart";

function OptionsExplorer({
  showLoadingWidget, isComplete,
}) {
  const categories = useMemo(() => (['single', 'spread', 'ratio (1x2)', 'ratio (2x1)']), []);
  const riskTabs = useMemo(() => (['premium', 'pnl', 'delta', 'gamma', 'vega', 'theta']), []);
  const defaultExplorerData = useMemo(() => ({
    cols: [], rows: [], data: []
  }), [])

  const { requestId } = useParams();
  const [category, setCategory] = useState(null);
  const [heatmapBaseData, setHeatmapBaseData] = useState(null);
  const { hash } = useLocation();
  const [explorerData, setExplorerData] = useState(defaultExplorerData);
  const [tableContent, setTableContet] = useState({});
  const [isLoading, setIsLoading] = useState(false)

  const [showStructure, setShowStructure] = useState(false);
  const [arrowPos, setArrowPos] = useState(78);

  const [heatmapData, setHeatmapData] = useState(null);
  const [leftSecurity, setLeftSecurity] = useState('');
  const [rightSecurity, setRightSecurity] = useState('');
  const [securityOptions, setSecurityOptions] = useState([]);

  const availableWeights = [-2, -1, 0, 1, 2];
  const [leftWeight, setLeftWeight] = useState(availableWeights[1]);
  const [rightWeight, setRightWeight] = useState(availableWeights[3]);
  const [left, setLeft] = useState({});
  const [right, setRight] = useState({});
  const [initialValues, setInitialValues] = useState({});

  const [isSingleLeg, setIsSingleLeg] = useState(false);

  const [showWeightModal, setShowWeightModal] = useState(false);
  const [title, setTitle] = useState('');

  const [curRisk, setCurRisk] = useState('premium');  // Tabs for scenario analysis
  const [colorThreashold, setColorThreashold] = useState(6);

  const [mktDataValueDate, setMktDataValueDate] = useState('2023-07-20');
  const [mktDataUndSettleValue, setMktDataUndSettleValue] = useState(0);

  const ref = useRef(null);

  const history = useHistory();

  const isSingleLegChanged = (value: Boolean) => {
    if (!value) {
      if (!rightWeight) {
        setRightWeight(availableWeights[1]);
      }
      if (!rightSecurity & securityOptions.length > 0) {
        setRightSecurity(securityOptions[0]);
      }
    }
    setIsSingleLeg(value)
  }

  const generateHashSegment = (security: String, weight: Number) => {
    const [mode, value] = security.split('|');
    return `${weight}_${value}_${mode}`;
  }
  const selectElements = () => {
    const elements = [category];
    elements.push(generateHashSegment(leftSecurity, leftWeight));

    if (!isSingleLeg) {
      elements.push(generateHashSegment(rightSecurity, rightWeight));
    }
    setShowWeightModal(false);
    history.push(`/admin/explorer/${requestId}#${elements.join('__')}`);
  }

  const getSubTitle = () => {
    if (!title.length) {
      return 'loading...'
    }
    // {leftWeight}x $85 call versus - 1 $95 call
    const segs = [`${title.toLowerCase()} `]
    const modeMap = { 'c': 'call', 'p': 'put' }

    if (!leftSecurity) {
      return ''
    }
    const [leftMode, leftValue] = leftSecurity.split('|');

    segs.push(`${leftWeight}x $${leftValue} ${modeMap[leftMode]}`)

    if (!isSingleLeg) {
      const [rightMode, rightValue] = rightSecurity.split('|');
      segs.push('versus')
      segs.push(`${rightWeight}x $${rightValue} ${modeMap[rightMode]}`)
    }

    return segs.join(' ')
  }

  const getInitialValue = (identifier: String = 'premium') => {
    if (Object.keys(initialValues).length === 0) {
      return ''
    }

    if (!initialValues[leftSecurity]) {
      return ''
    }

    const validIds = ['premium', 'gamma', 'delta', 'theta', 'vega'];

    if (validIds.indexOf(identifier) < 0) {
      return '0.0'
    }

    let initialValue = initialValues[leftSecurity][identifier] * leftWeight;

    if (!isSingleLeg && !!rightSecurity && !!initialValues[rightSecurity]) {
      initialValue += initialValues[rightSecurity][identifier] * rightWeight;
    }

    let returnText = initialValue.toFixed(2);
    if (identifier === 'delta')
      returnText = returnText * 100 + '%'
    else if (identifier === 'gamma')
      returnText = returnText * 100 + '% per usd'
    else if (identifier === 'vega')
      returnText = returnText + ' usd per % change'
    else if (identifier === 'theta')
      returnText = returnText + ' usd per day'

    return returnText;
  }

  useEffect(() => {
    if (isComplete !== undefined && !isComplete) {
      history.push('/auth/onboarding')
    }
  }, [isComplete, history])

  useEffect(() => {
    Promise.all(categories.map((item) => {
      let catItem = item;
      if (catItem === 'ratio (1x2)')
        catItem = 'ratio'
      else if (catItem === 'ratio (2x1)')
        catItem = 'ratio2'
      return API.fetchOptionsExplorer(requestId, catItem)
        .then(({ cols, rows, data }) => {
          return [item, { cols, rows, data }]
        })
        .catch((err) => {
          console.error(err);
          return [item, { cols: [], rows: [], data: [] }]
        })
    })).then((responses) => {
      setTableContet(
        responses.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
      )
    })
  }, [
    requestId, categories,
  ]);

  useEffect(() => {
    if (!!category) {
      switch (category) {
        case 'single':
          setLeftWeight(1);
          setRightWeight(null);
          break;

        case 'spread':
          setLeftWeight(1);
          setRightWeight(-1);
          break;

        case 'ratio (1x2)':
          setLeftWeight(1);
          setRightWeight(-2);
          break;

        case 'ratio (2x1)':
          setLeftWeight(2);
          setRightWeight(-1);
          break;

        default:
          break;
      }
      setExplorerData(tableContent[category] || defaultExplorerData)
    }
  }, [
    category, tableContent, defaultExplorerData,
  ])

  useEffect(() => {
    setIsLoading(true);
    API.fetchOptionsRecommenderScenario(requestId)
      .then(({ data, native_id: nativeId, contract }) => {
        setTitle(`${contract} ${nativeId}`)
        setHeatmapBaseData(data);
      })
      .catch((err) => { console.error(err) })
      .finally(() => {
        setIsLoading(false);
      })
  }, [
    requestId,
  ]);

  useEffect(() => {
    showLoadingWidget(isLoading);
  }, [
    isLoading, showLoadingWidget
  ])

  useEffect(() => {
    if (hash.length === 0) {
      setCategory(categories[1]);
      // setShowWeightModal(true);
      return
    }

    const [_category, left, right] = hash.substring(1).split('__');

    if (categories.indexOf(_category.toLowerCase()) > -1) {
      setCategory(_category.toLowerCase());
    }

    if (!left) {
      // setShowWeightModal(true);
      return
    }

    const _extract = (identifier: String): Object => {
      const [weight, value, mode] = identifier.replace('/', '').split('_');
      return {
        value, mode,
        weight: parseInt(weight),
      }
    }

    if (!!left) {
      setLeft(_extract(left));
    }

    if (!!right) {
      setRight(_extract(right));
      setIsSingleLeg(false);
    } else {
      setIsSingleLeg(true);
    }

  }, [
    requestId, hash, categories,
  ]);

  useEffect(() => {
    if (!heatmapBaseData) {
      return
    }

    setSecurityOptions(Array.from(new Set(heatmapBaseData.map(({ security }) => (security)))));
    setHeatmapData(heatmapBaseData);

    const filtered = heatmapBaseData.filter((item) => (
      parseFloat(item['f-scen']) === 0 && parseFloat(item['sigma-scen']) === 0
    ));

    const [{ firstDate, undSettle }] = new Set(filtered.map(({
      value_date: firstDate,
      und_settle: undSettle
    }) => ({ firstDate, undSettle })));
    const firstDateValues = filtered.filter(({ value_date }) => (value_date === firstDate)).reduce(
      (acc, { PV, security, delta, gamma, vega, theta }) => {
        acc[security] = { premium: PV, delta, gamma, vega, theta }
        return acc;
      }, {});
    setInitialValues(firstDateValues);
    setMktDataValueDate(firstDate);
    setMktDataUndSettleValue(undSettle);
  }, [
    heatmapBaseData,
  ]);

  useEffect(() => {
    if (!leftSecurity) {
      setLeftSecurity(securityOptions[0]);
    }
  }, [
    leftSecurity,
    securityOptions
  ])

  useEffect(() => {
    const { weight, value, mode } = left;

    if (!value || !mode) {
      return
    }

    setLeftWeight(weight);
    setLeftSecurity(`${mode}|${value}`)
  }, [
    left,
  ]);

  useEffect(() => {
    const { weight, value, mode } = right;

    if (!weight || !mode) {
      return
    }

    setRightWeight(weight);
    setRightSecurity(`${mode}|${value}`)
  }, [
    right,
  ]);

  useEffect(() => {
    if (!leftSecurity || !heatmapBaseData) {
      return
    }

    const dictionary = {
      [leftSecurity]: leftWeight
    }

    if (!isSingleLeg) {
      if (rightSecurity in dictionary) {
        dictionary[rightSecurity] += rightWeight
      } else {
        dictionary[rightSecurity] = rightWeight
      }
    }

    if (Object.keys(initialValues).length > 0) {
      setHeatmapData(heatmapBaseData.filter(({ security }) => (security in dictionary)).map((item) => {
        const { security, PV, delta, gamma, theta, vega } = item
        const struct_pv = PV * dictionary[security];
        const struct_delta = delta * dictionary[security];
        const struct_gamma = gamma * dictionary[security];
        const struct_theta = theta * dictionary[security];
        const struct_vega = vega * dictionary[security];
        const struct_pnl = (PV - (initialValues[security] || {})['premium']) * dictionary[security];

        return {
          ...item,
          struct_pv,
          struct_delta,
          struct_gamma,
          struct_theta,
          struct_vega,
          struct_pnl,
        }
      }));
    }
  }, [
    heatmapBaseData,
    leftWeight, leftSecurity,
    rightWeight, rightSecurity,
    isSingleLeg,
    initialValues,
  ]);

  useEffect(() => {
    if (heatmapData && heatmapData.length > 0 && 'struct_pv' in heatmapData[0]) {
      const valueKey = curRisk === 'premium' ? 'struct_pv' : `struct_${curRisk}`
      const buffer = {}

      for (let index = 0; index < heatmapData.length; index++) {
        const {
          value_date, und_settle,
          'sigma-scen': sigma,
          [valueKey]: value,
        } = heatmapData[index];

        const key = `${sigma}_${und_settle}_${value_date}`;

        if (key in buffer) {
          buffer[key] += value
        } else {
          buffer[key] = value
        }
      }
      const minStructPV = Math.min(...Object.values(buffer));
      const maxStructPV = Math.max(...Object.values(buffer));
      setColorThreashold(minStructPV + (maxStructPV - minStructPV) * 0.25);
    }
  }, [
    heatmapData, curRisk,
  ])

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowStructure(false);
      }
      if (ref.current && event.target.href) {
        setShowStructure(false);
      }
    }
    document.addEventListener('click', handleOutsideClick, true)
    return () => {
      document.removeEventListener('click', handleOutsideClick, true)
    }
  }, [])

  return (
    <section className="optimizer">
      <h1 className="mb-0">{'options score explorer'}</h1>
      {!showWeightModal && (<div className="pdfLinkWrap">
        <div className="pdfLinkTabs">
          {categories.map((item, idx) => (
            <Button
              className="order-btn" color={item === category ? "select" : ""}
              key={idx} onClick={() => {
                setCategory(item);
                // history.push(`/admin/explorer/${requestId}#${getCategoryButtonHash(item)}`)
                setArrowPos(idx * 140 + 78);
                setShowStructure(true);
              }}
            >
              {item}
            </Button>
          ))}
          {showStructure && (
            <div className="pdfLinkStructure" ref={ref}>
              <span className="arrow" style={{ left: arrowPos }}>&nbsp;</span>
              {/* <span className="close" onClick={() => setShowStructure(false)}>×</span> */}
              <p>
                {category !== 'single' && (
                  <span><b>The rows show the long ({leftWeight}) leg, the columns show the short ({rightWeight}) leg of the spread.</b></span>
                )}
                <br />
                click any link below to show the scenario analysis for that structure.
                click out of the explorer window at any time.
              </p>
              <OptionsExplorerTable
                category={category}
                leftWeight={leftWeight}
                rightWeight={rightWeight}
                tableData={explorerData} />
            </div>
          )}
        </div>
        <div className="pdfLinkHeatMap">
          {/* <div>
              <select value={leftSecurity} onChange={(event) => setLeftSecurity(event.target.value)}>
                {securityOptions.map((security, idx) => (
                  <option key={idx} value={security}>{security}</option>))}
              </select>
              <select value={leftWeight} onChange={(event) => setLeftWeight(parseInt(event.target.value))}>
                {availableWeights.map((weight, idx) => (
                  <option key={idx} value={weight}>{weight}</option>))}
              </select>
              <select value={rightSecurity} onChange={(event) => setRightSecurity(event.target.value)}>
                <option>none</option>
                {securityOptions.map((security, idx) => (
                  <option key={idx} value={security}>{security}</option>))}
              </select>
              <select value={rightWeight} onChange={(event) => setRightWeight(parseInt(event.target.value))}>
                {availableWeights.map((weight, idx) => (
                  <option key={idx} value={weight}>{weight}</option>))}
              </select>
            </div> */}
          <div className="flex">
            <div className="col-md-2 mt-6">
              <div className="box-wrap">
                navigate via the option score explorer in the tabs above <br />
                <span className="arrow-up-triangle"></span>
              </div>
              < br />
              <p className="text-center">or</p>
              <div className="box-wrap text-left">
                build your preferred options structure<br />
                <div className="select-wrap">
                  <p>first leg</p>
                  <div className="section-no">
                    <span>1</span>
                  </div>
                  <div className="sub-box-wrap">
                    <label className="left">weight</label>
                    <select
                      value={leftWeight}
                      className={'size-min right'}
                      onChange={(e) => setLeftWeight(parseInt(e.target.value))}
                    >
                      {availableWeights.map((weight, idx) => (
                        <option key={idx} value={weight}>{weight}</option>))}
                    </select>
                    <div className="clear"></div>
                  </div>
                  <div className="sub-box-wrap">
                    <label className="left">security</label>
                    <select
                      value={leftSecurity}
                      className={'size-min right'}
                      onChange={(e) => setLeftSecurity(e.target.value)}
                    >
                      {securityOptions.map((security, idx) => (
                        <option key={idx} value={security}>{security}</option>))}
                    </select>
                    <div className="clear"></div>
                  </div>
                </div>

                <div className="custom-control custom-control-alternative custom-checkbox mt-3">
                  <input
                    className="custom-control-input"
                    id="customCheckSingle"
                    type="checkbox"
                    checked={isSingleLeg}
                    onChange={(e) => isSingleLegChanged(e.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheckSingle"
                  >
                    <span className="text-muted">single leg only &nbsp;</span>
                  </label>
                </div>

                {!isSingleLeg && (
                  <div className="select-wrap">
                    <p>second leg</p>
                    <div className="section-no">
                      <span>2</span>
                    </div>
                    <div className="sub-box-wrap">
                      <label className="left">weight</label>
                      <select
                        value={rightWeight}
                        className={'size-min right'}
                        onChange={(e) => setRightWeight(parseInt(e.target.value))}
                      >
                        {availableWeights.map((weight, idx) => (
                          <option key={idx} value={weight}>{weight}</option>))}
                      </select>
                      <div className="clear"></div>
                    </div>
                    <div className="sub-box-wrap">
                      <label className="left">security</label>
                      <select
                        value={rightSecurity}
                        className={'size-min right'}
                        onChange={(e) => setRightSecurity(e.target.value)}
                      >
                        {securityOptions.map((security, idx) => (
                          <option key={idx} value={security}>{security}</option>))}
                      </select>
                      <div className="clear"></div>
                    </div>
                  </div>)}
              </div>
            </div>
            <div className="col-md-10 align-left pl-4">
              <div className="flex">
                {/* <div className="col-md-2"></div> */}
                <div className="col-md-6">
                  <h2><strong>scenario analysis:</strong>
                    <br />
                    {getSubTitle()}</h2>
                </div>
                <div className="col-md-6 border-left">
                  <div className="row pt-2">
                    <div className="col-md-5">
                      <h3><strong>starting premium:</strong>
                        <br />
                        {getInitialValue('premium')} usd</h3>
                    </div>
                    <div className="col-md-7">
                      <div className="sub-params">
                        <strong>delta: </strong>{getInitialValue('delta')} <br />
                        <strong> gamma: </strong>{getInitialValue('gamma')} <br />
                        <strong> theta: </strong>{getInitialValue('theta')} <br />
                        <strong>vega: </strong>{getInitialValue('vega')}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <hr />
              <div className="risk-tabs">
                {riskTabs.map((item, idx) => (<Fragment key={idx}>
                  <Button
                    className={curRisk === item ? 'tab-btn active' : 'tab-btn'}
                    onClick={() => {
                      setCurRisk(item)
                      // need to add updating heatmap action here
                    }}
                  >
                    {item}
                  </Button> {idx < riskTabs.length - 1 ? '|' : ''}
                </Fragment>))}
              </div>
              {!!heatmapData && <VegaChart
                spec={{
                  ...getRecommenderScenarioSpec(curRisk, colorThreashold, mktDataValueDate, mktDataUndSettleValue),
                  width: 'container',
                  autosize: {
                    type: 'fit',
                    contains: 'padding',
                  },
                  data: {
                    values: heatmapData,
                  }
                }}
              />}
            </div>
          </div>
        </div>
      </div>)}
      <Modal className="companyModal credentialModal" toggle={() => { setShowWeightModal(!showWeightModal) }} isOpen={showWeightModal}>
        <ModalHeader>
          {'please choose at least one option and a weight'}
        </ModalHeader>
        <ModalBody>
          <select value={leftSecurity} onChange={(event) => setLeftSecurity(event.target.value)}>
            {securityOptions.map((security, idx) => (
              <option key={idx} value={security}>{security}</option>))}
          </select>
          <select value={leftWeight} onChange={(event) => setLeftWeight(parseInt(event.target.value))}>
            {availableWeights.map((weight, idx) => (
              <option key={idx} value={weight}>{weight}</option>))}
          </select>
          <select value={rightSecurity} onChange={(event) => setRightSecurity(event.target.value)}>
            <option>none</option>
            {securityOptions.map((security, idx) => (
              <option key={idx} value={security}>{security}</option>))}
          </select>
          <select value={rightWeight} onChange={(event) => setRightWeight(parseInt(event.target.value))}>
            {availableWeights.map((weight, idx) => (
              <option key={idx} value={weight}>{weight}</option>))}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button className="btn-info" color="success" type="button" onClick={selectElements}>{'confirm'}</Button>
        </ModalFooter>
      </Modal>
    </section>
  )
}

const mapStateToProps = state => {
  return {
    isComplete: state.auth.profile.isComplete,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showLoadingWidget: isLoading => dispatch(setIsLoading(isLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsExplorer);
