import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import Amplify from 'aws-amplify';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import store from './redux';
import App from './App';


Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.AWS_DEFAULT_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    // identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  API: {
    endpoints: [
      {
        name: "ethereal",
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.AWS_DEFAULT_REGION
      },
    ]
  }
});

if (process.env.REACT_APP_SENTRY_CONFIG_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CONFIG_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.REACT_APP_STAGE || 'local',
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
