import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { connect } from 'react-redux';

import { useAppContext } from 'libs/contextLib';
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import LoadingModal from 'components/modals/LoadingModal';

import { Auth } from 'aws-amplify';

import { fetchProfileRequest } from 'containers/auth/actions';
import {
  NavItem, NavLink, Nav,
  Modal, ModalBody, Button,
} from "reactstrap";

import routes from "routes.js";

function AdminLayout({
  fetchProfileRequest,
  isLoading,
  membership,
}) {
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [showlogout, setShowLogout] = useState(false);
  const location = useLocation();
  const mainContentRef = useRef(null);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const history = useHistory()
  const { is_expired: isExpired } = membership;
  const { hash, search } = useLocation();

  if (!isAuthenticated) {
    if (location.pathname !== '/auth/login') {
      history.push(`/auth/login?redirect=${location.pathname}${search}${hash}`);
    }
  }

  useEffect(() => {
    if (!isLoading && isExpired === true) {
      // history.push('/auth/upgrade');
    }
  }, [isLoading, isExpired, history])

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    fetchProfileRequest()
  }, [fetchProfileRequest, location])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const confirmLogout = async () => {
    Auth.signOut().then(() => {
      userHasAuthenticated(false);
      history.push('/auth/login');
    });
  }

  // const getBrandText = (path) => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Brand";
  // };

  const submitLogout = async () => {
    setShowLogout(!showlogout);
  }

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  // const getNavbarTheme = () => {
  //   return location.pathname.indexOf("admin/alternative-dashboard") === -1
  //     ? "dark"
  //     : "light";
  // };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: require("assets/img/brand/logo_white.png").default,
          imgAlt: "...",
        }}
      />
      {/* <AdminNavbar
        theme={getNavbarTheme()}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        brandText={getBrandText(location.pathname)}
      /> */}
      <div className="main-content" ref={mainContentRef}>
        <Nav className="ml-auto signout-nav" navbar>
          <NavItem>
            <NavLink to='#' tag={Link} onClick={submitLogout}>
              <span className="nav-link-inner--text">sign out</span>
            </NavLink>
          </NavItem>
        </Nav>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/admin/*" to="/admin/home" />
        </Switch>
      </div>
      <AuthFooter />
      <LoadingModal isOpen={isLoading} />

      {/* logout modal */}
      <Modal className="credentialModal" toggle={() => setShowLogout(!showlogout)} isOpen={showlogout}>
        <ModalBody>
          <h5>are you sure you want to log out?</h5>
          <div className="logout-wrap">
            <Button color="warning" type="button" onClick={confirmLogout}>
              yes, please!
            </Button>
            <Button color="warning" type="button" onClick={() => setShowLogout(!showlogout)}>
              no, not yet!
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  isLoading: (
    state.admin.isLoading || state.datasets.fetchingDatasets ||
    state.datasets.fetchingDatasets || state.datasets.retrievingDataset || state.datasets.updatingDataset ||
    state.datasets.addingItemToDataset || state.admin.checkingOutCart
  ),
  membership: state.auth.profile.membership || {},
})

const mapDispatchToProps = dispatch => {
  return {
    fetchProfileRequest: () => dispatch(fetchProfileRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
