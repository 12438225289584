import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { 
  memberModes, contractColors, months, stripTypes, 
  stripYears, stripQuarters, stripHalfYears, MEMBER_MODE_FIXED, MONTH_MAPPINGS,
  MEMBER_MODE_RELATIVE, MEMBER_MODE_COLOR, MEMBER_MODE_STRIP, MEMBER_SUB_MODE_STRIPT_YEAR,
  MEMBER_SUB_MODE_STRIPT_HALF_YEAR, MEMBER_SUB_MODE_STRIPT_QUARTER, MONTH_NUMBER_MAPPINGS, cotActorMeasureMappings,
} from 'helpers/constants';
import MarketFamilyMember from './MarketFamilyMember';
import MarketFamilyCotMember from './MarketFamilyCotMember';

const MarketFamily = ({
  type, family,
  members, setDatasetMember, removeDatasetMember,
  queryType, selIndice, copyToIndexBtnClick
}) => {
  const {
    product_name: productName,
    raw_native_id: nativeId,
    product_id: productId,
    publisher: exchangeGroup,
    options,
    details: {
      units: { conversion_list: conversions = [] } = { conversion_list: []},
      column_name: {
        alt_name: alternativeName,
        is_native_id_column_name: isNativeIdAsColumnName
      } = {
        is_native_id_column_name: false,
      }
    } = {
      units: { conversion_list: []},
      column_name: {
        is_native_id_column_name: false,
      }
    },
  } = family;

  const [defaultUnit] = Object.entries(conversions).filter(([caption, unit]) => `${unit}` === '1').map(
    ([caption, unit]) => ({unit, caption}))

  const membersCount = members.length;
  const [defaultMemberMode] = memberModes;
  const [defaultColor] = contractColors;
  const [defaultMonth] = months;
  const [defaultStripType] = stripTypes;
  const [defaultStripYear] = stripYears;
  const [defaultStripQuarter] = stripQuarters;
  const [defaultStripHalfYear] = stripHalfYears;

  const getMemberColumnName = useCallback((member) => {
    // debugger;
    const {
      mode,
      contract_line_num: contractLineNumber,
      tenor,
      // attribute,
      color,
      month,
      sub_mode: subMode,
      strip_year: stripYear,
      strip_half_year: stripHalfYear,
      strip_quarter: stripQuarter,
      unit_caption: originalUnit = '',
    } = member;
    
    const unit = originalUnit.split(' ').join('_');

    const namePrefix = isNativeIdAsColumnName ? nativeId : alternativeName;

    switch (mode) {
      case MEMBER_MODE_FIXED:
        const regEx1 = /(\d{2})(\w{3})(\d{2})/i,
              regEx2 = /(\d{4})-(\d{2})-(\d{2})/i

        let match;
        if (tenor.match(regEx1)) {
          match = tenor.match(regEx1);
          return `${namePrefix}_${MONTH_MAPPINGS[match[2].toLowerCase()]}${match[3]}_${match[1]}_${unit}`;
        } else if (tenor.match(regEx2)) {
          match = tenor.match(regEx2);
          return `${namePrefix}_${Object.values(MONTH_MAPPINGS)[parseInt(match[1])]}${match[2].slice(2)}_${match[3]}_${unit}`;
        } else {
          console.error(`Unknown case found - ${tenor}`);
          return `${namePrefix}_${tenor}_${unit}`;
        }

      case MEMBER_MODE_RELATIVE:
        return `${namePrefix}_${contractLineNumber}_${unit}`;

      case MEMBER_MODE_COLOR:
        return `${namePrefix}_${color}_${MONTH_NUMBER_MAPPINGS[month]}_${unit}`;

      case MEMBER_MODE_STRIP:
        switch (subMode) {
          case MEMBER_SUB_MODE_STRIPT_YEAR:
            return `${namePrefix}_${stripYear}_${unit}_cal`;

          case MEMBER_SUB_MODE_STRIPT_HALF_YEAR:
            return `${namePrefix}_${stripYear}_hy${stripHalfYear}_${unit}_cal`;

          case MEMBER_SUB_MODE_STRIPT_QUARTER:
            return `${namePrefix}_${stripYear}_q${stripQuarter}_${unit}_cal`;

          default:
            return 'unknown'
        }

      default:
        return 'unknown'
    }
  }, [
    nativeId, alternativeName, isNativeIdAsColumnName,
  ]);

  const onAddNewMember = useCallback(() => {
    let defaultMember = {
      id: uuidv4(),
    }

    if (exchangeGroup === 'cftc') {
      const {
        actor: actorOptions, // measure: measureOptions
      } = options
      defaultMember = {
        ...defaultMember,
        actor: actorOptions[0],
        measure: cotActorMeasureMappings[actorOptions[0]][0],
      }
    } else {
      const {
        tenor_dates
      } = options;
      defaultMember = {
        ...defaultMember,
        mode: defaultMemberMode.value,
        contract_line_num: 1,
        tenor: tenor_dates[0],
        attribute: 'settlement',
        color: defaultColor,
        month: defaultMonth.value,
        sub_mode: defaultStripType.value,
        strip_year: defaultStripYear,
        strip_half_year: defaultStripHalfYear,
        strip_quarter: defaultStripQuarter,
        unit: defaultUnit.unit,
        unit_caption: defaultUnit.caption,
      }
    }
    setDatasetMember(productId, membersCount, {
      ...defaultMember,
      column_name_alias: getMemberColumnName(defaultMember)
    })
  }, [
    defaultColor, defaultMemberMode, defaultMonth, defaultStripQuarter,
    defaultStripYear, exchangeGroup, membersCount, options, productId,
    setDatasetMember, defaultStripType, defaultStripHalfYear,
    getMemberColumnName, defaultUnit,
  ]);

  useEffect(() => {
    if (members.length === 0) {
      onAddNewMember()
    }
  }, [members, onAddNewMember]);

  const onRemoveMember = (index: number) => {
    removeDatasetMember(productId, index)
  }

  const onClickCopyToIndex = (member) => {
    const { column_name_alias: alias } = member;
    copyToIndexBtnClick(alias);
  }

  return (
    <>
      <div className="family-item">
        <p title={productName}>
          <strong>{isNativeIdAsColumnName ? nativeId: alternativeName}</strong>: {productName}</p>

          {members.map((member, idx) => {
            if (exchangeGroup === 'cftc') {
              return (
                <MarketFamilyCotMember
                  key={idx} idx={idx} productId={productId} member={member}
                  setDatasetMember={setDatasetMember}
                  isLast={queryType === 'time_series' && idx === members.length - 1}
                  options={options}
                  onAddNewMember={onAddNewMember}
                  onClickCopyToIndex={onClickCopyToIndex}
                  selIndice={selIndice}
                  getMemberColumnName={getMemberColumnName}
                  onRemoveMember={() => onRemoveMember(idx)}
                  />)
            } else {
              return (
                <MarketFamilyMember
                  key={idx}
                  idx={idx}
                  productId={productId}
                  member={member}
                  setDatasetMember={setDatasetMember}
                  options={options}
                  isLast={queryType === 'time_series' && idx === members.length - 1}
                  getMemberColumnName={getMemberColumnName}
                  onAddNewMember={onAddNewMember}
                  onClickCopyToIndex={onClickCopyToIndex}
                  selIndice={selIndice}
                  onRemoveMember={() => onRemoveMember(idx)}
                  units={Object.entries(conversions || {}).map(([name, value]) => ({name, value}))}
                  />)
            }
          })}
      </div>
    </>
  )
}

MarketFamily.propTypes = {
  family: PropTypes.shape({
    product_name: PropTypes.string.isRequired,
    product_id: PropTypes.string.isRequired,
    native_id: PropTypes.string.isRequired,
    publisher: PropTypes.string.isRequired,
    subpublisher: PropTypes.string.isRequired,
  }).isRequired,
  option: PropTypes.shape({
    tenor: PropTypes.arrayOf(PropTypes.string),
    max_contract_line_num: PropTypes.number.isRequired,
  }),
  idx: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDatasetMember: PropTypes.func.isRequired,
  queryType: PropTypes.string,
}

MarketFamily.defaultProps = {
  queryType: 'time_series',
}

export default MarketFamily;
