import React from 'react';

import PropTypes from 'prop-types';

import {
  Modal,
  ModalBody,
} from "reactstrap";

const LoadingModal = ({
  isOpen,
}) => {
  return (
    <Modal className="loading-modal" toggle={() => {}} isOpen={isOpen}>
      <ModalBody>
          <img
              alt="..."
              src={require("assets/img/brand/logo_loading.png").default}
            />
      </ModalBody>
    </Modal>
  )
}

LoadingModal.propTypes = {
  isOpen: PropTypes.bool,
}

export default LoadingModal;
