import React, { useState, useEffect, useCallback } from "react";

import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  FormGroup,
  Modal,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ModalBody,
  Row, Col,
} from "reactstrap";

import API from '../../../helpers/api';
import MemberPublisher from "./MemberPublisher";
import MemberInvitedItem from "./MemberInvitedItem";
import { setIsLoading, setUsers, setError } from '../../../containers/admin/actions';
import { QuickSightRoles, userRoles } from "helpers/constants";

const Members = ({
  isLoading,
  onLoadingUpdated,
  users,
  onUserStateUpdated,
  onUsersUpdated,
  onErrorUpdated,
}) => {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(userRoles[0]);
  // const [authorConfirmed, setAuthorConfirmed] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [alreadyExists, setAlreadyExists] = useState(false);

  const activeMembers = users.filter(user => user.status === 'active').sort((a, b) => {
    return userRoles.indexOf(a.role) - userRoles.indexOf(b.role)
  });
  const invitedMembers = users.filter(user => user.status === 'invited').sort((a, b) => {
    return userRoles.indexOf(a.role) - userRoles.indexOf(b.role)
  });;
  const disabledMembers = users.filter(user => user.status === 'disabled').sort((a, b) => {
    return userRoles.indexOf(a.role) - userRoles.indexOf(b.role)
  });;

  const isValidInviteForm = () => {
    // if (!authorConfirmed) {
    //   return false;
    // }

    return (email.length > 0 && userRoles.slice(0, 2).indexOf(role) >= 0)
  }

  const submitInviteUser = (existing = false) => {
    onLoadingUpdated(true);
    API.inviteUser(email, role, QuickSightRoles[0], '', existing).then(res => {
      const { status, msg, users, code } = res;
      if (status) {
        onUsersUpdated(users);
        setAlreadyExists(false);
      } else {
        if (code === 'already_exists') {
          setAlreadyExists(true);
        } else {
          alert(msg);
        }
      }
    }).catch(err => {
      onErrorUpdated(err)
    }).finally(() => {
      setShowConfirmModal(false);
      onLoadingUpdated(false);
    })
  }

  const reinviteUser = useCallback((userId) => {
    onLoadingUpdated(true);
    return API.reinviteCompanyUserPassword(userId).then(res => {
      const { status, msg } = res;

      if (!status) {
        alert(msg);
      }
    }).finally(() => {
      onLoadingUpdated(false);
    })
  }, [onLoadingUpdated])

  const onUserSubscriptionUpdated = useCallback((userId, groupName, toggle) => {
    const promise = toggle
      ? API.disableUserSubscription(userId, groupName)
      : API.enableUserSubscription(userId, groupName)

    onLoadingUpdated(true);
    return promise.then(res => {
      const { status, msg, users } = res;
      if (status) {
        onUsersUpdated(users);
      } else {
        alert(msg ? msg : 'Something went wrong');
      }
    }).catch(err => {
      onErrorUpdated(err);
    }).finally(() => {
      onLoadingUpdated(false);
    });
  }, [onLoadingUpdated, onErrorUpdated, onUsersUpdated])

  const onUserPasswordReset = useCallback((userId) => {
    onLoadingUpdated(true);
    return API.resetCompanyUserPassword(userId).then(res => {
      const { status } = res;
      if (!status) {
        alert('something went wrong');
      }
    }).catch(err => {
      onErrorUpdated(err)
    }).finally(() => {
      onLoadingUpdated(false);
    })
  }, [onLoadingUpdated, onErrorUpdated])

  onUserStateUpdated = useCallback((userId, toggle) => {
    const promise = toggle
      ? API.enableCompanyUser(userId)
      : API.disableCompanyUser(userId);

    onLoadingUpdated(true);
    return promise.then(res => {
      const { status, users } = res;
      if (status) {
        onUsersUpdated(users);
      } else {
        alert('something went wrong');
      }
    }).catch(err => {
      onErrorUpdated(err);
    }).finally(() => {
      onLoadingUpdated(false);
    })
  }, [onLoadingUpdated, onUsersUpdated, onErrorUpdated]);

  const onUserRoleUpdated = useCallback((userId: String, role: String, qsRole: String) => {
    onLoadingUpdated(true);
    return API.updateCompanyUserRole(userId, role, qsRole,).then(res => {
      const { status, users, msg } = res;
      if (status) {
        onUsersUpdated(users);
      } else {
        alert(msg);
      }
    }).catch(err => {
      onErrorUpdated(err);
    }).finally(() => {
      onLoadingUpdated(false);
    })
  }, [onUsersUpdated, onLoadingUpdated, onErrorUpdated])

  useEffect(() => {
    onLoadingUpdated(true);
    API.getCompanyUsers().then(res => {
      const { users } = res;
      onUsersUpdated(users);
      onLoadingUpdated(false);
    }).catch(err => {
      onLoadingUpdated(false);
      onErrorUpdated(err);
    })
  }, [
    onLoadingUpdated, onUsersUpdated, onErrorUpdated])

  return (<>
    <Button className="add--member" onClick={() => { setShowConfirmModal(true); }}>add member</Button>

    <h3>active members</h3>
    {activeMembers.length > 0 && activeMembers.map((member, idx) => (
      <MemberPublisher
        key={idx}
        member={member}
        roles={userRoles.slice(0, 2)}
        onUserStateUpdated={onUserStateUpdated}
        onUserPasswordReset={onUserPasswordReset}
        onUserRoleUpdated={onUserRoleUpdated}
        onUserSubscriptionUpdated={onUserSubscriptionUpdated} />
    ))}

    {invitedMembers.length > 0 && (
      <>
        <h3>invited members</h3>

        {invitedMembers.map((member, idx) => (
          <MemberInvitedItem
            key={idx}
            member={member}
            onUserStateUpdated={onUserStateUpdated}
            onUserReinvited={reinviteUser}
          />
        ))}
      </>
    )}

    {disabledMembers.length > 0 && (
      <>
        <h3>disabled members</h3>

        {disabledMembers.map((member, idx) => (
          <MemberPublisher
            key={idx}
            member={member}
            onUserStateUpdated={onUserStateUpdated}
          />
        ))}
      </>
    )}

    <Modal className="credentialModal errModal" toggle={() => { setAlreadyExists(false) }} isOpen={alreadyExists}>
      <ModalBody>
        <div className="errorMessage_wrap">
          <p>
            we found this user already in our system.<br />
            do you want to add the user to your team?
          </p>

          <Button
            className="btn-info" color="info" type="button"
            onClick={() => submitInviteUser(true)}
          >invite</Button>
        </div>
      </ModalBody>
    </Modal>

    {/* Add Member modal */}
    <Modal className="credentialModal errModal" toggle={() => setShowConfirmModal(!showConfirmModal)} isOpen={showConfirmModal}>
      <ModalBody>
        <div className="errorMessage_wrap">
          <h2>invite new member</h2>
          <p>we just need a few details first! <br /> then the user will receive an invitation to sign up.</p>

          <center>
            <FormGroup
              className={"mb-3"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="email"
                  type="email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value) }}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col>
                  <InputGroup className="input-group-merge input-group-alternative user--role--select">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-user-run" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <Input
                      type="select" name="role" id="roleSelect"
                      onChange={e => setRole(e.target.value)}
                    >
                      {userRoles.slice(0, 2).map((item, idx) => <option key={idx} value={item}>{item}</option>)}
                    </Input>
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            {/* <FormGroup>
              <Row>
                <Col>
                  <label>
                    <Input
                      type="checkbox" name="confirm-author"
                      onChange={event => { setAuthorConfirmed(event.target.checked) }} value={authorConfirmed} />
                    confirm you are requesting a visualization authoring account
                  </label>

                </Col>
              </Row>
            </FormGroup> */}
          </center>

          <Button
            className="btn-info" color="info" type="button"
            disabled={!isValidInviteForm()}
            onClick={() => submitInviteUser(false)}
          >invite</Button>
        </div>
      </ModalBody>
    </Modal>
  </>);
}

const mapStateToProps = state => {
  return {
    isLoading: state.admin.isLoading,
    users: state.admin.users,
    error: state.admin.error,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadingUpdated: isLoading => dispatch(setIsLoading(isLoading)),
    onUsersUpdated: users => dispatch(setUsers(users)),
    onErrorUpdated: error => dispatch(setError(error))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
