import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router";

// import classnames from "classnames";
import { commodities, viewFlags } from "helpers/constants";
import API from '../../helpers/api';

import { setIsLoading } from "./actions";

import Commodity from "components/molecules/optimizer/Commodity";
import CommoditySub from "components/molecules/optimizer/CommoditySub";
import PriceView from "components/molecules/optimizer/PriceView";
import VolatilityView from "components/molecules/optimizer/VolatilityView";
import { getDatesInRange } from "libs/utils";



function OptionsOptimizer({
  showLoadingWidget, isComplete,
}) {
  const [defaultSubpublisher] = Object.keys(commodities);
  const [[defaultNativeID, defaultProudctName]] = Object.entries(commodities[defaultSubpublisher]);
  const [commodity, setCommodity] = useState({
    nativeID: defaultNativeID,
    subpublisher: defaultSubpublisher,
    title: defaultProudctName,
  });
  const [viewFlag, setViewFlag] = useState(viewFlags[0])
  const [fetchingContracts, setFetchingContracts] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [contract, setContract] = useState({});
  const [fetchingQuickFacts, setFetchingQuickFacts] = useState(false);
  const [facts, setFacts] = useState({})
  const [fetchingVolatility, setFetchingVolatility] = useState(false);
  const [volatilityData, setVolatilityData] = useState([]);

  const defaultTimeHorizon = useMemo(() => {
    let temp = new Date();
    temp.setDate(temp.getDate() + 14);
    return temp
  }, [])

  const [parameters, setParameters] = useState({
    timeHorizon: defaultTimeHorizon.toISOString().split('T')[0],
  });

  const history = useHistory();

  const getViewLength = (): Number => {
    if (timeSeries.length === 0) {
      return 0
    }

    const { timeHorizon } = parameters;
    const lastDate = new Date(timeSeries[timeSeries.length - 1].x);
    const objTimeHorizon = new Date(timeHorizon);

    if (lastDate > timeHorizon) {
      return 0
    }

    const extraDates = getDatesInRange(lastDate, objTimeHorizon);
    return extraDates.length - 1
  }

  const [targetObject, setTargetObject] = useState({
    value: 150,
    min: 80,
    max: 150,
  });

  const [stopLossObject, setStopLossObject] = useState({
    value: 80,
    min: 65,
    max: 80,
  });

  const [timeSeries, setTimeSeries] = useState([]);
  const [futureSeries, setFutureSeries] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [volLow, setVolLow] = useState(-.05);
  const [volHigh, setVolHigh] = useState(.05);
  const [steepness, setSteepness] = useState(-0.25);
  const {
    current_price: startFuturePrice,
    trade_date: tradeDate
  } = facts;

  const onParameterChanged = useCallback((changedValues: Object) => {
    setParameters({
      ...parameters,
      ...changedValues,
    })
  }, [
    parameters,
  ])

  const onCommodityChanged = (commodity) => {
    setFacts({});
    setCommodity(commodity);
  }

  const onOrderAnalysis = () => {
    const { nativeID, subpublisher } = commodity;
    const { timeHorizon } = parameters;
    const { value: target } = targetObject;
    const { value: loss } = stopLossObject;
    const { contractTenor } = contract;

    if (!contractTenor || !timeHorizon) {
      alert('please set contract and time-horizon.');
      return
    }

    showLoadingWidget(true);
    API.getOptionsRecommenderReportCheckoutUrl(
      [subpublisher, 'futures', nativeID],
      tradeDate, contractTenor,
      (target - startFuturePrice)/startFuturePrice,
      (loss - startFuturePrice)/startFuturePrice,
      timeHorizon, getViewLength(),
      steepness, volLow, volHigh,
    ).then(({ url }) => {
      window.location.href = url;
      return
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      showLoadingWidget(false);
    })
  }

  useEffect(() => {
    if (isComplete !== undefined && !isComplete) {
      history.push('/auth/onboarding')
    }
  }, [isComplete, history])

  useEffect(() => {
    setContract({});
    setContracts([]);
    const { nativeID, subpublisher } = commodity;
    setFetchingContracts(true);
    API.fetchOptionContracts(subpublisher, nativeID)
      .then(({ contracts }) => {
        const [{ contract_tenor: contractTenor, expiry_date: expiryDate },] = contracts;
        setContracts(contracts.map(({
          contract_tenor: contractTenor,
          expiry_date: expiryDate }) => ({ contractTenor, expiryDate }))
        );
        setContract({ contractTenor, expiryDate });
      })
      .catch(err => { console.error(err) })
      .finally(() => {
        setFetchingContracts(false);
      })
  }, [
    commodity,
  ]);

  useEffect(() => {
    const { contractTenor } = contract
    const { nativeID } = commodity;

    if (!contractTenor) {
      return
    }

    setFacts({});
    setFetchingQuickFacts(true);
    API.fetchOptionQuickFacts(nativeID, contractTenor)
      .then(({ quick_facts: facts }) => {
        setFacts(facts);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setFetchingQuickFacts(false);
      })
  }, [
    commodity, contract,
  ]);

  useEffect(() => {
    const { nativeID, subpublisher } = commodity;
    const { contractTenor } = contract;
    if (!contract || !nativeID || !subpublisher || !contractTenor) {
      return
    }

    setFetchingVolatility(true);
    API.fetchContractVolatilityScatter(subpublisher, nativeID, contractTenor)
      .then(({ data }) => {
        // TODO: need to check how the null value happened.
        const filteredData = data.filter(({ futuresprice, atmvol }) => (futuresprice !== null && atmvol !== null));
        setVolatilityData(filteredData);
        setTimeSeries(filteredData.map(({ trade_date: x, futuresprice: y }) => ({ x, y })));
      })
      .catch((err) => { console.error(err) })
      .finally(() => { setFetchingVolatility(false); })
  }, [
    commodity, contract,
  ]);

  useEffect(() => {
    showLoadingWidget(fetchingVolatility || fetchingQuickFacts || fetchingContracts);
  }, [
    fetchingVolatility, fetchingQuickFacts, fetchingContracts, showLoadingWidget,
  ])

  useEffect(() => {
    const { timeHorizon } = parameters;
    const { value: target } = targetObject;
    const { value: loss } = stopLossObject;
    const defaultMarkers = [
      {
        axis: 'y',
        value: target,
        lineStyle: { stroke: '#36b27e', strokeWidth: 2 },
        legend: `target price: $${target}`,
        legendPosition: 'bottom-left',
      },
      {
        axis: 'y',
        value: loss,
        lineStyle: { stroke: '#b00000', strokeWidth: 1 },
        legend: `stop loss: $${loss}`,
        legendPosition: 'bottom-left',
      }
    ]

    if (new Date() < new Date(timeHorizon)) {
      setMarkers([
        ...defaultMarkers,
        {
          axis: 'x',
          value: new Date(timeHorizon),
          lineStyle: { stroke: '#00b000', strokeWidth: 1 },
          legend: `time horizon: ${timeHorizon}`,
          legendPosition: 'top-left',
        }
      ])
    } else {
      setMarkers(defaultMarkers);
    }
  }, [
    parameters, targetObject, stopLossObject,
  ]);

  const { contractTenor = '' } = contract;

  return (
    <section className="optimizer">
      <h1 className="border--b">{'options score explorer'}</h1>
      <div className="optimizer-wrap">
        <div className="col-md-2 pd-10 border-r">
          <Commodity
            commodity={commodity} onCommodityChanged={onCommodityChanged}
            contracts={contracts} selectedContract={contract}
            parameters={parameters} timeSeries={timeSeries}
            onParameterChanged={onParameterChanged}
            setFutureSeries={setFutureSeries} setMarkers={setMarkers}
            volLow={volLow} setVolLow={setVolLow}
            volHigh={volHigh} setVolHigh={setVolHigh}
            steepness={steepness} setSteepness={setSteepness}
            onContractSelected={setContract}
            onOrderClicked={onOrderAnalysis}
            viewFlag={viewFlag}
            onChangeViewFlag={setViewFlag}
            targetObject={targetObject}
            setTarget={(val) => setTargetObject({ ...targetObject, value: val })}
            lossObject={stopLossObject}
            setLoss={(val) => setStopLossObject({ ...stopLossObject, value: val })}
          />
        </div>
        <div className="col-md-8">
          <h2 className="mb-0 align-center">charts</h2>
          <hr />

          <PriceView
            commodity={commodity}
            contract={contractTenor}
            parameters={parameters}
            timeSeries={timeSeries}
            futureSeries={futureSeries}
            markers={markers}
            viewFlag={viewFlag}
            currentTradeDate={tradeDate}
            targetObject={targetObject}
            setTargetObject={setTargetObject}
            lossObject={stopLossObject}
            setLossObject={setStopLossObject}
            onParameterChanged={onParameterChanged}
            volatilityData={volatilityData}
          />

          <VolatilityView
            commodity={commodity}
            contract={contractTenor}
            data={volatilityData}
            volLow={volLow}
            volHigh={volHigh}
            steepness={steepness}
          />
        </div>
        <div className="col-md-2 pd-10 border-l" style={{ backgroundColor: '#D8C6D6' }}>
          <CommoditySub commodity={commodity} contract={contract} facts={facts} />
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    isComplete: state.auth.profile.isComplete,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showLoadingWidget: isLoading => dispatch(setIsLoading(isLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsOptimizer);
