export const getRecommenderScenarioSpec = (identifier: String, colorThreashold: Number = 6, mktdatavalueDate: String = '2023-07-20', mktdataundSettleValue: Number = 75.43) => {
  const field = identifier === 'premium' ? 'struct_pv' : `struct_${identifier}`;
  const caption = identifier === 'premium' ? 'structure premium value' : `structure ${identifier}`;

  return {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "padding": 10,
    "vconcat": [
      {
        "width": 900,
        "height": 20,
        "padding": 30,
        "transform": [
          {
            "aggregate": [
              {
                "op": "mean",
                "field": "f-scen",
                "as": "scen_single"
              }
            ],
            "groupby": [
              "und_settle"
            ]
          },
          { "calculate": "format(datum.scen_single, '.2f') + '%'", "as": "scen_single_format" }
        ],
        "layer": [
          {
            "mark": {
              "type": "rect"
            },
            "encoding": {
              "x": {
                "field": "und_settle",
                "type": "ordinal"
              },
              "fill": { "value": "#F1DCD9" }
            }
          },
          {
            "mark": {
              "type": "text",
              "align": "center",
              "baseline": "middle",
              "dx": -2,
              "fontSize": 12  // Default font size
            },
            "encoding": {
              "x": {
                "field": "und_settle",
                "type": "ordinal",
                "axis": {
                  "format": ".2f",
                  "ticks": false,
                  "title": null,
                  "labelFontSize": 16 // add this
                }
              },
              "text": {
                "field": "scen_single_format"
              },
              "color": {
                "condition": {
                  "test": `datum['${field}'] > ${colorThreashold}`,
                  "value": "white"
                },
                "value": "black"
              }
            }
          },
          {
            "data": {
              "values": [{}]
            },
            "layer": [
              {
                "mark": {
                  "type": "text",
                  "fontSize": 13  // Default font size
                },
                "encoding": {
                  "text": { "value": "Future scenario" },
                  "x": { "value": -70 },
                  "y": { "value": 10 }
                }
              },
              {
                "mark": {
                  "type": "text",
                  "fontSize": 13  // Default font size
                },
                "encoding": {
                  "text": { "value": "Future value" },
                  "x": { "value": -70 },
                  "y": { "value": 28 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "y": { "value": 0 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "y": { "value": 40 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "x": { "value": 0 },
                  "y2": { "value": 40 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "x": { "value": 900 },
                  "y2": { "value": 40 }
                }
              }
            ]
          }
        ],
      },
      {
        "width": 900,
        "height": 80,
        "padding": 30,
        "transform": [
          {
            "aggregate": [
              {
                "op": "mean",
                "field": "atm",
                "as": "atm_single"
              }
            ],
            "groupby": [
              "und_settle",
              "sigma-scen"
            ]
          },
          { "calculate": "format(datum.atm_single, '.2%')", "as": "atm_single_format" }
        ],
        "layer": [
          {
            "mark": {
              "type": "text",
              "align": "center",
              "baseline": "middle",
              "dx": -2,
              "fontSize": 13  // Default font size
            },
            "encoding": {
              "y": {
                "field": "sigma-scen",
                "type": "nominal",
                "sort": "descending",
                "axis": {
                  "title": "atm for vol scenario",
                  "labelExpr": "datum.label + '%'",
                  "labelFontSize": 12,
                  "labelFontWeight": "bold"
                }
              },
              "x": {
                "field": "und_settle",
                "type": "ordinal",
                "axis": null
              },
              "text": {
                "field": "atm_single_format"
              },
              "color": {
                "condition": {
                  "test": `datum['${field}'] > ${colorThreashold}`,
                  "value": "white"
                },
                "value": "black"
              }
            }
          },
          {
            "data": {
              "values": [{}]
            },
            "layer": [
              {
                "mark": "rule",
                "encoding": {
                  "y": { "value": 0 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "y": { "value": 80 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "x": { "value": 0 }
                }
              },
              {
                "mark": "rule",
                "encoding": {
                  "x": { "value": 900 }
                }
              }
            ]
          }
        ]
      },
      {
        "transform": [
          {
            "aggregate": [
              {
                "op": "sum",
                "field": field,
                "as": field
              }
            ],
            "groupby": [
              "value_date",
              "und_settle",
              "sigma-scen"
            ]
          }
        ],
        "facet": {
          "row": {
            "field": "sigma-scen",
            "type": "nominal",
            "sort": "descending",
            "header": { "title": "vol scenario", "labelExpr": "datum.label + '%'", "labelFontSize": 14, "labelFontWeight": "bold" }
          }
        },
        "spec": {
          "width": 900,
          "height": 300,
          "padding": 30,
          "resolve": { "axis": { "x": "independent" } },
          "layer": [
            {
              "mark": {
                "type": "rect"
              },
              "encoding": {
                "y": {
                  "field": "value_date",
                  "type": "ordinal",
                },
                "x": {
                  "field": "und_settle",
                  "type": "ordinal",
                  "axis": {
                    "format": ".2f",
                    "labelPadding": 8,
                    "orient": "top",
                    "title": caption,
                    "labelFontSize": 16
                  }
                },
                "color": {
                  "field": field,
                  "type": "quantitative",
                  "title": caption,
                  "scale": {
                    "range": [
                      "#b085ad",
                      "#F1DCD9",
                      "#98d1a1"]
                  },
                  "condition": {
                    "test": `(datum['value_date'] == "${mktdatavalueDate}") && (datum.und_settle == ${mktdataundSettleValue}) && (datum['sigma-scen'] == '0')`,
                    "value": "#61FBE9"
                  }
                }
              }
            },
            {
              "mark": {
                "type": "text",
                "align": "center",
                "baseline": "middle",
                "dx": -2,
                "fontSize": 15  //  font size for data inside the third plot
              },
              "encoding": {
                "y": {
                  "field": "value_date",
                  "type": "ordinal"
                },
                "x": {
                  "field": "und_settle",
                  "type": "ordinal",
                  "axis": {
                    "format": ".2f",
                    "labelPadding": 8,
                    "orient": "bottom",
                    "title": caption
                  }
                },
                "text": {
                  "field": field,
                  "type": "quantitative",
                  "format": ".2f"
                },
                "color": {
                  "condition": {
                    "test": `datum['${field}'] < ${colorThreashold}`,
                    "value": "white"
                  },
                  "value": "black"
                }
              }
            }
          ]
        },
      }
    ],
    "config": {
      "text": {
        "fontSize": 13
      },
      "axis": {
        "grid": false,
        "domain": false,
        "labelAngle": 0
      },
      "view": {
        "stroke": "transparent"
      }
    }
  }
};
