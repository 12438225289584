import createReducer from 'redux/helpers/createReducer';

import {
  namespace,
  setError,
  setDataSources,
  setIsLoading,
  setCredentials,
  setUsers,
  setSelectedFilterOptions,
  setProducts,
  setCartItems,

  fetchFilterOptionsRequest, fetchFilterOptionsSuccess, fetchFilterOptionsError,
  fetchCartRequest, fetchCartSuccess, fetchCartError,
  addToCartRequest, addToCartSuccess, addToCartError,
  removeFromCartRequest, removeFromCartSuccess, removeFromCartError,
  checkoutCartRequest, checkoutCartSuccess, checkoutCartError,
  clearSelectedFilterOptions,
 } from './actions';

// =======================================
// Default state
// =======================================
const defaultState = {
  error: null,
  dataSources: null,
  isLoading: false,
  credentials: {},
  users: [],
  fetchingFilterOptions: false,
  fetchingFilterOptionsError: null,
  availableFilterOprions: null,
  selectedFilterOptions: {},
  products: {},
  fetchingCart: false,
  fetchingCartError: null,
  addingToCart: false,
  addingToCartError: null,
  removingFromCart: false,
  removingFromCartError: null,
  checkingOutCart: false,
  checkingOutCartError: null,
  cartItems: [],
}

// =======================================
// Reducers
// =======================================
const reducer = createReducer(namespace, defaultState, {
  [setError.Type]: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  [setDataSources.Type]: (state, action) => ({
      ...state,
      dataSources: action.payload,
  }),
  [setIsLoading.Type]: (state, action) => ({
    ...state,
    isLoading: action.payload,
  }),
  [setCredentials.Type]: (state, action) => ({
    ...state,
    credentials: action.payload,
  }),
  [setUsers.Type]: (state, action) => ({
    ...state,
    users: action.payload,
  }),
  [setSelectedFilterOptions.Type]: (state, action) => ({
    ...state,
    selectedFilterOptions: action.payload,
  }),
  [clearSelectedFilterOptions.Type]: (state, action) => ({
    ...state,
    selectedFilterOptions: {},
  }),
  [setProducts.Type]: (state, action) => ({
    ...state,
    products: action.payload,
  }),
  [setCartItems.Type]: (state, action) => ({
    ...state,
    cartItems: action.payload,
  }),

  [fetchFilterOptionsRequest.Type]: (state, action) => ({
    ...state,
    fetchingFilterOptions: true,
    fetchingFilterOptionsError: null,
  }),
  [fetchFilterOptionsSuccess.Type]: (state, action: { payload: {options: Object}}) => ({
    ...state,
    fetchingFilterOptions: false,
    availableFilterOprions: action.payload.options,
  }),
  [fetchFilterOptionsError.Type]: (state, action: { payload: { error: Any } }) => ({
    ...state,
    fetchingFilterOptions: false,
    fetchingFilterOptionsError: action.payload.error,
  }),
  [fetchCartRequest.Type]: (state, action) => ({
    ...state,
    fetchingCart: true,
    fetchingCartError: null,
  }),
  [fetchCartSuccess.Type]: (state, action: {payload: {items: Array<Object>}}) => ({
    ...state,
    fetchingCart: false,
    fetchingCartError: null,
    cartItems: action.payload.items,
  }),
  [fetchCartError.Type]: (state, {payload}) => ({
    ...state,
    fetchingCart: true,
    fetchingCartError: payload.error,
  }),

  [addToCartRequest.Type]: (state, action) => ({
    ...state,
    addingToCart: true,
    addingToCartError: null,
  }),
  [addToCartSuccess.Type]: (state, action) => ({
    ...state,
    addingToCart: false,
    addingToCartError: null,
  }),
  [addToCartError.Type]: (state, {payload}) => ({
    ...state,
    addingToCart: true,
    addingToCartError: payload.error,
  }),

  [removeFromCartRequest.Type]: (state, action) => ({
    ...state,
    removingFromCart: true,
    removingFromCartError: null,
  }),
  [removeFromCartSuccess.Type]: (state, action) => ({
    ...state,
    removingFromCart: false,
    removingFromCartError: null,
  }),
  [removeFromCartError.Type]: (state, {payload}) => ({
    ...state,
    removingFromCart: true,
    removingFromCartError: payload.error,
  }),

  [checkoutCartRequest.Type]: (state, action) => ({
    ...state,
    checkingOutCart: true,
    checkingOutCartError: null,
  }),
  [checkoutCartSuccess.Type]: (state, action) => ({
    ...state,
    checkingOutCart: false,
    checkingOutCartError: null,
  }),
  [checkoutCartError.Type]: (state, {payload}) => ({
    ...state,
    checkingOutCart: false,
    checkingOutCartError: payload,
  }),
})

export default reducer
export {
  namespace,
}
