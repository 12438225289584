import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Container,
  Row, Col,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import FilterCondition from "components/molecules/filter/FilterCondition";
import LibraryItem from "components/molecules/filter/LibraryItem";

import API from '../../helpers/api';

import {
  fetchFilterOptionsRequest,
  setSelectedFilterOptions,
} from 'containers/admin/actions'

import {
  fetchDatasetsRequest, fetchDatasetsSuccess, fetchDatasetsError,
} from './actions';

function DataLibrary({
  availableOptions, fetchFilterOptionsRequest,
  selectedOptions, onSelectedOptionsUpdated,

  datasets, fetchDatasetsSuccess, fetchDatasetsRequest, fetchDatasetsError,
}) {
  const [search, setSearch] = useState('');
  const [queryTimer, setQueryTimer] = useState(null);

  const {
    asset_classes: assetClassesOptions,
    publishers: sources,
    data_types: dataTypes,
    data_categories: riskCategories,
    publisher_classifications,
    sectors, 
  } = availableOptions || {};

  const {
    query,
    asset_classes: selectedAssetClasses,
    publishers: selectedSources,
    data_types: selectedDataTypes,
    publisher_classifications: selectedSecurityTypes,
    data_categories: selectedRiskCategories,
    sectors: selectedSectors,
  } = selectedOptions;

  useEffect(() => {
    fetchFilterOptionsRequest()
  }, [fetchFilterOptionsRequest])

  const history = useHistory();

  const getDatasets = useCallback(() => {
    fetchDatasetsRequest()
    API.filterDatasets({
      assetClasses:selectedAssetClasses,
      sectors: selectedSectors,
      sources: selectedSources,
      dataTypes: selectedDataTypes,
      riskCategories: selectedRiskCategories,
      publisher_classifications: selectedSecurityTypes,
      query,
    }).then(({status, datasets}) => {
      if (status) {
        fetchDatasetsSuccess({datasets})
      } else {
        fetchDatasetsError()
      }
    }).catch(err => {
      console.error(err);
      fetchDatasetsError()
    })
  }, [
    selectedAssetClasses,
    selectedSectors,
    selectedSources,
    selectedDataTypes,
    selectedRiskCategories,
    selectedSecurityTypes,
    query,
    fetchDatasetsRequest, fetchDatasetsSuccess, fetchDatasetsError
  ])

  useEffect(() => {
    getDatasets()
  }, [selectedOptions, getDatasets]);

  const onOptionSelectionUpdated = (identifier, option, checked) => {
    const originalValue = selectedOptions[identifier] || []
    const updated = {
      ...selectedOptions,
      [identifier]: checked ? originalValue.concat([option]) : originalValue.filter(item => item !== option)
    }
    onSelectedOptionsUpdated(updated);
  }

  const resetFilters = () => {
    onSelectedOptionsUpdated({})
  }

  const updateSearchQuery = (value) => {
    onSelectedOptionsUpdated({
      ...selectedOptions,
      query: value
    });
    setQueryTimer(null);
  }

  const onSearchQueryUpdated = (e) => {
    setSearch(e.target.value);
    if (queryTimer !== null) {
      clearTimeout(queryTimer);
      setQueryTimer(null);
    }

    setQueryTimer(setTimeout(() => {updateSearchQuery(e.target.value)}, 1000));
  }

  return (
    <>
      <Container className="databuilder pt--20" fluid>
        <Row>
          <Col xl="3">
            <h2>search your datasets</h2>
            <div className="reset--btn">
              <Button color="info" type="button" onClick={() => resetFilters()}>
                reset filters
              </Button>
            </div>

            <div className="filter_wrap">
              <FilterCondition
                identifier={'asset_classes'}
                name={'asset class'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedAssetClasses || []}
                items={assetClassesOptions || []} />

              <FilterCondition
                identifier={'sectors'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSectors || []}
                name={'sector'}
                items={sectors || []} />

              <FilterCondition
                identifier={'publishers'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSources || []}
                name={'source'}
                items={sources || []} />

              {/* <FilterCondition
                name={'location'}
                items={['north america', 'south america', 'europe', 'asia']} /> */}

              <FilterCondition
                identifier={'data_types'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedDataTypes || []}
                name={'data type'}
                items={dataTypes || []} />

              <FilterCondition
                identifier={'data_categories'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedRiskCategories || []}
                name={'risk category'}
                items={riskCategories || []} />

              <FilterCondition
                identifier={'publisher_classifications'}
                onCheckUpdated={onOptionSelectionUpdated}
                selectedOptions={selectedSecurityTypes || []}
                name={'publisher classification'}
                items={publisher_classifications || []} />
            </div>
          </Col>
          <Col xl="9">
            <FormGroup
              className={"mb-3 mt-5"}>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="narrow the results by adding search criteria"
                  type="text"
                  value={search}
                  onChange={onSearchQueryUpdated}
                />
              </InputGroup>
            </FormGroup>
            <div className="product--list flex">
              <p><strong>dataset list</strong> | view and edit your saved datasets</p>
              <Button color="info" type="button" onClick={() => history.push('/admin/creator/switch')}>
                create new dataset
              </Button> 
            </div>
            <br />

            <div className="filter_wrap">
              {datasets.map((item, key) => 
                <LibraryItem
                  data={item} key={key}
                  getDatasets={getDatasets}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const mapStateToProps = state => {
  return {
    availableOptions: state.admin.availableFilterOprions,
    selectedOptions: state.admin.selectedFilterOptions,

    datasets: state.datasets.datasets,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchFilterOptionsRequest: () => dispatch(fetchFilterOptionsRequest({queryType: 'time_series'})),
    onSelectedOptionsUpdated: options => dispatch(setSelectedFilterOptions(options)),

    fetchDatasetsRequest: () => dispatch(fetchDatasetsRequest()),
    fetchDatasetsSuccess: payload => dispatch(fetchDatasetsSuccess(payload)),
    fetchDatasetsError: () => dispatch(fetchDatasetsError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataLibrary);
