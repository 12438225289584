/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Row, Col, NavbarBrand } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

function AuthFooter() {
  const history = useHistory();
  return (
    <>
      <footer className="dashboard--footer" id="footer-main">
        {/* <Container> */}
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              {/* <div className="copyright text-center text-xl-left">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ml-1"
                  href="https://etherealrisk.com"
                  target="_blank"
                >
                  etherealrisk.com
                </a>
              </div> */}
              <NavbarBrand className="ml-3 mb-3" to="/" tag={Link}>
                <img
                  alt="..."
                  src={require("assets/img/brand/logo_white.png").default}
                />
              </NavbarBrand>
            </Col>
            <Col xl="6">
              <Nav className="nav-footer justify-content-center justify-content-xl-end mr-3">
                {/* <NavItem>
                  <NavLink
                    to='#'
                    target="_blank"
                  >
                    about us
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink to='/privacy' tag={Link}>
                    privacy
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to='/terms' tag={Link}>
                    terms of use
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        {/* </Container> */}
      </footer>
    </>
  );
}

export default AuthFooter;
