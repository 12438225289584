import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classnames from "classnames";

import { StringUtil } from 'libs/utils';
import { isProduction } from 'helpers/constants';

import {
  Button,
  Collapse,
  NavLink,
} from "reactstrap";

const ProductItem = ({
  data,
  onAddToCart,
  cartItems,
  onRemoveFromCart,
  queryType,
}) => {
  const [ isOpen, setIsOpen ] = useState(false);
  const {
    product_name: productName,
    sub_sector: subSector,
    publisher,
    subpublisher: subPublisher,
    _id: productId,
    enabled,
    raw_native_id: nativeId,
  } = data;
  const inCart = cartItems && cartItems.filter(item => item.product_id === productId).length > 0;

  const addProduct = () => {
    if (!enabled) {
      alert(`Your company has no permission to ${publisher} group`)
    } else {
      onAddToCart(productId, queryType);
    }
  }

  const onRemoveYes = () => {
    onRemoveFromCart(productId, queryType);
  }

  return (
  <>
    <div className="datasource-wrap">
      <div className="drop-menu product-item">
        {inCart ? (
          <Button color="remove" type="button" onClick={onRemoveYes} >
            remove
          </Button>
        ) : (
          <Button disabled={!enabled} color="info" type="button" onClick={addProduct}>
            add
          </Button>
        )}
        <NavLink
          className={classnames("mb-sm-3 mb-md-0")}
          onClick={() => setIsOpen(!isOpen)}
          role="tab"
        >
          <div title={productName}>
            {StringUtil.truncate(productName, 60)}
            {!isProduction && <strong className={classnames('shorten-native-id')}>({publisher}, {subPublisher}, {StringUtil.truncate(nativeId, 5)})</strong>}
          </div>
          <i className={classnames("ni ni-button-play", {rotate: isOpen})}></i>
        </NavLink>
        <div className="clear"></div>
      </div>
    </div>
    <Collapse isOpen={isOpen}>
      <div className="pl--85">
          location detail | {subSector}<br />
          native id: {nativeId}<br />
          {subPublisher}, {publisher}
      </div>
    </Collapse>
  </>
  )
}

ProductItem.propTypes = {
  data: PropTypes.object.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  queryType: PropTypes.string,
}

ProductItem.defaultProps = {
  queryType: 'time_series',
}

export default ProductItem;
