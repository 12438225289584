import React from "react";
// reactstrap components
import {
  Container
} from "reactstrap";

function DailyDash() {
  return (
    <>
      <Container className="home-wrap" fluid>
        <h2>daily dash page</h2>
      </Container>
    </>
  );
}

export default DailyDash;
