import createAction from 'redux/helpers/createAction';

export const namespace = 'auth';

export const setLoading = createAction(namespace, 'SET_LOADING');
export const setError = createAction(namespace, 'SET_ERROR');

export const fetchProfileRequest = createAction(namespace, 'FETCH_PROFILE_REQUEST');
export const fetchProfileSuccess = createAction(namespace, 'FETCH_PROFILE_SUCCESS');
export const fetchProfileError = createAction(namespace, 'FETCH_PROFILE_ERROR');

export const submitProfileRequest = createAction(namespace, 'SUBMIT_PROFILE_REQUEST');
export const submitProfileSuccess = createAction(namespace, 'SUBMIT_PROFILE_SUCCESS');
export const submitProfileError = createAction(namespace, 'SUBMIT_PROFILE_ERROR');

export const fetchCredentialsRequest = createAction(namespace, 'FETCH_CREDENTIALS_REQUEST');
export const fetchCredentialsSuccess = createAction(namespace, 'FETCH_CREDENTIALS_SUCCESS');
export const fetchCredentialsFailure = createAction(namespace, 'FETCH_CREDENTIALS_FAILURE');

export const fetchTransactionsRequest = createAction(namespace, 'FETCH_TRANSACTIONS_REQUEST');
export const fetchTransactionsSuccess = createAction(namespace, 'FETCH_TRANSACTIONS_SUCCESS');
export const fetchTransactionsFailure = createAction(namespace, 'FETCH_TRANSACTIONS_FAILURE');
