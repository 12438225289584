import React from 'react';

import PropTypes from 'prop-types';

// reactstrap components
import { Input, Button } from "reactstrap";

import AttributeSelect from './AttributeSelect';
import { MEMBER_MODE_COLOR } from 'helpers/constants';

const IndicesItem = ({
  item, type, members, itemkey,
  getMemberOptionValue,
  onUpdate, onRemove,
}) => {
  const { left, right, func, whole } = item;

  const isSetApplicatable = () => {
    const [
      {
        productId: leftProductId,
        data: {
          mode: leftMode,
          color: leftColor,
          month: leftMonth,
        },
      },
      {
        productId: rightProductId,
        data: {
          mode: rightMode,
          color: rightColor,
          month: rightMonth,
        },
      } = { data: {}}] = members.filter(({id}) => [left, right].indexOf(id) >= 0);

    const result = (
      leftProductId === rightProductId &&
      leftMode === MEMBER_MODE_COLOR && rightMode === MEMBER_MODE_COLOR &&
      leftColor === rightColor && leftMonth !== rightMonth
    );

    if (!result && !!whole) {
      onUpdate('whole', false)
    }

    return result;
  }

  return (
    <div className="indices-item">
      <AttributeSelect value={left} options={members} onChange={(value) => onUpdate('left', value)}/>&nbsp;
      {type === 'spreads' && <>-</>}
      <AttributeSelect value={right} options={members} onChange={(value) => onUpdate('right', value)} />&nbsp;
      {type !== 'spreads' && (
        <>
          <Input
            placeholder={'enter function'}
            className={'custom-index-function'}
            type={'text'}
            value={func}
            onChange={(e) => onUpdate('func', e.target.value)}
          />
        </>
      )}
      <Button color="remove" type="button" className="remove-btn" onClick={onRemove}>remove</Button>
      {isSetApplicatable() && <div className="custom-control custom-control-alternative custom-checkbox evaluates-checkbox">
        <input
          className="custom-control-input"
          id={"evaluates" + itemkey}
          data={itemkey}
          type="checkbox"
          checked={!!whole}
          onChange={(event) => onUpdate('whole', event.target.checked)}
        />
        <label
          className="custom-control-label"
          htmlFor={"evaluates" + itemkey}
        >evaluate as a set
        </label>
      </div>}
    </div>
  )
}

IndicesItem.propTypes = {
  itemkey: PropTypes.number,
  type: PropTypes.oneOf(['spreads', 'custom']),
  members: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  item: PropTypes.shape({
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
    func: PropTypes.string,
  }).isRequired,
  getMemberOptionValue: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
}

IndicesItem.defaultProps = {
  type: 'custom',
  onRemove: () => {},
}

export default IndicesItem;
