import { call, put, takeEvery } from 'redux-saga/effects';

import API from 'helpers/api';

import {
  // fetchFamilyOptionsRequest, fetchFamilyOptionsSuccess, fetchFamilyOptionsError,
  retrieveDatasetRequest, retrieveDatasetSuccess, retrieveDatasetFailure,
  // updateDatasetRequest, updateDatasetSuccess, updateDatasetFailure,
  addItemToDatasetRequest, addItemToDatasetSuccess, addItemToDatasetFailure,
  removeItemFromDatasetRequest, removeItemFromDatasetSuccess, removeItemFromDatasetFailure,
  clearDatasetDraft,
} from './actions';

// function* fetchFamilyOptions({ payload }) {
//   try {
//     const { nativeIds } = payload
//     const { options } = yield call(API.getDatasetFamilyOptions, nativeIds);
//     yield put(fetchFamilyOptionsSuccess({familyOptions: options}));
//   } catch (e) {
//     yield put(fetchFamilyOptionsError({error: e}));
//   }  
// }

function * retrieveDataset({ payload }) {
  const { datasetId } = payload;
  try {
    yield put(clearDatasetDraft());
    const { dataset, status } = yield call(API.retrieveDataSet, datasetId);

    if (status) {
      const {
        name, spreads, custom, families, query_type: queryType, details,
      } = dataset;
      yield put(retrieveDatasetSuccess({
        name, spreads, custom,
        datasetItems: families,
        queryType, details,}));

      // const nativeIds = families.map(family => family.native_id)
      // yield put(fetchFamilyOptionsRequest({nativeIds}));
    } else {
      yield put(retrieveDatasetFailure())
    }
  } catch (e) {
    yield put(retrieveDatasetFailure())
  }
}

function * addItemToDataset({ payload }) {
  const { datasetId, productId } = payload;
  try {
    const { dataset, status } = yield call(API.addItemToDataset, datasetId, productId);

    if (status) {
      const {
        name, spreads, custom, families
      } = dataset;
      yield put(addItemToDatasetSuccess({name, spreads, custom, datasetItems: families}))
    } else {
      yield put(addItemToDatasetFailure())
    }
  } catch (e) {
    yield put(addItemToDatasetFailure())
  }
}

function * removeItemFromDataset({ payload }) {
  const { datasetId, productId } = payload;
  try {
    const { dataset, status } = yield call(API.removeItemFromDataset, datasetId, productId);

    if (status) {
      const {
        name, spreads, custom, families
      } = dataset;
      yield put(removeItemFromDatasetSuccess({name, spreads, custom, datasetItems: families}))
    } else {
      yield put(removeItemFromDatasetFailure())
    }
  } catch (e) {
    yield put(removeItemFromDatasetFailure())
  }
}

export const datasetSagas = [
  // takeEvery(fetchFamilyOptionsRequest.Type, fetchFamilyOptions),
  takeEvery(retrieveDatasetRequest.Type, retrieveDataset),
  takeEvery(addItemToDatasetRequest.Type, addItemToDataset),
  takeEvery(removeItemFromDatasetRequest.Type, removeItemFromDataset),
];
