/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import API from '../../helpers/api';
// import { useAppContext } from 'libs/contextLib';
import ProfileDataSource from '../../components/molecules/dataSources/ProfileDataSource';
import {
  fetchProfileRequest
} from 'containers/auth/actions';
import {
  setDataSources, setIsLoading,
  setCredentials,
} from './actions';

const DataSources = ({
  profile,
  fetchingProfile,
  dataSources,
  onDataSourceUpdated,
  isLoading,
  onLoadingUpdated,
  error,
  credentials,
  onCredentialsUpdated,
  fetchProfileRequest,
}) => {
  const history = useHistory();
  // const { isAuthenticated } = useAppContext();
  const { company } = profile || {};

  const { isComplete } = profile || {};

  const onErrorUpdated = useCallback((error) => {
    if (error) {
      alert(error);
    }
  }, [])

  const retrieveDataSources = useCallback(() => {
    onLoadingUpdated(true);
    API.getDataSources().then(res => {
      const { datasources } = res;
      onDataSourceUpdated(datasources);
      onErrorUpdated(null);
    }).catch(err => {
      onErrorUpdated(err);
    })
      onLoadingUpdated(false);
  }, [
    onLoadingUpdated, onDataSourceUpdated, onErrorUpdated
  ])

  const onCreateCompany = (companyName) => {
    onLoadingUpdated(true);
    return API.createCompany(companyName).then(res => {
      fetchProfileRequest();
      onLoadingUpdated(false);
    }).catch(err => {
      onErrorUpdated(err)
      onLoadingUpdated(false);
    })
  }

  const enableDataSource = (groupName, params) => {
    onLoadingUpdated(true);
    API.subscribeDataSource(groupName, params).then(res => {
      const { status, datasources, msg } = res;
      if (status) {
        onDataSourceUpdated(datasources);
        onErrorUpdated(null);
      } else {
        onErrorUpdated(msg);
      }
        onLoadingUpdated(false);
    }).catch(err => {
      onErrorUpdated(err.response?.data?.message);
      onLoadingUpdated(false);
    })
  }

  const disableDataSource = (groupName, reason = '') => {
    onLoadingUpdated(true);
    API.unsubscribeDataSource(groupName, reason).then(res => {
      const { status, datasources, msg } = res;
      if (status) {
        onDataSourceUpdated(datasources);
        onErrorUpdated(null);
      } else {
        onErrorUpdated(msg);
      }
      onLoadingUpdated(false);
    }).catch(err => {
      onErrorUpdated(err);
      onLoadingUpdated(false);
    })
  }

  useEffect(() => {
    retrieveDataSources()
  }, [retrieveDataSources])

  useEffect(() => {
    if (isComplete === false) {
      history.push('/auth/onboarding');
    }
  }, [isComplete, history])


  return (
    <section className="profile">
      <div className="profile-wrap">
        <ProfileDataSource
          company={company} onSubmitCompanyCreation={onCreateCompany}
          onEnableDataSource={enableDataSource}
          onDisableDataSource={disableDataSource}
          isLoading={isLoading}
          error={error}
          credentials={credentials}
          onCredentialsUpdated={onCredentialsUpdated}
          dataSources={dataSources || {}} />
      </div>
    </section>
  );
}

const mapStateToProps = state => ({
  profile: state.auth.profile,
  fetchingProfile: state.auth.fetchingProfile,
  dataSources: state.admin.dataSources,
  isLoading: state.admin.isLoading,
  error: state.admin.error,
  credentials: state.admin.credentials,
})

const mapDispatchToProps = dispatch => ({
  onDataSourceUpdated: data => dispatch(setDataSources(data)),
  onLoadingUpdated: isLoading => dispatch(setIsLoading(isLoading)),
  onCredentialsUpdated: credentials => dispatch(setCredentials(credentials)),
  fetchProfileRequest: () => dispatch(fetchProfileRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataSources);
