import { API, Auth } from 'aws-amplify';


export default class RestAPI {
  static apiName = 'ethereal';
  /**
   * Return Authorized header
   * @returns {Promise}
   */
  static async __getAuthHeader() {
    try {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      return {
        Authorization: `Bearer ${token}`,
      }
    } catch (e) {
      console.error(e);
      if (window.location.pathname !== '/auth/login') {
        window.location.href = `/auth/login?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;
      }
    }
  }

  /**
   * Method to send GET request with authentication header
   * @param {String} endpoint 
   * @returns {Promise}
   */
  static async sendGetRequest(endpoint: String, headers: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        ...headers,
      },
    };

    return API.get(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPostRequestWithoutAuthentication(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.post(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPostRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.post(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPutRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.put(this.apiName, endpoint, myInit);
  }

  /**
   * Send POST request with authentication header
   * @param {String} endpoint 
   * @param {Object} payload 
   */
  static async sendPatchRequest(endpoint: String, payload: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: payload,
      response: false,
    };

    return API.patch(this.apiName, endpoint, myInit);
  }

  static async sendDelRequest(endpoint: String, params: Object = {}): Promise {
    const myInit = {
      headers: {
        ...await RestAPI.__getAuthHeader(),
        'Content-Type': 'application/json',
      },
      body: params
    };

    return API.del(this.apiName, endpoint, myInit);
  }

  /**
   * Get onboarding status for a company. The response contains company info
   * @returns {Promise}
   */
  static async getOnBoardingStatus() {
    return RestAPI.sendGetRequest('/on-boarding');
  }

  /**
   * Save profile/onboarding status
   * @param {Object} params 
   * @returns 
   */
  static async saveOnBoarding(params) {
    return RestAPI.sendPostRequest(`/on-boarding`, params);
  }

  /**
   * Get database credentials
   * @returns {Promise}
   */
  static async getCredentials() {
    return RestAPI.sendGetRequest('/credentials');
  }

  static async getCheckoutURL() {
    return RestAPI.sendGetRequest('/upgrade');
  }

  static async getTransactions() {
    return RestAPI.sendGetRequest('/transactions');
  }

  /**
   * Create a company. This will make the user as owner.
   * @param {String} companyName 
   * @param {String} address 
   * @param {String} domain 
   * @returns {Promise}
   */
  static async createCompany(companyName, address = null, domain = null) {
    return RestAPI.sendPostRequest(
      '/on-boarding/company',
      {
        company_name: companyName,
        address,
        domain
      }
    )
  }

  /**
   * Get all the data sources including subscription status
   * @returns {Promise}
   */
  static async getDataSources() {
    return RestAPI.sendGetRequest(`/datasources`);
  }

  /**
   * Subscribe a data source for the company user belongs to.
   * @param {String} source 
   * @param {Object} params 
   * @returns {Promise}
   */
  static async subscribeDataSource(source, params) {
    return RestAPI.sendPostRequest(`/subscribe/${source}`, params);
  }

  /**
   * Unsubscribe a data source for the company
   * @param {String} source 
   * @returns {Promise}
   */
  static async unsubscribeDataSource(source, reason = '') {
    return RestAPI.sendDelRequest(`/subscribe/${source}`, { reason });
  }

  /**
   * Subscribe a data source for a given company user.
   * @param {String} userId 
   * @param {String} groupName
   * @returns {Promise}
   */
  static async enableUserSubscription(userId, groupName) {
    return RestAPI.sendPostRequest(`/users/${userId}/subscribe/${groupName}`);
  }

  /**
   * Unsubscribe a data source for a given company user.
   * @param {String} userId 
   * @param {String} groupName
   * @returns {Promise}
   */
  static async disableUserSubscription(userId, groupName) {
    return RestAPI.sendDelRequest(`/users/${userId}/subscribe/${groupName}`);
  }

  /**
   * Get dashboard URL
   * @param {String} dashboardId 
   * @returns {Promise}
   */
  static async getDashboardUrl(dashboardId) {
    return RestAPI.sendGetRequest(`/qs/dashboards/${dashboardId}`);
  }

  /**
   * Get session embedding URL.
   * @returns {Promise}
   */
  static async getSessionEmbedUrl({ analysisId }) {
    const path = (analysisId !== undefined)
      ? `/qs/session-embed-url?analysis_id=${analysisId}`
      : '/qs/session-embed-url';

    return RestAPI.sendGetRequest(path)
  }

  /**
   * Create a new analysis with a given dataset and get session embedding URL.
   * @returns {Promise}
   */
  static async createAnalysis(dataSetId: String, name: String) {
    return RestAPI.sendPostRequest(`/qs/datasets/${dataSetId}/analyses`, { name });
  }

  /**
   * Invite a user to the company
   * @param {String} email 
   * @param {String} role 
   * @param {String} qsRole 
   * @param {String} name 
   */
  static async inviteUser(
    email: String, role: String, qsRole: String, name: String = '', existing: Boolean = false
  ) {
    return RestAPI.sendPostRequest(
      '/users',
      {
        email, role,
        qs_role: qsRole,
        full_name: name, existing
      }
    );
  }

  /**
   * Send email contact
   * @param {String} email 
   * @param {String} fullName 
   * @param {String} companyName 
   * @param {String} role 
   * @param {String} referer 
   */
  static async contactUs(email: String, fullName: String, companyName: String, role: String, referer: String) {
    return RestAPI.sendPostRequestWithoutAuthentication(
      '/contact-us',
      {
        email, role, referer,
        full_name: fullName,
        company_name: companyName
      }
    );
  }

  /**
   * Create a new department
   * @returns {Promise}
   */
  static async createDepartment(name: String, userIds: Array<String>) {
    return RestAPI.sendPostRequest(`/departments`, { name, user_ids: userIds });
  }

  /**
   * Delete a department
   * @param {String} departmentId 
   * @returns Promise
   */
  static async deleteDepartment(departmentId: String) {
    return RestAPI.sendDelRequest(`/departments/${departmentId}`);
  }

  /**
   * Get departments.
   * @returns {Promise}
   */
  static async fetchDepartments() {
    return RestAPI.sendGetRequest('/departments');
  }

  /**
   * Update department members.
   * @returns {Promise}
   */
  static async updateDepartmentMembers(departmentId: String, userIds: Array<String>) {
    return RestAPI.sendPutRequest(`/departments/${departmentId}`, { user_ids: userIds });
  }

  /**
   * Get company users.
   * @returns {Promise}
   */
  static async getCompanyUsers() {
    return RestAPI.sendGetRequest('/users');
  }

  /**
   * Enable a company user
   * * @param {String} userId
   * @returns {Promise}
   */
  static async enableCompanyUser(userId) {
    return RestAPI.sendPostRequest(`/members/${userId}`);
  }

  /**
   * disable a company user
   * @param {String} userId
   * @returns {Promise}
   */
  static async disableCompanyUser(userId) {
    return RestAPI.sendDelRequest(`/members/${userId}`);
  }

  /**
   * Update a company user's role
   * @param {String} userId
   * @param {String} role
   * @returns {Promise}
   */
  static async updateCompanyUserRole(userId: String, role: String, qsRole: String) {
    return RestAPI.sendPutRequest(
      `/members/${userId}`,
      {
        role,
        qs_role: qsRole.toUpperCase(),
      });
  }

  /**
   * Reset Company user's password
   * @param {String} userId 
   * @returns 
   */
  static async resetCompanyUserPassword(userId) {
    return RestAPI.sendPostRequest(`/members/${userId}/reset`);
  }

  /**
   * Reset Company user's password
   * @param {String} userId 
   * @returns 
   */
  static async reinviteCompanyUserPassword(userId) {
    return RestAPI.sendPostRequest(`/members/${userId}/reinvite`);
  }

  /**
   * Reset Company user's password
   * @returns {Promise}
   */
  static async getAvailableOptions(queryType: String) {
    return RestAPI.sendPostRequest(`/products/filter-options?query_type=${queryType}`);
  }

  /**
   * Filter products
   * @param {Object} options 
   * @returns {Promise}
   */
  static async filterProducts(options: Object, queryType: String = 'time_series') {
    return RestAPI.sendPostRequest(`/products/search?query_type=${queryType}`, options);
  }

  /**
   * Get cart items for the given user.
   * @returns {Promise}
   */
  static async getCartItems(queryType: String) {
    return RestAPI.sendGetRequest(`/cart?query_type=${queryType}`);
  }

  /**
   * Add a product to cart
   * @param {String} productId 
   * @returns {Promise}
   */
  static async addToCart(productId: String, queryType: String = 'time_series') {
    return RestAPI.sendPostRequest(`/cart?query_type=${queryType}`, { product_id: productId });
  }

  /**
   * Remove a product from cart
   * @param {String} productId 
   * @returns {Promise}
   */
  static async removeFromCart(productId: String, queryType: String = 'time_series') {
    return RestAPI.sendDelRequest(`/cart?query_type=${queryType}`, { product_id: productId });
  }

  /**
   * Checkout the cart
   * @param {String} name 
   * @returns {Promise}
   */
  static async checkout(
    name, indexes, members, timeFrame: String = '1 year',
    queryType: String = 'time_series',
    isHistorical: Boolean = false,
    relativeDays: Array<String> = [],
    snapshotDay: String = null,
    limit: Number = 25,
  ) {
    return RestAPI.sendPostRequest(
      `/cart/checkout?query_type=${queryType}`,
      {
        name, indexes, members, time_frame: timeFrame, limit,
        is_historical: isHistorical,
        relative_days: relativeDays,
        snapshot_day: snapshotDay,
      }
    );
  }

  static async getDatasetFamilyOptions(nativeIds: Array<String>) {
    return RestAPI.sendPostRequest(`/families/options`, { native_ids: nativeIds });
  }

  static async previewDataSetQuery(
    members, spreads, custom, timeFrame: String = '1 year',
    queryType: String = 'time_series',
    isHistorical: Boolean = false,
    relativeDays: Array<String> = [],
    snapshotDay: String = null,
    limit: Number = 25,
  ) {
    return RestAPI.sendPostRequest(
      `/cart/query?query_type=${queryType}`,
      {
        members, spreads, custom, time_frame: timeFrame,
        is_historical: isHistorical,
        relative_days: relativeDays,
        snapshot_day: snapshotDay,
        limit,
      }
    );
  }

  static async encrypt(value: String) {
    return RestAPI.sendPostRequest(`/admin/schema/encrypt`, { value });
  }

  static async decrypt(value: String) {
    return RestAPI.sendPostRequest(`/admin/schema/decrypt`, { value });
  }

  static async encryptNativeID(value: String) {
    return RestAPI.sendPostRequest(`/admin/code/encrypt`, { code: value });
  }

  static async decryptNativeID(value: String) {
    return RestAPI.sendPostRequest(`/admin/code/decrypt`, { native_id: value });
  }

  static async filterDatasets({
    assetClasses, sectors, sources, dataTypes,
    riskCategories, classifications, query,
  }) {
    return RestAPI.sendPostRequest(`/datasets`, {
      query,
      asset_class: assetClasses,
      sector: sectors,
      publisher: sources,
      data_type: dataTypes,
      data_category: riskCategories,
      publisher_classification: classifications
    });
  }

  static async renameDataSet(dataSetId: String, newName: String) {
    return RestAPI.sendPatchRequest(`/datasets/${dataSetId}`, { new_name: newName });
  }

  static async retrieveDataSet(dataSetId: String) {
    return RestAPI.sendGetRequest(`/datasets/${dataSetId}`);
  }

  /**
   * Checkout the cart
   * @param {String} name 
   * @returns {Promise}
   */
  static async updateDataset(
    datasetId: String, name: String, members, spreads, custom,
    queryType: String = 'time_series',
    timeFrame: String = '1 year',
    isHistorical: Boolean = false,
    relativeDays: Array<Number> = [],
    snapshotDay: String = null,
    limit: Number = 25,
  ) {
    return RestAPI.sendPutRequest(
      `/datasets/${datasetId}`,
      {
        name, members, spreads, custom, limit,
        query_type: queryType,
        time_frame: timeFrame,
        is_historical: isHistorical,
        relative_days: relativeDays,
        snapshot_day: snapshotDay,
      }
    );
  }

  static async deleteDataSet(dataSetId: String) {
    return RestAPI.sendDelRequest(`/datasets/${dataSetId}`);
  }

  static async addItemToDataset(datasetId, productId) {
    return RestAPI.sendPostRequest(`/datasets/${datasetId}/items/${productId}`);
  }

  static async removeItemFromDataset(datasetId, productId) {
    return RestAPI.sendDelRequest(`/datasets/${datasetId}/items/${productId}`);
  }

  static async fetchWhiteList() {
    return RestAPI.sendGetRequest(`/admin/whitelist`);
  }

  static async addIpAddress(ipAddress: String, description: String = '') {
    return RestAPI.sendPostRequest(
      `/admin/whitelist`,
      {
        ip_address: ipAddress,
        description,
      }
    );
  }

  static async removeIpAddress(ipAddress: String) {
    return RestAPI.sendDelRequest(`/admin/whitelist`, { ip_address: ipAddress });
  }

  static async fetchMarketData(legs: Array<Object>) {
    return RestAPI.sendPostRequest(`/options/scenario`, { legs });
  }

  /**
   * Get Options Analysis
   * @param {Array<Object>} legs 
   * @param {String} startDate 
   * @param {String} endDate 
   * @param {Object} flatPrice 
   * @param {Object} volatility 
   * @returns {Promise}
   */
  static async fetchScenarioAnalysis(
    legs: Array<Object>, startDate: String, endDate: String,
    flatPrice: Object, volatility: Object,
  ) {
    return RestAPI.sendPostRequest(
      `/analysis/scenario`,
      {
        legs,
        start_date: startDate,
        end_date: endDate,
        flat_price: flatPrice,
        volatility,
      }
    );
  }

  static async fetchOptionContracts(subpublisher: String, nativeID: String) {
    return RestAPI.sendGetRequest(`/options/contracts?native_id=${nativeID}&subpublisher=${subpublisher}`);
  }

  static async fetchOptionQuickFacts(nativeID: String, contract: String) {
    return RestAPI.sendGetRequest(`/options/quick_facts?native_id=${nativeID}&contract=${contract}`);
  }

  /**
   * Get options explorer table content
   * @param {String} requestId 
   * @param {String} mode 
   * @returns {Promise}
   */
  static async fetchOptionsExplorer(requestId: String, mode: String) {
    return RestAPI.sendGetRequest(`/analysis/explorer/${requestId}/${mode}`);
  }

  /**
   * Fetch heatmap data from individual_option_scenarios.json file
   * @param {String} requestId 
   * @returns 
   */
  static async fetchOptionsRecommenderScenario(requestId: String) {
    return RestAPI.sendGetRequest(`/analysis/scenario/${requestId}`, {
      'Accept-Encoding': 'gzip',
      'Accept': 'application/json',
    });
  }

  static async getMonteCarloHistoricalData(
    subpublisher: String, nativeId: String, contract: String,
    viewLength: Number, profit: Number, loss: Number, paths: Number
  ) {
    return RestAPI.sendPostRequest(
      `/options/historical/mc`,
      {
        'subpublisher': subpublisher,
        'native_id': nativeId,
        'contract': contract,
        'view_length': viewLength,
        'take_profit': profit,
        'stop_loss': loss,
        'num_paths': paths
      }
    );
  }

  /**
   * Fetch all the OptionsRecommender Logs requested by the current user.
   * @returns {Promise}
   */
  static async fetchOptionsRecommenderLogs() {
    return RestAPI.sendGetRequest(`/options/recommender`);
  }

  /**
   * Create a Stripe Checkout Session and return the URL to complete payment.
   * @param {Array<String>} commodity 
   * @param {String} tradeDate 
   * @param {String} contract 
   * @param {Number} takeProfit 
   * @param {Number} stopLoss 
   * @param {String} timeHorizon 
   * @param {Number} viewLength 
   * @returns {Promise}
   */
  static async getOptionsRecommenderReportCheckoutUrl(
    commodity: Array<String>, tradeDate: String, contract: String,
    takeProfit: Number, stopLoss: Number,
    timeHorizon: String, viewLength: Number,
    steepness: Number, low: Number, high: Number,
  ) {
    return RestAPI.sendPostRequest(
      `/options/recommender/checkout`,
      {
        commodity: commodity,
        trade_date: tradeDate,
        contract: contract,
        take_profit: takeProfit,
        stop_loss: stopLoss,
        time_horizon: timeHorizon,
        view_length: viewLength,
        volatility: { steepness, low, high }
      }
    );
  }

  static async requestOptionsRecommender(requestID: String) {
    return RestAPI.sendPostRequest(`/options/recommender/${requestID}`);
  }

  /**
   * Get heatmap for the given PDF scenario
   * @param {String} requestId: UUID
   * @param {String} left: example: 1_80_c
   * @param {String} right: example: -2_81_c
   * @returns Heatmap data
   */
  static async fetchPdfScenario(requestId: String, left: String, right: String) {
    return RestAPI.sendGetRequest(`/analysis/scenario/${requestId}/${left}/${right}`);
  }

  static async fetchProductTimeSeries(
    subpublisher: String, nativeID: String, contract: String,
  ) {
    return RestAPI.sendGetRequest(
      `/mkt/contract/series?native_id=${nativeID}&subpublisher=${subpublisher}&contract=${contract}`);
  }

  static async fetchContractVolatilityScatter(
    subpublisher: String, nativeID: String, contract: String,
  ) {
    return RestAPI.sendGetRequest(
      `/mkt/contract/volitility?interval="2 YEAR"&native_id=${nativeID}&subpublisher=${subpublisher}&contract=${contract}`);
  }
}
