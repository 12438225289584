import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Table,
  Form,
  FormGroup,
  InputGroup,
  Input,
} from "reactstrap";
import AttributeSelect from '../builder/AttributeSelect';
import ToggleSelect from '../builder/ToggleSelect';
import { analysisContractOptions, analysisProductOptions } from 'helpers/constants';

const PortfolioSection = ({
  title, legs, portfolioName,
  onNewLeg, onChangeLeg, onRemoveLeg,
  onChangePortfolioName, onSavePortfolio,
  fetching,
}) => {
  const directionOpt = ['buy', 'sell'];
  const typeOpt = ['call', 'put'];
  const sortByOpt = ['product', 'contract', 'type'];

  const [sortBy, setSortBy] = useState('product');
  // const [stateUpdate, setStateUpdate] = useState(true);

  return (
  <Row className='scenario-item-wrap ml-0 mr-0 datamember portfolio-wrap'>
    <Col xl="12">
      <h2 className='pl-2 pt-2'>{title}</h2>
    </Col>
    <Col xl="6" className="flex flex-start">
      <span className='ml-3'>sort by&nbsp;</span>
      <AttributeSelect 
        value={sortBy}
        size={'size-min'}
        options={sortByOpt.map(item => ({name: item, value: item}))}
        onChange={setSortBy} />
    </Col>
    <Col xl="6">
      <Form role="form" className="flex flex-end">
        <FormGroup className="mb-0 mr-1">
          <InputGroup className="input-group-merge input-group-alternative">
            <Input
              type="text" placeholder='portfolio name'
              value={portfolioName}
              onChange={(e) => {onChangePortfolioName(e.target.value)}}
            />
          </InputGroup>
        </FormGroup>
        <Button color="info" onClick={onSavePortfolio} size="sm">
          save
        </Button>
      </Form>
    </Col>
    <Col xl="12" className="mt-3 pt-3 border--t pl-0 pr-0">
      <Table className="align-items-center portfolio-table" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">product</th>
            <th scope="col">contract</th>
            <th scope="col">DIRECTION</th>
            <th scope="col">WEIGHT</th>
            <th scope="col">STRIKE</th>
            <th scope="col">TYPE</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {legs.map((item, idx) => 
            <tr key={idx}>
              <td className='align-left'>
                <AttributeSelect 
                  value={item.product}
                  disabled={fetching}
                  size={'size-min'}
                  options={analysisProductOptions}
                  onChange={onChangeLeg('product', idx)} />
              </td>
              <td>
                <AttributeSelect 
                  value={item.contract}
                  size={'size-min'}
                  disabled={fetching}
                  options={analysisContractOptions.map(item => ({name: item, value: item}))}
                  onChange={onChangeLeg('contract', idx)} />
              </td>
              <td className='align-center'>
                <ToggleSelect 
                  value={item.direction}
                  disabled={fetching}
                  options={directionOpt}
                  onChange={onChangeLeg('direction', idx)} />
              </td>
              <td>
                <FormGroup className="mb-0 mr-1">
                  <InputGroup className="input-group-merge input-group-alternative max-width-70">
                    <Input
                      type="number" placeholder='weight'
                      value={item.weight}
                      className="hide-arrow"
                      onChange={(e) => {onChangeLeg('weight', idx)(parseFloat(e.target.value))}} />
                  </InputGroup>
                </FormGroup>
              </td>
              <td>
                <FormGroup className="mb-0 mr-1">
                  <InputGroup className="input-group-merge input-group-alternative max-width-70">
                    <Input
                      type="number" placeholder='strike'
                      value={item.strike}
                      className="hide-arrow"
                      onChange={e => {onChangeLeg('strike', idx)(parseFloat(e.target.value))}} />
                  </InputGroup>
                </FormGroup>
              </td>
              <td className='align-center'>
                <ToggleSelect 
                  value={item.type}
                  disabled={fetching}
                  options={typeOpt}
                  onChange={onChangeLeg('type', idx)} />
              </td>
              <td className='align-center'>
                <Button disabled={fetching} color="remove" onClick={() => onRemoveLeg(idx)} size="sm">
                  remove
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Button color="info" onClick={onNewLeg} size="sm" className="mb--15 ml--30">
        add leg
      </Button>
    </Col>
  </Row>
  )
}

PortfolioSection.propTypes = {
  title: PropTypes.string.isRequired,
  legs: PropTypes.arrayOf(PropTypes.shape({
    product: PropTypes.string.isRequired,
    contract: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['buy', 'cell']).isRequired,
    type: PropTypes.oneOf(['call', 'put']).isRequired,
    strike: PropTypes.number.isRequired,
    weight: PropTypes.number.isRequired,
  })).isRequired,
  portfolioName: PropTypes.string.isRequired,
  onNewLeg: PropTypes.func.isRequired,
  onChangeLeg: PropTypes.func.isRequired,
  onRemoveLeg: PropTypes.func.isRequired,
  onChangePortfolioName: PropTypes.func.isRequired,
  onSavePortfolio: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
}

export default PortfolioSection;
